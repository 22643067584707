import { Icon } from "@blueprintjs/core"
import { HeadlessService } from "@novu/headless"
import {
  IMessage,
  INotificationCenterStyles,
  NovuProvider,
  PopoverNotificationCenter,
} from "@novu/notification-center"
import React, { useState } from "react"

import styles from "./Notifications.module.css"

import { track } from "~/helpers/analytics"

import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"

type Props = {
  subscriberId: string | null
  subscriberHash: string | null
  appIdentifier: string | null
  backendUrl: string | null
  email: string | null
  accountId: number | null
}

type State =
  | {
      tag: "loading"
    }
  | {
      tag: "not-available"
    }
  | {
      tag: "not-enabled"
    }
  | {
      tag: "enabled"
    }

const EmptyFooter = () => <></>

const Notifications = (props: Props) => {
  const { subscriberId, appIdentifier, backendUrl, subscriberHash } = props

  const [state, setState] = React.useState<State>({ tag: "loading" })
  const [isInboxOpen, setIsInboxOpen] = useState(false)

  React.useEffect(() => {
    if (!subscriberId || !subscriberHash || !appIdentifier || !backendUrl) {
      setState({ tag: "not-available" })
    } else {
      const headlessService = new HeadlessService({
        applicationIdentifier: appIdentifier,
        subscriberId,
        subscriberHash,
        backendUrl,
      })

      headlessService.initializeSession({
        listener: () => void 0,
        onSuccess: () => {
          headlessService.fetchUserPreferences({
            onSuccess: (settings) => {
              const isInAppChannelEnabled = settings.some(
                (setting) => setting.preference.channels.in_app,
              )

              if (!isInAppChannelEnabled) {
                setState({ tag: "not-enabled" })
              } else {
                setState({ tag: "enabled" })
              }
            },
            listener: () => void 0,
            onError: (error) => {
              console.error(
                "Failed to load notifications user preferences",
                error,
              )

              // Render the bell anyway
              setState({ tag: "enabled" })
            },
          })
        },
        onError: (error) => {
          console.error("Failed to initialize session", error)
          setState({ tag: "not-available" })
        },
      })
    }
  }, [subscriberId, appIdentifier, backendUrl, subscriberHash])

  React.useEffect(() => {
    if (isInboxOpen && state.tag === "enabled") {
      track("Notification Center Opened")
    }
  }, [isInboxOpen, state.tag])

  function handleOnNotificationClick(message: IMessage) {
    const identifier = message?.templateIdentifier

    if (identifier.includes("timesheet-not-completed")) {
      track("Notification Clicked", {
        notification_type: "timesheet_incomplete",
      })
    }

    if (identifier.includes("resource-requests-updates")) {
      track("Notification Clicked", {
        notification_type: "resource_requests_updates",
      })
    }

    if (identifier.includes("user-resource-requests")) {
      track("Notification Clicked", {
        notification_type: "user_resource_requests",
      })
    }

    if (message?.payload?.url) {
      window.location.href = message.payload.url as string
    }
  }

  const customStyles = {
    loader: {
      root: {
        stroke: "var(--runn-blue)",
      },
    },
    header: {
      root: {
        ".mantine-Badge-root": {
          background: " linear-gradient(90deg, #4057df 0%, #a200ff 100%)",
        },
      },
      cog: { display: "none" },
    },
    notifications: {
      root: {
        ".nc-notifications-list-item": {
          "::before": {
            background: " linear-gradient(90deg, #4057df 0%, #a200ff 100%)",
          },
        },
        ".nc-notifications-list-item-unread": {
          ".nc-bell-button-dot rect": {
            fill: "#4057df !important",
          },
        },
        ".nc-notifications-list-item-read": {
          p: {
            color: "var(--smoke) !important",
          },
        },
      },
      listItem: {
        dotsButton: {
          path: {
            fill: "black",
          },
        },
        unread: {
          "::before": { background: "black" },
        },
      },
    },
  } as INotificationCenterStyles

  if (state.tag === "loading") {
    return null
  }

  if (state.tag === "not-available") {
    console.warn(
      "src/containers/Notifications.tsx component is missing subscriberId, appIdentifier or backendUrl. Skipping rendering.",
    )

    return null
  }

  if (state.tag === "not-enabled") {
    return null
  }

  const handleBellClick = () => {
    setIsInboxOpen(!isInboxOpen)
  }

  return (
    <NovuProvider
      subscriberId={subscriberId}
      applicationIdentifier={appIdentifier}
      subscriberHash={subscriberHash}
      backendUrl={backendUrl}
      styles={customStyles}
      onLoad={() => setIsInboxOpen(false)}
    >
      <PopoverNotificationCenter
        colorScheme={"light"}
        onNotificationClick={handleOnNotificationClick}
        showUserPreferences={false}
        offset={4}
        footer={EmptyFooter}
      >
        {({ unseenCount }) => (
          <div className={styles.bell} onClick={handleBellClick}>
            <Icon icon="notifications" size={20} color="var(--winter)" />
            {unseenCount > 0 && <div className={styles.unseenCountDot} />}
          </div>
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  )
}

export default withRelayPageContainerNoLoading(Notifications)
