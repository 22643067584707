import React from "react"

type Props = {
  color?: any
}

const CustomFields = (props: Props) => {
  const { color = "var(--midnight)" } = props

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 7H4.165L5.915 5.25H0.875C0.39375 5.25 0 5.64375 0 6.125C0 6.60625 0.39375 7 0.875 7ZM13.5538 3.49125C13.825 3.21125 14 2.82625 14 2.40625C14 1.5575 13.3175 0.875 12.4688 0.875C12.0488 0.875 11.6637 1.05 11.3837 1.32125L10.1237 2.58125L12.285 4.7425L13.5538 3.49125ZM0.875 3.5H7.665L9.415 1.75H0.875C0.39375 1.75 0 2.14375 0 2.625C0 3.10625 0.39375 3.5 0.875 3.5ZM0.875 8.75C0.39375 8.75 0 9.14375 0 9.625C0 10.045 0.30625 10.3775 0.7 10.465L2.415 8.75H0.875ZM9.58125 3.12375L3.7275 8.9775L5.88875 11.1388L11.7425 5.285L9.58125 3.12375ZM13.3 5.285L11.585 7H13.125C13.6063 7 14 6.60625 14 6.125C14 5.705 13.6938 5.3725 13.3 5.285ZM1.75 13.125L5.1275 11.9088L2.975 9.77375L1.75 13.125ZM13.125 8.75H9.835L8.085 10.5H13.125C13.6062 10.5 14 10.1063 14 9.625C14 9.14375 13.6062 8.75 13.125 8.75Z"
        fill={color}
      />
    </svg>
  )
}

export default CustomFields
