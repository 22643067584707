import React from "react"

const CircleCrossIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="5" r="4.5" stroke="white" />
    <path d="M2 2L8 8" stroke="white" />
  </svg>
)

export default CircleCrossIcon
