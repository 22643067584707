import cc from "classcat"
import React from "react"

import styles from "./PaidFeatureMenuItemContainer.module.css"

import { useIsInFreePlan, useIsInTrial } from "~/Entitlements/useEntitlements"
import { usePermissions } from "~/Permissions/usePermissions"

import Button from "./buttons/Button"

type PaidFeatureMenuItemContainerProps = {
  children?: React.ReactNode
  customStyles?: Record<string, any>
  roundedButton?: boolean
  hideButton?: boolean
  title?: string
  subtitle?: string
  noGap?: boolean
}

const PaidFeatureMenuItemContainer = ({
  children,
  customStyles,
  roundedButton,
  hideButton,
  title = "Paid plan features",
  subtitle,
  noGap,
}: PaidFeatureMenuItemContainerProps) => {
  const { can, subject } = usePermissions()
  const isInTrial = useIsInTrial()
  const isInFreePlan = useIsInFreePlan()

  if (!isInTrial && !isInFreePlan) {
    return null
  }
  const accountSubject = subject("Account")
  const canEditAccount = can("edit", accountSubject)
  const showSelectPlanButton = isInFreePlan && canEditAccount && !hideButton

  const onSelectPlan = () => {
    window.location.href = "/account/billing"
  }

  return (
    <div
      className={cc([
        styles.container,
        {
          [styles.noGap]: noGap,
        },
      ])}
      style={customStyles}
      data-test="paid-feature-container"
    >
      <span className={styles.title}>{title}</span>
      {!!subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      {children}
      {showSelectPlanButton && (
        <Button
          className={`${styles.selectPlanButton} ${roundedButton ? styles.roundButton : ""}`}
          onClick={onSelectPlan}
        >
          Select a Plan
        </Button>
      )}
    </div>
  )
}

export default PaidFeatureMenuItemContainer
