import React from "react"

const NewContract = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00001 1.99999H12.3333C13.0733 1.99999 13.6667 2.59332 13.6667 3.33332V9.99999C13.6667 10.74 13.0733 11.3333 12.3333 11.3333H1.66668C0.926677 11.3333 0.333344 10.74 0.333344 9.99999L0.34001 1.99999C0.34001 1.25999 0.926677 0.666656 1.66668 0.666656H5.66668L7.00001 1.99999ZM9.66668 7.33332H11.6667V5.99999H9.66668V3.99999H8.33334V5.99999H6.33334V7.33332H8.33334V9.33332H9.66668V7.33332Z"
      fill="#3C4260"
    />
  </svg>
)

export default NewContract
