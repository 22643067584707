import {
  AnchorButton,
  AnchorButtonProps,
  Button as BlueprintButton,
  ButtonProps as IButtonProps,
} from "@blueprintjs/core"
import { Intent } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./Button.module.css"

export type ButtonProps = {
  id?: string
  href?: string
  stretch?: boolean
  style?: React.CSSProperties
  tabIndex?: number
  autoFocus?: boolean
  children?: React.ReactNode
  target?: string
  active?: boolean
  outlined?: boolean
  forceAnchorButton?: boolean // to handle disabled button tooltips https://github.com/palantir/blueprint/issues/1591
} & (IButtonProps | AnchorButtonProps)

// This component uses the Blueprint Button as a basis.
// https://blueprintjs.com/docs/#core/components/button
// We use it so we can adjust the styles here
const Button = (props: ButtonProps) => {
  const {
    children,
    href,
    outlined = true,
    className,
    stretch,
    style,
    forceAnchorButton,
    disabled,
    ...rest
  } = props

  const restProps = {
    ...rest,
    intent: rest.intent || ("primary" as Intent),
  }

  const styleObject =
    props.icon && props.text
      ? { paddingLeft: "8px", paddingRight: "12px", ...style }
      : style

  if (href || forceAnchorButton) {
    return (
      <AnchorButton
        href={href}
        outlined={props.active ? false : outlined}
        className={cc([
          className,
          {
            [styles.stretch]: stretch,
          },
        ])}
        style={styleObject}
        target={props.target}
        disabled={disabled}
        {...(restProps as AnchorButtonProps)}
      >
        {children}
      </AnchorButton>
    )
  }

  return (
    <BlueprintButton
      outlined={props.active ? false : outlined}
      className={cc([
        className,
        {
          [styles.stretch]: stretch,
          "bp5-intent-none": restProps.intent === "none",
        },
      ])}
      style={styleObject}
      disabled={disabled}
      {...(restProps as IButtonProps)}
    >
      {children}
    </BlueprintButton>
  )
}

export default Button
