type ArrayOneOrMore<T> = { 0: T } & Array<T>

type Encoder = (...ids: ArrayOneOrMore<number>) => string
type Decoder = (relayId: string) => ArrayOneOrMore<number>

type RelayidInstance = {
  encode: Encoder
  decode: Decoder
}

const createInstance = (instanceTypename: string): RelayidInstance => {
  const encode: Encoder = (...ids) => {
    if (ids.length < 1) {
      throw new Error("Failed to encode empty list of IDs.")
    }

    const uniqueString = `${instanceTypename}-${ids.join("-")}`
    const base64Encoded = window.btoa(uniqueString)
    return base64Encoded
  }

  const decode: Decoder = (relayId) => {
    const [typename, ...stringIds] = window.atob(relayId).split("-")
    if (instanceTypename !== typename) {
      throw new Error(
        `Failed to decode "${instanceTypename}" Relay ID: "${relayId}", has type "${typename}".`,
      )
    }

    return stringIds.map((stringId) => {
      const numId = Number.parseInt(stringId, 10)
      if (Number.isNaN(numId)) {
        throw new Error(
          `Failed to decode "${instanceTypename}" Relay ID: "${relayId}", encountered NaN.`,
        )
      }
      return numId
    }) as ArrayOneOrMore<number>
  }

  return {
    encode,
    decode,
  }
}

export const accounts = createInstance("Account")
export const actuals = createInstance("Actual")
export const assignments = createInstance("Assignment")
export const clients = createInstance("Client")
export const competencies = createInstance("Competency")
export const contracts = createInstance("Contract")
export const currentAccountUser = createInstance("CurrentAccountUser")
export const customTextTypes = createInstance("CustomTextTypes")
export const customTextValues = createInstance("CustomTextValues")
export const customDateTypes = createInstance("CustomDateTypes")
export const customDateValues = createInstance("CustomDateValues")
export const customSelectTypes = createInstance("CustomSelectTypes")
export const customSelectOptions = createInstance("CustomSelectOptions")
export const customSelectValues = createInstance("CustomSelectValues")
export const customCheckboxTypes = createInstance("CustomCheckboxTypes")
export const customCheckboxValues = createInstance("CustomCheckboxValues")
export const extPerson = createInstance("ExtPerson")
export const extPersonLink = createInstance("ExtPersonLink")
export const extProject = createInstance("ExtProject")
export const extProjectLink = createInstance("ExtProjectLink")
export const extProjectMember = createInstance("ExtProjectMember")
export const extProjectMemberLink = createInstance("ExtProjectMemberLink")
export const extTimeOff = createInstance("ExtTimeOff")
export const extTimeOffLink = createInstance("ExtTimeOffLink")
export const field_definitions = createInstance("FieldDefinition")
export const helpDocuments = createInstance("HelpDocument")
export const invitations = createInstance("Invitation")
export const integrations = createInstance("Integration")
export const integrationServices = createInstance("IntegrationService")
export const milestones = createInstance("Milestone")
export const otherCosts = createInstance("OtherCost")
export const notes = createInstance("Notes")
export const people = createInstance("Person")
export const personRequests = createInstance("PersonRequest")
export const phases = createInstance("Phase")
export const projectMembers = createInstance("ProjectMember")
export const projectRates = createInstance("ProjectRate")
export const projectRoles = createInstance("ProjectRole")
export const projectManagers = createInstance("ProjectManager")
export const projects = createInstance("Project")
export const rateCards = createInstance("RateCard")
export const reports = createInstance("Report")
export const roleChargeOutRates = createInstance("RoleChargeOutRate")
export const roles = createInstance("Role")
export const savedReports = createInstance("SavedReport")
export const skills = createInstance("Skill")
export const tags = createInstance("Tag")
export const teams = createInstance("Team")
export const timeOffs = createInstance("TimeOff")
export const users = createInstance("User")
export const user_accounts = createInstance("UserAccount")
export const userFilterSets = createInstance("UserFilterSet")
export const views = createInstance("View")
export const features = createInstance("Features")
export const featureAccounts = createInstance("FeaturesAccounts")
export const holidays = createInstance("Holiday")
export const holidaysGroup = createInstance("HolidaysGroup")
export const peopleNotes = createInstance("PeopleNotes")
export const apiTokens = createInstance("ApiTokens")
export const workstreams = createInstance("Workstream")
export const loggedActions = createInstance("LoggedActions")
