/**
 * @generated SignedSource<<ea4179a5e33fab7051573a4993cd6a92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DeleteAccountFormQuery$variables = Record<PropertyKey, never>;
export type DeleteAccountFormQuery$data = {
  readonly user_accounts: ReadonlyArray<{
    readonly account: {
      readonly account_type: string;
      readonly alternative_account_id: number | null | undefined;
      readonly id: number;
      readonly name: string;
    };
    readonly id: number;
  }>;
};
export type DeleteAccountFormQuery = {
  response: DeleteAccountFormQuery$data;
  variables: DeleteAccountFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "user_accounts",
    "kind": "LinkedField",
    "name": "user_accounts",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "account_type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "alternative_account_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAccountFormQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeleteAccountFormQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a733f677eaa8fc112f1c4b711b5beff",
    "id": null,
    "metadata": {},
    "name": "DeleteAccountFormQuery",
    "operationKind": "query",
    "text": "query DeleteAccountFormQuery {\n  user_accounts {\n    id\n    account {\n      id\n      name\n      account_type\n      alternative_account_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5dd13fefd6ad943d3b26dbbb53d6c4f";

export default node;
