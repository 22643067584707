/**
 * @generated SignedSource<<3b1d165aacac8a29cec590e4626e2a5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavigationContainerQuery$variables = Record<PropertyKey, never>;
export type NavigationContainerQuery$data = {
  readonly billing: {
    readonly " $fragmentSpreads": FragmentRefs<"EntitlementsProvider_billing">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ServerFlagsProvider_gql">;
};
export type NavigationContainerQuery = {
  response: NavigationContainerQuery$data;
  variables: NavigationContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "syncSubscription",
    "value": false
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EntitlementsProvider_billing"
          }
        ],
        "storageKey": "billing(syncSubscription:false)"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ServerFlagsProvider_gql"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavigationContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInTrial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSubscriptionItem",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemPriceId",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscriptionEntitlement",
            "kind": "LinkedField",
            "name": "entitlements",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featureId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featureType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "billing(syncSubscription:false)"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features",
        "kind": "LinkedField",
        "name": "features",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features_accounts",
        "kind": "LinkedField",
        "name": "features_accounts",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feature_id",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37a0ab5727262645857daba1091f04e8",
    "id": null,
    "metadata": {},
    "name": "NavigationContainerQuery",
    "operationKind": "query",
    "text": "query NavigationContainerQuery {\n  billing(syncSubscription: false) {\n    ...EntitlementsProvider_billing\n  }\n  ...ServerFlagsProvider_gql\n}\n\nfragment EntitlementsProvider_billing on Billing {\n  subscription {\n    id\n    isInTrial\n    plan {\n      itemPriceId\n      id\n    }\n  }\n  entitlements {\n    featureId\n    featureType\n    value\n  }\n}\n\nfragment ServerFlagsProvider_gql on query_root {\n  features {\n    id\n    enabled\n    description\n    name\n  }\n  features_accounts {\n    id\n    feature_id\n    enabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0dd5602c420b386efbbb6354523f737";

export default node;
