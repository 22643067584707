import React from "react"

import styles from "./CancelReasonsForm.module.css"

import Input from "~/common/Input"
import Checkbox from "~/common/inputs/Checkbox"

import { CANCEL_REASONS } from "~/GLOBALVARS"

type Reason = {
  idx: number
  reason: string
  explanation: string
}

type Props = {
  selectedReasons: Reason[]
  setSelectedReasons: (reasons: Reason[]) => void
  triggerType: "Delete Account" | "Cancel Subscription"
}

export const formattedReasons = (
  account: { id: number; name: string },
  subscription: {
    id?: string
    status?: string
    mrr?: number
  },
  reasons: Reason[],
) => {
  // This formats reasons for cancelReasonsCreateRelay
  const formatted = reasons.map((r) => {
    return {
      account_name: account.name,
      reason: r.reason,
      explanation: r.explanation,
      paid_subscription:
        subscription?.status === "active" &&
        (subscription?.mrr ? subscription.mrr > 0 : false),
      subscription_id: subscription?.id,
    }
  })
  return formatted
}

const CancelReasonsForm = (props: Props) => {
  const { selectedReasons, setSelectedReasons, triggerType } = props

  const onUpdateExplanation = (explanation, idx) => {
    const updatedReasons = selectedReasons.map((r) => {
      return { ...r, explanation: r.idx === idx ? explanation : r.explanation }
    })

    setSelectedReasons(updatedReasons)
  }

  const onCheck = (selectedReason, idx) => {
    const alreadySelected = selectedReasons.some((r) => r.idx === idx)

    if (alreadySelected) {
      // remove from list
      const filteredReasons = selectedReasons.filter((r) => r.idx !== idx)
      setSelectedReasons(filteredReasons)
    } else {
      // add to list
      setSelectedReasons([
        ...selectedReasons,
        {
          idx,
          reason: selectedReason,
          explanation: "",
        },
      ])
    }
  }

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        We’re really sorry to see you go! Could you share the reason why you’re
        {triggerType === "Delete Account"
          ? " closing your account"
          : " cancelling Runn"}
        ?
      </p>
      {CANCEL_REASONS.map((r, idx) => {
        const selected = selectedReasons.find((reason) => reason.idx === idx)
        const showExplanationInput = selected && r.placeholder

        return (
          <div className={styles.reason} key={idx}>
            <Checkbox
              id={`CancelReasonsForm_Checkbox_reason_${idx}`}
              checked={Boolean(selected)}
              onChange={() => onCheck(r.reason, idx)}
              label={r.reason}
            />
            {showExplanationInput && (
              <Input
                name={`explanation-${idx}`}
                placeholder={r.placeholder}
                value={selected?.explanation}
                onChange={(e) => onUpdateExplanation(e.target.value, idx)}
                style={{ margin: "10px 0" }}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default CancelReasonsForm
