import React from "react"
import {
  Environment,
  GraphQLResponse,
  LogEvent,
  Network,
  RecordSource,
  Observable as RelayObservable,
  RequestParameters,
  Store,
  Variables,
} from "relay-runtime"

import { getEnvInfo } from "../helpers/environment"
import { reportDebug, reportError } from "~/helpers/error-helpers"
import { sendLogToNodeServer } from "~/helpers/logHelpers"
import * as relayids from "~/helpers/relayids"

import { renderErrorModal } from "~/common/ErrorModal/ErrorModal"

import { showToast } from "~/containers/ToasterContainer"

import SessionStore from "./SessionStore"
import { getSubscriptionClient } from "./subscriptionClient"

export type HasuraErrorResponse = { errors: Error[]; message?: string }
export type HasuraSuccessResponse<T extends Record<string, any>> = { data: T }

const mutationErrorMessage = "Sorry, we couldn't save your changes."
const queryErrorMessage =
  "Sorry, we couldn't process your request. Please refresh the page and try again."
const allowedQueryErrorMessage =
  "Your app is outdated and couldn't be saved. Please refresh the page and try again."
export const HASURA_UNAUTHORISED_ERROR_MSG = "Session Unauthorized"

const HasuraContext = React.createContext(undefined)

export const HasuraContextProvider = HasuraContext.Provider

type Opts = {
  strict?: boolean
}
export const useHasuraContext = (options: Opts = {}) => {
  const strict = options.strict ?? false
  const context = React.useContext(HasuraContext)
  if (context === undefined) {
    if (strict) {
      throw new Error(
        "useHasuraContext must be used within a HasuraContextProvider",
      )
    }
    return null
  }
  const fragmentOnlyContext = {} as {
    " $fragmentSpreads": any
  }
  for (const [key, value] of Object.entries(context)) {
    if (key.startsWith("__")) {
      fragmentOnlyContext[key] = value
    }
  }
  return fragmentOnlyContext
}

const source = new RecordSource()
const store = new Store(source)
const sessionStore = new SessionStore()

export class HasuraError extends Error {}

const hasErrorMessage = (search: string, response: HasuraErrorResponse) => {
  if (response?.message?.includes(search)) {
    return true
  }

  if (
    response?.errors?.some((error) => {
      return error.message?.includes(search)
    })
  ) {
    return true
  }

  return false
}

export const fetchQuery = async <T = Record<string, any>>(
  operation: any,
  variables: any,
): Promise<HasuraErrorResponse | HasuraSuccessResponse<T>> => {
  const hasuraAuthToken = await sessionStore.getAuthToken()
  const hasuraEndpoint = await sessionStore.getHasuraEndpoint()
  const userId = await sessionStore.getUserId()
  const accountId = await sessionStore.getAccountId()

  if (!hasuraAuthToken) {
    return {
      errors: [
        { name: "Unauthorized", message: HASURA_UNAUTHORISED_ERROR_MSG },
      ],
    }
  }

  let response: Response
  let responseBody: string | undefined
  let json: HasuraErrorResponse
  const reqStart = performance.now()

  try {
    response = await fetch(`${hasuraEndpoint}/v1/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${hasuraAuthToken}`,
      },
      credentials: "include",
      body: JSON.stringify({
        query: operation.text,
        operationName: operation.name,
        variables,
      }),
    })

    if (!response.ok) {
      throw new Error("Hasura response is not ok")
    }

    // Parse as text so we can log the response if JSON parsing fails
    responseBody = await response.text()
    json = JSON.parse(responseBody)

    // This logs out slow requests for debugging purposes
    const reqTime = performance.now() - reqStart
    const responseSize = response?.headers.get("Content-Length")
    const responseSizeKb = responseSize ? Number(responseSize) / 1000 : 0
    const logResponseMetrics = {
      type: "Hasura Response Metrics",
      subtype: "Hasura Response Metrics - Slow Request",
      responseTimeMs: reqTime,
      responseTimeSec: reqTime / 1000,
      queryName: operation?.name,
      operationKind: operation?.operationKind,
      accountId: accountId,
      userId: userId,
      responseSize: responseSizeKb,
    }

    if (reqTime > 1000 && operation?.operationKind === "mutation") {
      void sendLogToNodeServer(logResponseMetrics)
    } else if (reqTime > 5000) {
      void sendLogToNodeServer(logResponseMetrics)
    }
  } catch (error) {
    const responseSize = response?.headers.get("Content-Length")
    const responseSizeKb = responseSize ? Number(responseSize) / 1000 : 0
    const logResponseMetrics = {
      type: "Hasura Response Metrics",
      subtype: "Hasura Response Metrics - Failure",
      queryName: operation.name,
      operationKind: operation.operationKind,
      accountId: accountId,
      userId: userId,
      responseSize: responseSizeKb,
      errorName: error.name,
      errorMessage: error.message,
      errorStack: error.stack,
    }

    void sendLogToNodeServer(logResponseMetrics)

    // Only log errors when client is online (they might be connectivity issues caused by the server)
    if (window.navigator.onLine) {
      void reportError(
        `hasura.fetchQuery: ${error?.message}`,
        {
          hasuraEndpoint,
          operation,
          variables,
          response: { status: response?.status, body: responseBody },
        },
        error,
      )
    }

    // This error is firefox cancelling the request
    if (
      !hasErrorMessage("NetworkError when attempting to fetch resource.", json)
    ) {
      showToast({
        message: "Operation failed",
        type: "error",
      })
    }

    throw new HasuraError(error.message, { cause: error })
  }

  // Allowlist failures are non-recoverable and require a page reload to fetch new JS containing updated queries.
  // Can't rely on <ErrorBoundary> here since fetches are usually
  // caused in event handlers, where React doesn't catch exceptions for error boundaries.
  if (hasErrorMessage("query is not allowed", json)) {
    if (operation.operationKind === "mutation") {
      // For mutations hitting this issue,
      // we rely on showing an error message to the user, rather than hiding the potential for
      // losing their entered data through a page reload.
      renderErrorModal({ children: allowedQueryErrorMessage })
      throw new HasuraError("Allowlist failure in mutation")
    } else {
      // Frozen tabs don't reload HTML or JS when resumed, which can lead to sending outdated queries.
      // This in turn fails query allowlists that might have been updated through a deployment
      // since last loading the JS containing them.
      await reportDebug("hasura.fetchQuery: Allowlist failure", { operation })
      // Avoid infinite reload loops
      const currentUrl = new URL(window.location.href)
      if (!currentUrl.searchParams.has("_allowlist_reloaded")) {
        currentUrl.searchParams.append("_allowlist_reloaded", "1")
        window.location.href = currentUrl.toString()
      }
      throw new HasuraError("Allowlist failure in query")
    }
  }

  if (json?.errors) {
    json.errors.forEach((error: Error) => {
      void reportError(`hasura.error in ${operation.name}: ${error.message}`, {
        hasuraEndpoint,
        operation,
        variables,
        response,
        error,
        // Useful for debugging potential clock skew issues (differences between client and server times)
        jwtExpiry: new Date(sessionStore.getSessionExpiryDate()),
        clientTime: new Date(),
      })
    })
  }

  // Potentially recoverable error handling with toast UX
  if (json?.errors) {
    let message =
      operation.operationKind === "mutation"
        ? mutationErrorMessage
        : queryErrorMessage
    if (json.message) {
      message += `\nMessage: ${json.message}`
    }
    message += `\nErrors: ${json.errors.map((e) => e.message).join(", ")}`
    showToast({ message, type: "error" })
    throw new HasuraError(message)
  }

  if ("data" in json) {
    return json as HasuraSuccessResponse<T>
  }

  return json
}

const subscribeToQuery = (
  request: RequestParameters,
  variables: Variables,
): RelayObservable<GraphQLResponse> =>
  RelayObservable.create((sink) => {
    getSubscriptionClient(sessionStore)
      .then((subscriptionClient) => {
        subscriptionClient.subscribe(
          {
            query: request.text,
            operationName: request.name,
            variables,
          },
          sink as any,
        )
      })
      .catch((error) => {
        void reportError(error)
      })
  })

const network = Network.create(fetchQuery, subscribeToQuery)

const getDataID = (data: any, typename: string) => {
  if (typename === "role_charge_out_rates") {
    if (
      typeof data.role_id !== "number" ||
      typeof data.rate_card_id !== "number"
    ) {
      console.warn(
        `WARNING: getDataID found a "role_charge_out_rates" row that is missing the "role_id" and/or the "rate_card_id" value. These fields are required for Relay to cache this record correctly.`,
      )
    }
    return relayids.roleChargeOutRates.encode(data.role_id, data.rate_card_id)
  }
  if (typename === "ext_project_member_link") {
    if (
      typeof data.ext_project_member_id !== "number" ||
      typeof data.project_member_id !== "number"
    ) {
      console.warn(
        `WARNING: getDataID found a "ext_project_member_link" row that is missing the "project_member_id" and/or the "ext_project_member_id" value. These fields are required for Relay to cache this record correctly.`,
      )
    }
    return relayids.extProjectMemberLink.encode(
      data.project_member_id,
      data.ext_project_member_id,
    )
  }

  if (typename === "ext_person_link") {
    if (
      typeof data.person_id !== "number" ||
      typeof data.ext_person_id !== "number"
    ) {
      console.warn(
        `WARNING: getDataID found a "ext_person_link" row that is missing the "person_id" and/or the "ext_person_id" value. These fields are required for Relay to cache this record correctly.`,
      )
    }
    return relayids.extPersonLink.encode(data.person_id, data.ext_person_id)
  }
  if (typename === "ext_project_link") {
    if (
      typeof data.project_id !== "number" ||
      typeof data.ext_project_id !== "number"
    ) {
      console.warn(
        `WARNING: getDataID found a "ext_project_link" row that is missing the "person_id" and/or the "ext_project_id" value. These fields are required for Relay to cache this record correctly.`,
      )
    }
    return relayids.extProjectLink.encode(data.project_id, data.ext_project_id)
  }

  if (typename === "ext_time_off_link") {
    if (
      typeof data.time_off_id !== "number" ||
      typeof data.ext_time_off_id !== "number"
    ) {
      console.warn(
        `WARNING: getDataID found a "ext_time_off_link" row that is missing the "time_off_id" and/or the "ext_time_off_id" value. These fields are required for Relay to cache this record correctly.`,
      )
    }
    return relayids.extTimeOffLink.encode(
      data.time_off_id,
      data.ext_time_off_id,
    )
  }

  if (
    typename === "LoggedActionSingleActionWithContext" ||
    typename === "LoggedActionModelGroupActionWithContext" ||
    typename === "LoggedActionSubjectGroupActionWithContext" ||
    typename === "LoggedActionSingleAction"
  ) {
    return relayids.loggedActions.encode(
      data.formatGroup ?? data.formatSingle,
      data.eventId,
    )
  }

  if (typeof data.id === "number") {
    switch (typename) {
      case "people":
        return relayids.people.encode(data.id)
      case "accounts":
        return relayids.accounts.encode(data.id)
      case "actuals":
        return relayids.actuals.encode(data.id)
      case "assignments":
        return relayids.assignments.encode(data.id)
      case "clients":
        return relayids.clients.encode(data.id)
      case "competencies":
        return relayids.competencies.encode(data.id)
      case "contracts":
        return relayids.contracts.encode(data.id)
      case "CurrentAccountUser":
        return relayids.currentAccountUser.encode(data.id)
      case "custom_select_types":
        return relayids.customSelectTypes.encode(data.id)
      case "custom_select_options":
        return relayids.customSelectOptions.encode(data.id)
      case "custom_select_values":
        return relayids.customSelectValues.encode(data.id)
      case "custom_text_values":
        return relayids.customTextValues.encode(data.id)
      case "custom_text_types":
        return relayids.customTextTypes.encode(data.id)
      case "custom_checkbox_values":
        return relayids.customCheckboxValues.encode(data.id)
      case "custom_checkbox_types":
        return relayids.customCheckboxTypes.encode(data.id)
      case "custom_date_types":
        return relayids.customDateTypes.encode(data.id)
      case "custom_date_values":
        return relayids.customDateValues.encode(data.id)
      case "features":
        return relayids.features.encode(data.id)
      case "features_accounts":
        return relayids.featureAccounts.encode(data.id)
      case "help_documents":
        return relayids.helpDocuments.encode(data.id)
      case "holidays":
        return relayids.holidays.encode(data.id)
      case "holidays_groups":
        return relayids.holidaysGroup.encode(data.id)
      case "invitations":
        return relayids.invitations.encode(data.id)
      case "milestones":
        return relayids.milestones.encode(data.id)
      case "notes":
        return relayids.notes.encode(data.id)
      case "people_notes":
        return relayids.peopleNotes.encode(data.id)
      case "other_costs":
        return relayids.otherCosts.encode(data.id)
      case "people":
        return relayids.people.encode(data.id)
      case "person_requests":
        return relayids.personRequests.encode(data.id)
      case "phases":
        return relayids.phases.encode(data.id)
      case "project_members":
        return relayids.projectMembers.encode(data.id)
      case "project_rates":
        return relayids.projectRates.encode(data.id)
      case "project_managers":
        return relayids.projectManagers.encode(data.id)
      case "project_roles":
        return relayids.projectRoles.encode(data.id)
      case "projects":
        return relayids.projects.encode(data.id)
      case "rate_cards":
        return relayids.rateCards.encode(data.id)
      case "reports":
        return relayids.reports.encode(data.id)
      case "roles":
        return relayids.roles.encode(data.id)
      case "saved_reports":
        return relayids.savedReports.encode(data.id)
      case "skills":
        return relayids.skills.encode(data.id)
      case "tags":
        return relayids.tags.encode(data.id)
      case "teams":
        return relayids.teams.encode(data.id)
      case "time_offs":
        return relayids.timeOffs.encode(data.id)
      case "users":
        return relayids.users.encode(data.id)
      case "user_accounts":
        return relayids.user_accounts.encode(data.id)
      case "user_filter_sets":
        return relayids.userFilterSets.encode(data.id)
      case "views":
        return relayids.views.encode(data.id)
      case "integration":
        return relayids.integrations.encode(data.id)
      case "integration_service":
        return relayids.integrationServices.encode(data.id)
      case "ext_person":
        return relayids.extPerson.encode(data.id)
      case "ext_project":
        return relayids.extProject.encode(data.id)
      case "ext_project_member":
        return relayids.extProjectMember.encode(data.id)
      case "ext_time_off":
        return relayids.extTimeOff.encode(data.id)
      case "api_tokens":
        return relayids.apiTokens.encode(data.id)
      case "workstreams":
        return relayids.workstreams.encode(data.id)
      // These don't need to be registered but let's handle the
      // cases to prevent the missing entry warning in the dev console
      case "AssignmentBulkChangesOutput":
      case "ProjectRateBulkCreateOutput":
      case "ProjectMemberBulkCreateOutput":
      case "ChangePersonWorkstreamResult":
      case "LoggedActionUser":
        return
      default:
        console.warn(
          `WARNING: getDataID is missing an entry for the table "${typename}".`,
        )
    }
  }

  // Chargebee types have unique IDs by default
  if (typename.match(/^Billing/) && data.id) {
    return data.id
  }

  // Relay will automatically generate an ID for us based on the query
  return undefined
}

function devLog(event: LogEvent): void {
  const message = "Relay Error: %j"
  switch (event.name) {
    // @ts-ignore Missing type https://github.com/DefinitelyTyped/DefinitelyTyped/pull/68477
    case "read.missing_required_field":
      console.error(message, event)
      break
    // We're handling network error logging separately in fetchQuery()
    case "execute.error":
    case "network.error":
      break
  }
}

function log(event: LogEvent): void {
  const message = "Relay Event"
  switch (event.name) {
    // @ts-ignore Missing type https://github.com/DefinitelyTyped/DefinitelyTyped/pull/68477
    case "read.missing_required_field":
      void reportError(message, event)
      break
    // We're handling network error logging separately in fetchQuery()
    case "execute.error":
    case "network.error":
      break
  }
}

const isDevelopment = getEnvInfo(window.location.href).env === "development"

const environment = new Environment({
  network,
  store,
  // @ts-ignore
  getDataID,
  log: isDevelopment ? devLog : log,
})

export { environment, sessionStore }
