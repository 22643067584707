import React from "react"

export const TangramTriangle = () => (
  <svg
    width="543"
    height="542"
    viewBox="0 0 543 542"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Triangle</title>
    <path
      d="M135.803 543.253L0.0388985 407.489L135.803 407.489L271.568 543.253L135.803 543.253Z"
      fill="#FFB077"
    />
    <path
      d="M271.471 543.236L543 543.236L271.471 271.707L271.471 543.236Z"
      fill="#4057DF"
    />
    <path
      d="M0.374424 271.724L136.139 271.724L136.139 407.489L0.374401 407.489L0.374424 271.724Z"
      fill="#FDCD4F"
    />
    <path
      d="M0.0564232 543.624L135.821 543.624L0.0564117 407.86L0.0564232 543.624Z"
      fill="#B19DE6"
    />
    <path
      d="M-0.0788345 272.036L-0.0788574 0.506653L271.45 272.036L-0.0788345 272.036Z"
      fill="#F5848C"
    />
    <path
      d="M135.692 407.447L271.456 271.682L271.456 543.211L135.692 407.447Z"
      fill="#67D0D5"
    />
    <path
      d="M135.739 271.989L135.739 407.753L271.503 271.989L135.739 271.989Z"
      fill="#0A9CC1"
    />
  </svg>
)
