import React, { useState } from "react"

import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import { createAccount } from "~/helpers/routes"

import Duration from "~/common/Duration"
import InputLabel from "~/common/InputLabel"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Select, { Option } from "~/common/Select"
import TitledHelpTooltip from "~/common/TitledHelpTooltip"
import UniqueInput from "~/common/UniqueInput"
import Button from "~/common/buttons/Button"
import { TangramBuilding } from "~/common/tangrams"

import { CURRENCIES } from "~/GLOBALVARS"

const ONE_DAY_IN_MINUTES = 24 * 60 - 1

type Props = {
  userAccountNames: string[]
  currency: string
  defaultFullTimeMinutes: number
  onClose: () => void
}

const NewAccountForm = (props: Props) => {
  const { userAccountNames, currency, defaultFullTimeMinutes, onClose } = props

  const currencyOptions = Object.entries(CURRENCIES).map(
    ([value, label]) =>
      ({
        value,
        label: `${label} (${value})`,
      }) as Option,
  )
  const defaultCurrency = currencyOptions.find((x) => x.value === currency)

  const [accountName, setAccountname] = useState("")
  const [selectedCurrency, setSelectedCurrency] = useState(
    defaultCurrency.value,
  )
  const [selectedDefaultFullTimeMinutes, setSelectedDefaultFullTimeMinutes] =
    useState(defaultFullTimeMinutes)
  const [isCreating, setIsCreating] = useState(false)

  const duplicateUserAccountName = userAccountNames.find(
    (userAccountName) =>
      userAccountName.trim().toLowerCase() === accountName.trim().toLowerCase(),
  )

  const handleAccountName = (e) => {
    setAccountname(e.target.value)
  }

  const handleClose = () => {
    setIsCreating(false)
    onClose()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsCreating(true)

    const response = await fetchWithCsrf(createAccount(), {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        name: accountName.trim(),
        currency: selectedCurrency,
        default_full_time_minutes: selectedDefaultFullTimeMinutes,
      }),
    })

    if (!response.ok) {
      setIsCreating(false)
    }
  }

  return (
    <ModalFormWrapper
      headerTitle="New Account"
      tangram={<TangramBuilding />}
      tangramStyles={{ maxWidth: "80%", marginBottom: "10px" }}
    >
      <ModalBody>
        <UniqueInput
          name="account-name"
          id="account-name"
          label="Account Name"
          dataTest="account-name"
          value={accountName}
          onChange={handleAccountName}
          autoComplete="off"
          autoFocus
          duplicate={
            duplicateUserAccountName && {
              name: duplicateUserAccountName,
            }
          }
        />
        <Select
          options={currencyOptions}
          placeholder={"Select currency"}
          dataTest="currency-input"
          isClearable={false}
          defaultValue={defaultCurrency}
          isSearchable={true}
          label={
            <TitledHelpTooltip
              title="Preferred Currency"
              tooltipContent="Currency used for the account"
            />
          }
          onChange={(option) => setSelectedCurrency(option.value)}
          minHeight={36}
        />
        <div style={{ marginBottom: "11px" }} />
        <InputLabel
          label={
            <TitledHelpTooltip
              title="Hours per day worked by FTE"
              tooltipContent="Daily working hours for a Full Time Equivalent (FTE)"
            />
          }
        />
        <Duration
          handleMinutesPerDay={setSelectedDefaultFullTimeMinutes}
          minutesPerDay={defaultFullTimeMinutes}
          dataTest="default-full-time-minutes-input"
          style={{ textAlign: "left" }}
          maxMinutes={ONE_DAY_IN_MINUTES}
          allowZero={false}
        />
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={handleClose} disabled={isCreating} />
        <Button
          text="Create New Account"
          onClick={handleSubmit}
          data-test="create-account-button"
          type="submit"
          disabled={!!duplicateUserAccountName || !accountName.length}
          outlined={false}
          loading={isCreating}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default NewAccountForm
