import { graphql } from "react-relay"

import { CancelReasonsCreateMutation } from "./__generated__/CancelReasonsCreateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

const cancelReasonsCreateMutation = graphql`
  mutation CancelReasonsCreateMutation(
    $reasons: [cancel_reasons_insert_input!]!
  ) {
    insert_cancel_reasons(objects: $reasons) {
      affected_rows
    }
  }
`

type CancelReasonsCreateOptions = {
  variables: CancelReasonsCreateMutation["variables"]
}

export const cancelReasonsCreateRelay = (
  options: CancelReasonsCreateOptions,
) => {
  const { variables } = options

  return commitMutationPromise<CancelReasonsCreateMutation>(environment, {
    mutation: cancelReasonsCreateMutation,
    variables,
  })
}
