import cc from "classcat"
import React from "react"

import styles from "./Checkbox.module.css"

import { Tick } from "~/common/react-icons"

type Props = Omit<React.HTMLProps<HTMLInputElement>, "label"> & {
  id: string
  containerClassName?: string
  label?: string | React.ReactElement
  labelClassName?: string
  tickClassName?: string
}

const Checkbox = (props: Props) => {
  const {
    id,
    containerClassName,
    label,
    labelClassName,
    tickClassName,
    ...restProps
  } = props

  return (
    <div
      className={cc([styles.container, containerClassName])}
      data-component="Checkbox"
    >
      <input
        id={id}
        className={styles.checkboxInput}
        type="checkbox"
        {...restProps}
      />
      <Tick className={cc([styles.checkboxTick, tickClassName])} />
      {label && (
        <label htmlFor={id} className={cc([styles.label, labelClassName])}>
          {label}
        </label>
      )}
    </div>
  )
}

export default Checkbox
