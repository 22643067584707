import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./PlannerGrid.module.css"

type Props = {
  children?: React.ReactNode
  className?: string
  gridRow100?: boolean
  disabled?: boolean
  allowInteraction?: boolean
  tooltipText?: string
  stickyBottom?: boolean
}

const PlannerGrid = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    gridRow100,
    disabled,
    allowInteraction,
    tooltipText = "",
    stickyBottom,
    ...otherProps
  } = props

  const classes = cc([
    styles.plannerGrid,
    {
      [styles.gridRow100]: gridRow100,
      [className ?? ""]: className,
      [styles.disabled]: disabled,
      [styles.allowInteraction]: allowInteraction,
      [styles.stickyBottom]: stickyBottom && !disabled,
    },
  ])

  const GridContent = <div {...otherProps} ref={ref} className={classes} />

  return tooltipText ? (
    <Tooltip
      className={cc([
        {
          [styles.disabledCursor]: disabled && !allowInteraction,
        },
      ])}
      placement="top"
      content={tooltipText}
    >
      {GridContent}
    </Tooltip>
  ) : (
    GridContent
  )
})

export default PlannerGrid
