import React from "react"

type Props = {
  fill?: string
  className?: string
}

const IdCard = (props: Props) => {
  const { className, fill = "#3C4260" } = props

  return (
    <svg
      className={className}
      height="18"
      width="18"
      viewBox="0 0 16 16"
      enableBackground="new 0 0 16 16"
    >
      <g id="id_number_2_">
        <g id="pt-icon-id-number">
          <path
            fill={fill}
            d="M2,5v7h12V5H2z M2,3h12c1.1,0,2,0.9,2,2v7c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V5C0,3.9,0.9,3,2,3z"
          />
          <path
            fill={fill}
            d="M7.9,10.48c-0.14-0.33-0.84-0.55-1.3-0.75c-0.46-0.2-0.4-0.33-0.42-0.5c0-0.02,0-0.05,0-0.07
        C6.34,9.02,6.47,8.83,6.55,8.6c0,0,0-0.01,0.01-0.02c0.02-0.05,0.03-0.1,0.05-0.15C6.71,8.42,6.77,8.3,6.8,8.2
        c0.03-0.04,0.07-0.15,0.06-0.27C6.84,7.77,6.78,7.69,6.71,7.67V7.64c0-0.2-0.02-0.48-0.05-0.67c-0.01-0.05-0.02-0.1-0.03-0.16
        c-0.07-0.23-0.21-0.44-0.4-0.58C6.03,6.08,5.75,6,5.5,6S4.97,6.08,4.78,6.23c-0.19,0.14-0.33,0.35-0.4,0.58
        c-0.02,0.05-0.03,0.1-0.03,0.16C4.3,7.15,4.29,7.44,4.29,7.64v0.03c-0.07,0.03-0.14,0.1-0.15,0.26C4.12,8.05,4.17,8.15,4.2,8.2
        C4.22,8.3,4.29,8.42,4.4,8.44c0.01,0.05,0.03,0.1,0.05,0.15c0,0,0,0.01,0,0.01l0,0c0.08,0.23,0.22,0.42,0.38,0.56
        c0,0.02,0,0.04,0,0.07C4.81,9.4,4.86,9.52,4.4,9.73c-0.46,0.2-1.16,0.42-1.3,0.75S3.01,11,3.01,11H8C7.99,11,8.05,10.81,7.9,10.48
        L7.9,10.48z"
          />
          <path
            fill={fill}
            d="M10,6h2c0.55,0,1,0.45,1,1l0,0c0,0.55-0.45,1-1,1h-2C9.45,8,9,7.55,9,7l0,0C9,6.45,9.45,6,10,6z"
          />
          <path
            fill={fill}
            d="M10,9h2c0.55,0,1,0.45,1,1l0,0c0,0.55-0.45,1-1,1h-2c-0.55,0-1-0.45-1-1l0,0C9,9.45,9.45,9,10,9
        z"
          />
        </g>
      </g>
    </svg>
  )
}

export default IdCard
