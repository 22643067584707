import { dateHelpers } from "@runn/calculations"
import React from "react"
import { connect } from "react-redux"

import { getTimeFrames } from "~/helpers/CalendarHelper"
import { dateIsAHoliday } from "~/helpers/holiday-helpers"

import { CalendarPeriods } from "~/common/calendar.reducer"

import { setHighlightedCellData as setHighlightedCellDataDispatch } from "~/GlobalState"

import Cell from "./Cell"

type TimeOffs = {
  id: number
  start_date: string
  end_date: string
  person_id: number
  leave_type: string
}

type Props = {
  calendarStartDate: Date
  calendarEndDate: Date
  popoverContent?: (
    day: Date,
    closePopover: any,
    isPopoverOpen: boolean,
  ) => React.ReactElement

  type:
    | "placeholder"
    | "standard"
    | "assignment"
    | "timeOff"
    | "milestone"
    | "unconfirmed"
  extraClassName?: string
  customPopoverIcon?: any
  staticContent?: (day: Date, resetHover: () => any) => any
  disabled?: boolean
  canEscapeKeyClose?: boolean
  allowScroll?: boolean
  setHighlightedCellData: (state: any) => void
  calendarWeekendsExpanded: boolean
  periods: CalendarPeriods
  timeOffs?: readonly TimeOffs[]
  holidaysOverlappingTimeOffs?: string[]
}

export const CalendarOutline = (props: Props) => {
  const {
    calendarStartDate,
    calendarEndDate,
    type,
    popoverContent,
    customPopoverIcon,
    extraClassName,
    staticContent,
    disabled,
    canEscapeKeyClose,
    allowScroll,
    setHighlightedCellData,
    calendarWeekendsExpanded,
    periods,
    timeOffs,
    holidaysOverlappingTimeOffs,
  } = props

  const { dailyDates, weeklyDates } = getTimeFrames({
    start: calendarStartDate,
    end: calendarEndDate,
    includeWeekends: calendarWeekendsExpanded,
  })

  const cells = periods === "weeks" ? weeklyDates : dailyDates

  return (
    <>
      {cells.map((day: Date, index) => {
        const formattedCellDate = dateHelpers.formatToRunnDate(day)

        const holidayOverlapsTimeOff =
          holidaysOverlappingTimeOffs?.includes(formattedCellDate)
        return (
          <Cell
            key={day.getTime()}
            day={day}
            type={type}
            popoverContent={popoverContent}
            customPopoverIcon={customPopoverIcon}
            extraClassName={extraClassName}
            staticContent={staticContent}
            disabled={disabled}
            allowScroll={allowScroll}
            canEscapeKeyClose={canEscapeKeyClose}
            setHighlightedCellData={setHighlightedCellData}
            calendarWeekendsExpanded={calendarWeekendsExpanded}
            isStartOfCalendar={index === 0}
            isInDaysCalendarView={periods === "days"}
            isAHoliday={
              timeOffs ? dateIsAHoliday(timeOffs, formattedCellDate) : false
            }
            holidayOverlapsTimeOff={holidayOverlapsTimeOff}
            {...props}
          />
        )
      })}
    </>
  )
}

const mapDispatchToProps = {
  setHighlightedCellData: setHighlightedCellDataDispatch,
}

const mapStateToProps = (state) => ({
  calendarStartDate: state.calendar.calendarStartDate,
  calendarEndDate: state.calendar.calendarEndDate,
  calendarWeekendsExpanded: state.calendar.calendarWeekendsExpanded,
  periods: state.calendar.periods,
})

const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(CalendarOutline)
