import React from "react"

export const TangramAlert = () => (
  <svg
    width="100"
    height="179"
    viewBox="0 0 100 179"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Exclamation mark</title>
    <g clipPath="url(#clip0_2863_29252)">
      <path
        d="M49.4708 105.841V141.2L67.1504 123.521V88.1615L49.4708 105.841Z"
        fill="#FFB077"
      />
      <path
        d="M37.0693 151.726L62.072 151.726L62.072 176.728L37.0693 176.728L37.0693 151.726Z"
        fill="#FDCD4F"
      />
      <path
        d="M49.3797 0.200333L31.7002 17.8799L67.0593 17.8799L49.3797 0.200333Z"
        fill="#B19DE6"
      />
      <path
        d="M31.8167 53.2263L67.1758 88.5854L67.1758 17.8672L31.8167 53.2263Z"
        fill="#4057DF"
      />
      <path
        d="M67.1667 88.2815L31.8076 123.641L31.8076 52.9224L67.1667 88.2815Z"
        fill="#F5848C"
      />
      <path
        d="M31.8411 17.8411H67.2002L31.8411 53.2002L31.8411 17.8411Z"
        fill="#67D0D5"
      />
      <path
        d="M31.7999 123.579L49.4795 141.259L49.4795 105.9L31.7999 123.579Z"
        fill="#0A9CC1"
      />
    </g>
    <defs>
      <clipPath id="clip0_2863_29252">
        <rect
          width="100"
          height="178"
          fill="white"
          transform="translate(100 178.5) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
)
