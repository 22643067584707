import cc from "classcat"
import React from "react"

import styles from "./CardGradientBorderWrapper.module.css"

type Props = {
  children: React.ReactNode
  className?: string
}
const CardGradientBorderWrapper = ({ children, className }: Props) => {
  return <div className={cc([styles.gradient, className])}>{children}</div>
}

export default CardGradientBorderWrapper
