import React from "react"

export const TangramPersonLeaping = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={60}
    height={60}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2546_108498)">
      <path
        d="M19.7446 10.314L24.7459 5.40702L19.7446 0.500071L14.7433 5.40702L19.7446 10.314Z"
        fill="#FDCD4F"
      />
      <path
        d="M35.9895 24.9832L40.9908 29.8901L30.9882 29.8901L35.9895 24.9832Z"
        fill="#0A9CC1"
      />
      <path
        d="M29.1128 36.8301L22.0399 29.8906H36.1856L29.1128 36.8301Z"
        fill="#67D0D5"
      />
      <path
        d="M9.7972 29.8782H19.7997L14.7985 34.7852H4.79593L9.7972 29.8782Z"
        fill="#FFB077"
      />
      <path
        d="M5.15757 39.6443L10.1588 34.7373L0.156303 34.7373L5.15757 39.6443Z"
        fill="#B19DE6"
      />
      <path
        d="M19.7538 20.1094L9.75127 29.9233L29.7563 29.9233L19.7538 20.1094Z"
        fill="#4057DF"
      />
      <path
        d="M19.7446 20.1098L29.7471 10.2959L9.74207 10.2959L19.7446 20.1098Z"
        fill="#F5848C"
      />
    </g>
    <defs>
      <clipPath id="clip0_2546_108498">
        <rect
          width={41}
          height={39}
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
