/**
 * @generated SignedSource<<3a504449d5fcf53106006143a062037d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkstreamCreateMutation$variables = {
  name: string;
};
export type WorkstreamCreateMutation$data = {
  readonly createWorkstream: {
    readonly account: {
      readonly id: number;
      readonly workstreams: ReadonlyArray<{
        readonly archived: boolean;
        readonly id: number;
        readonly name: string;
      }>;
    } | null | undefined;
  };
};
export type WorkstreamCreateMutation = {
  response: WorkstreamCreateMutation$data;
  variables: WorkstreamCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "CreateWorkstreamResult",
    "kind": "LinkedField",
    "name": "createWorkstream",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "workstreams",
            "kind": "LinkedField",
            "name": "workstreams",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archived",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkstreamCreateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkstreamCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "00fae752c491f20dcf3473444b62b0d4",
    "id": null,
    "metadata": {},
    "name": "WorkstreamCreateMutation",
    "operationKind": "mutation",
    "text": "mutation WorkstreamCreateMutation(\n  $name: String!\n) {\n  createWorkstream(name: $name) {\n    account {\n      id\n      workstreams {\n        id\n        name\n        archived\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "436997de1c1a316ec3988a20c236f90b";

export default node;
