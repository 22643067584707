import React from "react"

export const TangramPersonRunner = () => (
  <svg
    width="55"
    height="96"
    viewBox="0 0 55 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Runner</title>
    <path
      d="M32.4878 23.1082L21.4878 12.1467L32.4878 1.18513L43.4878 12.1467L32.4878 23.1082Z"
      fill="#FDCD4F"
    />
    <path
      d="M-0.0136719 62.1452V77.6472L15.5427 62.1452H-0.0136719Z"
      fill="#B19DE6"
    />
    <path
      d="M54.6898 68.7812L39.1335 53.2793V84.2832L54.6898 68.7812Z"
      fill="#67D0D5"
    />
    <path
      d="M8.23652 38.6101L23.7929 23.1082V38.6101L8.23652 54.1121V38.6101Z"
      fill="#FFB077"
    />
    <path
      d="M28.2278 84.5183L39.2278 73.5568L39.2278 95.4799L28.2278 84.5183Z"
      fill="#0A9CC1"
    />
    <path
      d="M39.1064 69.6141H7.99365L39.1064 38.6102V69.6141Z"
      fill="#4057DF"
    />
    <path
      d="M23.8064 23.0813V54.0852L54.9191 23.0813H23.8064Z"
      fill="#F5848C"
    />
  </svg>
)
