import React from "react"

export type EntitlementFeatureType = "QUANTITY" | "SWITCH" | "CUSTOM"

type EntitlementState = {
  readonly featureId: string
  readonly featureType: EntitlementFeatureType
  readonly value: string
}

export type EntitlementsContextData = {
  entitlements: readonly EntitlementState[] | null
  isInTrial?: boolean
  plan?: string
}

const EntitlementsContext = React.createContext<EntitlementsContextData>({
  entitlements: null,
  isInTrial: false,
  plan: "",
})

export default EntitlementsContext
