import React from "react"

const Assignment = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2645:11744)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0556 2.94444H11.0367C10.7333 2.10667 9.93889 1.5 9 1.5C8.06111 1.5 7.26667 2.10667 6.96333 2.94444H3.94444C3.15 2.94444 2.5 3.59444 2.5 4.38889V14.5C2.5 15.2944 3.15 15.9444 3.94444 15.9444H14.0556C14.85 15.9444 15.5 15.2944 15.5 14.5V4.38889C15.5 3.59444 14.85 2.94444 14.0556 2.94444ZM8.9999 2.94454C9.39713 2.94454 9.72213 3.26954 9.72213 3.66676C9.72213 4.06398 9.39713 4.38898 8.9999 4.38898C8.60268 4.38898 8.27768 4.06398 8.27768 3.66676C8.27768 3.26954 8.60268 2.94454 8.9999 2.94454ZM5.38908 13.0557H10.4446V11.6113H5.38908V13.0557ZM12.6113 10.1667H5.38908V8.72222H12.6113V10.1667ZM5.38908 7.27759H12.6113V5.83314H5.38908V7.27759Z"
        fill="#3C4260"
      />
    </g>
    <defs>
      <clipPath id="clip0_2645:11744">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Assignment
