import { Menu, Placement } from "@blueprintjs/core"
import React, { ComponentProps, useState } from "react"

import styles from "./Dropdown.module.css"

import { Popover2, Popover2Props } from "~/common/Popover2"
import Button from "~/common/buttons/Button"

import { IconThreeDot } from "../IconThreeDot"

type DropdownTargetProps = {
  active: boolean
  disabled?: boolean
}

const DefaultTarget = (props: DropdownTargetProps) => {
  const { active } = props
  return (
    <Button
      icon={
        <IconThreeDot
          size={20}
          style={{
            marginRight: -8,
          }}
        />
      }
      active={active}
      style={{
        height: 34,
        width: 34,
        padding: 0,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    />
  )
}

type DropdownProps<T> = {
  children: React.ReactNode | ((args: { close: () => void }) => React.ReactNode)
  id?: string
  disabled?: boolean
  placement?: Placement
  minWidth?: number
  minHeight?: number
  maxWidth?: number
  maxHeight?: number
  fixedWidth?: number
  hideArrow?: boolean
  onOpen?: () => void
  onClose?: () => void
  onClick?: (e) => void
  className?: string
  dataTest?: string
  modifiers?: Popover2Props["modifiers"]
  interactionKind?: Popover2Props["interactionKind"]
  style?: Record<string, any>
  preventRenderWhenClosed?: boolean
  Target?: T extends React.ComponentType<infer P>
    ? (p: P & DropdownTargetProps) => any
    : (p: DropdownTargetProps) => any
  targetProps?: T extends React.FC
    ? ComponentProps<T>
    : T extends React.ComponentType
      ? ComponentProps<T>
      : Record<string, any>
}
const Dropdown = <TargetComponent,>(props: DropdownProps<TargetComponent>) => {
  const {
    disabled,
    placement,
    Target = DefaultTarget,
    targetProps = {},
    minWidth = 140,
    minHeight,
    maxHeight,
    fixedWidth,
    maxWidth,
    hideArrow,
    onOpen,
    onClose,
    className,
    dataTest,
    children,
    modifiers,
    interactionKind = "click",
    preventRenderWhenClosed,
    ...rest
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const buttonTestName = dataTest ? `${dataTest}-button` : "dropdown-button"
  const menuTestName = dataTest ? `${dataTest}-menu` : "dropdown-menu"

  const handleOpen = () => {
    setIsOpen(true)
    if (onOpen) {
      onOpen()
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    if (onClose) {
      onClose()
    }
  }

  /**
   * Closes the dropdown without triggering the onClose callback (which will get
   * called when the dropdown is actually closed - this saves the `onClose` callback
   * getting called twice)
   */
  const close = () => {
    setIsOpen(false)
  }

  return (
    <div
      className={styles.dropdownWrapper}
      data-test={buttonTestName}
      {...rest}
    >
      <Popover2
        modifiers={modifiers}
        disabled={disabled}
        isOpen={isOpen}
        interactionKind={interactionKind}
        content={
          // After bp5 upgrade, the popover closes and immediately reopens when a
          // menu item that triggers a suspense is clicked eg. ViewsDropdown.tsx
          // related issue: https://github.com/palantir/blueprint/issues/6518
          (preventRenderWhenClosed && isOpen) || !preventRenderWhenClosed ? (
            <Menu
              className={`${styles.dropdown} ${className}`}
              data-test={menuTestName}
              style={{
                minWidth: `${minWidth}px`,
                minHeight: `${minHeight}px`,
                maxWidth: `${maxWidth}px`,
                width: `${fixedWidth}px`,
                maxHeight,
                overflowY: maxHeight ? "scroll" : undefined,
              }}
            >
              {typeof children === "function"
                ? children({
                    close,
                  })
                : children}
            </Menu>
          ) : undefined
        }
        placement={placement || "bottom-end"}
        onInteraction={handleOpen}
        onClose={handleClose}
        minimal={hideArrow}
        fill={true}
      >
        <Target {...targetProps} active={isOpen} disabled={!!disabled} />
      </Popover2>
    </div>
  )
}

export default Dropdown
export { DropdownProps, DropdownTargetProps }
