import React from "react"

const Dashboard = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33333 8.66667H2V2H7.33333V8.66667ZM7.33333 14H2V10H7.33333V14ZM8.66667 14H14V7.33333H8.66667V14ZM8.66667 6V2H14V6H8.66667Z"
      fill="#3C4260"
    />
  </svg>
)

export default Dashboard
