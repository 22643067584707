import React from "react"

export const TangramBuilding = () => (
  <svg
    width="193"
    height="193"
    viewBox="0 0 193 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3610_32518)">
      <path
        d="M128.411 128.411L192.579 64.2429L192.506 128.484L128.338 192.652L128.411 128.411Z"
        fill="#FFB077"
      />
      <path
        d="M0.146047 192.436L128.482 192.582L-0.0001842 64.0999L0.146047 192.436Z"
        fill="#4057DF"
      />
      <path
        d="M192.647 192.653L128.479 192.58L192.574 128.485L192.647 192.653Z"
        fill="#B19DE6"
      />
      <path
        d="M64.054 0.153355L-0.0408636 64.2482L128.295 64.3944L64.054 0.153355Z"
        fill="#67D0D5"
      />
      <path
        d="M128.402 64.7207L128.329 128.889L192.57 64.6476L128.402 64.7207Z"
        fill="#0A9CC1"
      />
      <path
        d="M192.534 64.6475L128.293 64.6475L128.293 0.55264L192.534 0.552632L192.534 64.6475Z"
        fill="#FDCD4F"
      />
      <path
        d="M128.334 64.2413L128.48 192.577L-0.00212654 64.095L128.334 64.2413Z"
        fill="#F5848C"
      />
    </g>
  </svg>
)
