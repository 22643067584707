import React from "react"

export const TangramInvite = () => (
  <svg
    width="70"
    height="75"
    viewBox="0 0 76 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M38 80.8933H0L19 61.8933H57L38 80.8933Z" fill="#FFB077" />
    <path d="M76 80.8933H38L76 42.8933V80.8933Z" fill="#67D0D5" />
    <path
      d="M57 61.8933L38 42.8933L57 23.8933L76 42.8933L57 61.8933Z"
      fill="#FDCD4F"
    />
    <path d="M57 23.8933L76 42.8933L76 4.89331L57 23.8933Z" fill="#0A9CC1" />
    <path d="M38 42.8933L19 61.8933L57 61.8933L38 42.8933Z" fill="#B19DE6" />
    <path d="M38 42.8933L0 4.89331V80.8933L38 42.8933Z" fill="#F5848C" />
    <path d="M38 38.3335L0 0.333496L76 0.333496L38 38.3335Z" fill="#4057DF" />
  </svg>
)
