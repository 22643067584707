import React from "react"

type Props = {
  color?: string
  size?: number
  alt?: string
}

const Widgets = (props: Props) => {
  const { size = 20, color = "var(--runn-blue)", alt } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <mask
        id="mask0_149_2503"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_149_2503)">
        <path
          d="M13.875 10.8333L9.16667 6.12496L13.875 1.41663L18.5833 6.12496L13.875 10.8333ZM2.5 9.16663V2.49996H9.16667V9.16663H2.5ZM10.8333 17.5V10.8333H17.5V17.5H10.8333ZM2.5 17.5V10.8333H9.16667V17.5H2.5Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default Widgets
