import React from "react"

type Props = {
  color?: string
  size?: number
  alt?: string
}

const Social = (props: Props) => {
  const { size = 18, color = "var(--midnight)", alt } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <g clipPath="url(#clip0_2645:11744)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.20607 4.96429C8.20607 6.32786 7.11357 7.42857 5.75 7.42857C4.38643 7.42857 3.28571 6.32786 3.28571 4.96429C3.28571 3.60071 4.38643 2.5 5.75 2.5C7.11357 2.5 8.20607 3.60071 8.20607 4.96429ZM14.7775 4.96429C14.7775 6.32786 13.685 7.42857 12.3214 7.42857C10.9579 7.42857 9.85714 6.32786 9.85714 4.96429C9.85714 3.60071 10.9579 2.5 12.3214 2.5C13.685 2.5 14.7775 3.60071 14.7775 4.96429ZM5.75 9.07143C3.83607 9.07143 0 10.0325 0 11.9464V14H11.5V11.9464C11.5 10.0325 7.66393 9.07143 5.75 9.07143ZM11.5246 9.1125C11.8121 9.08786 12.0832 9.07143 12.3214 9.07143C14.2353 9.07143 18.0714 10.0325 18.0714 11.9464V14H13.1428V11.9464C13.1428 10.7307 12.4775 9.8025 11.5246 9.1125Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2645:11744">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Social
