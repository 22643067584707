/**
 * @generated SignedSource<<defc394f2df59cb451ce31bf05ccb8f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FeatureAccountCheckboxToggleMutation$variables = {
  enabled?: boolean | null | undefined;
  feature_id?: string | null | undefined;
};
export type FeatureAccountCheckboxToggleMutation$data = {
  readonly insert_features_accounts_one: {
    readonly enabled: boolean;
    readonly feature_id: string;
    readonly id: number;
  } | null | undefined;
};
export type FeatureAccountCheckboxToggleMutation = {
  response: FeatureAccountCheckboxToggleMutation$data;
  variables: FeatureAccountCheckboxToggleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "enabled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "feature_id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "enabled",
            "variableName": "enabled"
          },
          {
            "kind": "Variable",
            "name": "feature_id",
            "variableName": "feature_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "object"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "index_features_accounts_on_account_id_and_feature_id",
          "update_columns": [
            "enabled"
          ]
        }
      }
    ],
    "concreteType": "features_accounts",
    "kind": "LinkedField",
    "name": "insert_features_accounts_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feature_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeatureAccountCheckboxToggleMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FeatureAccountCheckboxToggleMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b6cb583177e1a89f4604a78cc21d4582",
    "id": null,
    "metadata": {},
    "name": "FeatureAccountCheckboxToggleMutation",
    "operationKind": "mutation",
    "text": "mutation FeatureAccountCheckboxToggleMutation(\n  $feature_id: String\n  $enabled: Boolean\n) {\n  insert_features_accounts_one(object: {feature_id: $feature_id, enabled: $enabled}, on_conflict: {constraint: index_features_accounts_on_account_id_and_feature_id, update_columns: [enabled]}) {\n    id\n    feature_id\n    enabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "9fdedb005cf476e30412373696519189";

export default node;
