/**
 * @generated SignedSource<<93d681abfe1e96648e552957ea857544>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PersonBulkUpdateInput = {
  client_date: any;
  people: ReadonlyArray<PersonBulkUpdateInputPeople>;
};
export type PersonBulkUpdateInputPeople = {
  active?: boolean | null | undefined;
  contract?: PersonBulkUpdateInputPeopleContract | null | undefined;
  email?: string | null | undefined;
  first_name?: string | null | undefined;
  id: number;
  last_name?: string | null | undefined;
  tags?: ReadonlyArray<string | null | undefined> | null | undefined;
  team_id?: number | null | undefined;
};
export type PersonBulkUpdateInputPeopleContract = {
  cost?: number | null | undefined;
  create_new?: boolean | null | undefined;
  id?: number | null | undefined;
  job_title?: string | null | undefined;
  minutes_per_day?: number | null | undefined;
  role?: PersonBulkUpdateInputPeopleContractRole | null | undefined;
  rostered_days?: ReadonlyArray<number | null | undefined> | null | undefined;
};
export type PersonBulkUpdateInputPeopleContractRole = {
  create_new?: boolean | null | undefined;
  id?: number | null | undefined;
  name?: string | null | undefined;
};
export type PersonBulkUpdateMutation$variables = {
  input: PersonBulkUpdateInput;
};
export type PersonBulkUpdateMutation$data = {
  readonly action_person_bulk_update: {
    readonly account: {
      readonly id: number;
      readonly people: ReadonlyArray<{
        readonly active: boolean;
        readonly contracts: ReadonlyArray<{
          readonly cost: number | null | undefined;
          readonly employment_type: string;
          readonly end_date: string | null | undefined;
          readonly id: number;
          readonly job_title: string | null | undefined;
          readonly minutes_per_day: number | null | undefined;
          readonly role: {
            readonly default_hour_cost: number | null | undefined;
            readonly id: number;
            readonly name: string | null | undefined;
          };
          readonly start_date: string | null | undefined;
        }>;
        readonly email: string | null | undefined;
        readonly first_name: string;
        readonly id: number;
        readonly last_name: string;
        readonly tags: any;
        readonly team: {
          readonly id: number;
          readonly name: string;
        } | null | undefined;
      }>;
      readonly people_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
      readonly roles: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
  } | null | undefined;
};
export type PersonBulkUpdateMutation = {
  response: PersonBulkUpdateMutation$data;
  variables: PersonBulkUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PersonBulkUpdateOutput",
    "kind": "LinkedField",
    "name": "action_person_bulk_update",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "people",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "teams",
                "kind": "LinkedField",
                "name": "team",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "contracts",
                "kind": "LinkedField",
                "name": "contracts",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minutes_per_day",
                    "storageKey": null
                  },
                  {
                    "alias": "cost",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cost_private",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employment_type",
                    "storageKey": null
                  },
                  {
                    "alias": "start_date",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "start_date_runn",
                    "storageKey": null
                  },
                  {
                    "alias": "end_date",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "end_date_runn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "roles",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": "default_hour_cost",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "default_hour_cost_private",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "job_title",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "active": {
                    "_eq": true
                  },
                  "is_placeholder": {
                    "_eq": false
                  }
                }
              }
            ],
            "concreteType": "people_aggregate",
            "kind": "LinkedField",
            "name": "people_aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "people_aggregate_fields",
                "kind": "LinkedField",
                "name": "aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "people_aggregate(where:{\"active\":{\"_eq\":true},\"is_placeholder\":{\"_eq\":false}})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "roles",
            "kind": "LinkedField",
            "name": "roles",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonBulkUpdateMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonBulkUpdateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "dd2313a1080a203cc9bcd9d84e1ab4ef",
    "id": null,
    "metadata": {},
    "name": "PersonBulkUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation PersonBulkUpdateMutation(\n  $input: PersonBulkUpdateInput!\n) {\n  action_person_bulk_update(input: $input) {\n    account {\n      id\n      people {\n        id\n        active\n        first_name\n        last_name\n        email\n        tags\n        team {\n          id\n          name\n        }\n        contracts {\n          id\n          minutes_per_day\n          cost: cost_private\n          employment_type\n          start_date: start_date_runn\n          end_date: end_date_runn\n          role {\n            id\n            name\n            default_hour_cost: default_hour_cost_private\n          }\n          job_title\n        }\n      }\n      people_aggregate(where: {active: {_eq: true}, is_placeholder: {_eq: false}}) {\n        aggregate {\n          count\n        }\n      }\n      roles {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f2ed7e09a765fc8d263512356b2700b";

export default node;
