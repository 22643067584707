import React, { useState } from "react"

import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import { resetAccount } from "~/helpers/routes"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { TangramTriangle } from "~/common/tangrams"

type Props = {
  onClose: () => void
}
const DeleteTestAccountDataModal = (props: Props) => {
  const { onClose } = props
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDeleteTestAccountData = async () => {
    setIsDeleting(true)
    await fetchWithCsrf(resetAccount(), {
      method: "POST",
    })
    onClose()
  }

  return (
    <ModalFormWrapper
      headerTitle="Delete Test Data"
      tangram={<TangramTriangle />}
      tangramStyles={{ maxWidth: "80%", marginBottom: "10px" }}
    >
      <ModalBody>
        <div>
          Are you sure you want to <b>delete</b> all your Test Account data?
        </div>
        <br />
        <div>You cannot undo this action</div>
        <br />
        <div>This action may take a few minutes</div>
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={onClose} disabled={isDeleting} />
        <Button
          text="Delete Test Account Data"
          onClick={handleDeleteTestAccountData}
          data-test="delete-test-account-data-button"
          type="submit"
          disabled={isDeleting}
          outlined={false}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default DeleteTestAccountDataModal
