import React, { useState } from "react"

import Select, { ReactSelectProps } from "~/common/Select"

import { USER_TYPES, UserTypeOption } from "~/GLOBALVARS"

type Props = ReactSelectProps & {
  onChange: (value: UserTypeOption) => void
}
const SelectUserType = (props: Props) => {
  const { onChange, defaultValue, label, name, id, ...rest } = props
  const [selectedAccountType, setSelectedAccountType] = useState(
    defaultValue || USER_TYPES[1],
  )

  const handleChange = (e: UserTypeOption) => {
    onChange(e)
    setSelectedAccountType(e)
  }

  return (
    <Select
      name={name || "user-account-type"}
      id={id || "user-account-type"}
      value={selectedAccountType}
      onChange={handleChange}
      label={label || ""}
      options={USER_TYPES}
      placeholder="Select Account Type"
      {...rest}
    />
  )
}

export default SelectUserType
