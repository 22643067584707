export enum UserType {
  Superuser = "superuser",
  Admin = "admin",
  Editor = "editor",
  ViewerAll = "viewer_all",
  ViewerBasic = "viewer_basic",
  TimesheetOnly = "timesheet_only",
}

export enum FinancialType {
  All = "all",
  Restricted = "restricted",
  None = "none",
}

export enum ManageProjectsType {
  All = "all",
  Restricted = "restricted",
  None = "none",
}

export enum ManageOthersType {
  All = "all",
  None = "none",
}

export type Permissions = {
  financial: FinancialType
  type: UserType
  manage_projects: ManageProjectsType
  manage_others: ManageOthersType
}

// We should always pass userType -- but now it wont fail silently if you accidentally pass permissions
// If the future we should change the relay.config.json json from any to unknown and fix all the issues
const getType = (permissionType: UserType | Permissions) => {
  const type =
    typeof permissionType === "string" ? permissionType : permissionType.type
  if (!Object.values(UserType).includes(type)) {
    throw new Error("Unknown Permission Type")
  }
  return type
}

/**
 * @deprecated Use permissions framework (services/permissions) via usePermissions()
 */
export const hasWritePermission = (permissionType: UserType) => {
  const type = getType(permissionType)
  return (
    type === UserType.Superuser ||
    type === UserType.Admin ||
    type === UserType.Editor
  )
}

/**
 * @deprecated Use permissions framework (services/permissions) via usePermissions()
 */
export const isAdminUser = (permissionType: UserType) => {
  const type = getType(permissionType)
  return type === UserType.Superuser || type === UserType.Admin
}
