import { Classes } from "@blueprintjs/core"
import cc from "classcat"
import React, { useEffect, useRef, useState } from "react"
import { match } from "ts-pattern"

import styles from "~/common/Input.module.css"

import DurationHelper from "~/helpers/DurationHelper"

import { showToast } from "~/containers/ToasterContainer"

export type DurationProps = {
  handleMinutesPerDay: (minutes: number, error: boolean) => void
  minutesPerDay: any
  minMinutes?: number
  maxMinutes?: number
  maxMinutesLabel?: string
  className?: string
  inputName?: string
  autoFocus?: boolean
  tabIndex?: number
  allowZero?: boolean
  allowEmpty?: boolean
  defaultDuration?: number
  dataTest?: string
  height?: number
  style?: Record<string, any>
  placeholder?: number
  disabled?: boolean
  totalForPercentage?: number
  onPressEnter?: (minutes: number) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  effortDisplayUnit?: string
  isTimeOff?: boolean
}

const Duration = (props: DurationProps) => {
  const {
    minutesPerDay,
    allowEmpty,
    defaultDuration = 480,
    style,
    height,
    onFocus,
    onBlur,
    totalForPercentage,
    effortDisplayUnit,
    isTimeOff,
  } = props

  const [totalMinutes, setTotalMinutes] = useState(minutesPerDay)
  const [warning, setWarning] = useState("")
  const [durationValue, setDurationValue] = useState(
    typeof minutesPerDay === "number"
      ? DurationHelper.formatMinutesToTime(minutesPerDay)
      : "",
  )

  useEffect(() => {
    setTotalMinutes(minutesPerDay)
    const duration =
      allowEmpty && typeof minutesPerDay !== "number"
        ? ""
        : DurationHelper.formatMinutesToTime(minutesPerDay)

    setDurationValue(duration)
  }, [minutesPerDay]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e: any) => {
    const val = e.target.value

    // Handle percentages
    if (totalForPercentage && val.includes("%")) {
      const percentNum = val.replace("%", "")
      const minutes = (totalForPercentage / 100) * percentNum
      setDurationValue(val)
      setTotalMinutes(minutes)
      return
    }

    // Check if it's in the right format. Else don't let them type anything
    if (!/^\d{0,5}((\.|:)\d{0,2})?$/.test(val)) {
      return
    }

    let totalMins = 0

    if (val.includes(":")) {
      const hours = parseInt(val.substring(0, val.indexOf(":")), 10) || 0
      const minutes = parseInt(val.substr(val.indexOf(":") + 1), 10) || 0
      totalMins = hours * 60 + minutes
    } else {
      totalMins = Math.round(val * 60)
    }
    setDurationValue(val)
    setTotalMinutes(totalMins)
  }

  const handleSave = () => {
    const { maxMinutes, minMinutes } = props
    const effortUnit = match({ effortDisplayUnit, isTimeOff: !!isTimeOff })
      .with(
        { effortDisplayUnit: "hoursPerWeek", isTimeOff: false },
        () => " per week",
      )
      .with(
        { effortDisplayUnit: "hoursPerWeek", isTimeOff: true },
        () => " off per week",
      )
      .with(
        { effortDisplayUnit: "hoursPerDay", isTimeOff: false },
        () => " per day",
      )
      .with(
        { effortDisplayUnit: "hoursPerDay", isTimeOff: true },
        () => " off per day",
      )
      .otherwise(() => "")

    if (durationValue === "" && allowEmpty) {
      props.handleMinutesPerDay(undefined, false)
    }

    if (!durationValue && allowEmpty) {
      return
    }

    if (!durationValue) {
      setDurationValue(DurationHelper.formatMinutesToTime(defaultDuration))
      setTotalMinutes(defaultDuration)
      props.handleMinutesPerDay(defaultDuration, false)
    } else if (maxMinutes && totalMinutes > maxMinutes) {
      const label =
        props.maxMinutesLabel || DurationHelper.formatMinutesToTime(maxMinutes)
      showToast({
        message: `Hours${effortUnit} cannot exceed ${label} and have been automatically adjusted`,
        type: "warning",
      })
      props.handleMinutesPerDay(maxMinutes, true)
      setDurationValue(DurationHelper.formatMinutesToTime(maxMinutes))
      setTotalMinutes(maxMinutes)
    } else if (minMinutes && totalMinutes < props.minMinutes) {
      const minTime = DurationHelper.formatMinutesToTime(minMinutes)

      showToast({
        message: `A minimum ${minTime}${effortUnit} must be added`,
        type: "warning",
      })
      setDurationValue(minTime)
      setTotalMinutes(minMinutes)
      props.handleMinutesPerDay(minMinutes, true)
    } else if (totalMinutes <= 0 && props.allowZero !== true) {
      showToast({
        message: "Hours cannot be set to zero",
        type: "warning",
      })
      props.handleMinutesPerDay(totalMinutes, true)
    } else {
      props.handleMinutesPerDay(totalMinutes, false)
      setWarning("")
      setDurationValue(DurationHelper.formatMinutesToTime(totalMinutes))
    }
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSave()
      if (props.onPressEnter && totalMinutes < props.maxMinutes) {
        props.onPressEnter(totalMinutes)
      }
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur != null) {
      onBlur(event)
    }
    handleSave()
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus != null) {
      onFocus(event)
    }
    event.target.select()
  }

  // This avoids a bug with blueprintJS popover
  // that causes the page to jump because it autofocus
  // before the content has rendered.
  // Same implementation in Button.tsx
  const inputRef = useRef(null)

  useEffect(() => {
    // only run on first render so that user can still "tab" out
    requestAnimationFrame(() => {
      if (inputRef.current !== null && props.autoFocus) {
        inputRef.current.select()
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const placeholderTime =
    props.placeholder && DurationHelper.formatMinutesToTime(props.placeholder)
      ? DurationHelper.formatMinutesToTime(props.placeholder)
      : null

  return (
    <div>
      <input
        data-test={props.dataTest}
        data-component="Duration"
        type="text"
        ref={inputRef}
        className={cc([
          `tc`,
          { [Classes.INTENT_WARNING]: warning },
          styles.input,
          props.className,
        ])}
        style={{ ...style, height }}
        disabled={props.disabled}
        name={props.inputName}
        value={durationValue}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        onFocus={handleFocus}
        autoComplete="off"
        tabIndex={props.tabIndex}
        placeholder={placeholderTime}
      />
    </div>
  )
}

export default Duration
