import React from "react"

import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"

import DevToolsStatus, { StatusProps } from "./Status/Status"

// rendered by Rails inside the top navigation
const DevTools = (props: StatusProps) => {
  return <DevToolsStatus {...props} />
}

export default withRelayPageContainerNoLoading(DevTools)
