import { graphql } from "react-relay"

import { SkillDeleteMutation } from "./__generated__/SkillDeleteMutation.graphql"
import { SkillUpdateMutation } from "./__generated__/SkillUpdateMutation.graphql"
import { SkillsCreateMutation } from "./__generated__/SkillsCreateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

const skillsCreateMutation = graphql`
  mutation SkillsCreateMutation($input: SkillBulkCreateInput!) {
    bulkCreateSkill(input: $input) {
      account {
        id
        skills {
          id
          name
        }
      }
    }
  }
`

type SkillsCreateOptions = {
  variables: SkillsCreateMutation["variables"]["input"]
}

export const skillsCreateRelay = async (options: SkillsCreateOptions) => {
  const { variables } = options
  const formattedVariables = {
    ...variables,
    input: {
      skills: variables.skills.map((v) => ({
        ...v,
        name: v.name.trim(),
      })),
    },
  }
  const data = await commitMutationPromise<SkillsCreateMutation>(environment, {
    mutation: skillsCreateMutation,
    variables: formattedVariables,
  })

  return data.bulkCreateSkill.account.skills
}

const skillUpdateMutation = graphql`
  mutation SkillUpdateMutation($id: Int!, $name: String!) {
    update_skills_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
      name
    }
  }
`

export const updateSkillRelay = async (
  variables: SkillUpdateMutation["variables"],
) => {
  const data = await commitMutationPromise<SkillUpdateMutation>(environment, {
    mutation: skillUpdateMutation,
    variables,
  })

  return data
}

const skillDeleteMutation = graphql`
  mutation SkillDeleteMutation($id: Int!) {
    deleteSkill(id: $id) {
      account {
        id
        skills {
          id
        }
        competencies {
          id
        }
      }
    }
  }
`

export const skillDeleteRelay = async (
  variables: SkillDeleteMutation["variables"],
) => {
  const data = await commitMutationPromise<SkillDeleteMutation>(environment, {
    mutation: skillDeleteMutation,
    variables,
  })

  return data.deleteSkill.account.skills
}
