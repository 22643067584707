import ReactRailsUJS from "react_ujs"
import "regenerator-runtime/runtime.js"
import Rollbar from "rollbar"

import "../stylesheets/application.css"

import { createAnalytics } from "../src/helpers/analytics"

// Only load actually referenced components to avoid breaking Webpack's chunk lazy loading
ReactRailsUJS.useContext(
  // Webpack's module name parsing is very obscure, so we have to make the regex pretty weak to ensure
  // react_component name to module matching doesn't break. It would be cleaner with multiple contexts
  // through ReactRailsUJS.useContexts(), but that requires us to upgrade to react_ujs:3 for React 18 compat,
  // which in turn requires us to upgrade from Shakapacker 6 to 7.
  // See README for details.
  require.context("src", true, /\/pages|\/react-rails-components/),
)

// Exposing analytics as global to enable tracking outside of React app.
// Remove this once the app becomes a full SPA.
// Initialise even when analytics is disabled (config is null)
window.runnAnalytics = createAnalytics(window.__RUNN_ANALYTICS_CONFIG__)

// https://docs.rollbar.com/docs/importing-or-requiring-rollbar
if (window.__RUNN_ROLLBAR_CONFIG__) {
  window.Rollbar = new Rollbar(window.__RUNN_ROLLBAR_CONFIG__)
}

// Setup Coralogix if when the config is present
document.addEventListener("DOMContentLoaded", (_) => {
  if (window._RUNN_CORALOGIX_RUM_ENABLED && window._RUNN_CORALOGIX_RUM_CONFIG) {
    // Lazy load this to minimise the security impact of loading potentially unnecessary third party code
    import("@coralogix/browser").then(() => {
      window.CoralogixRum.init(window._RUNN_CORALOGIX_RUM_CONFIG)
    })
  }
})
