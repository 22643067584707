import React from "react"

type Props = {
  children: React.ReactNode
  style?: Record<string, any>
}

const PageBody = (props: Props) => (
  <div
    style={{
      maxWidth: "1650px",
      margin: "0 auto 70px auto",
      padding: "0 2rem",
      display: "grid",
      gridGap: 20,
      gridTemplateColumns: "minmax(0, 1fr)",
      width: "100%",
      ...props.style,
    }}
  >
    {props.children}
  </div>
)

export default PageBody
