import React from "react"

type Props = {
  className?: string
  color?: string
}

const Filter = (props: Props) => {
  const { className, color = "var(--midnight)" } = props
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M6.3334 11.2002h4.2222s1 0 1 1v0s0 1 -1 1h-4.2222s-1 0 -1 -1v0s0 -1 1 -1"
      />
      <path
        fill={color}
        d="M4.489 7.2002h7.9111s0.9333 0 0.9333 0.9333v0s0 0.9333 -0.9333 0.9333h-7.9111s-0.9333 0 -0.9333 -0.9333v0s0 -0.9333 0.9333 -0.9333"
      />
      <path
        fill={color}
        d="M2.711 2.9336h11.4666s0.9333 0 0.9333 0.9333v0s0 0.9333 -0.9333 0.9333h-11.4666s-0.9333 0 -0.9333 -0.9333v0s0 -0.9333 0.9333 -0.9333"
      />
    </svg>
  )
}

export default Filter
