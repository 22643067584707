import React from "react"

type Props = {
  color?: any
  alt?: string
}
const NonBillableIcon = (props: Props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.alt}</title>
    <path
      fill={props.color || "currentColor"}
      d="M6 0C2.68625 0 0 2.68625 0 6C0 9.31375 2.68625 12 6 12C9.31375 12 12 9.31375 12 6C12 2.68625 9.31375 0 6 0ZM1.37175 4.39113L4.41575 5.75362L5.36925 6.17163L6.07638 6.49275L6.65525 6.73938C6.76237 6.83238 6.81588 6.9535 6.81588 7.10362C6.81588 7.31812 6.74237 7.47162 6.59613 7.56425C6.44963 7.65725 6.27288 7.70375 6.06575 7.70375C5.56563 7.70375 5.11925 7.52163 4.72638 7.15737L3.9015 7.993C4.45125 8.46437 5.05862 8.704 5.72262 8.71075V9.6325H6.41925V8.71075C6.83325 8.67537 7.1925 8.53763 7.49613 8.29825C7.79938 8.059 7.99063 7.75025 8.06913 7.3715L10.2933 8.36225C9.45925 9.87488 7.84963 10.9 5.99988 10.9C3.29375 10.9 1.1 8.70613 1.1 6C1.1 5.43612 1.19638 4.89513 1.37175 4.39113ZM7.6945 5.91437L6.73 5.48575L6.205 5.25L5.44425 4.90712C5.4085 4.85725 5.39062 4.80725 5.39062 4.75737C5.39062 4.586 5.46387 4.46438 5.61037 4.39313C5.75662 4.3215 5.92275 4.28575 6.10838 4.28575C6.45862 4.28575 6.80137 4.40387 7.137 4.63925L7.91925 3.83575C7.46925 3.49275 6.96925 3.30725 6.41925 3.27863V2.35737H5.72262V3.27863C5.35112 3.3 5.02612 3.40587 4.74762 3.59462C4.46912 3.78413 4.27975 4.03562 4.17987 4.35L1.89513 3.32538C2.76975 1.986 4.28088 1.1 5.99988 1.1C8.70613 1.1 10.8999 3.29387 10.8999 6C10.8999 6.43925 10.8412 6.8645 10.7329 7.2695L7.6945 5.91437Z"
    />
  </svg>
)

export default NonBillableIcon
