import React, { HTMLAttributes } from "react"

import styles from "./CardFooter.module.css"

type Props = HTMLAttributes<HTMLOrSVGElement> & {
  children: React.ReactNode
  style?: Record<string, any>
}

const CardFooter = (props: Props) => {
  const { children, ...rest } = props
  return (
    <div className={styles.container} {...rest}>
      {children}
    </div>
  )
}

export default CardFooter
