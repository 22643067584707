import React from "react"

export const TangramPersonLegUp = () => (
  <svg
    width="58"
    height="92"
    viewBox="0 0 58 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.7244 22.4457H58.0339L26.7244 54.2277V22.4457Z"
      fill="#F5848C"
    />
    <path
      d="M34.1311 22.4733L23.0615 11.2366L34.1311 -1.50331e-05L45.2007 11.2366L34.1311 22.4733Z"
      fill="#FDCD4F"
    />
    <path
      d="M48.8635 92.0895V76.1985L33.2088 92.0895H48.8635Z"
      fill="#0A9CC1"
    />
    <path
      d="M26.7515 22.4733L26.7515 44.9465L4.6123 22.4733L26.7515 22.4733Z"
      fill="#67D0D5"
    />
    <path
      d="M15.6548 65.5194H37.7939L26.7244 54.2828H4.5852L15.6548 65.5194Z"
      fill="#FFB077"
    />
    <path
      d="M26.7112 54.2691L48.8503 31.7958V76.7423L26.7112 54.2691Z"
      fill="#4057DF"
    />
    <path
      d="M0.0145258 65.5594L15.7073 65.5209L0.0525235 49.6299L0.0145258 65.5594Z"
      fill="#B19DE6"
    />
  </svg>
)
