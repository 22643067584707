import React from "react"

const Sort = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00809 2.83103H8.55411V1.33337H1.00809C0.59455 1.33337 0.259277 1.66865 0.259277 2.08219C0.259277 2.49572 0.59455 2.83103 1.00809 2.83103Z"
      fill="#707593"
    />
    <path
      d="M1.00809 6.51857H8.55411V4.96301H1.00809C0.59455 4.96301 0.259277 5.31125 0.259277 5.74081C0.259277 6.17036 0.59455 6.51857 1.00809 6.51857Z"
      fill="#707593"
    />
    <path
      d="M1.00809 8.59265C0.59455 8.59265 0.259277 8.92792 0.259277 9.34149C0.259277 9.75506 0.59455 10.0903 1.00809 10.0903H6.2505C6.19128 9.90793 6.16002 9.71556 6.16002 9.51824C6.16002 9.1878 6.24666 8.87084 6.4085 8.59265H1.00809Z"
      fill="#707593"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6296 0.583374C11.0438 0.583374 11.3796 0.91916 11.3796 1.33337V9.93567L12.7537 8.83641C13.0771 8.57765 13.5491 8.63009 13.8079 8.95354C14.0666 9.27699 14.0142 9.74895 13.6907 10.0077L11.0981 12.0818C10.8242 12.3009 10.435 12.3009 10.1611 12.0818L7.56849 10.0077C7.24505 9.74895 7.19261 9.27699 7.45136 8.95354C7.71012 8.63009 8.18209 8.57765 8.50554 8.83641L9.87961 9.93567V1.33337C9.87961 0.91916 10.2154 0.583374 10.6296 0.583374Z"
      fill="#707593"
    />
  </svg>
)

export default Sort
