export const SET_INTERACTING_WITH_PILL = "SET_INTERACTING_WITH_PILL"
export const SET_HIGHLIGHTED_ITEM_DATA = "SET_HIGHLIGHTED_ITEM_DATA"
export const SET_HIGHLIGHTED_COLUMN_DATA = "SET_HIGHLIGHTED_COLUMN_DATA"
export const SET_SELECTED_CELL_DATE = "SET_SELECTED_CELL_DATE"

export type GlobalReducerState = {
  interactingWithPill: boolean
  highlightedItemData: HighlightedItemData | null
  highlightedCellData: any
  highlightedRowData: any
  selectedCellDate: any
}

type HighlightedItemData = {
  type: "assignment" | "milestone" | "phase"
  start_date: string
  end_date: string
  color?: string
}

export const setHighlightedItemData = (
  highlightedItemData: HighlightedItemData,
) => ({
  type: SET_HIGHLIGHTED_ITEM_DATA,
  payload: highlightedItemData,
})

export const setHighlightedCellData = (highlightedCellData: any) => ({
  type: SET_HIGHLIGHTED_COLUMN_DATA,
  payload: highlightedCellData,
})

const defaultState = {
  interactingWithPill: false,
  highlightedItemData: null,
  highlightedCellData: null,
  highlightedRowData: {},
  selectedCellDate: null,
}

export default (
  state: GlobalReducerState = defaultState,
  action: any,
): GlobalReducerState => {
  switch (action.type) {
    case SET_INTERACTING_WITH_PILL:
      return {
        ...state,
        interactingWithPill: action.payload,
      }
    case SET_HIGHLIGHTED_ITEM_DATA:
      return {
        ...state,
        highlightedItemData: action.payload,
      }
    case SET_HIGHLIGHTED_COLUMN_DATA:
      return {
        ...state,
        highlightedCellData: action.payload,
      }
    case SET_SELECTED_CELL_DATE:
      return {
        ...state,
        selectedCellDate: action.payload,
      }
    default:
      return state
  }
}
