import { dateHelpers } from "@runn/calculations"
import { v4 as createUniqueId } from "uuid"

import { reportError } from "~/helpers/error-helpers"

type Session = {
  userId: number
  accountId: number
  sessionId: string
  authToken: string
  expiryDate: number
  hasuraEndpoint: string
  nodeServerEndpoint: string
}

const emptySessionObject: Session = {
  userId: 0,
  accountId: 0,
  sessionId: "",
  authToken: "",
  expiryDate: 0,
  hasuraEndpoint: "",
  nodeServerEndpoint: "",
}

type FetchSessionFn = (clientId: string) => Promise<Session>

const defaultFetchSessionFn: FetchSessionFn = async (
  clientId: string,
): Promise<Session> => {
  try {
    const searchParams = new URLSearchParams({
      client_id: clientId,
      todays_date: dateHelpers.getTodaysDate(),
    }).toString()
    const response = await fetch(`/user/current_session?${searchParams}`, {
      method: "POST",
    })

    // // If someone is logged out - the session is redirected to sign_in page
    // // So refresh the browser - because otherwise it will error.
    if (response.redirected === true) {
      window.location.reload()
      // we return a fake object - so we can properly handle the error later
      // rather than throwing a type error somewhere down the line
      return emptySessionObject
    }

    const {
      user_id: userId,
      account_id: accountId,
      session_id: sessionId,
      auth_token: authToken,
      expiry_date: expiryDate,
      hasura_endpoint: hasuraEndpoint,
      node_server_endpoint: nodeServerEndpoint,
    } = await response.json()

    return {
      userId,
      accountId,
      sessionId,
      authToken,
      expiryDate,
      hasuraEndpoint,
      nodeServerEndpoint,
    }
  } catch (e) {
    // Only log errors when client is online (they might be connectivity issues caused by the server)
    if (window.navigator.onLine) {
      await Promise.all([
        reportError("Error fetching session token", e),
        reportError(e),
      ])
      window.location.reload()
    }
    return emptySessionObject
  }
}

type HasuraAuthTokenStoreConstructorOptions = {
  fetchSessionFn?: FetchSessionFn
  expiryDateBufferMs?: number // how much time do we allow before hiting the expiry date before fetching a new token?
}

class SessionStore {
  private readonly fetchSessionFn: FetchSessionFn
  private readonly expiryDateBufferMs: number
  private clientId: string
  private session: Session | undefined
  private promise: Promise<Session> | undefined

  public constructor(options: HasuraAuthTokenStoreConstructorOptions = {}) {
    this.clientId = createUniqueId()
    this.fetchSessionFn = options.fetchSessionFn ?? defaultFetchSessionFn
    this.expiryDateBufferMs = options.expiryDateBufferMs ?? 60 * 1000
  }

  private async concurrentFetch(): Promise<Session> {
    if (this.promise != null) {
      return this.promise
    }
    this.promise = this.fetchSessionFn(this.clientId)
    this.session = await this.promise
    this.promise = undefined
    return this.session
  }

  public async getUserId(): Promise<number> {
    if (this.session?.userId) {
      return this.session.userId
    }
    const { userId } = await this.concurrentFetch()
    return userId
  }

  public async getAccountId(): Promise<number> {
    if (this.session?.accountId) {
      return this.session.accountId
    }
    const { accountId } = await this.concurrentFetch()
    return accountId
  }

  public async getSessionId(): Promise<string> {
    if (this.session?.sessionId) {
      return this.session.sessionId
    }
    const { sessionId } = await this.concurrentFetch()
    return sessionId
  }

  public getSessionExpiryDate() {
    return this.session?.expiryDate ?? 0
  }

  public async getAuthToken(): Promise<string> {
    if (
      this.session?.authToken &&
      this.session.expiryDate > Date.now() + this.expiryDateBufferMs
    ) {
      return this.session.authToken
    }

    const { authToken } = await this.concurrentFetch()
    return authToken
  }

  public async getHasuraEndpoint(): Promise<string> {
    if (this.session?.hasuraEndpoint) {
      return this.session.hasuraEndpoint
    }
    const { hasuraEndpoint } = await this.concurrentFetch()
    return hasuraEndpoint
  }

  public async getNodeServerEndpoint(): Promise<string> {
    if (this.session?.nodeServerEndpoint) {
      return this.session.nodeServerEndpoint
    }
    const { nodeServerEndpoint } = await this.concurrentFetch()
    return nodeServerEndpoint
  }
}

export default SessionStore
