import { Icon } from "@blueprintjs/core"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import styles from "./WorkstreamsContainer.module.css"

import { WorkstreamCard_user$key } from "./__generated__/WorkstreamCard_user.graphql"

import { sortByString } from "~/helpers/sorting-helpers"

import AddNewWorkstream from "./AddNewWorkstream"
import WorkstreamItem from "./WorkstreamItem"

type Props = {
  canCreate: boolean
  canEdit: boolean
  user: WorkstreamCard_user$key
  activeFilter: boolean | "all"
}

const WorkstreamCard = (props: Props) => {
  const { canEdit, canCreate, activeFilter } = props

  const user = useFragment(
    graphql`
      fragment WorkstreamCard_user on users {
        id
        account {
          id
          workstreams {
            id
            name
            archived
          }
        }
        ...WorkstreamForm_user
        ...WorkstreamItem_user
      }
    `,
    props.user,
  )

  const allWorkstreams = user.account.workstreams
  const sortedWorkstreams = [...allWorkstreams].sort((a, b) =>
    sortByString(a.name, b.name),
  )

  const [activeWorkstreams, archivedWorkstreams] = sortedWorkstreams.reduce(
    ([active, archived], workstream) => {
      if (workstream.archived) {
        archived.push(workstream)
      } else {
        active.push(workstream)
      }
      return [active, archived]
    },
    [[] as (typeof allWorkstreams)[0][], [] as (typeof allWorkstreams)[0][]],
  )

  const visibleWorkstreams =
    activeFilter === "all"
      ? sortedWorkstreams
      : activeFilter
        ? activeWorkstreams
        : archivedWorkstreams

  return (
    <div className={styles.container}>
      {visibleWorkstreams.map((w) => (
        <WorkstreamItem key={w.id} workstream={w} canEdit={canEdit} user={user}>
          {w.name} {w.archived && <Icon icon="archive" size={12} />}
        </WorkstreamItem>
      ))}
      {activeFilter && canCreate && (
        <AddNewWorkstream
          workstreams={activeWorkstreams}
          archivedWorkstreams={archivedWorkstreams}
        />
      )}
      {!activeFilter && !visibleWorkstreams.length && (
        <div className={styles.noResults}>No archived workstreams found</div>
      )}
    </div>
  )
}

export default WorkstreamCard
