import React from "react"

type Props = {
  color?: any
  size?: number
  alt?: string
}
const Start = (props: Props) => {
  const { size = 18, color = "#3C4260", alt } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8625 8.6375L11.4875 4.2625C11.33 4.09625 11.1112 4 10.8662 4C10.385 4 9.99121 4.39375 9.99121 4.875C9.99121 5.12 10.0875 5.33875 10.245 5.49625L13.1237 8.375H6.74121C6.25996 8.375 5.86621 8.76875 5.86621 9.25C5.86621 9.73125 6.25996 10.125 6.74121 10.125H13.1325L10.2537 13.0037C10.0962 13.1612 9.99996 13.38 9.99996 13.625C9.99996 14.1063 10.3937 14.5 10.875 14.5C11.12 14.5 11.3387 14.4038 11.4962 14.2463L15.8712 9.87125C16.0287 9.71375 16.125 9.495 16.125 9.25C16.125 9.005 16.02 8.795 15.8625 8.6375Z"
        fill={color}
      />
      <rect x="3" y="4.00879" width="2" height="10.5" fill={color} />
    </svg>
  )
}

export default Start
