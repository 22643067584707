import { graphql } from "react-relay"

import { CompetencyCreateMutation } from "./__generated__/CompetencyCreateMutation.graphql"
import { CompetencyDeleteMutation } from "./__generated__/CompetencyDeleteMutation.graphql"
import { CompetencyUpdateMutation } from "./__generated__/CompetencyUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

const competencies = graphql`
  mutation CompetencyCreateMutation($competencies: [CompetencyToCreate!]!) {
    createCompetency(competencies: $competencies) {
      account {
        id
        people {
          id
          competencies {
            id
          }
        }
        competencies {
          id
          level
          person_id
          skill_id
          skill {
            id
            name
          }
          person {
            id
            is_placeholder
            first_name
            last_name
          }
        }
      }
    }
  }
`

type CompetenciesCreateOptions = {
  variables: CompetencyCreateMutation["variables"]
}

export const competenciesCreateRelay = async (
  options: CompetenciesCreateOptions,
) => {
  const { variables } = options
  const data = await commitMutationPromise<CompetencyCreateMutation>(
    environment,
    {
      mutation: competencies,
      variables,
    },
  )

  return data.createCompetency.account.competencies
}

const competencyUpdateMutation = graphql`
  mutation CompetencyUpdateMutation($id: Int!, $level: Int!) {
    updateCompetency(id: $id, level: $level) {
      competency {
        id
        level
        person {
          id
          first_name
          last_name
        }
      }
    }
  }
`

export const updateCompetencyRelay = async (
  variables: CompetencyUpdateMutation["variables"],
) => {
  const data = await commitMutationPromise<CompetencyUpdateMutation>(
    environment,
    {
      mutation: competencyUpdateMutation,
      variables,
    },
  )

  return data
}

const competencyDeleteMutation = graphql`
  mutation CompetencyDeleteMutation($id: Int!) {
    deleteCompetency(id: $id) {
      account {
        id
        people {
          id
          competencies {
            id
          }
        }
        competencies {
          id
        }
      }
    }
  }
`

export const competencyDeleteRelay = async (
  variables: CompetencyDeleteMutation["variables"],
) => {
  const data = await commitMutationPromise<CompetencyDeleteMutation>(
    environment,
    {
      mutation: competencyDeleteMutation,
      variables,
    },
  )

  return data.deleteCompetency.account.competencies
}
