/**
 * @generated SignedSource<<1852d1040a0b2c77d7d21cb47303ac25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SkillUpdateMutation$variables = {
  id: number;
  name: string;
};
export type SkillUpdateMutation$data = {
  readonly update_skills_by_pk: {
    readonly id: number;
    readonly name: string;
  } | null | undefined;
};
export type SkillUpdateMutation = {
  response: SkillUpdateMutation$data;
  variables: SkillUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "skills",
    "kind": "LinkedField",
    "name": "update_skills_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "73ecd03e73b7107fbbd93e48b705bdde",
    "id": null,
    "metadata": {},
    "name": "SkillUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation SkillUpdateMutation(\n  $id: Int!\n  $name: String!\n) {\n  update_skills_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb931ec6f753c3c29faa475c538bd3a9";

export default node;
