import {
  ContextMenu,
  ContextMenuChildrenProps,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
} from "@blueprintjs/core"
import React, { useCallback, useMemo, useState } from "react"
import { graphql, useFragment } from "react-relay"

import dropdownStyles from "~/common/Dropdown/Dropdown.module.css"

import { HolidaysGroupList_viewer$key } from "./__generated__/HolidaysGroupList_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { fetchWithCsrf } from "../../helpers/fetch-helpers"
import routes from "../../helpers/routes"
import * as hashids from "~/helpers/hashids"

import Dialog from "../../common/Dialog"
import { IconThreeDot } from "../../common/IconThreeDot"
import TableWithFilter from "~/common/TableWithFilter/TableWithFilter"

import { usePermissions } from "~/Permissions/usePermissions"

import DeleteModal from "../../Modals/Delete/DeleteModal"

import EditHolidaysGroupForm from "../Forms/EditHolidaysGroupForm/EditHolidaysGroupForm"

const fragment = graphql`
  fragment HolidaysGroupList_viewer on accounts {
    holidays_groups(order_by: { name: asc }) {
      id
      name
      country_code
      region_name
      country_name
      holidays_aggregate(distinct_on: name) {
        aggregate {
          count
        }
      }
      people_aggregate(where: { active: { _eq: true } }, distinct_on: id) {
        aggregate {
          count
        }
      }
      ...EditHolidaysGroupForm_holidays_group
    }
    ...EditHolidaysGroupForm_account
  }
`

const HolidaysGroupList = () => {
  const viewerQuery = useHasuraContext()
  const viewer = useFragment<HolidaysGroupList_viewer$key>(
    fragment,
    viewerQuery,
  )
  const holidaysGroups = viewer.holidays_groups
  const { can, subject } = usePermissions()
  const holidaysSubject = subject("HolidaysGroup")
  const canEdit = can("edit", holidaysSubject)
  const canDelete = can("delete", holidaysSubject)

  const [groupToEdit, setGroupToEdit] = useState(null)
  const [groupToDelete, setGroupToDelete] = useState(null)

  const closeEditModal = useCallback(() => {
    setGroupToEdit(null)
  }, [])

  const closeDeleteModal = useCallback(() => {
    setGroupToDelete(null)
  }, [])

  const handleDelete = async () => {
    const groupHashId = hashids.holidaysGroups.encode(groupToDelete.id)
    await fetchWithCsrf(routes.viewHolidaysGroupUrl(groupHashId), {
      method: "DELETE",
      redirect: "manual",
    })

    closeDeleteModal()
    document.location.href = routes.holidaysGroupListUrl()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Holiday Group",
        accessor: "name",
        Cell: (cell) => <div>{cell.value}</div>,
      },
      {
        Header: "Region",
        accessor: "region_name",
      },
      {
        Header: "Country",
        accessor: "country_name",
      },
      {
        Header: "Holidays",
        accessor: "holidays_aggregate.aggregate.count",
        Cell: (cell) => <div>{cell.value || 0}</div>,
      },
      {
        Header: "People",
        accessor: "people_aggregate.aggregate.count",
        Cell: (cell) => <div>{cell.value || 0}</div>,
      },
      {
        Header: " ",
        accessor: "",
        Cell: (cell) => {
          if (!canEdit && !canDelete) {
            return <></>
          }

          const holidayGroupItem = cell.row.original

          return (
            <ContextMenu
              content={
                <Menu className={dropdownStyles.dropdown}>
                  {canEdit && (
                    <>
                      <MenuItem
                        text="Edit Details"
                        icon={<Icon icon="cog" />}
                        onClick={() => {
                          setGroupToEdit(holidayGroupItem)
                        }}
                      />
                      <MenuItem
                        text="Edit People"
                        icon={<Icon icon="people" />}
                        href={`/holidays/${holidayGroupItem.hashid}#People`}
                      />
                      <MenuDivider />
                    </>
                  )}
                  {canDelete && (
                    <MenuItem
                      text="Delete"
                      icon={<Icon icon="trash" />}
                      onClick={() => setGroupToDelete(holidayGroupItem)}
                    />
                  )}
                </Menu>
              }
            >
              {(ctxMenuProps: ContextMenuChildrenProps) => {
                const onMenuClick = (e) => {
                  e.stopPropagation()
                  ctxMenuProps.onContextMenu(e)
                }

                return (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {ctxMenuProps.popover}
                    <IconThreeDot
                      onClick={onMenuClick}
                      active={ctxMenuProps.contentProps.isOpen}
                    />
                  </div>
                )
              }}
            </ContextMenu>
          )
        },
      },
    ],
    [canEdit, canDelete],
  )

  const table = {
    autoResetGlobalFilter: false,
    columns,
    initialState: {
      sortBy: [{ id: "name" }],
    },
    data: holidaysGroups.map((t) => ({
      ...t,
      hashid: hashids.holidaysGroups.encode(t.id),
    })),
  }

  return (
    <>
      <TableWithFilter
        type="holidays"
        table={table}
        showActiveFilterDropdown={false}
        gridTemplateColumns="minmax(150px, 1.5fr) repeat(2, 1fr) repeat(2, 0.6fr) 30px"
      />

      {groupToEdit && (
        <Dialog isOpen={Boolean(groupToEdit)} onClose={closeEditModal}>
          <EditHolidaysGroupForm
            account={viewer}
            holidaysGroup={groupToEdit}
            closeDialog={closeEditModal}
          />
        </Dialog>
      )}

      {groupToDelete && (
        <Dialog isOpen={Boolean(groupToDelete)} onClose={closeDeleteModal}>
          <DeleteModal
            type="holiday group"
            name={groupToDelete.name}
            closeDialog={closeDeleteModal}
            onSubmit={handleDelete}
            isAdmin={canEdit}
            deletable={true}
            customWarning={
              Boolean(groupToDelete.people_aggregate.aggregate.count)
                ? "This will remove all scheduled future time-offs for the people in this group and cannot be undone."
                : null
            }
          />
        </Dialog>
      )}
    </>
  )
}

export default HolidaysGroupList
