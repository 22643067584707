/**
 * @generated SignedSource<<f2e319d8680d59283ff8a5c3cc91f09b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PersonBulkUpdateHolidaysGroupMutation$variables = {
  current_holidays_group_id?: number | null | undefined;
  id: ReadonlyArray<number>;
  new_holidays_group_id?: number | null | undefined;
};
export type PersonBulkUpdateHolidaysGroupMutation$data = {
  readonly update_people: {
    readonly returning: ReadonlyArray<{
      readonly account: {
        readonly holidays_groups: ReadonlyArray<{
          readonly id: number;
          readonly people_aggregate: {
            readonly aggregate: {
              readonly count: number;
            } | null | undefined;
          };
        }>;
        readonly id: number;
        readonly people: ReadonlyArray<{
          readonly holidays_group: {
            readonly id: number;
          } | null | undefined;
          readonly id: number;
        }>;
      };
      readonly id: number;
    }>;
  } | null | undefined;
};
export type PersonBulkUpdateHolidaysGroupMutation = {
  response: PersonBulkUpdateHolidaysGroupMutation$data;
  variables: PersonBulkUpdateHolidaysGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "current_holidays_group_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "new_holidays_group_id"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "holidays_group_id",
            "variableName": "new_holidays_group_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "people_mutation_response",
    "kind": "LinkedField",
    "name": "update_people",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "people",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "is_placeholder": {
                        "_eq": false
                      }
                    }
                  }
                ],
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "people",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "holidays_groups",
                    "kind": "LinkedField",
                    "name": "holidays_group",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "people(where:{\"is_placeholder\":{\"_eq\":false}})"
              },
              {
                "alias": null,
                "args": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "_eq",
                            "variableName": "current_holidays_group_id"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "id"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "where"
                  }
                ],
                "concreteType": "holidays_groups",
                "kind": "LinkedField",
                "name": "holidays_groups",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "distinct_on",
                        "value": "id"
                      }
                    ],
                    "concreteType": "people_aggregate",
                    "kind": "LinkedField",
                    "name": "people_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "people_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "people_aggregate(distinct_on:\"id\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonBulkUpdateHolidaysGroupMutation",
    "selections": (v4/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PersonBulkUpdateHolidaysGroupMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "1ea76e2d7e5e7c05015f233fa63a694d",
    "id": null,
    "metadata": {},
    "name": "PersonBulkUpdateHolidaysGroupMutation",
    "operationKind": "mutation",
    "text": "mutation PersonBulkUpdateHolidaysGroupMutation(\n  $id: [Int!]!\n  $current_holidays_group_id: Int\n  $new_holidays_group_id: Int\n) {\n  update_people(where: {id: {_in: $id}}, _set: {holidays_group_id: $new_holidays_group_id}) {\n    returning {\n      id\n      account {\n        id\n        people(where: {is_placeholder: {_eq: false}}) {\n          id\n          holidays_group {\n            id\n          }\n        }\n        holidays_groups(where: {id: {_eq: $current_holidays_group_id}}) {\n          id\n          people_aggregate(distinct_on: id) {\n            aggregate {\n              count\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e584954238871a336e71c90b053f7353";

export default node;
