import React, { useState } from "react"
import { connect } from "react-redux"

import styles from "./PlannerRightColumn.module.css"

type Props = {
  rowId?: string
  hasDropShadow?: boolean
  showHighlight?: boolean
  isPlaceholder?: boolean
  isUnconfirmedProject?: boolean
  type?: string
  interactingWithPill: boolean
  children: React.ReactNode
}

const PlannerRightColumn = (props: Props) => {
  const { children, hasDropShadow, showHighlight = true, rowId, type } = props

  const [highlight, setHighlight] = useState(false)

  const handleMouseEnter = () => {
    if (!props.interactingWithPill && showHighlight) {
      setHighlight(true)
    }
  }

  const handleMouseLeave = () => {
    if (!props.interactingWithPill && showHighlight) {
      setHighlight(false)
    }
  }

  return (
    <div
      className={`${styles.plannerRightColumn} ${
        hasDropShadow ? styles.dropShadow : ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-test={`type-${type}`}
      id={rowId}
    >
      {highlight && (
        <div
          className={styles.highlight}
          style={{
            backgroundColor: props.isPlaceholder
              ? "#abd3ff"
              : props.type === "timeOff"
                ? "rgb(117, 121, 145)"
                : props.isUnconfirmedProject
                  ? "var(--tentative)"
                  : "",
          }}
        />
      )}
      {children}
    </div>
  )
}

const mapStateToProps = (state) => ({
  interactingWithPill: state.global.interactingWithPill,
})

const connector = connect(mapStateToProps)
export default connector(PlannerRightColumn)
