import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"

import { WorkstreamForm_user$key } from "./__generated__/WorkstreamForm_user.graphql"

import { track } from "~/helpers/analytics"
import { reportError } from "~/helpers/error-helpers"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import UniqueInput from "~/common/UniqueInput"
import Button from "~/common/buttons/Button"

import { workstreamUpdateRelay } from "~/mutations/Workstream"

import { Workstream as WorkstreamType } from "./WorkstreamItem"

type Props = {
  closeDialog: () => void
  workstream: WorkstreamType
  user: WorkstreamForm_user$key
}

const fragment = graphql`
  fragment WorkstreamForm_user on users {
    account {
      all_workstreams: workstreams {
        id
        name
      }
    }
  }
`

const WorkstreamForm = (props: Props) => {
  const { closeDialog, workstream } = props
  const user = useFragment(fragment, props.user)

  const {
    account: { all_workstreams },
  } = user
  const [isUpdating, setIsUpdating] = useState(false)
  const [workstreamName, setWorkstreamName] = useState(workstream?.name ?? "")

  const handleSubmit = async () => {
    setIsUpdating(true)

    try {
      await workstreamUpdateRelay({
        variables: {
          workstreamId: workstream.id,
          name: workstreamName,
        },
      })
      track("Workstream Updated")
      return
    } catch (error) {
      void reportError("Workstream updating", error)
    } finally {
      setIsUpdating(false)
      closeDialog()
    }
  }

  const [existingWorkstream, setExistingWorkstream] = useState<string>(null)

  const handleChangeWorkstreamName = (e) => {
    const name = e.target.value
    const allWorkstreamsExcludingCurrent = all_workstreams.filter(
      (w) => w.name !== workstream.name,
    )
    const duplicateWorkstream =
      allWorkstreamsExcludingCurrent &&
      allWorkstreamsExcludingCurrent.find(
        (c) => c.name.toLowerCase().trim() === name.toLowerCase().trim(),
      )
    setWorkstreamName(name)
    setExistingWorkstream(duplicateWorkstream?.name ?? null)
  }

  return (
    <ModalFormWrapper headerTitle={`Update ${workstream.name}`}>
      <ModalBody>
        <UniqueInput
          name="workstream-name"
          id="workstream-name"
          duplicate={
            existingWorkstream && {
              name: existingWorkstream,
            }
          }
          placeholder="e.g. Research and Development"
          label="Workstream Name"
          value={workstreamName}
          onChange={handleChangeWorkstreamName}
          autoComplete="off"
          autoFocus
          style={{ marginBottom: "20px" }}
        />
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={() => closeDialog()} />
        <Button
          text="Update"
          loading={isUpdating}
          id="workstream-form-button"
          outlined={false}
          onClick={handleSubmit}
          disabled={
            isUpdating ||
            !workstreamName.trim().length ||
            !!existingWorkstream ||
            workstreamName === workstream.name
          }
          style={{ marginLeft: 7 }}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default WorkstreamForm
