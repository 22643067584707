import React, { useEffect, useState } from "react"

import Select, { ReactSelectProps } from "~/common/Select"

import {
  MANAGE_PERMISSIONS,
  ManageProjectsPermissionsOption,
} from "~/GLOBALVARS"

type Props = ReactSelectProps & {
  onChange: (value: ManageProjectsPermissionsOption) => void
  excludeNone: boolean
}

const SelectEditorType = (props: Props) => {
  const { onChange, defaultValue, excludeNone, label, name, id, ...rest } =
    props

  const excludedPermission = excludeNone
    ? "none_projects_none_others"
    : "all_projects_none_others"

  const editPermissions = MANAGE_PERMISSIONS.filter(
    (p) => p.value !== excludedPermission,
  )

  const [selectedProjectPermission, setSelectedProjectPermission] = useState(
    defaultValue ?? editPermissions[0],
  )

  useEffect(() => {
    setSelectedProjectPermission(defaultValue ?? editPermissions[0])
  }, [defaultValue, editPermissions])

  const handleChange = (e: ManageProjectsPermissionsOption) => {
    onChange(e)
    setSelectedProjectPermission(e)
  }

  return (
    <Select
      name={name || "invite-manage-projects-permissions"}
      id={id || "invite-manage-projects-permissions"}
      value={selectedProjectPermission}
      onChange={handleChange}
      label={label || ""}
      options={editPermissions}
      placeholder="Select Projects Permission"
      doNotSortOptions
      {...rest}
    />
  )
}

export default SelectEditorType
