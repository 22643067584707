import cc from "classcat"
import React from "react"

type Props = {
  color?: any
  alt?: string
  size?: number
  className?: string
}
const Update = (props: Props) => {
  const {
    color = "var(--midnight)",
    alt = "Update",
    size = 18,
    className,
  } = props

  return (
    <div
      style={{
        height: `${size}px`,
        width: `${size}px`,
        display: "flex",
        justifyContent: "center",
      }}
      className={cc([className])}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <title>{alt}</title>
        <path
          d="M10.125 1C5.90325 1 2.466 4.327 2.26875 8.5H0L3.40275 12.9377L6.75 8.5H4.51875C4.71375 5.5735 7.14975 3.25 10.125 3.25C13.227 3.25 15.75 5.773 15.75 8.875C15.75 11.977 13.227 14.5 10.125 14.5C8.33925 14.5 6.7485 13.6607 5.718 12.3595L4.33275 14.1962C5.772 15.7622 7.83075 16.75 10.125 16.75C14.4735 16.75 18 13.2235 18 8.875C18 4.5265 14.4735 1 10.125 1Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

export default Update
