import React, { ReactElement, ReactNode } from "react"
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
} from "react-error-boundary"

import ErrorModal from "~/common/ErrorModal/ErrorModal"
import ApplicationError from "~/common/errors/ApplicationError"

import { reportError } from "./error-helpers"

type Props = {
  children: ReactNode
  className?: string
  portalClassName?: string
  backdropClassName?: string

  getMessage?: (error: Error) => ReactNode
  fallback?: (props: FallbackProps) => ReactElement
}

const defaultMessage = (
  <>
    <p>
      Please <b>refresh the page</b> and try again.
    </p>
    <p>
      If you continue to encounter this error, <b>reset your browser cache</b>{" "}
      or use the chat to alert support.
    </p>
  </>
)

// Recoverable errors should be caught closer to the origin if possible,
// with feedback to the user provided in a non-obtrusive way (e.g. toast messages).
// This component is a "last resort" for errors which aren't recoverable.
// The modal it produces is blocking, the only choice given to the user is a hard refresh.
export default ({
  getMessage,
  children,
  fallback,
  backdropClassName,
  portalClassName,
  className,
}: Props) => {
  const defaultRender = ({ error }: FallbackProps) => {
    // Using ApplicationError signals that the message has been crafted in a user-friendly way.
    const friendlyMessage =
      error instanceof ApplicationError ? error.message : defaultMessage
    const message = getMessage ? getMessage(error) : friendlyMessage

    return (
      <ErrorModal
        backdropClassName={backdropClassName}
        portalClassName={portalClassName}
        className={className}
      >
        {message}
      </ErrorModal>
    )
  }
  const fallbackRender = fallback ?? defaultRender
  return (
    <ReactErrorBoundary
      fallbackRender={fallbackRender}
      onError={(error) => {
        void reportError(error)
      }}
    >
      {children}
    </ReactErrorBoundary>
  )
}
