import React from "react"

type Props = {
  color?: string
  className?: string
}

const PalmTreeIcon = (props: Props) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="M5.92023 1.81819C5.84276 1.81819 5.76527 1.82121 5.68715 1.82707L6.0003 1.63993C6.05369 1.60797 6.0869 1.54689 6.0869 1.48048V1.33986L6.28123 1.4075C6.32452 1.42258 6.3714 1.41762 6.41143 1.39435L7.57939 0.705427C7.651 0.662983 7.6842 0.569949 7.65752 0.485796C7.63147 0.404659 7.44625 0 6.3333 0C5.59438 0 4.53514 0.786938 3.99998 1.68946C3.46483 0.786938 2.40558 0 1.66667 0C0.553717 0 0.368499 0.404659 0.342452 0.485796C0.315764 0.569966 0.348967 0.663001 0.420576 0.705427L1.58854 1.39435C1.62859 1.41762 1.67513 1.42258 1.71874 1.4075L1.91309 1.33984V1.48047C1.91309 1.54688 1.94629 1.60795 1.99968 1.63991L2.31283 1.82705C2.23471 1.82119 2.15724 1.81817 2.07976 1.81817C0.874668 1.81819 0 2.5064 0 3.45455C0 3.50799 0.0214843 3.55879 0.0589216 3.59322C0.096359 3.62784 0.146484 3.6424 0.194015 3.63387L1.33692 3.42612C1.38868 3.41671 1.43328 3.38155 1.45735 3.33096L1.55795 3.12038L1.61882 3.24786C1.65235 3.31746 1.72267 3.35547 1.79395 3.34303L3.08764 3.10782C2.54863 4.82146 3.14905 8.71824 3.16894 8.84819C3.18229 8.93572 3.25196 9 3.33333 9H5C5.05958 9 5.11458 8.96538 5.1442 8.90927C5.17416 8.85298 5.17416 8.78373 5.14453 8.72744C5.13019 8.70068 3.60856 5.08444 4.26434 2.99L6.20605 3.34303C6.27604 3.35509 6.34797 3.31746 6.38118 3.24786L6.44205 3.12038L6.54265 3.33096C6.56674 3.38156 6.61133 3.41671 6.66308 3.42612L7.80598 3.63387C7.81509 3.63547 7.82422 3.63636 7.83333 3.63636C7.87239 3.63636 7.9108 3.62128 7.94108 3.59322C7.97852 3.55877 8 3.50799 8 3.45455C7.99997 2.5064 7.1253 1.81819 5.92023 1.81819Z"
      fill={props.color || "white"}
    />
  </svg>
)

export default PalmTreeIcon
