import cc from "classcat"
import React from "react"
import { match } from "ts-pattern"

import styles from "./SimpleLabel.module.css"

type Props = {
  text: string
  className?: string
  style?: Record<string, any>
  type?: "new" | "beta" | "gradient"
}

const SimpleLabel = (props: Props) => {
  const { text, type, style, className } = props

  const background = match(type)
    .with("new", () => "var(--shadow)")
    .with("beta", () => "var(--runn-blue)")
    .with(
      "gradient",
      () => "linear-gradient(180deg, var(--runn-blue), var(--runn-purple))",
    )
    .otherwise(() => "var(--snow)")

  const color = match(type)
    .with("new", () => "var(--white)")
    .with("beta", () => "var(--white)")
    .with("gradient", () => "var(--white)")
    .otherwise(() => "var(--midnight)")

  return (
    <span
      className={cc([styles.label, className])}
      style={{ background, color, ...style }}
    >
      {text}
    </span>
  )
}

export default SimpleLabel
