import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

import { isEmailValid } from "~/helpers/email"

import Input from "~/common/Input"

type Props = {
  id?: string
  onChange: (any) => void
  value: string | any
  label?: string
  disabled?: boolean
  handleValidation?: (valid: boolean) => void
  name?: string
  className?: string
  style?: Record<string, any>
  tabIndex?: number
  autoFocus?: boolean
  isDuplicate?: boolean
  lowercaseTrimed?: boolean
}

const InputEmail = (props: Props) => {
  const {
    value,
    onChange,
    handleValidation = () => void 0,
    disabled = false,
    isDuplicate,
    lowercaseTrimed = true,
    ...rest
  } = props

  const [emailIsInvalid, setEmailIsInvalid] = useState(false)

  const handleChange = (e: any) => {
    if (lowercaseTrimed) {
      e.target.value = e.target.value.trim().toLowerCase()
    }

    onChange(e)
  }

  const validateEmail = (email) => {
    const emailIsValid = isEmailValid(email)

    if (value && !emailIsValid) {
      setEmailIsInvalid(true)
      handleValidation(true)
    } else {
      setEmailIsInvalid(false)
      handleValidation(false)
    }
  }

  const setEmailDebounced = useDebouncedCallback((email: string) => {
    validateEmail(email)
  }, 800)

  useEffect(() => {
    if (value) {
      setEmailDebounced(value)
    } else {
      setEmailIsInvalid(false)
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleBlur = (e) => {
    validateEmail(e.target.value)
  }

  return (
    <Input
      {...rest}
      name={props.name || "email"}
      disabled={disabled}
      type="email"
      value={value}
      error={emailIsInvalid || isDuplicate}
      onChange={handleChange}
      onBlur={handleBlur}
      autoComplete="off"
    />
  )
}

export default InputEmail
