import { getEnvInfo } from "~/helpers/environment"

type Status = {
  badgeText: string
  color: string
  background: string
  linkHref?: string
  linkText?: string
}

const getStatus = (locationHref: string): Status | undefined => {
  const { env, pullRequestId, pullRequestUrl } = getEnvInfo(locationHref)

  switch (env) {
    case "review-app":
      return {
        badgeText: `Review App #${pullRequestId}`,
        linkText: "View PR in Github",
        linkHref: pullRequestUrl,
        color: "black",
        background: "#F1D301",
      }
    case "development":
      return {
        badgeText: "Development",
        color: "black",
        background: "#F5848C",
      }
    case "staging":
      return {
        badgeText: "Staging",
        color: "black",
        background: "#67D0D5",
      }
    default:
      return undefined
  }
}

export { getStatus, Status }
