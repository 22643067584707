import { Classes, Drawer, DrawerSize, Tooltip } from "@blueprintjs/core"
import React, { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./Status.modules.css"

import { StatusQuery } from "./__generated__/StatusQuery.graphql"

import AssetsDialog from "~/DevTools/Assets/AssetsDialog"
import NotificationDialog from "~/DevTools/Notifications/NotificationsDialog"

import FeatureFlags from "../FeatureFlags/FeatureFlags"

import StatusTooltipContent from "./StatusTooltipContent"
import { getStatus } from "./get-status"

export type StatusProps = {
  currentUser?: {
    account_id: number
    id: number
  }
  novuSubscriberId?: string
}

type ModalState = "feature-flags" | "icon-table" | "notifications" | undefined

const DevToolsStatus = ({ currentUser, novuSubscriberId }: StatusProps) => {
  const data = useLazyLoadQuery<StatusQuery>(
    graphql`
      query StatusQuery {
        ...StatusTooltipContent_gql
        ...FeatureFlags_gql
      }
    `,
    {},
  )

  const [visible, setVisible] = useState(true)
  const [modalState, setModalState] = useState<ModalState>(undefined)
  const status = getStatus(window.location.href)

  if (!status) {
    return null
  }

  const handleClick = () => setVisible((state) => !state)

  return (
    <div className={styles.parentContainer}>
      <Tooltip
        content={
          <StatusTooltipContent
            status={status}
            onOpenIconTable={() => setModalState("icon-table")}
            onOpenFeatureFlags={() => setModalState("feature-flags")}
            onOpenNotifications={() => setModalState("notifications")}
            currentUser={currentUser}
            gql={data}
            novuSubscriberId={novuSubscriberId}
          />
        }
        disabled={!visible}
        hoverCloseDelay={300}
        usePortal={false}
        placement="bottom"
      >
        <div
          className={`${styles.badge} ${
            visible ? styles.visible : styles.invisible
          }`}
          style={{ color: status.color, background: status.background }}
          onClick={handleClick}
        >
          {status.badgeText}
        </div>
      </Tooltip>

      <AssetsDialog
        isOpen={modalState === "icon-table"}
        onClose={() => setModalState(undefined)}
      />

      <NotificationDialog
        isOpen={modalState === "notifications"}
        onClose={() => setModalState(undefined)}
      />

      <Drawer
        title="Feature flags"
        icon="eye-open"
        size={DrawerSize.SMALL}
        isOpen={modalState === "feature-flags"}
        onClose={() => setModalState(undefined)}
        className={styles.drawer}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            <FeatureFlags gql={data} />
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default DevToolsStatus
