import React from "react"
import { graphql, useFragment } from "react-relay"

import { EntitlementsProvider_billing$key } from "./__generated__/EntitlementsProvider_billing.graphql"

import { SUBSCRIPTIONS } from "~/GLOBALVARS"

import EntitlementsContext, {
  EntitlementFeatureType,
} from "./EntitlementsContext"

type Props = {
  billing: EntitlementsProvider_billing$key
  children: React.ReactNode
}

const FRAGMENT = graphql`
  fragment EntitlementsProvider_billing on Billing {
    subscription {
      id
      isInTrial
      plan {
        itemPriceId
      }
    }
    entitlements {
      featureId
      featureType
      value
    }
  }
`

const EntitlementsProvider = (props: Props) => {
  const { children, billing } = props
  const data = useFragment(FRAGMENT, billing)
  const planId = data.subscription?.plan?.itemPriceId

  return (
    <EntitlementsContext.Provider
      value={{
        entitlements: data.entitlements
          ? data.entitlements.map((e) => ({
              ...e,
              featureType: e.featureType as EntitlementFeatureType,
            }))
          : null,
        isInTrial: data.subscription?.isInTrial ?? false,
        plan: planId ?? SUBSCRIPTIONS.pro_plan_id,
      }}
    >
      {children}
    </EntitlementsContext.Provider>
  )
}
export default EntitlementsProvider
