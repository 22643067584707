import { format as formatDate } from "date-fns"
import React, { useEffect, useState } from "react"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"

import styles from "./CreateHolidaysGroupForm.module.css"

import { CreateHolidaysGroupFormQuery } from "./__generated__/CreateHolidaysGroupFormQuery.graphql"
import { CreateHolidaysGroupForm_viewer$key } from "./__generated__/CreateHolidaysGroupForm_viewer.graphql"

import { useHasuraContext } from "~/store/hasura"

import { track } from "~/helpers/analytics"
import { reportError } from "~/helpers/error-helpers"
import * as hashids from "~/helpers/hashids"
import { CustomHoliday, PublicHoliday } from "~/helpers/holiday-helpers"
import routes from "~/helpers/routes"

import { ModalFormWrapper } from "~/common/ModalForm"
import Select from "~/common/Select"
import UniqueInput from "~/common/UniqueInput"

import {
  holidaysBulkCreateRelay,
  holidaysGroupCreateRelay,
} from "~/mutations/Holidays"

import { HOLIDAYS_DATE_RANGES, HOLIDAY_TYPES } from "~/GLOBALVARS"
import HolidaysGroupForm from "~/Holidays/Forms/HolidaysGroupForm/HolidaysGroupForm"
import { showToast } from "~/containers/ToasterContainer"

type Props = {
  closeDialog: () => void
}

type RegionOption = {
  label: string
  value: string
  isDisabled: boolean
}

const fragment = graphql`
  fragment CreateHolidaysGroupForm_viewer on accounts {
    holidays_groups(order_by: { name: asc }) {
      id
      name
      country_code
    }
  }
`

const GET_COUNTRIES_QUERY = graphql`
  query CreateHolidaysGroupFormQuery {
    action_countries_get {
      countries
    }
  }
`

const excludedCountries = ["UN", "AQ"]

const formatAsSelectOptions = (locationsArray) =>
  locationsArray.map((item) => ({
    label: item.name,
    value: item.code,
  }))

const formatHolidaysForMutation = (holidays, groupId, holidayType) => {
  const isPublicHoliday = holidayType === HOLIDAY_TYPES.PUBLIC

  return holidays.reduce((acc, holiday) => {
    const holidayData = {
      name: isPublicHoliday ? holiday.holiday.name : holiday.name,
      holidays_group_id: groupId,
      type: holidayType,
    }

    const ungroupedHolidays = []
    const years = ["currentYear", "secondYear", "thirdYear"]
    years.forEach((year) => {
      if (holiday[year]) {
        return ungroupedHolidays.push({
          ...holidayData,
          date: isPublicHoliday
            ? holiday[year].date
            : formatDate(holiday[year].date, "yyy-MM-dd"),
          observed: holiday[year].observed || null,
          holiday_api_uuid: holiday?.holiday?.uuid || null,
        })
      }
    })

    return [...acc, ...ungroupedHolidays]
  }, [])
}

const CreateHolidaysGroupForm = (props: Props) => {
  const { closeDialog } = props

  const [groupName, setGroupName] = useState<string>("")
  const [selectedCountry, setSelectedCountry] = useState<{
    label: string
    value: string
  }>(null)
  const [selectedRegion, setSelectedRegion] = useState<{
    label: string
  }>(null)
  const [selectedLocationCode, setSelectedLocationCode] = useState<string>(null)
  const [publicHolidays, setPublicHolidays] = useState<PublicHoliday[]>([])
  const [customHolidays, setCustomHolidays] = useState<CustomHoliday[]>([])
  const [regionOptions, setRegionOptions] = useState<RegionOption[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [holidayGroupCreated, setHolidayGroupCreated] = useState(false)

  const { holidays_groups: savedHolidaysGroups } =
    useFragment<CreateHolidaysGroupForm_viewer$key>(
      fragment,
      useHasuraContext(),
    )

  const {
    action_countries_get: { countries: allCountries },
  } = useLazyLoadQuery<CreateHolidaysGroupFormQuery>(GET_COUNTRIES_QUERY, {})

  // exclude countries without public holidays. e.g United Nations and Antartica
  const countries = allCountries.filter(
    (country) => !excludedCountries.includes(country.code),
  )
  const groupNameExists = savedHolidaysGroups.some(
    (group) => group.name === groupName,
  )

  useEffect(() => {
    track("Holidays Group Create Form Opened")
  }, [])

  const handleCountryChange = (e) => {
    setPublicHolidays([])
    setSelectedRegion(null)
    setSelectedCountry(e)

    const selectedCountryItem = countries.find(
      (country) => country.code === e.value,
    )

    const regionsInSelectedCountry = selectedCountryItem.subdivisions
    if (!regionsInSelectedCountry.length) {
      setRegionOptions([])
      setGroupName(selectedCountryItem.name)
      return setSelectedLocationCode(selectedCountryItem.code)
    }

    const formattedRegionOptions = formatAsSelectOptions(
      regionsInSelectedCountry,
    )
    setRegionOptions(formattedRegionOptions)
    setGroupName(e.label)
    setSelectedLocationCode(e.value)
  }

  const handleRegionChange = (e) => {
    if (!e) {
      setPublicHolidays([])
      setSelectedRegion(null)
      setGroupName(selectedCountry.label)
      setSelectedLocationCode(selectedCountry.value)
      return
    }
    if (e.value === selectedLocationCode) {
      return
    }

    setSelectedRegion(e)
    setPublicHolidays([])
    setGroupName(e.label)
    setSelectedLocationCode(e.value)
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const completeCustomHolidayRows = customHolidays.filter(
      (holiday) =>
        holiday.name.trim() &&
        (holiday.currentYear || holiday.secondYear || holiday.thirdYear),
    )

    const holidayGroup = {
      group: {
        name: groupName || selectedRegion?.label || selectedCountry.label,
        country_code: selectedLocationCode,
        region_name: selectedRegion?.label,
        country_name: selectedCountry.label,
      },
    }

    try {
      const newHolidayGroup = await holidaysGroupCreateRelay(holidayGroup)
      setHolidayGroupCreated(true)
      track("Holidays Group Created")

      const formattedPublicHolidays = formatHolidaysForMutation(
        publicHolidays,
        newHolidayGroup.id,
        HOLIDAY_TYPES.PUBLIC,
      )
      const formattedCustomHolidays = formatHolidaysForMutation(
        completeCustomHolidayRows,
        newHolidayGroup.id,
        HOLIDAY_TYPES.CUSTOM,
      )
      await holidaysBulkCreateRelay({
        holidays: [...formattedPublicHolidays, ...formattedCustomHolidays],
        ...HOLIDAYS_DATE_RANGES,
      })

      showToast({
        type: "success",
        message: "New holiday group created",
        description: newHolidayGroup.name,
        actions: [
          {
            href: routes.viewHolidaysGroupUrl(
              hashids.holidaysGroups.encode(newHolidayGroup.id),
            ),
          },
        ],
      })

      formattedPublicHolidays.length && track("Public Holiday Added")
      formattedCustomHolidays.length && track("Custom Holiday Added")
    } catch (error) {
      void reportError(`create holiday group error`, error)
    } finally {
      setIsLoading(false)
      closeDialog()
    }
  }

  const InputFields = (
    <>
      <Select
        label="Country"
        value={selectedCountry}
        onChange={handleCountryChange}
        placeholder="Select a Country"
        options={formatAsSelectOptions(countries)}
        dataTest="create-holidays-group-form-country-select"
        autoFocus
      />
      <div>
        <Select
          label="Region (optional)"
          isClearable={true}
          value={selectedRegion}
          onChange={handleRegionChange}
          isDisabled={!selectedCountry || !regionOptions.length}
          placeholder={
            selectedCountry && !regionOptions.length
              ? "No Regions Available"
              : "None"
          }
          options={regionOptions}
          dataTest="create-holidays-group-form-region-select"
        />
        {savedHolidaysGroups.some(
          (group) => group.country_code === selectedLocationCode,
        ) && !holidayGroupCreated ? (
          <div
            className={styles.textBelowSelect}
            aria-live="polite"
            role="status"
          >
            Holiday group already exists
          </div>
        ) : null}
      </div>
      <UniqueInput
        label="Name"
        value={groupName}
        placeholder="e.g. London"
        onChange={(e) => setGroupName(e.target.value)}
        dataTest="create-holidays-group-form-name-input"
        duplicate={groupNameExists && !isLoading ? { name: "Name" } : null}
      />
    </>
  )

  return (
    <ModalFormWrapper wide headerTitle={"New Holiday Group"}>
      <HolidaysGroupForm
        InputFields={InputFields}
        locationCode={selectedLocationCode}
        disableSubmitButton={
          !selectedLocationCode || groupNameExists || !groupName.length
        }
        publicHolidays={publicHolidays}
        customHolidays={customHolidays}
        isLoading={isLoading}
        setPublicHolidays={setPublicHolidays}
        setCustomHolidays={setCustomHolidays}
        closeDialog={closeDialog}
        onSubmit={onSubmit}
      />
    </ModalFormWrapper>
  )
}

export default CreateHolidaysGroupForm
