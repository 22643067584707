import cc from "classcat"
import React from "react"
import { Table as ReactTable } from "react-table"

import styles from "./Table.module.css"

type Props = {
  table: ReactTable
  children: React.ReactNode
  className?: string
}

const BaseTable = (props: Props) => {
  const { table, className = "", children } = props

  const { getTableProps, headerGroups, getTableBodyProps, hideHeader } = table

  return (
    <table
      data-component="Table"
      {...getTableProps()}
      className={cc([styles.table, className])}
    >
      {!hideHeader && (
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  })}
                  className={styles.th}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <tbody {...getTableBodyProps()}>{children}</tbody>
    </table>
  )
}

export default BaseTable
