import { DIVIDER } from "~/common/Select"

import { REPORTS_LIST, ReportName } from "~/GLOBALVARS"
import { NavigationSettings } from "~/containers/Navigation"
import { Report, ReportType } from "~/reports/types"

export type ReportNavItem = Report & {
  link: string
}

export type PivotByOptions = Array<{
  value: string
  label: string
  isCustom?: boolean
}>

const hasReport = (
  reportName: ReportName,
  settings: NavigationSettings,
): boolean => {
  switch (reportName) {
    case "Timesheets":
      return settings.timesheetsEnabled
    case "Variance":
      return settings.timesheetsEnabled
    default:
      return true
  }
}

export const getReportList = (
  settings: NavigationSettings,
  type?: ReportType,
): Array<ReportNavItem> =>
  REPORTS_LIST.filter(
    (x) =>
      !x.v2 &&
      (!type || x.type === type) &&
      hasReport(x.navigationName, settings),
  ).map((report) => ({
    ...report,
    link: `/reports?name=${report.name.replace(/ /g, "+")}`,
  }))

export const getReportV2List = (
  settings: NavigationSettings,
  type?: ReportType,
): Array<ReportNavItem> => {
  return REPORTS_LIST.filter(
    (x) =>
      x.v2 &&
      (!type || x.type === type) &&
      hasReport(x.navigationName, settings),
  ).map((report) => ({
    ...report,
    link: `/reports_v2?name=${report.name.replace(/ /g, "+")}`,
  }))
}
export const getPivotByOptions = (
  options: Array<string>,
  customFields: ReadonlyArray<{ singleSelect: boolean; name: string }>,
): PivotByOptions => {
  const nativeOptions = options.map((x) => ({
    value: x,
    label: x,
    isCustom: false,
  }))
  const customFieldsOptions = customFields
    .filter((x) => x.singleSelect)
    .map((x) => ({
      value: x.name,
      label: x.name,
      isCustom: true,
    }))

  if (customFieldsOptions.length) {
    return [...nativeOptions, DIVIDER, ...customFieldsOptions]
  }

  return nativeOptions
}
