import React, { useEffect, useState } from "react"

import { MessageBox } from "./LoadingSkeleton"

type Props = {
  delayMs?: number
  embed?: boolean
}

const Loading = (props: Props) => {
  const { delayMs = 2000, embed = false } = props

  // Use default delay to avoid flashing of loading indicators on fast loads
  const [delayed, setDelayed] = useState(delayMs === 0 ? false : true)

  useEffect(() => {
    if (delayMs > 0) {
      const timeout = setTimeout(() => setDelayed(false), delayMs)
      return () => clearTimeout(timeout)
    }
  }, [delayMs])

  return (
    !delayed && (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <MessageBox embed={embed} />
      </div>
    )
  )
}

export default Loading
