/**
 * @generated SignedSource<<74d141515f50e88dca85a419dca426a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HolidaysTimeOffBulkCreateInput = {
  holidays_group_id: number;
  people_ids: ReadonlyArray<number | null | undefined>;
};
export type HolidaysTimeOffBulkCreateMutation$variables = {
  input: HolidaysTimeOffBulkCreateInput;
};
export type HolidaysTimeOffBulkCreateMutation$data = {
  readonly action_holidays_time_off_bulk_create: {
    readonly account: {
      readonly id: number;
    } | null | undefined;
  } | null | undefined;
};
export type HolidaysTimeOffBulkCreateMutation = {
  response: HolidaysTimeOffBulkCreateMutation$data;
  variables: HolidaysTimeOffBulkCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "HolidaysTimeOffBulkCreateOutput",
    "kind": "LinkedField",
    "name": "action_holidays_time_off_bulk_create",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HolidaysTimeOffBulkCreateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HolidaysTimeOffBulkCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "67d473971a07802168dd2edd474eddff",
    "id": null,
    "metadata": {},
    "name": "HolidaysTimeOffBulkCreateMutation",
    "operationKind": "mutation",
    "text": "mutation HolidaysTimeOffBulkCreateMutation(\n  $input: HolidaysTimeOffBulkCreateInput!\n) {\n  action_holidays_time_off_bulk_create(input: $input) {\n    account {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f3bf60034ef1e607ab68963384bd590";

export default node;
