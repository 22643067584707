/**
 * @generated SignedSource<<853eeb1dfa94bb6ed41b424b4c3d29d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TagBulkCreateInput = {
  model: string;
  object_id?: number | null | undefined;
  tags: ReadonlyArray<string>;
};
export type TagBulkCreateMutation$variables = {
  input: TagBulkCreateInput;
};
export type TagBulkCreateMutation$data = {
  readonly action_tag_bulk_create: {
    readonly account: {
      readonly id: number;
      readonly person_tags: ReadonlyArray<{
        readonly archived: boolean;
        readonly id: number;
        readonly model: string;
        readonly name: string;
      }>;
      readonly project_tags: ReadonlyArray<{
        readonly archived: boolean;
        readonly id: number;
        readonly model: string;
        readonly name: string;
      }>;
      readonly tags: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
  } | null | undefined;
};
export type TagBulkCreateMutation = {
  response: TagBulkCreateMutation$data;
  variables: TagBulkCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "model",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "archived",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TagBulkCreateOutput",
    "kind": "LinkedField",
    "name": "action_tag_bulk_create",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "tags",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "person_tags",
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "model": {
                    "_eq": "person"
                  }
                }
              }
            ],
            "concreteType": "tags",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": "tags(where:{\"model\":{\"_eq\":\"person\"}})"
          },
          {
            "alias": "project_tags",
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "model": {
                    "_eq": "project"
                  }
                }
              }
            ],
            "concreteType": "tags",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": "tags(where:{\"model\":{\"_eq\":\"project\"}})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagBulkCreateMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagBulkCreateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5e266871c15d3e37e88d5338ad673be8",
    "id": null,
    "metadata": {},
    "name": "TagBulkCreateMutation",
    "operationKind": "mutation",
    "text": "mutation TagBulkCreateMutation(\n  $input: TagBulkCreateInput!\n) {\n  action_tag_bulk_create(input: $input) {\n    account {\n      id\n      tags {\n        id\n      }\n      person_tags: tags(where: {model: {_eq: \"person\"}}) {\n        id\n        name\n        model\n        archived\n      }\n      project_tags: tags(where: {model: {_eq: \"project\"}}) {\n        id\n        name\n        model\n        archived\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b379d4f63c2222688f8369a9d8cf7b3";

export default node;
