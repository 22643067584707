import React from "react"

const projectStatus = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.25 3H11.115C10.8 2.13 9.975 1.5 9 1.5C8.025 1.5 7.2 2.13 6.885 3H3.75C2.925 3 2.25 3.675 2.25 4.5V15C2.25 15.825 2.925 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V4.5C15.75 3.675 15.075 3 14.25 3ZM8.99967 2.99986C9.41217 2.99986 9.74967 3.33736 9.74967 3.74986C9.74967 4.16236 9.41217 4.49986 8.99967 4.49986C8.58717 4.49986 8.24967 4.16236 8.24967 3.74986C8.24967 3.33736 8.58717 2.99986 8.99967 2.99986ZM4.5003 10.5002L7.5003 13.5002L13.5003 7.50021L12.4428 6.43521L7.5003 11.3777L5.5578 9.44271L4.5003 10.5002Z"
      fill="#3C4260"
    />
  </svg>
)

export default projectStatus
