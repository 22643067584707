import { toast } from "react-toastify"

import {
  viewClientUrl,
  viewPersonUrl,
  viewProjectUrl,
  viewRateCard,
  viewRoleUrl,
} from "~/helpers/routes"

import { showToast } from "~/containers/ToasterContainer"

import { clientActiveStatusUpdateRelay } from "./Client"
import { activatePerson, archivePerson } from "./Person"
import { activateProjectRelay, archiveProjectRelay } from "./Project"
import { rateCardUpdateActiveRelay } from "./RateCard"
import { activateRole, archiveRole } from "./Role"
import { activateWorkstream, archiveWorkstream } from "./Workstream"

type Type = "project" | "person" | "client" | "rateCard" | "role" | "workstream"

const undoArchive = async (type: Type, id) => {
  toast.clearWaitingQueue()

  if (type === "person") {
    await activatePerson({ id })
  }

  if (type === "project") {
    await activateProjectRelay({ id })
  }

  if (type === "client") {
    await clientActiveStatusUpdateRelay({ id: id, active: true })
  }

  if (type === "rateCard") {
    await rateCardUpdateActiveRelay({ id, active: true })
  }

  if (type === "role") {
    await activateRole(id)
  }

  if (type === "workstream") {
    await activateWorkstream(id)
  }
}

type ArchiveItemOptions = {
  variables: {
    type: Type
    active: boolean
    hashid: string
    name: string
    id: number
    inPlanner?: boolean
  }
}

export const toggleActiveStatus = async (options: ArchiveItemOptions) => {
  const {
    type,
    active,
    hashid,
    name,
    id,
    inPlanner = false,
  } = options.variables

  let url

  switch (type) {
    case "project":
      url = viewProjectUrl(hashid)
      break
    case "person":
      url = viewPersonUrl(hashid)
      break
    case "client":
      url = viewClientUrl(hashid)
      break
    case "rateCard":
      url = viewRateCard(hashid)
      break
    case "role":
      url = viewRoleUrl(hashid)

    default:
      url = ""
      break
  }

  const notificationSuccess = () => {
    showToast({
      message: `${name} successfully ${active ? "archived" : "unarchived"}`,

      actions: [
        active && {
          text: "Undo",
          onClick: () => {
            void undoArchive(type, id)
          },
        },
        inPlanner && { href: url },
      ],
      type: "success",
    })
  }

  if (type === "person") {
    if (active) {
      await archivePerson({ id })
    } else {
      await activatePerson({ id })
    }
    notificationSuccess()
  }

  if (type === "project") {
    if (active) {
      await archiveProjectRelay({ id })
    } else {
      try {
        await activateProjectRelay({ id })
      } catch (e) {
        // Catch the error here which results in an error toast being shown instead
        // of the overlay error dialog. Unarchiving may legitimately fail if the
        // project name is already in use by an active project so we want a nicer
        // error experience in this case.
        console.error(e)
        return
      }
    }
    notificationSuccess()
  }

  if (type === "client") {
    await clientActiveStatusUpdateRelay({ id, active: !active })
    notificationSuccess()
  }

  if (type === "rateCard") {
    const result = await rateCardUpdateActiveRelay({ id, active: !active })
    if (result) {
      notificationSuccess()
    }
  }

  if (type === "role") {
    if (active) {
      await archiveRole(id)
    } else {
      await activateRole(id)
    }
    notificationSuccess()
  }

  if (type === "workstream") {
    if (active) {
      await archiveWorkstream(id)
    } else {
      await activateWorkstream(id)
    }
    notificationSuccess()
  }
}
