import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import { UpgradeBannerContainerQuery } from "./__generated__/UpgradeBannerContainerQuery.graphql"

import { HasuraContextProvider } from "~/store/hasura"

import UpgradeBanner from "~/common/subscriptions/UpgradeBanner"

import EntitlementsProvider from "~/Entitlements/EntitlementsProvider"
import PermissionsProvider, {
  usePermissionsContext,
} from "~/Permissions/PermissionsProvider"
import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"
import ServerFlagsProvider from "~/ServerFlags/ServerFlagsProvider"

const UPGRADE_BANNER_CONTAINER_QUERY = graphql`
  query UpgradeBannerContainerQuery($accountId: Int!) {
    account: accounts_by_pk(id: $accountId) {
      name
      id
      subscription
    }
    billing(syncSubscription: false) {
      ...EntitlementsProvider_billing
    }
    current_user {
      ...PermissionsProvider_user
    }
    ...ServerFlagsProvider_gql
  }
`
type Props = {
  error?: Record<string, any>
  accountId: number
  headingText?: string
}

const UpgradeBannerContainer = (props: Props) => {
  const { accountId, headingText } = props

  const data = useLazyLoadQuery<UpgradeBannerContainerQuery>(
    UPGRADE_BANNER_CONTAINER_QUERY,
    { accountId: accountId },
  )

  const { account, current_user } = data
  const { isAdmin } = usePermissionsContext({ user: current_user })
  const subscription = account.subscription

  return (
    <PermissionsProvider user={current_user}>
      <HasuraContextProvider value={current_user}>
        <ServerFlagsProvider gql={data}>
          <EntitlementsProvider billing={data.billing}>
            <UpgradeBanner
              headingText={headingText}
              isAdmin={isAdmin}
              subscription={subscription}
            />
          </EntitlementsProvider>
        </ServerFlagsProvider>
      </HasuraContextProvider>
    </PermissionsProvider>
  )
}

export default withRelayPageContainerNoLoading(UpgradeBannerContainer)
