import { Icon } from "@blueprintjs/core"
import React from "react"

import styles from "./UniqueInput.module.css"

import type { Props as InputProps } from "./Input"
import Input from "./Input"

type Props = InputProps & {
  duplicate?: null | {
    name: string
    link?: string
    label?: string
  }
}

const UniqueInput = ({ duplicate, ...rest }: Props) => {
  const inputProps = { ...rest }
  if (duplicate) {
    const { name, link, label = "already exists" } = duplicate
    inputProps.error = true
    inputProps.customErrorMessage = label
    inputProps.label = name

    if (link) {
      inputProps.labelRight = (
        <a href={link} className={styles.link} target="_blank" rel="noopener">
          View <Icon icon="share" size={8} />
        </a>
      )
    }
  }

  return (
    <div className={styles.wrapper}>
      <Input {...inputProps} />
    </div>
  )
}

export default UniqueInput
