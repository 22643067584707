import React from "react"

export const TangramAdventure = () => (
  <svg
    width="152"
    height="86"
    viewBox="0 0 152 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Adventure</title>
    <rect
      x="75.8228"
      width="24.3596"
      height="24.3596"
      transform="rotate(45 75.8228 0)"
      fill="#FDCD4F"
    />
    <path
      d="M2.13217e-06 61.5391L48.7783 61.5391V12.7608L2.13217e-06 61.5391Z"
      fill="#F5848C"
    />
    <path
      d="M102.877 12.7613V61.5395L78.4878 37.1504L102.877 12.7613Z"
      fill="#67D0D5"
    />
    <path
      d="M151.654 61.5391L102.876 61.5391V12.7608L151.654 61.5391Z"
      fill="#4057DF"
    />
    <path
      d="M73.1389 61.5203H97.5281L73.1389 37.1311V61.5203Z"
      fill="#0A9CC1"
    />
    <path
      d="M48.7978 37.1777L73.1594 61.5393L73.1319 37.1226L48.7703 12.761L48.7978 37.1777Z"
      fill="#FFB077"
    />
    <path
      d="M48.7688 61.5207V37.1316L73.1579 61.5207H48.7688Z"
      fill="#B19DE6"
    />
  </svg>
)
