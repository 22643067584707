import React from "react"

const ArrowRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3V3Z" fill="#AFB2C6" />
  </svg>
)

export default ArrowRight
