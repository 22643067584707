const mustGetElementHeight = (elementId: string): number => {
  const element = document.getElementById(elementId)
  if (element == null) {
    throw new Error(
      `Could not get height of element "${elementId}" - it does not exist in the DOM. `,
    )
  }
  return element.offsetHeight
}

const getElementHeight = (elementId: string, defaultValue: number): number => {
  try {
    return mustGetElementHeight(elementId)
  } catch {
    return defaultValue
  }
}

const getDashboardHeaderHeight = (): number => {
  const topBarHeight = mustGetElementHeight("top-bar")
  const stickyHeaderHeight = getElementHeight("page-header", 0)

  return topBarHeight + stickyHeaderHeight
}

type getWidthForTextareaProps = {
  cellWidth: number
  numOfReferences?: number
  numOfIntegrations?: number
  isArchived?: boolean
}

const getWidthForTextarea = ({
  cellWidth,
  numOfReferences = 0,
  numOfIntegrations = 0,
  isArchived,
}: getWidthForTextareaProps): string => {
  const marginBetweenItems = 8 //  var(--spacing-xs)
  const thumbnailWidth = 35
  const archivedIconWidth = 102
  const referenceIconWidth = 12
  const integrationIconWidth = 20

  let textareaWidth = cellWidth - thumbnailWidth - marginBetweenItems

  if (numOfReferences > 0) {
    textareaWidth -= (referenceIconWidth + marginBetweenItems) * numOfReferences
  }

  if (numOfIntegrations > 0) {
    textareaWidth -=
      (integrationIconWidth + marginBetweenItems) * numOfIntegrations
  }

  if (isArchived) {
    textareaWidth -= archivedIconWidth + marginBetweenItems
  }

  return `${textareaWidth}px`
}

export {
  mustGetElementHeight,
  getElementHeight,
  getDashboardHeaderHeight,
  getWidthForTextarea,
}
