import React from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

import { AccountDropdownMenuContainerQuery } from "./__generated__/AccountDropdownMenuContainerQuery.graphql"

import EntitlementsProvider from "~/Entitlements/EntitlementsProvider"
import PermissionsProvider from "~/Permissions/PermissionsProvider"
import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"
import ServerFlagsProvider from "~/ServerFlags/ServerFlagsProvider"

import AccountDropdownMenu from "./AccountDropdownMenu"

const ACCOUNT_DROPDOWN_MENU_CONTAINER_QUERY = graphql`
  query AccountDropdownMenuContainerQuery($accountId: Int!) {
    billing(syncSubscription: false) {
      ...EntitlementsProvider_billing
    }
    current_user {
      ...PermissionsProvider_user
    }
    ...AccountDropdownMenu_account @arguments(accountId: $accountId)
    ...ServerFlagsProvider_gql
  }
`

type Props = { accountId: number }

const AccountDropdownMenuContainer = ({ accountId }: Props) => {
  const data = useLazyLoadQuery<AccountDropdownMenuContainerQuery>(
    ACCOUNT_DROPDOWN_MENU_CONTAINER_QUERY,
    { accountId },
  )

  return (
    <PermissionsProvider user={data.current_user}>
      <ServerFlagsProvider gql={data}>
        <EntitlementsProvider billing={data.billing}>
          <AccountDropdownMenu account={data} />
        </EntitlementsProvider>
      </ServerFlagsProvider>
    </PermissionsProvider>
  )
}

export default withRelayPageContainerNoLoading(AccountDropdownMenuContainer)
