import cc from "classcat"
import React, { useRef, useState } from "react"
import AutosizeInput from "react-input-autosize"

import styles from "./AddNewInlineItem.module.css"

import Tag from "~/tagging/Tag"

import { Plus } from "./react-icons"

type Props = {
  name: string
  model?: "person" | "project"
  onSaveItem: (value: string) => void
  placeholder: string
  buttonStyle?: string
}

const AddNewInlineItem = (props: Props) => {
  const {
    model,
    onSaveItem: saveItemAction,
    placeholder,
    name,
    buttonStyle,
  } = props
  const [value, setValue] = useState("")
  const [showCreateNew, setShowCreateNew] = useState(false)

  const handleOnChange = (e) => {
    setValue(e.target.value)
  }

  const saveItem = () => {
    void saveItemAction(value.trim())
    setValue("")
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      saveItem()
    }

    if (e.key === "Escape") {
      setShowCreateNew(false)
      setValue("")
    }
  }

  const inputRef = useRef(null)

  const handleOnBlur = () => {
    if (value) {
      saveItem()
      inputRef.current.focus()
    } else {
      setShowCreateNew(false)
    }
  }

  const handleAddNew = () => {
    setShowCreateNew(true)
  }

  const itemIdentifier = model ? `${model}-${name}` : name

  return (
    <>
      {showCreateNew ? (
        <Tag light>
          <AutosizeInput
            className={styles.tagInputWrapper}
            ref={inputRef}
            id={`add-new-${itemIdentifier}-input`}
            name={`add-new-${itemIdentifier}-input`}
            value={value}
            onChange={handleOnChange}
            placeholder={placeholder}
            autoFocus
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
          />
        </Tag>
      ) : (
        <div
          id={`add-new-${itemIdentifier}`}
          onClick={handleAddNew}
          className={cc([styles.addNewTagIcon, buttonStyle])}
        >
          <Plus color="var(--midnight)" />
        </div>
      )}
    </>
  )
}

export default AddNewInlineItem
