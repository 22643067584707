import React from "react"
import { Table as ReactTable } from "react-table"

import styles from "./Table.module.css"

const Row = ({
  table,
  row,
  style,
}: {
  table: ReactTable
  row: any
  style?: Record<string, string | number>
}) => {
  table.prepareRow(row)

  return (
    <tr
      {...row.getRowProps({
        style,
      })}
      className={styles.tr}
    >
      {row.cells.map((cell) => (
        <td
          {...cell.getCellProps({
            style: {
              minWidth: cell.column.minWidth,
              width: cell.column.width,
            },
          })}
          className={styles.td}
        >
          {cell.render("Cell")}
        </td>
      ))}
    </tr>
  )
}

export default Row
