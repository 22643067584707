/**
 * @generated SignedSource<<5b9100d6671b7819fc4f4bd193131617>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvitationCreateInput = {
  email: string;
  fin_permission: string;
  manage_others_permission: string;
  manage_people_ids?: ReadonlyArray<number | null | undefined> | null | undefined;
  manage_people_permission?: string | null | undefined;
  manage_project_ids?: ReadonlyArray<number | null | undefined> | null | undefined;
  manage_projects_permission: string;
  user_type: string;
};
export type InvitationCreateMutation$variables = {
  input: InvitationCreateInput;
};
export type InvitationCreateMutation$data = {
  readonly action_invitation_create: {
    readonly account: {
      readonly id: number;
      readonly invitations: ReadonlyArray<{
        readonly email: string;
        readonly id: number;
      }>;
      readonly people: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
    readonly error: string | null | undefined;
    readonly invitation: {
      readonly email: string;
      readonly id: number;
    } | null | undefined;
  };
};
export type InvitationCreateMutation = {
  response: InvitationCreateMutation$data;
  variables: InvitationCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InvitationCreateOutput",
    "kind": "LinkedField",
    "name": "action_invitation_create",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "invitations",
        "kind": "LinkedField",
        "name": "invitation",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "people",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "invitations",
            "kind": "LinkedField",
            "name": "invitations",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationCreateMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationCreateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "067e31a4a07323227b75d31e772eb15c",
    "id": null,
    "metadata": {},
    "name": "InvitationCreateMutation",
    "operationKind": "mutation",
    "text": "mutation InvitationCreateMutation(\n  $input: InvitationCreateInput!\n) {\n  action_invitation_create(input: $input) {\n    invitation {\n      id\n      email\n    }\n    account {\n      id\n      people {\n        id\n      }\n      invitations {\n        id\n        email\n      }\n    }\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d2816218dbb0dad45f747a0a62fe328";

export default node;
