import React from "react"

export const TangramAdventureGroup = () => (
  <svg
    width="374"
    height="98"
    viewBox="0 0 374 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1609_17528)">
      <path
        d="M152.872 58.0666L186.813 92.0076L186.813 58.0666L152.872 24.1256L152.872 58.0666Z"
        fill="#FFB077"
      />
      <path
        d="M186.743 48L162.743 24.0001L186.743 0.000129769L210.743 24.0001L186.743 48Z"
        fill="#FDCD4F"
      />
      <path
        d="M186.764 91.9854H220.705L186.764 58.0443V91.9854Z"
        fill="#0A9CC1"
      />
      <path
        d="M152.87 92.0039H186.812L152.87 58.0629V92.0039Z"
        fill="#B19DE6"
      />
      <path
        d="M186.808 58.0659L220.749 24.1249V92.0069L186.808 58.0659Z"
        fill="#67D0D5"
      />
      <path
        d="M220.75 92.0085V24.1265L288.632 92.0085H220.75Z"
        fill="#4057DF"
      />
      <path
        d="M152.873 92.0071V24.125L84.991 92.0071H152.873Z"
        fill="#F5848C"
      />
    </g>
    <g clipPath="url(#clip1_1609_17528)">
      <path
        d="M32.9318 41.0333L49.9024 58.0038V41.0333L32.9318 24.0627L32.9318 41.0333Z"
        fill="#FFB077"
      />
      <path
        d="M49.8672 36L37.8672 24L49.8672 12L61.8672 24L49.8672 36Z"
        fill="#FDCD4F"
      />
      <path
        d="M49.878 57.9927H66.8486L49.878 41.0221V57.9927Z"
        fill="#0A9CC1"
      />
      <path
        d="M32.9308 58.002H49.9014L32.9308 41.0314V58.002Z"
        fill="#B19DE6"
      />
      <path
        d="M49.9001 41.0332L66.8707 24.0626V58.0038L49.9001 41.0332Z"
        fill="#67D0D5"
      />
      <path
        d="M66.8701 58.0042V24.063L100.811 58.0042H66.8701Z"
        fill="#4057DF"
      />
      <path
        d="M32.9321 58.0037V24.0625L-1.00904 58.0037H32.9321Z"
        fill="#F5848C"
      />
    </g>
    <g clipPath="url(#clip2_1609_17528)">
      <path
        d="M305.932 41.0333L322.902 58.0038V41.0333L305.932 24.0627L305.932 41.0333Z"
        fill="#FFB077"
      />
      <path
        d="M322.867 36L310.867 24L322.867 12L334.867 24L322.867 36Z"
        fill="#FDCD4F"
      />
      <path
        d="M322.878 57.9927H339.849L322.878 41.0221V57.9927Z"
        fill="#0A9CC1"
      />
      <path
        d="M305.931 58.002H322.901L305.931 41.0314V58.002Z"
        fill="#B19DE6"
      />
      <path
        d="M322.9 41.0332L339.871 24.0626V58.0038L322.9 41.0332Z"
        fill="#67D0D5"
      />
      <path d="M339.87 58.0042V24.063L373.811 58.0042H339.87Z" fill="#4057DF" />
      <path
        d="M305.932 58.0037V24.0625L271.991 58.0037H305.932Z"
        fill="#F5848C"
      />
    </g>
  </svg>
)
