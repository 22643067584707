/**
 * @generated SignedSource<<f2b2311e54c8b36f7c48411357eeb99f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TagPeopleDeleteMutation$variables = {
  id: number;
  peopleIds: ReadonlyArray<number>;
};
export type TagPeopleDeleteMutation$data = {
  readonly deletePeopleTag: {
    readonly account: {
      readonly id: number;
      readonly tags: ReadonlyArray<{
        readonly archived: boolean;
        readonly id: number;
        readonly model: string;
        readonly name: string;
      }>;
    } | null | undefined;
  };
};
export type TagPeopleDeleteMutation = {
  response: TagPeopleDeleteMutation$data;
  variables: TagPeopleDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "peopleIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "peopleIds",
        "variableName": "peopleIds"
      }
    ],
    "concreteType": "DeletePeopleTagResult",
    "kind": "LinkedField",
    "name": "deletePeopleTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "tags",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "model",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archived",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagPeopleDeleteMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagPeopleDeleteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "66f5c2cb885ce2d49298df3190a37725",
    "id": null,
    "metadata": {},
    "name": "TagPeopleDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation TagPeopleDeleteMutation(\n  $id: Int!\n  $peopleIds: [Int!]!\n) {\n  deletePeopleTag(id: $id, peopleIds: $peopleIds) {\n    account {\n      id\n      tags {\n        id\n        model\n        name\n        archived\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "43e70ec1a667e5e41f52d92e1852c918";

export default node;
