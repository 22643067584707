import { Tooltip, TooltipProps } from "@blueprintjs/core"
import React from "react"

type Props = {
  children: React.ReactNode
  canEdit?: boolean
  level?: number
  tooltipProps?: Omit<TooltipProps, "content">
}

const CompetencyTooltipLevel = (props: Props) => {
  const { level, canEdit, tooltipProps } = props

  return (
    <Tooltip
      content={
        level
          ? `Skill Level ${level}`
          : canEdit
            ? "Add a Skill Level"
            : "No Skill Level set"
      }
      placement="top"
      hoverOpenDelay={500}
      {...tooltipProps}
      fill={true}
    >
      {props.children}
    </Tooltip>
  )
}

export default CompetencyTooltipLevel
