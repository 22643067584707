import React, { useState } from "react"

import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import { copyDataFromLive } from "~/helpers/routes"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { TangramTriangle } from "~/common/tangrams"

type Props = {
  onClose: () => void
}
const CopyFromLiveModal = (props: Props) => {
  const { onClose } = props
  const [isCopying, setIsCopying] = useState(false)

  const handleCopyDataFromLive = async () => {
    setIsCopying(true)
    await fetchWithCsrf(copyDataFromLive(), {
      method: "POST",
    })
    onClose()
  }

  return (
    <ModalFormWrapper
      headerTitle="Copy Live Account"
      tangram={<TangramTriangle />}
      tangramStyles={{ maxWidth: "80%", marginBottom: "10px" }}
    >
      <ModalBody>
        <div>
          Are you sure you want to <b>copy</b> your Live Account data to the
          Test Account? This will overwrite the data currently in the Test
          Account.
        </div>
        <br />
        <div>This action may take a few minutes and cannot be undone.</div>
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={onClose} disabled={isCopying} />
        <Button
          text="Copy Live Account"
          onClick={handleCopyDataFromLive}
          data-test="copy-account-button"
          type="submit"
          disabled={isCopying}
          outlined={false}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default CopyFromLiveModal
