/**
 * @generated SignedSource<<d798468258f46e45d31b046725d1f53b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopBannerQuery$variables = {
  accountId: number;
};
export type TopBannerQuery$data = {
  readonly account: {
    readonly account_type: string;
    readonly alternative_account: {
      readonly account_type: string;
      readonly id: number;
      readonly projects: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
    readonly id: number;
    readonly last_copied: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly billing: {
    readonly subscription: {
      readonly id: string;
      readonly isInTrial: boolean;
      readonly trialDaysLeft: number | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"EntitlementsProvider_billing">;
  } | null | undefined;
  readonly current_user: {
    readonly id: number;
    readonly permissions: any;
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_user">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ServerFlagsProvider_gql">;
};
export type TopBannerQuery = {
  response: TopBannerQuery$data;
  variables: TopBannerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "account_type",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "alias": "account",
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "accountId"
    }
  ],
  "concreteType": "accounts",
  "kind": "LinkedField",
  "name": "accounts_by_pk",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_copied",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "alternative_account",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "projects",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "syncSubscription",
    "value": false
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInTrial",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trialDaysLeft",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopBannerQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionsProvider_user"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EntitlementsProvider_billing"
          }
        ],
        "storageKey": "billing(syncSubscription:false)"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ServerFlagsProvider_gql"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopBannerQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheets_protected",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "manageable_projects",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSubscriptionItem",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemPriceId",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscriptionEntitlement",
            "kind": "LinkedField",
            "name": "entitlements",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featureId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "featureType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "billing(syncSubscription:false)"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features",
        "kind": "LinkedField",
        "name": "features",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features_accounts",
        "kind": "LinkedField",
        "name": "features_accounts",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feature_id",
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "742469d57d141c3e356ff2ae4796f6bf",
    "id": null,
    "metadata": {},
    "name": "TopBannerQuery",
    "operationKind": "query",
    "text": "query TopBannerQuery(\n  $accountId: Int!\n) {\n  account: accounts_by_pk(id: $accountId) {\n    id\n    name\n    account_type\n    last_copied\n    alternative_account {\n      id\n      projects {\n        id\n      }\n      account_type\n    }\n  }\n  current_user {\n    id\n    permissions\n    ...PermissionsProvider_user\n  }\n  billing(syncSubscription: false) {\n    subscription {\n      id\n      isInTrial\n      trialDaysLeft\n    }\n    ...EntitlementsProvider_billing\n  }\n  ...ServerFlagsProvider_gql\n}\n\nfragment EntitlementsProvider_billing on Billing {\n  subscription {\n    id\n    isInTrial\n    plan {\n      itemPriceId\n      id\n    }\n  }\n  entitlements {\n    featureId\n    featureType\n    value\n  }\n}\n\nfragment PermissionsProvider_user on users {\n  id\n  email\n  account {\n    id\n    timesheets_protected\n  }\n  permissions\n  manageable_projects {\n    id\n    project {\n      id\n    }\n  }\n}\n\nfragment ServerFlagsProvider_gql on query_root {\n  features {\n    id\n    enabled\n    description\n    name\n  }\n  features_accounts {\n    id\n    feature_id\n    enabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a6e911ab9f9f003836270f6ba85e26b";

export default node;
