import sortBy from "lodash-es/sortBy"

import { formatName } from "~/helpers/person"

import { getDailyAssignmentData } from "./CalendarHelper"
import { getCurrentContract } from "./person"
import { isJustAdded } from "./project-member-helpers"

export const sortByString = (a: string, b: string): number =>
  a.toLowerCase().localeCompare(b.toLowerCase())

export const sortAlphaNumeric = (a, b) =>
  a.localeCompare(b, "en", { numeric: true })

const lotsOfZeds = "zzzzzzzzzzzzzzzzzzzzzzz"

/**
 * Sorts numbers before letters before nullish
 */
export const numericCompare = (a?: string | null, b?: string | null) => {
  const A = a ?? lotsOfZeds
  const B = b ?? lotsOfZeds
  return A.toLowerCase().localeCompare(B.toLowerCase(), undefined, {
    numeric: true,
  })
}

export type ProjectSortOption =
  | "projectName"
  | "clientName"
  | "startDate"
  | "endDate"
  | "priority"

export const projectSortOptions: {
  value: ProjectSortOption
  label: string
}[] = [
  { value: "projectName", label: "Project Name" },
  { value: "clientName", label: "Client Name" },
  { value: "startDate", label: "Start Date" },
  { value: "endDate", label: "End Date" },
  { value: "priority", label: "Priority" },
]

export type PeopleSortOption =
  | "availability"
  | "personName"
  | "roleName"
  | "teamName"

export type SortByOrder = "asc" | "desc"

export const peopleSortOptions: {
  value: PeopleSortOption
  label: string
}[] = [
  { value: "personName", label: "First Name" },
  { value: "roleName", label: "Default Role" },
  { value: "teamName", label: "Team" },
  { value: "availability", label: "Availability" },
]

export type PlaceholderSortOption =
  | "project"
  | "startDate"
  | "priority"
  | "roleName"
  | "teamName"
  | "placeholderName"

export const placeholderSortOptions: {
  value: PlaceholderSortOption
  label: string
}[] = [
  { value: "placeholderName", label: "Placeholder Name" },
  { value: "project", label: "Project Name" },
  { value: "roleName", label: "Default Role" },
  { value: "startDate", label: "Start Date" },
  { value: "teamName", label: "Team" },
  { value: "priority", label: "Priority" },
]

export type SortableProject = {
  name: string
  client: {
    name: string
  }
  calc_start_date: string
  calc_end_date: string
  priority: string | null
}

export const sortProjectsByProjectName = <
  P extends Pick<SortableProject, "name">,
>(
  projects: readonly P[],
): readonly P[] => sortBy(projects, (project) => project.name.toLowerCase())

export const sortProjectsByPriority = <
  P extends Pick<SortableProject, "priority">,
>(
  projects: readonly P[],
): readonly P[] =>
  [...projects].sort((a, b) => numericCompare(a.priority, b.priority))

export const sortProjectsByClientName = <
  P extends Pick<SortableProject, "client">,
>(
  projects: readonly P[],
): readonly P[] =>
  sortBy(projects, (project) => project.client.name.toLowerCase())

export const sortProjectsByStartDate = <
  P extends Pick<SortableProject, "calc_start_date">,
>(
  projects: readonly P[],
): readonly P[] => sortBy(projects, (project) => project.calc_start_date)

export const sortProjectsByEndDate = <
  P extends Pick<SortableProject, "calc_end_date">,
>(
  projects: readonly P[],
): readonly P[] => sortBy(projects, (project) => project.calc_end_date)

export const sortProjectsBy = <P extends SortableProject>(
  projects: readonly P[],
  sortOption: ProjectSortOption,
): readonly P[] => {
  switch (sortOption) {
    case "projectName":
      return sortProjectsByProjectName(projects)
    case "clientName":
      return sortProjectsByClientName(projects)
    case "startDate":
      return sortProjectsByStartDate(projects)
    case "endDate":
      return sortProjectsByEndDate(projects)
    case "priority":
      return sortProjectsByPriority(projects)
    default:
      return projects
  }
}

type SortablePerson = {
  id: number
  first_name: string
  last_name: string
  contracts: readonly {
    id: number
    start_date: string
    end_date: string
    minutes_per_day: number
    role: {
      name: string
    }
  }[]
  assignments: readonly {
    id: number
    start_date: string
    end_date: string
    minutes_per_day: number
    person_id: number
    role_id: number
    project_id: number
    note: string
    is_billable: boolean
    phase_id: number
    non_working_day: boolean
  }[]
  time_offs: readonly {
    start_date: string
    end_date: string
    leave_type: string
  }[]
  team?: {
    name: string
  }
  priority?: string | null
}

export const sortPeopleByPersonName = <
  ArrayItem,
  P extends Pick<SortablePerson, "first_name" | "last_name">,
>(
  list: readonly ArrayItem[],
  getPerson: (item: ArrayItem) => P = (item) => item as unknown as P,
): readonly ArrayItem[] => {
  return sortBy(list, (item) => {
    const p = getPerson(item)
    return formatName(p.first_name, p.last_name).toLowerCase()
  })
}

const sortPlaceholdersPriority = <T extends SortablePlaceholders>(
  placeholders: ReadonlyArray<T>,
  projects: ReadonlyArray<{
    id: number
    name: string
    priority?: string | null
  }>,
) => {
  return placeholders
    .filter(
      (p) =>
        p.assignments.length > 0 ||
        (p.project_memberships ?? []).some((m) => isJustAdded(m)),
    )
    .map((p) => {
      const project_id = p.project_memberships[0]?.project_id
      const project = projects.find((proj) => proj.id === project_id)
      return {
        ...p,
        /// z to make no priority come last
        priority: project?.priority ?? lotsOfZeds,
      }
    })
    .sort((a, b) => numericCompare(a.priority, b.priority))
}

export const sortPeopleByRoleName = <
  P extends Pick<SortablePerson, "contracts">,
>(
  people: readonly P[],
): readonly P[] =>
  sortBy(people, [
    (p) => getCurrentContract(p.contracts)?.role.name.toLowerCase(),
  ])

export const sortPeopleByTeamName = <P extends Pick<SortablePerson, "team">>(
  people: readonly P[],
): readonly P[] => sortBy(people, [(p) => p.team?.name.toLowerCase()])

export const getSortedAvailabilityIds = <P extends SortablePerson>(
  people: readonly P[],
  calendarStartDate: Date,
  calendarEndDate: Date,
  visibleProjectIds: number[],
  isConsistentTimeOffEnabled: boolean,
  sortByOrder?: SortByOrder,
): number[] => {
  const peopleAvailability = people.map((person) => {
    const visibleAssignments = person.assignments.filter((a) =>
      visibleProjectIds.includes(a.project_id),
    )
    const dailyAssignmentData = getDailyAssignmentData(
      person,
      visibleAssignments,
      calendarStartDate,
      calendarEndDate,
      isConsistentTimeOffEnabled,
    )

    const totalAvailableHours = Object.values(dailyAssignmentData).reduce(
      (acc, i) => {
        const contractedMinutes = i.isWeekend ? 0 : i.contractedMinutes
        const timeOffMinutes = i.timeOff ? i.contractedMinutes : 0

        const availableHours =
          (contractedMinutes - i.assignedMinutes - timeOffMinutes) / 60

        return acc + availableHours
      },
      0,
    )

    return {
      personId: person.id,
      totalAvailableHours,
    }
  })

  return peopleAvailability
    .sort((a, b) => {
      if (sortByOrder && sortByOrder === "asc") {
        return a.totalAvailableHours - b.totalAvailableHours
      }

      return b.totalAvailableHours - a.totalAvailableHours
    })
    .map((list) => list.personId)
}

export const sortPeopleBy = <
  P extends Pick<
    SortablePerson,
    "team" | "contracts" | "first_name" | "last_name"
  >,
>(
  people: readonly P[],
  sortOption: PeopleSortOption,
): readonly P[] => {
  switch (sortOption) {
    case "availability":
      return people // we handle the availability order afterwards, as we don't want it to re-render everytime availability changes
    case "personName":
      return sortPeopleByPersonName(people)
    case "roleName":
      return sortPeopleByRoleName(people)
    case "teamName":
      return sortPeopleByTeamName(people)
    default:
      return people
  }
}

type SortablePlaceholders = SortablePerson & {
  assignments: ReadonlyArray<{
    id: number
    project_id: number
  }>
  project_memberships: ReadonlyArray<{
    just_added_timestamp?: number
    project_id: number
  }>
}

const sortPlaceholdersProjects = <T extends SortablePlaceholders>(
  placeholders: ReadonlyArray<T>,
  projects: ReadonlyArray<{ id: number; name: string }>,
) => {
  const sortedByRole = sortPeopleBy(placeholders, "roleName")
  const placeholderProjects = sortedByRole
    .filter(
      (p) =>
        p.assignments.length > 0 ||
        (p.project_memberships ?? []).some((m) => isJustAdded(m)),
    )
    .map((p) => p.project_memberships[0].project_id)
    .map((projectId) => projects.find((p) => p.id === projectId))
    .sort()

  const projectGroups = [...new Set(placeholderProjects)]

  return projectGroups
    .map((project) => {
      const filteredPlaceholders = sortedByRole.filter(
        (p) => project.id === p.project_memberships[0]?.project_id,
      )

      return {
        project: project,
        placeholders: filteredPlaceholders,
      }
    })
    .sort((a, b) => a.project.name.localeCompare(b.project.name))
    .map((group) => sortPeopleBy(group.placeholders, "personName"))
    .flat()
}

const sortPlaceholdersStartDate = <T extends SortablePlaceholders>(
  placeholders: ReadonlyArray<T>,
) => {
  return placeholders
    .filter(
      (p) =>
        p.assignments.length > 0 ||
        (p.project_memberships ?? []).some((m) => isJustAdded(m)),
    )
    .map((p) => {
      const hasBeenJustAdded = p.project_memberships.some((m) => isJustAdded(m))

      if (hasBeenJustAdded) {
        return {
          ...p,
          start: new Date(
            p.project_memberships.find((m) =>
              isJustAdded(m),
            ).just_added_timestamp,
          ).toISOString(),
        }
      }

      return {
        ...p,
        start: [...p.assignments].sort((a, b) =>
          sortByString(a.start_date, b.start_date),
        )[0].start_date,
      }
    })
    .sort((a, b) => sortByString(a.start, b.start))
}

const sortPlaceholdersName = <T extends SortablePlaceholders>(
  placeholders: ReadonlyArray<T>,
) =>
  sortBy(placeholders, (p) =>
    formatName(p.first_name, p.last_name).toLowerCase(),
  )

const sortPlaceholdersRoleName = <T extends SortablePlaceholders>(
  placeholders: ReadonlyArray<T>,
) => sortPeopleByRoleName(placeholders)

const sortPlaceholdersTeamName = <T extends SortablePlaceholders>(
  placeholders: ReadonlyArray<T>,
) => sortPeopleByTeamName(placeholders)

export const sortPlaceholders = <T extends SortablePlaceholders>(
  placeholders: ReadonlyArray<T>,
  projects: ReadonlyArray<{
    id: number
    name: string
    priority: string | null
  }>,
  sortByOption: PlaceholderSortOption,
) => {
  switch (sortByOption) {
    case "startDate":
      return sortPlaceholdersStartDate(placeholders)
    case "priority":
      return sortPlaceholdersPriority(placeholders, projects)
    case "placeholderName":
      return sortPlaceholdersName(placeholders)
    case "roleName":
      return sortPlaceholdersRoleName(placeholders)
    case "teamName":
      return sortPlaceholdersTeamName(placeholders)
    default:
      return sortPlaceholdersProjects(placeholders, projects)
  }
}

export const sortByListOrder = <T extends { name: string; id: number }>(
  items: T[],
  listOrder?: number[],
): T[] => {
  if (!listOrder || !listOrder.length) {
    return items.sort((a, b) => sortByString(a.name, b.name))
  }

  return items.sort((a, b) => {
    return listOrder.indexOf(a.id) - listOrder.indexOf(b.id)
  })
}

export const sortByNumber = (a, b) => {
  const numA = Number(a)
  const numB = Number(b)
  if (numA < numB) {
    return -1
  }

  if (numA > numB) {
    return 1
  }

  return 0
}

export default {
  sortByString,
  sortProjectsBy,
  sortPeopleBy,
  sortByListOrder,
  sortByNumber,
}
