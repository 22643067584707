import React from "react"

type Props = {
  size?: number
  color?: string
  style?: any
}

const DashCircle = (props: Props) => {
  const { size = 8, color = "var(--midnight)", ...other } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1C4.58 1 1 4.58 1 9C1 13.42 4.58 17 9 17C13.42 17 17 13.42 17 9C17 4.58 13.42 1 9 1ZM12 10H6C5.45 10 5 9.55 5 9C5 8.45 5.45 8 6 8H12C12.55 8 13 8.45 13 9C13 9.55 12.55 10 12 10Z"
        fill={color}
      />
    </svg>
  )
}
export default DashCircle
