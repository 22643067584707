type ErrorLevels = "debug" | "info" | "warning" | "error" | "critical"

const report = (
  level: ErrorLevels,
  error: any,
  meta?: Record<string, any>,
  errorObject?: Error,
): Promise<any> => {
  // eslint-disable-next-line no-console
  console.log("reportError", error, error.message, meta)

  // Ensure callbacks get resolved if Rollbar isn't defined
  if (!window?.Rollbar) {
    return Promise.resolve()
  }

  return new Promise((resolve) => {
    const args = [error, meta, errorObject, resolve].filter(Boolean)
    // @ts-ignore
    window.Rollbar[level](...args)
  })
}

export const reportDebug = (
  error: any,
  meta?: Record<string, any>,
  errorObject?: Error,
) => report("debug", error, meta, errorObject)

export const reportWarning = (
  error: any,
  meta?: Record<string, any>,
  errorObject?: Error,
) => report("warning", error, meta, errorObject)

export const reportInfo = (
  error: any,
  meta?: Record<string, any>,
  errorObject?: Error,
) => report("info", error, meta, errorObject)
export const reportError = (
  error: any,
  meta?: Record<string, any>,
  errorObject?: Error,
) => report("error", error, meta, errorObject)

export const reportCritical = (
  error: any,
  meta?: Record<string, any>,
  errorObject?: Error,
) => report("critical", error, meta, errorObject)

export const captureEvent = (
  event: Record<string, any>,
  level: ErrorLevels,
) => {
  // @ts-ignore
  if (window.Rollbar) {
    // @ts-ignore
    window.Rollbar.captureEvent(event, level)
  }
}

export default {
  reportError,
  reportDebug,
  reportWarning,
  reportInfo,
  captureEvent,
  reportCritical,
}
