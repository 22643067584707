import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type ScenarioPlanningState = {
  /**
   * An array of tentative project id's that are enabled
   */
  enabledTentativeProjects: number[]
}

const initialState: ScenarioPlanningState = {
  enabledTentativeProjects: [],
}

export const scenarioPlanning = createSlice({
  name: "scenarioPlanning",
  initialState,
  reducers: {
    toggleProject: (state, action: PayloadAction<number>) => {
      const id = action.payload
      const index = state.enabledTentativeProjects.indexOf(id)
      if (index === -1) {
        state.enabledTentativeProjects.push(id)
      } else {
        state.enabledTentativeProjects.splice(index, 1)
      }
    },
    enableProject: (state, action: PayloadAction<number>) => {
      const id = action.payload
      const isDuplicate = state.enabledTentativeProjects.includes(id)
      if (!isDuplicate) {
        state.enabledTentativeProjects.push(id)
      }
    },
    disableProject: (state, action: PayloadAction<number>) => {
      const id = action.payload
      const index = state.enabledTentativeProjects.indexOf(id)
      if (index !== -1) {
        state.enabledTentativeProjects.splice(index, 1)
      }
    },
    enableProjects: (state, action: PayloadAction<number[]>) => {
      const enabledTentativeProjects = new Set([
        ...state.enabledTentativeProjects,
        ...action.payload,
      ])
      state.enabledTentativeProjects = Array.from(enabledTentativeProjects)
    },
    disableProjects: (state, action: PayloadAction<number[]>) => {
      state.enabledTentativeProjects = state.enabledTentativeProjects.filter(
        (id) => !action.payload.includes(id),
      )
    },
    disableAllProjects: (state) => {
      state.enabledTentativeProjects = []
    },
    enableOnly: (state, action: PayloadAction<number[]>) => {
      state.enabledTentativeProjects = action.payload
    },
  },
})

const { actions, reducer } = scenarioPlanning
export const {
  toggleProject,
  enableProject,
  disableProject,
  enableProjects,
  disableAllProjects,
  enableOnly,
  disableProjects,
} = actions

export default reducer

export const selectNumberOfEnabledTentativeProjects = (
  state: ScenarioPlanningState,
) => state.enabledTentativeProjects.length

export const selectAnyTentativeProjectsEnabled = (
  state: ScenarioPlanningState,
) => state.enabledTentativeProjects.length > 0

/**
 * Where possible use {@link selectIsTentativeProjectEnabled} as this function is not tied to the scenario planning state
 *
 */
export const isTentativeProjectEnabled = (
  enabledTentativeProjects: number[],
  id: number,
): boolean => enabledTentativeProjects.includes(id)

export const selectIsTentativeProjectEnabled = (
  state: ScenarioPlanningState,
  id: number,
): boolean => isTentativeProjectEnabled(state.enabledTentativeProjects, id)
