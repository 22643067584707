/**
 * @generated SignedSource<<2264ab854d04d45fded7fcced1c9c1d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TagPeopleArchiveMutation$variables = {
  archived: boolean;
  id: number;
};
export type TagPeopleArchiveMutation$data = {
  readonly updatePeopleTag: {
    readonly account: {
      readonly id: number;
      readonly tags: ReadonlyArray<{
        readonly archived: boolean;
        readonly id: number;
        readonly model: string;
        readonly name: string;
      }>;
    } | null | undefined;
  };
};
export type TagPeopleArchiveMutation = {
  response: TagPeopleArchiveMutation$data;
  variables: TagPeopleArchiveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archived"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "archived",
        "variableName": "archived"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "UpdatePeopleTagResult",
    "kind": "LinkedField",
    "name": "updatePeopleTag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "tags",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "model",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archived",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TagPeopleArchiveMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TagPeopleArchiveMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9741bde93a213ea72d1d268365b36e87",
    "id": null,
    "metadata": {},
    "name": "TagPeopleArchiveMutation",
    "operationKind": "mutation",
    "text": "mutation TagPeopleArchiveMutation(\n  $id: Int!\n  $archived: Boolean!\n) {\n  updatePeopleTag(id: $id, archived: $archived) {\n    account {\n      id\n      tags {\n        id\n        model\n        name\n        archived\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "712df6288933e6779786dbe2c8b91e5f";

export default node;
