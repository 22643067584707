export const LOCAL_PLACEHOLDER_ID = -999999

type GetLocalIdFn = () => number

/*
 * LOCAL IDs vs SERVER IDs
 *
 * Local IDs are always negative.
 * Server IDs are always positive.
 *
 * Use getLocalId() to generate a new unique local ID.
 * Use isLocalId(id) to test if an id is local or not.
 */

export const getLocalId: GetLocalIdFn = (() => {
  // NOTE(GC): instead of storing `nextId` in the global scope,
  // we can use an IIFE to limit its scope to just this function.
  let nextId = -1

  return () => {
    const id = nextId
    nextId -= 1
    return id
  }
})()

export const isLocalId = (id: number) => {
  return id < 0
}
