import React from "react"

type Props = {
  alt?: string
}

const CompanyDefault = (props: Props) => (
  <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>{props.alt}</title>
    <g clipPath="url(#clip0_3610_32519)">
      <path
        d="M12.6415 12.6416L18.9585 6.32452L18.9513 12.6488L12.6343 18.9658L12.6415 12.6416Z"
        fill="#FFB077"
      />
      <path
        d="M0.0143352 18.9446L12.6484 18.959L-6.08005e-05 6.31049L0.0143352 18.9446Z"
        fill="#4057DF"
      />
      <path
        d="M18.9655 18.9657L12.6484 18.9585L18.9583 12.6486L18.9655 18.9657Z"
        fill="#B19DE6"
      />
      <path
        d="M6.30592 0.0153104L-0.00393665 6.32516L12.6302 6.33956L6.30592 0.0153104Z"
        fill="#67D0D5"
      />
      <path
        d="M12.6405 6.37143L12.6333 12.6885L18.9575 6.36423L12.6405 6.37143Z"
        fill="#0A9CC1"
      />
      <path
        d="M18.9541 6.36426L12.6299 6.36426L12.6299 0.0544055L18.9541 0.0544047L18.9541 6.36426Z"
        fill="#FDCD4F"
      />
      <path
        d="M12.634 6.32439L12.6484 18.9585L-5.94518e-05 6.31L12.634 6.32439Z"
        fill="#F5848C"
      />
    </g>
  </svg>
)

export default CompanyDefault
