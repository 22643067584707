import React from "react"

type Props = {
  color?: string
}

const Lock = (props: Props) => {
  const { color = "var(--midnight)" } = props

  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6H9.75V4.5C9.75 2.43 8.07 0.75 6 0.75C3.93 0.75 2.25 2.43 2.25 4.5V6H1.5C0.675 6 0 6.675 0 7.5V15C0 15.825 0.675 16.5 1.5 16.5H10.5C11.325 16.5 12 15.825 12 15V7.5C12 6.675 11.325 6 10.5 6ZM6 12.75C5.175 12.75 4.5 12.075 4.5 11.25C4.5 10.425 5.175 9.75 6 9.75C6.825 9.75 7.5 10.425 7.5 11.25C7.5 12.075 6.825 12.75 6 12.75ZM3.75 4.5V6H8.25V4.5C8.25 3.255 7.245 2.25 6 2.25C4.755 2.25 3.75 3.255 3.75 4.5Z"
        fill={color}
      />
    </svg>
  )
}

export default Lock
