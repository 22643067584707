import React from "react"

const PersonPin = () => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 0.5H1.75C0.9175 0.5 0.25 1.175 0.25 2V12.5C0.25 13.325 0.9175 14 1.75 14H4.75L7 16.25L9.25 14H12.25C13.075 14 13.75 13.325 13.75 12.5V2C13.75 1.175 13.075 0.5 12.25 0.5ZM7 2.975C8.1175 2.975 9.025 3.8825 9.025 5C9.025 6.1175 8.1175 7.025 7 7.025C5.8825 7.025 4.975 6.1175 4.975 5C4.975 3.8825 5.8825 2.975 7 2.975ZM2.5 10.325V11H11.5V10.325C11.5 8.825 8.5 8 7 8C5.5 8 2.5 8.825 2.5 10.325Z"
      fill="#3C4260"
    />
  </svg>
)

export default PersonPin
