/**
 * @generated SignedSource<<db1c3e02005e27cf03dfbd0cc83e0754>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermissionsProvider_user$data = {
  readonly account: {
    readonly id: number;
    readonly timesheets_protected: boolean | null | undefined;
  };
  readonly email: string;
  readonly id: number;
  readonly manageable_projects: ReadonlyArray<{
    readonly id: number;
    readonly project: {
      readonly id: number;
    };
  }>;
  readonly permissions: any;
  readonly " $fragmentType": "PermissionsProvider_user";
};
export type PermissionsProvider_user$key = {
  readonly " $data"?: PermissionsProvider_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PermissionsProvider_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timesheets_protected",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "project_managers",
      "kind": "LinkedField",
      "name": "manageable_projects",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "projects",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "422029a93f74fa08925a2829e4229f94";

export default node;
