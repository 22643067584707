import cc from "classcat"
import React from "react"

import styles from "./CellDivider.module.css"

// We use this CellDivider, which is absolutely positioned,
// to avoid issues when we use borders on some components and not others
// This ensures everything is aligned correctly.

type Props = {
  onTopOfPills?: boolean
  bold?: boolean
  color?: string
}

const CellDivider = (props: Props) => {
  const { onTopOfPills, bold, color } = props

  return (
    <div
      className={cc([
        styles.cellDivider,
        {
          [styles.onTopOfPills]: onTopOfPills,
          [styles.bold]: bold,
        },
      ])}
      style={{ backgroundColor: color }}
    />
  )
}

export default CellDivider
