import React from "react"

type Props = {
  color?: string
} & React.SVGProps<SVGSVGElement>

const BookmarkFilled = (props: Props) => {
  const { color, ...otherProps } = props
  return (
    <svg
      {...otherProps}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 2.25H5.25C4.425 2.25 3.75 2.925 3.75 3.75V15.75L9 13.5L14.25 15.75V3.75C14.25 2.925 13.575 2.25 12.75 2.25Z"
        fill={color}
      />
    </svg>
  )
}

export default BookmarkFilled
