import React from "react"

type Props = {
  color?: string
  size?: number
  alt?: string
}
const ProjectClipboard = (props: Props) => {
  const { size = 18, color = "var(--midnight)", alt } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 2.25H11.115C10.8 1.38 9.975 0.75 9 0.75C8.025 0.75 7.2 1.38 6.885 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM9 2.25C9.4125 2.25 9.75 2.5875 9.75 3C9.75 3.4125 9.4125 3.75 9 3.75C8.5875 3.75 8.25 3.4125 8.25 3C8.25 2.5875 8.5875 2.25 9 2.25ZM5.25 12.75H10.5V11.25H5.25V12.75ZM12.75 9.75H5.25V8.25H12.75V9.75ZM5.25 6.75H12.75V5.25H5.25V6.75Z"
        fill={color}
      />
    </svg>
  )
}

export default ProjectClipboard
