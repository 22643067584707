/**
 * @generated SignedSource<<9e36d7fd1121aa3fafd75696600cc803>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusTooltipContent_gql$data = {
  readonly billing: {
    readonly subscription: {
      readonly id: string;
      readonly plan: {
        readonly id: string;
        readonly itemPriceId: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "StatusTooltipContent_gql";
};
export type StatusTooltipContent_gql$key = {
  readonly " $data"?: StatusTooltipContent_gql$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusTooltipContent_gql">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusTooltipContent_gql",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "syncSubscription",
          "value": false
        }
      ],
      "concreteType": "Billing",
      "kind": "LinkedField",
      "name": "billing",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingSubscription",
          "kind": "LinkedField",
          "name": "subscription",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "BillingSubscriptionItem",
              "kind": "LinkedField",
              "name": "plan",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "itemPriceId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "billing(syncSubscription:false)"
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "f1f83cc44fe33573ce0623049a54ac7c";

export default node;
