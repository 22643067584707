type UserAccount = {
  account: {
    id: number
    account_type: string
    alternative_account_id: number
  }
}

export const getIsMemberOfOtherAccounts = <U extends UserAccount>(
  user_accounts: readonly U[],
  currentAccountId: number,
): boolean =>
  user_accounts.some(
    ({ account: otherAccount }) =>
      otherAccount.id !== currentAccountId &&
      otherAccount.account_type !== "test" &&
      otherAccount.alternative_account_id !== currentAccountId,
  )
