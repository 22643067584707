import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React, { useCallback, useEffect } from "react"
import ReactDOM from "react-dom/client"

import styles from "./ErrorModal.module.css"

import { reportDebug } from "~/helpers/error-helpers"

import Dialog from "~/common/Dialog"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { TangramAlert } from "~/common/tangrams/tangramAlert"

import { showToast } from "~/containers/ToasterContainer"

import { Help } from "../react-icons"

type Props = {
  children?: React.ReactNode
  className?: string
  portalClassName?: string
  backdropClassName?: string
}

const ErrorModal = (props: Props) => {
  const { children, className, portalClassName, backdropClassName } = props
  let offset = 0
  const topBarRect = document.getElementById("top-bar")?.getBoundingClientRect()
  if (topBarRect) {
    offset = topBarRect.y + topBarRect.height
  }
  const reloadFn = useCallback(() => {
    window.location.reload()
  }, [])

  useEffect(() => {
    void reportDebug("Error modal triggered")
  }, [])

  const resetLocalSettings = () => {
    localStorage.clear()
    showToast({
      message: "Your browser cache has been reset. No data has been deleted.",
      type: "success",
    })
  }
  return (
    <Dialog
      className={className}
      backdropClassName={cc([styles.backdrop, backdropClassName])}
      backdropProps={{
        style: { top: offset },
      }}
      portalClassName={cc([
        "error-modal-portal",
        styles.portal,
        portalClassName,
      ])}
      portalContainer={document.getElementById("app-body") ?? document.body}
      isOpen={true}
      style={{
        marginTop: offset,
      }}
    >
      <ModalFormWrapper
        headerTitle="We're sorry! Something went wrong"
        tangram={<TangramAlert />}
        tangramStyles={{ marginBottom: 25 }}
      >
        <ModalBody wide>{children}</ModalBody>
        <ModalFooter className={styles.footer}>
          <div className={styles.tooltip}>
            <a className={styles.link} onClick={resetLocalSettings}>
              Reset Browser Cache
            </a>
            <Tooltip
              placement="top"
              content={
                "This resets your browser cache, fixing problems caused by\nchanges or errors. No data will be lost on reset. It can be used safely."
              }
              popoverClassName={styles.tooltipLineBreak}
              className={styles.icon}
            >
              <Help />
            </Tooltip>
          </div>
          <Button
            text={`Refresh Page`}
            intent="primary"
            active={true}
            onClick={reloadFn}
          />
        </ModalFooter>
      </ModalFormWrapper>
    </Dialog>
  )
}

// Helper to render the modal outside of the React component hierarchy.
// This can be useful when <ErrorBoundary> can't be used, e.g.
// when errors are thrown in event handlers that don't trigger React's error boundary behaviour.
const renderErrorModal = (props: Props) => {
  let root = document.getElementById("error-modal-root")
  if (!root) {
    root = document.createElement("div")
    root.id = "error-modal-root"
  }

  const container = ReactDOM.createRoot(root)
  return container.render(<ErrorModal {...props} />)
}

export default ErrorModal
export { renderErrorModal }
