import React from "react"

const FixedPrice = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0549 4.10779H9.96509V2.93413C9.96509 1.31449 8.6506 0 7.03096 0C5.41132 0 4.09682 1.31449 4.09682 2.93413V4.10779H3.00701C2.3615 4.10779 1.82999 4.63593 1.82999 5.28144V12.9236C1.82999 13.5691 2.3615 14 3.00701 14H11.0549C11.7004 14 12.2319 13.5691 12.2319 12.9236V5.28144C12.2319 4.63593 11.7004 4.10779 11.0549 4.10779ZM5.27048 4.10778V2.93412C5.27048 1.95999 6.05683 1.17364 7.03096 1.17364C8.00509 1.17364 8.79144 1.95999 8.79144 2.93412V4.10778H5.27048ZM7.65721 12.491V11.6504C8.43002 11.5051 9.0385 11.065 9.04294 10.2596C9.04294 9.15051 8.08359 8.7676 7.18641 8.53874C6.28924 8.30988 6.00055 8.06781 6.00055 7.69811C6.00055 7.27559 6.40028 6.97631 7.06649 6.97631C7.76824 6.97631 8.03029 7.3108 8.0525 7.79934H8.92302C8.89637 7.12595 8.48332 6.50538 7.65721 6.30733V5.4491H6.47134V6.29853C5.70741 6.46137 5.09005 6.95431 5.09005 7.71131C5.09005 8.61356 5.84509 9.06248 6.94657 9.32655C7.93702 9.55982 8.13244 9.90311 8.13244 10.2684C8.13244 10.5369 7.93702 10.9682 7.06649 10.9682C6.25371 10.9682 5.93392 10.6073 5.88951 10.1452H5.01898C5.06784 10.999 5.71185 11.4831 6.47134 11.6416V12.491H7.65721Z"
      fill="#3C4260"
    />
  </svg>
)

export default FixedPrice
