import React, { useState } from "react"

import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import { seedAccountWithDemoData } from "~/helpers/routes"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { TangramTriangle } from "~/common/tangrams"

type Props = {
  onClose: () => void
}
const ResetTestAccountModal = (props: Props) => {
  const { onClose } = props
  const [isReseting, setIsReseting] = useState(false)

  const handleResetTestAccount = async () => {
    setIsReseting(true)
    await fetchWithCsrf(seedAccountWithDemoData(), {
      method: "POST",
    })
    onClose()
  }

  return (
    <ModalFormWrapper
      headerTitle="Reset Test Account"
      tangram={<TangramTriangle />}
      tangramStyles={{ maxWidth: "80%", marginBottom: "10px" }}
    >
      <ModalBody>
        <div>
          Are you sure you want to <b>reset</b> your Test Account with demo
          data? This will overwrite the data currently in the Test Account.
        </div>
        <br />
        <div>This action may take a few minutes and cannot be undone.</div>
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={onClose} disabled={isReseting} />
        <Button
          text="Reset Test Account"
          onClick={handleResetTestAccount}
          data-test="reset-account-button"
          type="submit"
          disabled={isReseting}
          outlined={false}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default ResetTestAccountModal
