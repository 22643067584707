import React from "react"

import styles from "./TangramTableItem.module.css"

import TooltipEllipsis from "~/common/TooltipEllipsis"

import { handleCopy } from "../../helper"

type Props = {
  name: string
  Tangram: React.ComponentType<any>
}

const TangramTableItem = (props: Props) => {
  const { name, Tangram } = props

  return (
    <div className={styles.container} onClick={() => handleCopy(name)}>
      <div className={styles.tangram}>
        <Tangram />
      </div>
      <TooltipEllipsis
        text={name}
        className={styles.name}
        customMaxWidth="130px"
      />
    </div>
  )
}

export { TangramTableItem }
