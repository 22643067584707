export enum TIME_OFF_TYPES {
  HOLIDAY = "holiday",
  ROSTERED = "rostered-off",
  ANNUAL = "annual",
}

export enum PERSON_REQUEST_STATUSES {
  NEED_TO_HIRE = "NEED_TO_HIRE",
  REQUESTED = "REQUESTED",
  PENDING = "PENDING",
  CANCEL = "CANCEL", // Not included in database enums
}

export enum NAME_EXTERNAL_REFERENCES {
  HARVEST = "Harvest",
  CLOCKIFY = "Clockify",
  WORKFLOWMAX = "Workflowmax",
}
