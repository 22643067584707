import { commitLocalUpdate } from "react-relay"

import { environment as hasuraEnvironment } from "~/store/hasura"

import * as relayids from "~/helpers/relayids"

export type BaseMember = {
  project_id: number
  person_id: number
  role_id: number
  workstream_id: number
  just_added_timestamp?: number
}
export type Member = BaseMember & { is_placeholder: boolean }
export type Membership = BaseMember & { is_template: boolean }

export type ProjectMember = Member | Membership
export type MemberWithArchiveStatus = Member & { active: boolean }
export type MembershipWithArchiveStatus = Membership & { active: boolean }
type ExistingProjectMember = ProjectMember & { id: number }

export type MemberWithName = Member & {
  person: {
    first_name: string
    last_name: string
  }
}

type Person = {
  id: number
  is_placeholder: boolean
  workstream_id?: number
}

type PersonWithRoleAndContracts = Person & {
  role: {
    id: number
  }
  contracts: {
    role: {
      id: number
    }
  }[]
}

type Project = {
  id: number
  role: {
    id: number
  }
  workstream_id: number
}

type Assignment = {
  id: number
  project_id: number
  person_id: number
  role_id: number
  workstream_id: number
}

type DistinctMember = {
  project_id: number
  role_id: number
  person_id: number
  workstream_id?: number
}

export const getMemberKey = <T extends DistinctMember>(m: T) => {
  let key = `${m.person_id}-${m.role_id}-${m.project_id}`

  if (m.workstream_id) {
    key = `${key}-${m.workstream_id}`
  }

  return key
}

export const isNew = <P extends { just_added_timestamp?: number }>(member: P) =>
  !!member.just_added_timestamp

export const isJustAdded = <P extends { just_added_timestamp?: number }>(
  member: P,
  since = 3000,
) => isNew(member) && member.just_added_timestamp + since > Date.now()

export const isSameMember = (m1: DistinctMember, m2: DistinctMember) =>
  getMemberKey(m1) === getMemberKey(m2)

export const containsMember = (
  members: ReadonlyArray<DistinctMember>,
  member: DistinctMember,
) => members.some((m) => isSameMember(m, member))

const getAssignmentsBasedOnMemberRecord = <A extends Assignment>(
  assignments: ReadonlyArray<A>,
): A[] => {
  const uniqueAssignments = new Set<string>()
  return assignments?.filter((a) => {
    if (!a) {
      return
    }

    const uniqueKey = getMemberKey(a)

    if (uniqueAssignments.has(uniqueKey)) {
      return false
    }
    uniqueAssignments.add(uniqueKey)
    return true
  })
}

export const separateActiveInactiveMembers = <
  M extends Omit<MemberWithArchiveStatus, "is_placeholder">,
  A extends Assignment,
>(
  projectMembers: ReadonlyArray<M>,
  assignments: ReadonlyArray<A>,
) => {
  const activeMembers: M[] = []
  const inactiveMembers: M[] = [...projectMembers]

  const projectMemberAssignments =
    getAssignmentsBasedOnMemberRecord(assignments)

  for (const assignment of projectMemberAssignments) {
    const index = inactiveMembers.findIndex((member) =>
      isSameMember(member, assignment),
    )

    if (index !== -1) {
      const member = inactiveMembers[index]
      activeMembers.push(member)
      inactiveMembers.splice(index, 1)
    }
  }

  // Remove archived people from the inactive list
  const noArchivedInactivePeople = inactiveMembers.filter(
    (member) => member.active,
  )

  return {
    activeMembers,
    inactiveMembers: noArchivedInactivePeople,
  }
}

export const countPeoplePlaceholderMembers = (members: ReadonlyArray<Member>) =>
  members.reduce(
    (count: { people: number; placeholders: number }, member: Member) => {
      if (member.is_placeholder) {
        count.placeholders++
      } else {
        count.people++
      }
      return count
    },
    { people: 0, placeholders: 0 },
  )

export const separatePeoplePlaceholderMembers = <M extends Member>(
  projectMembers: ReadonlyArray<M>,
) => {
  const placeholders: M[] = []
  const people: M[] = []

  for (const member of projectMembers) {
    if (member.is_placeholder) {
      placeholders.push(member)
    } else {
      people.push(member)
    }
  }

  return {
    placeholders,
    people,
  }
}

const createMembershipSet = <P extends ProjectMember>(
  projectMembers: ReadonlyArray<P>,
) => new Set(projectMembers.map(getMemberKey))

export const findNewMembers = <
  P extends PersonWithRoleAndContracts,
  PM extends ExistingProjectMember,
>(
  people: ReadonlyArray<P>,
  currentProjectMembers: ReadonlyArray<PM>,
  projectId: number,
  workstreamId: number,
) => {
  const membershipSet = createMembershipSet(currentProjectMembers)
  const existingMembers: PM[] = []

  const newMembers = people
    .map((person) => {
      const roleId = person.is_placeholder
        ? person.contracts[0].role.id
        : person.role.id

      const memberData = {
        project_id: projectId,
        person_id: person.id,
        role_id: roleId,
        workstream_id: workstreamId ?? person.workstream_id ?? null,
        is_placeholder: person.is_placeholder,
      }
      const key = getMemberKey(memberData)

      if (membershipSet.has(key)) {
        const existingMember = currentProjectMembers.find(
          (member) => getMemberKey(member) === key,
        )
        if (existingMember) {
          existingMembers.push(existingMember)
        }
        return null
      } else {
        return memberData
      }
    })
    .filter((m) => !!m)

  return {
    newMembers,
    existingMembers,
  }
}

export const findNewMemberships = <
  P extends Person,
  PM extends ProjectMember,
  NP extends Project,
>(
  person: P,
  currentProjectMemberships: ReadonlyArray<PM>,
  newProjects: ReadonlyArray<NP>,
) => {
  const membershipSet = createMembershipSet(currentProjectMemberships)

  const newMemberships = newProjects
    .map((project) => {
      const memberData = {
        project_id: project.id,
        person_id: person.id,
        role_id: project.role.id,
        workstream_id: project.workstream_id ?? null,
        is_placeholder: person.is_placeholder,
      }
      const key = getMemberKey(memberData)
      return membershipSet.has(key)
        ? null // Already a member of this project for the role. Skip.
        : memberData
    })
    .filter((m) => !!m)

  return newMemberships
}

export const forceShowMembers = (membersToShow: { id: number }[]) => {
  commitLocalUpdate(hasuraEnvironment, (store) => {
    for (const member of membersToShow) {
      const $member = store.get(relayids.projectMembers.encode(member.id))
      if ($member) {
        $member.setValue(new Date().getTime(), "just_added_timestamp")
      }
    }
  })
}
