/**
 * @generated SignedSource<<97bc0cb3d8ff98d7efc1cfe4a1d2ac5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkstreamCard_user$data = {
  readonly account: {
    readonly id: number;
    readonly workstreams: ReadonlyArray<{
      readonly archived: boolean;
      readonly id: number;
      readonly name: string;
    }>;
  };
  readonly id: number;
  readonly " $fragmentSpreads": FragmentRefs<"WorkstreamForm_user" | "WorkstreamItem_user">;
  readonly " $fragmentType": "WorkstreamCard_user";
};
export type WorkstreamCard_user$key = {
  readonly " $data"?: WorkstreamCard_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkstreamCard_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkstreamCard_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "workstreams",
          "kind": "LinkedField",
          "name": "workstreams",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "archived",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkstreamForm_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WorkstreamItem_user"
    }
  ],
  "type": "users",
  "abstractKey": null
};
})();

(node as any).hash = "60e4db88ecf22fc14977c6f165da1303";

export default node;
