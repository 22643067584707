import React from "react"

import ArchiveModal from "~/Modals/ArchiveModal"

type Props = {
  workstream: {
    id: number
    name: string
    archived: boolean
  }
  closeDialog: () => void
}

const ArchiveWorkstreamModal = (props: Props) => {
  const { workstream, closeDialog } = props
  const { id, name, archived } = workstream

  return (
    <ArchiveModal
      type="workstream"
      id={id}
      name={name}
      archivable={true}
      customMessage={
        <div>
          <p>
            You will no longer be able to add the <b>{name}</b> workstream to
            projects.
          </p>
          <p>It will remain on all projects that already use it.</p>
        </div>
      }
      active={!archived}
      closeDialog={closeDialog}
    />
  )
}

export default ArchiveWorkstreamModal
