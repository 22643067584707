/**
 * @generated SignedSource<<8f00bebdc4383a103fb7efb7a8a1d31f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusQuery$variables = Record<PropertyKey, never>;
export type StatusQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeatureFlags_gql" | "StatusTooltipContent_gql">;
};
export type StatusQuery = {
  response: StatusQuery$data;
  variables: StatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StatusQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "StatusTooltipContent_gql"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FeatureFlags_gql"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StatusQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "syncSubscription",
            "value": false
          }
        ],
        "concreteType": "Billing",
        "kind": "LinkedField",
        "name": "billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingSubscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingSubscriptionItem",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemPriceId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "billing(syncSubscription:false)"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features",
        "kind": "LinkedField",
        "name": "features",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "features_accounts",
        "kind": "LinkedField",
        "name": "features_accounts",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feature_id",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9820d6abbb6ae63e290660cf150c6917",
    "id": null,
    "metadata": {},
    "name": "StatusQuery",
    "operationKind": "query",
    "text": "query StatusQuery {\n  ...StatusTooltipContent_gql\n  ...FeatureFlags_gql\n}\n\nfragment FeatureAccountCheckbox_feature on features {\n  id\n  enabled\n  description\n  name\n}\n\nfragment FeatureFlags_gql on query_root {\n  features {\n    id\n    name\n    ...FeatureAccountCheckbox_feature\n  }\n  billing(syncSubscription: false) {\n    subscription {\n      id\n      plan {\n        id\n        itemPriceId\n      }\n    }\n  }\n  ...ServerFlagsProvider_gql\n}\n\nfragment ServerFlagsProvider_gql on query_root {\n  features {\n    id\n    enabled\n    description\n    name\n  }\n  features_accounts {\n    id\n    feature_id\n    enabled\n  }\n}\n\nfragment StatusTooltipContent_gql on query_root {\n  billing(syncSubscription: false) {\n    subscription {\n      id\n      plan {\n        id\n        itemPriceId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4b7d672fb70599faf1988ed7344f39a";

export default node;
