type Environment = "production" | "staging" | "review-app" | "development"

type EnvInfo = {
  env: Environment
  subdomain?: string
  pullRequestId?: number
  pullRequestUrl?: string
}

const getEnvInfo = (locationHref: string): EnvInfo => {
  const { hostname } = new URL(locationHref)

  const prMatch = /^runn-pr-(\d+)-app-\w{12}.herokuapp.com$/.exec(hostname)
  if (prMatch) {
    const pullRequestId = Number.parseInt(prMatch[1], 10)
    return {
      env: "review-app",
      pullRequestId,
      pullRequestUrl: `https://github.com/Runn-Fast/runn/pull/${pullRequestId}`,
    }
  }
  const subdomain = hostname.split(".").at(0)

  const stagingMatch = /runn-testing/.test(hostname)
  if (stagingMatch) {
    return {
      env: "staging",
      subdomain,
    }
  }

  if (hostname === "localhost" || hostname === "127.0.0.1") {
    return {
      env: "development",
    }
  }

  return {
    env: "production",
    subdomain,
  }
}

export { getEnvInfo }
