import React from "react"

const ProjectTemplateAvatarBlue = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20.5" r="20" fill="#4057DF" />
    <path
      d="M18.7813 29H21.6787V15.2314H26.6763V12.7944H13.7949V15.2314H18.7813V29Z"
      fill="#F8F9FA"
    />
  </svg>
)

export default ProjectTemplateAvatarBlue
