import React from "react"

type Props = {
  color?: any
}

const ProjectTemplate = (props: Props) => {
  const { color = "var(--midnight)" } = props

  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="7.5"
        y="13.375"
        width="3.75"
        height="1.25"
        transform="rotate(180 7.5 13.375)"
        fill={color}
      />
      <rect
        x="7.5"
        y="10.875"
        width="3.75"
        height="1.25"
        transform="rotate(180 7.5 10.875)"
        fill={color}
      />
      <rect
        x="7.5"
        y="8.375"
        width="3.75"
        height="1.25"
        transform="rotate(180 7.5 8.375)"
        fill={color}
      />
      <rect
        x="7.5"
        y="5.875"
        width="3.75"
        height="1.25"
        transform="rotate(180 7.5 5.875)"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 17.125L18.75 17.125C19.5 17.125 20 16.625 20 15.875L20 2.125C20 1.375 19.5 0.875004 18.75 0.875004L1.25 0.875005C0.500002 0.875006 2.43779e-06 1.375 2.50336e-06 2.125L3.70542e-06 15.875C3.77099e-06 16.625 0.500004 17.125 1.25 17.125ZM10 3.375L17.5 3.375L17.5 14.625L10 14.625L10 3.375ZM8.75 3.375L2.5 3.37501L2.5 14.625L8.75 14.625L8.75 3.375Z"
        fill={color}
      />
    </svg>
  )
}

export default ProjectTemplate
