import React from "react"

type Props = {
  color?: string
  size?: number
  alt?: string
}

const Client = (props: Props) => {
  const { size = 18, color = "var(--midnight)", alt } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0367 2.44444H14.0556C14.85 2.44444 15.5 3.09444 15.5 3.88889V14C15.5 14.7944 14.85 15.4444 14.0556 15.4444H3.94444C3.15 15.4444 2.5 14.7944 2.5 14V3.88889C2.5 3.09444 3.15 2.44444 3.94444 2.44444H6.96333C7.26667 1.60667 8.06111 1 9 1C9.93889 1 10.7333 1.60667 11.0367 2.44444ZM9.72222 3.16667C9.72222 2.76944 9.39722 2.44444 9 2.44444C8.60278 2.44444 8.27778 2.76944 8.27778 3.16667C8.27778 3.56389 8.60278 3.88889 9 3.88889C9.39722 3.88889 9.72222 3.56389 9.72222 3.16667ZM9 5.33333C10.1989 5.33333 11.1667 6.30111 11.1667 7.5C11.1667 8.69889 10.1989 9.66667 9 9.66667C7.80111 9.66667 6.83333 8.69889 6.83333 7.5C6.83333 6.30111 7.80111 5.33333 9 5.33333ZM4.66667 12.9889V14H13.3333V12.9889C13.3333 11.5444 10.4444 10.75 9 10.75C7.55556 10.75 4.66667 11.5444 4.66667 12.9889Z"
        fill={color}
      />
    </svg>
  )
}
export default Client
