/**
 * @generated SignedSource<<485b335473686c5bc9a97670ffcff00a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RateCardUpdateActiveMutation$variables = {
  active?: boolean | null | undefined;
  id: number;
};
export type RateCardUpdateActiveMutation$data = {
  readonly update_rate_cards: {
    readonly returning: ReadonlyArray<{
      readonly active: boolean;
      readonly id: number;
    }>;
  } | null | undefined;
};
export type RateCardUpdateActiveMutation = {
  response: RateCardUpdateActiveMutation$data;
  variables: RateCardUpdateActiveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "active"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "active",
            "variableName": "active"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "_and",
            "value": [
              {
                "name": {
                  "_neq": "Standard"
                }
              },
              {
                "name": {
                  "_neq": "Internal"
                }
              }
            ]
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "rate_cards_mutation_response",
    "kind": "LinkedField",
    "name": "update_rate_cards",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "rate_cards",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RateCardUpdateActiveMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RateCardUpdateActiveMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "187ab5d5a5871a422ba6d5775fda5c5f",
    "id": null,
    "metadata": {},
    "name": "RateCardUpdateActiveMutation",
    "operationKind": "mutation",
    "text": "mutation RateCardUpdateActiveMutation(\n  $id: Int!\n  $active: Boolean\n) {\n  update_rate_cards(where: {id: {_eq: $id}, _and: [{name: {_neq: \"Standard\"}}, {name: {_neq: \"Internal\"}}]}, _set: {active: $active}) {\n    returning {\n      id\n      active\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "279d7106793614e11e13415fe853d1d0";

export default node;
