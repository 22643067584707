import React from "react"

import styles from "./InputLabel.module.css"

type Props = {
  label: string | React.ReactElement
  htmlFor?: string // TODO: Make this required for accessibility
  error?: boolean
  customErrorMessage?: string
  optional?: boolean
  labelRight?: string | React.ReactElement
}

const InputLabel = (props: Props) => {
  const { label, error, customErrorMessage, optional, labelRight, htmlFor } =
    props
  const errorMessage = customErrorMessage || "is invalid"

  return (
    <label
      htmlFor={htmlFor}
      className={`${styles.inputLabel} ${error && styles.error}`}
    >
      <div className={styles["inputLabel-text"]}>
        {label}{" "}
        {`${error ? errorMessage : ""}${
          optional && !error ? " (optional)" : ""
        }`}
      </div>
      {labelRight}
    </label>
  )
}

export default InputLabel
