import { Icon, IconProps } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./IconThreeDot.module.css"

type Props = {
  active?: boolean
  onClick?: (any) => void
  role?: "button"
} & Omit<IconProps, "icon">

const IconThreeDot = (props: Props) => {
  const { active, className, size, ...rest } = props

  return (
    <Icon
      className={cc([
        styles.icon,
        className,
        {
          [styles.active]: active,
        },
      ])}
      icon="more"
      size={size}
      {...rest}
    />
  )
}

export { IconThreeDot }
