import { useFeature } from "flagged"
import React, { ReactNode, useState } from "react"

import styles from "./UpgradePlanModal.module.css"

import { ModalFormWrapper, ModalHeader } from "~/common/ModalForm"
import ModalBody from "~/common/ModalForm/ModalBody"
import ModalFooter from "~/common/ModalForm/ModalFooter"
import Select from "~/common/Select"
import Button from "~/common/buttons/Button"
import { TangramAlert } from "~/common/tangrams/tangramAlert"

import { subscriptionUpdate } from "~/mutations/Subscription"

import {
  CHARGEBEE_STARTER_TRIAL_PRICE_ITEM_ID,
  CHARGEBEE_TRIAL_PRICE_ITEM_ID,
  ChargebeeFeatures,
} from "~/Entitlements/plansAndFeatures"
import { useEntitlementQuantity } from "~/Entitlements/useEntitlements"
import { SUBSCRIPTIONS } from "~/GLOBALVARS"
import { showToast } from "~/containers/ToasterContainer"

type Props = {
  isAdmin: boolean
  closeDialog: () => void
  isInPanel?: boolean
  onSuccess?: () => void
  title?: string
  description?: ReactNode
}

const planOptions = [
  {
    label: "Starter Plan",
    value: CHARGEBEE_STARTER_TRIAL_PRICE_ITEM_ID,
  },
  {
    label: "Professional Plan",
    value: CHARGEBEE_TRIAL_PRICE_ITEM_ID,
  },
]

const UpgradePlanModal = (props: Props) => {
  const { closeDialog, isAdmin, isInPanel, onSuccess } = props
  const [trialPlan, setTrialPlan] = useState(planOptions[1])
  const [isUpgrading, setIsUpgrading] = useState(false)
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const peopleEntitlement = useEntitlementQuantity(ChargebeeFeatures.people)
  const peopleLimit = entitlementsEnabled
    ? peopleEntitlement
    : SUBSCRIPTIONS.free_plan_limit

  const startTrial = async () => {
    setIsUpgrading(true)
    const subscriptionPayload = {
      input: {
        item_price_id: entitlementsEnabled
          ? trialPlan.value
          : SUBSCRIPTIONS.pro_plan_id,
      },
    }
    await subscriptionUpdate(subscriptionPayload)

    setIsUpgrading(false)
    showToast({
      message: "Upgrade Successful. This page will now reload.",
      type: "success",
    })
    setTimeout(() => window.location.reload(), 500)
    onSuccess?.()
  }

  const title = props.title || "Start a free trial to add more people"

  const Wrapper = ({ children }) => {
    if (isInPanel) {
      return (
        <>
          <ModalHeader text={title} onCloseClick={closeDialog} />
          {children}
        </>
      )
    }

    return (
      <ModalFormWrapper
        data-test="start-trial-modal"
        headerTitle={title}
        tangram={<TangramAlert />}
        tangramStyles={{ marginBottom: 15 }}
      >
        {children}
      </ModalFormWrapper>
    )
  }

  return (
    <Wrapper>
      <ModalBody>
        <>
          {props.description || (
            <p>
              You have reached the limit of{" "}
              {entitlementsEnabled
                ? peopleLimit
                : SUBSCRIPTIONS.free_plan_limit}{" "}
              active people on the free plan
            </p>
          )}
          <p>
            <b>Start a 14 day free trial</b> to access unlimited people and
            unlock additonal features. No credit card needed.
          </p>
          {isAdmin && entitlementsEnabled && (
            <div className={styles.select}>
              <Select
                dataTest="trial-plan-select"
                name="trial-plan"
                value={trialPlan}
                onChange={setTrialPlan}
                label="Select a plan"
                options={planOptions}
              />
            </div>
          )}
          {!isAdmin ? (
            <p>Ask your account admin to upgrade this account.</p>
          ) : null}
        </>
      </ModalBody>
      <ModalFooter>
        <Button text="Close" onClick={closeDialog} />
        {isAdmin && (
          <Button
            text="Start Trial"
            loading={isUpgrading}
            onClick={startTrial}
            outlined={false}
            disabled={isUpgrading}
            style={{ textTransform: "capitalize" }}
          />
        )}
      </ModalFooter>
    </Wrapper>
  )
}

export default UpgradePlanModal
