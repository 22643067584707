import { Icon } from "@blueprintjs/core"
import React, { useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

import styles from "./GlobalFilter.module.css"

import Input from "~/common/Input"

type Props = {
  globalFilter: string
  setGlobalFilter: (string) => void
  placeholder?: string
  style?: Record<string, any>
}

const GlobalFilter = (props: Props) => {
  const {
    globalFilter,
    setGlobalFilter,
    placeholder = "Search",
    ...rest
  } = props

  const [value, setValue] = useState(globalFilter)

  useEffect(() => {
    setValue(globalFilter)
  }, [globalFilter])

  const resetFilter = () => {
    setValue("")
    setGlobalFilter("")
  }

  const debouncedOnChange = useDebouncedCallback((val) => {
    setGlobalFilter(val)
  }, 500)

  const handleChange = (e) => {
    setValue(e.target.value)
    debouncedOnChange(e.target.value || undefined)
  }

  return (
    <div className={styles.globalFilter} {...rest}>
      <Icon icon="search" className={styles.searchIcon} />
      <Input
        dataTest="GlobalFilter"
        value={value || ""}
        onChange={handleChange}
        placeholder={placeholder}
        autoComplete="off"
      />
      {value && (
        <Icon icon="cross" onClick={resetFilter} className={styles.clearIcon} />
      )}
    </div>
  )
}

export default GlobalFilter
