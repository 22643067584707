import React from "react"

import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"

import { toggleActiveStatus } from "~/mutations/ActiveStatus"

type Props = {
  id: number
  name: string
  type: "project" | "person" | "client" | "rateCard" | "role" | "workstream"
  hashid?: string
  active: boolean
  archivable: boolean
  customMessage?: string | React.ReactElement
  inPlanner?: boolean
  closeDialog: () => void
  onHandleSubmit?: () => void
}

const ArchiveModal = (props: Props) => {
  const {
    name,
    closeDialog,
    customMessage,
    archivable,
    type,
    hashid,
    id,
    active,
    inPlanner = false,
    onHandleSubmit,
  } = props

  const header = archivable ? `Archive ${name}` : `You cannot archive ${name}`

  const message = archivable
    ? `Are you sure you want to archive ${name}?`
    : `${name} cannot be archived`

  const handleSubmit = async () => {
    await toggleActiveStatus({
      variables: { type, id, active, hashid, name, inPlanner },
    })
    onHandleSubmit && onHandleSubmit()
    closeDialog()
  }

  const renderCallToActionButton = () => {
    if (!archivable) {
      return <Button text="OK" onClick={closeDialog} />
    }
    return (
      <>
        <Button text="Cancel" onClick={closeDialog} tabIndex={-1} />
        <Button text="Archive" outlined={false} onClick={handleSubmit} />
      </>
    )
  }

  return (
    <ModalFormWrapper headerTitle={header}>
      <ModalBody>{customMessage || message}</ModalBody>
      <ModalFooter>{renderCallToActionButton()}</ModalFooter>
    </ModalFormWrapper>
  )
}

export default ArchiveModal
