import React, { ReactElement } from "react"

import DashboardLabel from "~/common/dashboard-lists/DashboardLabel"
import ReferenceLabels from "~/common/dashboard-lists/ReferenceLabels"

type Props = {
  children?:
    | Array<ReactElement<typeof DashboardLabel>>
    | ReactElement<typeof DashboardLabel>
    | ReactElement<typeof ReferenceLabels>
}

const PageHeaderLabels = (props: Props) => (
  <div
    style={{ display: "inline-flex", margin: "5px 5px 15px 0" }}
    data-test="page-header-labels"
  >
    {props.children}
  </div>
)
export default PageHeaderLabels
