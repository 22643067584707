import React from "react"

const TickCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 13C2.90875 13 0 10.0913 0 6.5C0 2.90875 2.90875 0 6.5 0C10.0913 0 13 2.90875 13 6.5C13 10.0913 10.0913 13 6.5 13ZM9.75 4.0625C9.5225 4.0625 9.31938 4.15188 9.17313 4.29812L5.6875 7.79187L3.82687 5.92312C3.68062 5.77688 3.4775 5.6875 3.25 5.6875C2.80312 5.6875 2.4375 6.05312 2.4375 6.5C2.4375 6.7275 2.52688 6.93062 2.67313 7.07688L5.11062 9.51437C5.25688 9.66063 5.46 9.75 5.6875 9.75C5.915 9.75 6.11812 9.66063 6.26438 9.51437L10.3269 5.45188C10.4731 5.30562 10.5625 5.1025 10.5625 4.875C10.5625 4.42812 10.1969 4.0625 9.75 4.0625Z"
      fill="#3C4260"
    />
  </svg>
)

export default TickCircle
