import { dateHelpers } from "@runn/calculations"
import cc from "classcat"
import { isMonday, isToday, isWeekend } from "date-fns"
import React, { useEffect, useState } from "react"

import styles from "./Cell.module.css"

import { Popover2 } from "~/common/Popover2"

import GLOBAL from "~/GLOBALVARS"

import CellDivider from "./CellDivider"

const CellPlusIcon = () => (
  <div className={styles.plusIcon}>
    <div className={styles.circle}>
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Add</title>
        <path
          fill="#D5D8E4"
          d="M10,8 L10,5 C10,4.44771525 9.55228475,4 9,4 C8.44771525,4 8,4.44771525 8,5 L8,8 L5,8 C4.44771525,8 4,8.44771525 4,9 C4,9.55228475 4.44771525,10 5,10 L8,10 L8,13 C8,13.5522847 8.44771525,14 9,14 C9.55228475,14 10,13.5522847 10,13 L10,10 L13,10 C13.5522847,10 14,9.55228475 14,9 C14,8.44771525 13.5522847,8 13,8 L10,8 Z M9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 Z"
        ></path>
      </svg>
    </div>
  </div>
)

type Props = {
  allowScroll?: boolean
  day: Date
  disabled: boolean
  children?: React.ReactNode
  extraClassName?: string
  style?: React.CSSProperties
  highlight?: boolean
  isTimeline?: boolean
  type:
    | "placeholder"
    | "standard"
    | "assignment"
    | "timeOff"
    | "milestone"
    | "unconfirmed"
  customPopoverIcon?: any
  canEscapeKeyClose?: boolean
  calendarWeekendsExpanded: boolean
  isStartOfCalendar: boolean
  popoverContent?: (
    day: Date,
    boolean,
    isPopoverOpen: boolean,
  ) => React.ReactElement
  staticContent?: (day: Date, resetHover: () => void) => any
  setHighlightedCellData: (state: any) => void
  isAHoliday?: boolean
  isInDaysCalendarView?: boolean
  holidayOverlapsTimeOff?: boolean
}

export const Cell = (props: Props) => {
  const {
    allowScroll,
    day,
    disabled,
    children,
    extraClassName,
    style,
    popoverContent,
    setHighlightedCellData,
    type,
    customPopoverIcon,
    staticContent,
    canEscapeKeyClose,
    calendarWeekendsExpanded,
    isStartOfCalendar,
    isAHoliday,
    isInDaysCalendarView,
    holidayOverlapsTimeOff,
  } = props

  const [popoverOpen, setPopoverOpen] = useState(false)
  const [hoverState, setHoverState] = useState(false)

  const setHoverStateTrue = () => {
    setHoverState(true)
  }

  const setHoverStateFalse = () => {
    setHoverState(false)
  }

  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  useEffect(() => {
    // Because we use 'hasBackdrop' it stops scrolling.
    if (popoverOpen && allowScroll) {
      const body = document.getElementsByTagName("body")[0]
      body.style.overflow = "scroll"
    }

    return () => {
      if (popoverOpen && allowScroll) {
        const body = document.getElementsByTagName("body")[0]
        body.style.overflow = ""
      }
    }
  }, [allowScroll, popoverOpen])

  const formattedDate = dateHelpers.formatToRunnDate(day)

  let content = children

  if (staticContent) {
    const renderedStaticContent = staticContent(day, setHoverStateFalse)
    if (renderedStaticContent) {
      content = (
        <div
          onClick={stopPropagation}
          style={{ width: "100%", height: "100%" }}
        >
          {renderedStaticContent}
        </div>
      )
    }
  }

  const cellIsWeekend = isWeekend(day)
  const cellIsMonday = isMonday(day)

  const cellClasses = cc({
    [styles.today]: isToday(day),
    [styles.nonWorkingDay]:
      cellIsWeekend || (isInDaysCalendarView && isAHoliday),
    [styles.holidayOverlapsTimeOff]:
      isInDaysCalendarView && holidayOverlapsTimeOff,
  })

  const renderDivider = () => {
    if (!cellIsMonday || isStartOfCalendar || holidayOverlapsTimeOff) {
      return null
    }

    return (
      <CellDivider
        onTopOfPills={
          (cellIsWeekend || cellIsMonday) && calendarWeekendsExpanded
        }
        bold={cellIsMonday && !calendarWeekendsExpanded}
      />
    )
  }

  const handleMouseEnter = (e) => {
    e.preventDefault()

    if (!GLOBAL.INTERACTING_WITH_PILL && type !== "standard") {
      setHoverState(true)
      // cleaner movement of top highlight when resizing an assignment
      setHighlightedCellData({ type, day })
    }
  }

  const handleMouseLeave = (e) => {
    e.preventDefault()
    setHoverState(false)
    if (!popoverOpen) {
      setHoverState(false)
    }
    if (type !== "standard") {
      setHighlightedCellData(null)
    }
  }

  if (!hoverState || type === "standard" || disabled) {
    return (
      <div
        data-test={`calendar-cell-${formattedDate}`}
        className={`${styles.calendarCell} ${cellClasses} ${
          extraClassName || ""
        }`}
        style={style}
        onMouseEnter={setHoverStateTrue}
        onMouseLeave={setHoverStateFalse}
      >
        {renderDivider()}
        {content}
      </div>
    )
  }

  const closePopover = () => {
    setPopoverOpen(false)
    setHoverState(false)
  }

  const onInteraction = (popoverShouldBeOpen) => {
    if (!popoverShouldBeOpen) {
      closePopover()
    }
  }

  const handleMouseClick = (e) => {
    if (popoverOpen === true) {
      // If it's already open. Dont open again
      return
    }

    if (e.target.classList.contains("bp5-popover-dismiss")) {
      // If dismissing hide the hover state
      return closePopover()
    }

    if (popoverContent) {
      setPopoverOpen(true)
    }
  }

  const renderedPopoverContent =
    popoverContent && popoverContent(day, closePopover, popoverOpen)

  return (
    <div
      data-test={`calendar-cell-${formattedDate}`}
      className={`${styles.calendarCell} ${cellClasses} ${
        extraClassName || ""
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      style={style}
    >
      {renderDivider()}
      <Popover2
        className={styles.cellPopover}
        content={renderedPopoverContent}
        isOpen={popoverOpen}
        onClose={closePopover}
        hasBackdrop
        onInteraction={onInteraction}
        canEscapeKeyClose={canEscapeKeyClose}
      >
        <span />
      </Popover2>
      <div>
        {hoverState && (
          <div
            data-test="cell-popover-target"
            onClick={() => setPopoverOpen(true)}
          >
            {customPopoverIcon ? customPopoverIcon : <CellPlusIcon />}
          </div>
        )}
        {content}
      </div>
    </div>
  )
}

export default Cell
