import React from "react"

const InfoCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0ZM7 3H9V5H7V3ZM10 13H6V12H7V7H6V6H9V12H10V13Z"
      fill={props.fill || "#969AB3"}
    />
  </svg>
)

export default InfoCircle
