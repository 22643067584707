/**
 * @generated SignedSource<<a07b313c18e0041690bddd0fdda2acd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectBulkUpdateInput = {
  projects: ReadonlyArray<ProjectBulkUpdateInputProject>;
};
export type ProjectBulkUpdateInputProject = {
  active?: boolean | null | undefined;
  client_id?: number | null | undefined;
  confirmed?: boolean | null | undefined;
  id: number;
  manager_ids?: ReadonlyArray<number | null | undefined> | null | undefined;
  name?: string | null | undefined;
  priority?: string | null | undefined;
  tags?: ReadonlyArray<string | null | undefined> | null | undefined;
  team_id?: number | null | undefined;
};
export type ProjectBulkUpdateMutation$variables = {
  input: ProjectBulkUpdateInput;
};
export type ProjectBulkUpdateMutation$data = {
  readonly action_project_bulk_update: ReadonlyArray<{
    readonly project: {
      readonly active: boolean;
      readonly archivable: boolean | null | undefined;
      readonly client: {
        readonly image_key: string | null | undefined;
        readonly name: string;
        readonly website: string | null | undefined;
      };
      readonly client_id: number;
      readonly confirmed: boolean;
      readonly id: number;
      readonly managers: ReadonlyArray<{
        readonly id: number;
        readonly project: {
          readonly id: number;
        };
        readonly user: {
          readonly first_name: string | null | undefined;
          readonly id: number;
          readonly last_name: string | null | undefined;
        };
      }>;
      readonly name: string;
      readonly priority: string | null | undefined;
      readonly tags_computed: any | null | undefined;
      readonly team: {
        readonly name: string;
      } | null | undefined;
      readonly team_id: number | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type ProjectBulkUpdateMutation = {
  response: ProjectBulkUpdateMutation$data;
  variables: ProjectBulkUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProjectBulkUpdateOutput",
    "kind": "LinkedField",
    "name": "action_project_bulk_update",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confirmed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "team_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "client_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags_computed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priority",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "clients",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image_key",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "managers",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "teams",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectBulkUpdateMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectBulkUpdateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "3f5a0c867abcd7e400c91f169a07058d",
    "id": null,
    "metadata": {},
    "name": "ProjectBulkUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectBulkUpdateMutation(\n  $input: ProjectBulkUpdateInput!\n) {\n  action_project_bulk_update(input: $input) {\n    project {\n      id\n      name\n      confirmed\n      active\n      archivable\n      team_id\n      client_id\n      tags_computed\n      priority\n      client {\n        name\n        website\n        image_key\n      }\n      managers {\n        id\n        user {\n          id\n          first_name\n          last_name\n        }\n        project {\n          id\n        }\n      }\n      team {\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ff8e8defea9883b4d8d38c2b27ed360";

export default node;
