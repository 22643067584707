import React from "react"

type Props = {
  color?: any
  alt?: string
}
const ReportsProject = (props: Props) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.alt}</title>
    <path
      fill={props.color || "#fff"}
      d="M29.7008 5.89775L17.7371 0.444434C16.4398 -0.148144 14.3178 -0.148144 13.0205 0.444434L1.06484 5.89775C-0.344531 6.53838 -0.344531 7.5874 1.06484 8.22803L12.9164 13.6333C14.2697 14.2499 16.4959 14.2499 17.8492 13.6333L29.7008 8.22803C31.1102 7.5874 31.1102 6.53838 29.7008 5.89775ZM12.9164 24.9243L4.92461 21.2808C4.58828 21.1286 4.1959 21.1286 3.85957 21.2808L1.06484 22.554C-0.344531 23.1946 -0.344531 24.2436 1.06484 24.8843L12.9164 30.2895C14.2697 30.9061 16.4959 30.9061 17.8492 30.2895L29.7008 24.8843C31.1102 24.2436 31.1102 23.1946 29.7008 22.554L26.9061 21.2808C26.5697 21.1286 26.1773 21.1286 25.841 21.2808L17.8492 24.9243C16.4959 25.5409 14.2697 25.5409 12.9164 24.9243V24.9243Z"
    />
    <path
      fill={props.color || "#fff"}
      d="M29.701 14.2259L27.1625 13.0728C26.8262 12.9206 26.4418 12.9206 26.1055 13.0728L17.457 16.9886C16.2318 17.405 14.5262 17.405 13.301 16.9886L4.65254 13.0728C4.31621 12.9206 3.93184 12.9206 3.59551 13.0728L1.05703 14.2259C-0.352344 14.8665 -0.352344 15.9156 1.05703 16.5562L12.9086 21.9615C14.2619 22.5781 16.4881 22.5781 17.8414 21.9615L29.693 16.5562C31.1104 15.9156 31.1103 14.8665 29.701 14.2259V14.2259Z"
    />
  </svg>
)

export default ReportsProject
