import React from "react"

const USAFlag = (svgProps: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="29"
      height="19"
      viewBox="0 0 29 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g clipPath="url(#clip0_1363_73713)">
        <path
          d="M2.23715 0H27.1582C28.0053 0 28.6951 0.672917 28.6951 1.49922V17.4958C28.6951 18.3221 28.0053 18.9951 27.1582 18.9951H2.23715C1.39005 19 0.700195 18.3271 0.700195 17.5008V1.49922C0.700195 0.672917 1.39005 0 2.23715 0Z"
          fill="#B22234"
        />
        <path
          d="M0.71034 1.35059H28.6951C28.7002 1.40007 28.7053 1.44954 28.7053 1.50397V2.95866H0.700195V1.49902C0.700195 1.44954 0.705268 1.40007 0.71034 1.35059ZM28.7002 4.28965V5.89277H0.700195V4.28965H28.7002ZM28.7002 7.22871V8.83184H0.700195V7.22871H28.7002ZM28.7002 10.1678V11.7709H0.700195V10.1678H28.7002ZM28.7002 13.1068V14.71H0.700195V13.1068H28.7002ZM28.7002 16.0459V17.4956C28.7002 17.5451 28.6951 17.5995 28.6901 17.649H0.71034C0.705268 17.6045 0.700195 17.5501 0.700195 17.5006V16.0508H28.7002V16.0459Z"
          fill="white"
        />
        <path
          d="M11.2509 0V10.2323H0.700195V1.49922C0.700195 0.672917 1.39005 0 2.23715 0H11.2509Z"
          fill="#3C3B6E"
        />
        <path
          d="M1.32416 1.41504L1.67416 2.47884L0.750977 1.82077H1.89735L0.969092 2.47884L1.32416 1.41504Z"
          fill="white"
        />
        <path
          d="M1.32416 3.47363L1.67416 4.53743L0.750977 3.87936H1.89735L0.969092 4.53743L1.32416 3.47363Z"
          fill="white"
        />
        <path
          d="M1.32416 5.53223L1.67416 6.59603L0.750977 5.93796H1.89735L0.969092 6.59603L1.32416 5.53223Z"
          fill="white"
        />
        <path
          d="M1.32416 7.58984L1.67416 8.65365L0.750977 7.99557H1.89735L0.969092 8.65365L1.32416 7.58984Z"
          fill="white"
        />
        <path
          d="M2.56147 0.385742L2.91654 1.44954L1.98828 0.791471H3.13973L2.2064 1.44954L2.56147 0.385742Z"
          fill="white"
        />
        <path
          d="M2.56147 2.44434L2.91654 3.50814L1.98828 2.85007H3.13973L2.2064 3.50814L2.56147 2.44434Z"
          fill="white"
        />
        <path
          d="M2.56147 4.50195L2.91654 5.56576L1.98828 4.90768H3.13973L2.2064 5.56576L2.56147 4.50195Z"
          fill="white"
        />
        <path
          d="M2.56147 6.56055L2.91654 7.62435L1.98828 6.96628H3.13973L2.2064 7.62435L2.56147 6.56055Z"
          fill="white"
        />
        <path
          d="M2.56147 8.61914L2.91654 9.68294L1.98828 9.02487H3.13973L2.2064 9.68294L2.56147 8.61914Z"
          fill="white"
        />
        <path
          d="M3.80463 1.41504L4.15971 2.47884L3.23145 1.82077H4.37782L3.44956 2.47884L3.80463 1.41504Z"
          fill="white"
        />
        <path
          d="M3.80463 3.47363L4.15971 4.53743L3.23145 3.87936H4.37782L3.44956 4.53743L3.80463 3.47363Z"
          fill="white"
        />
        <path
          d="M3.80463 5.53223L4.15971 6.59603L3.23145 5.93796H4.37782L3.44956 6.59603L3.80463 5.53223Z"
          fill="white"
        />
        <path
          d="M3.80463 7.58984L4.15971 8.65365L3.23145 7.99557H4.37782L3.44956 8.65365L3.80463 7.58984Z"
          fill="white"
        />
        <path
          d="M5.04682 0.385742L5.39682 1.44954L4.47363 0.791471H5.62001L4.69175 1.44954L5.04682 0.385742Z"
          fill="white"
        />
        <path
          d="M5.04682 2.44434L5.39682 3.50814L4.47363 2.85007H5.62001L4.69175 3.50814L5.04682 2.44434Z"
          fill="white"
        />
        <path
          d="M5.04682 4.50195L5.39682 5.56576L4.47363 4.90768H5.62001L4.69175 5.56576L5.04682 4.50195Z"
          fill="white"
        />
        <path
          d="M5.04682 6.56055L5.39682 7.62435L4.47363 6.96628H5.62001L4.69175 7.62435L5.04682 6.56055Z"
          fill="white"
        />
        <path
          d="M5.04682 8.61914L5.39682 9.68294L4.47363 9.02487H5.62001L4.69175 9.68294L5.04682 8.61914Z"
          fill="white"
        />
        <path
          d="M6.2851 1.41504L6.64017 2.47884L5.71191 1.82077H6.85829L5.93003 2.47884L6.2851 1.41504Z"
          fill="white"
        />
        <path
          d="M6.2851 3.47363L6.64017 4.53743L5.71191 3.87936H6.85829L5.93003 4.53743L6.2851 3.47363Z"
          fill="white"
        />
        <path
          d="M6.2851 5.53223L6.64017 6.59603L5.71191 5.93796H6.85829L5.93003 6.59603L6.2851 5.53223Z"
          fill="white"
        />
        <path
          d="M6.2851 7.58984L6.64017 8.65365L5.71191 7.99557H6.85829L5.93003 8.65365L6.2851 7.58984Z"
          fill="white"
        />
        <path
          d="M7.52729 0.385742L7.88236 1.44954L6.9541 0.791471H8.10048L7.17222 1.44954L7.52729 0.385742Z"
          fill="white"
        />
        <path
          d="M7.52729 2.44434L7.88236 3.50814L6.9541 2.85007H8.10048L7.17222 3.50814L7.52729 2.44434Z"
          fill="white"
        />
        <path
          d="M7.52729 4.50195L7.88236 5.56576L6.9541 4.90768H8.10048L7.17222 5.56576L7.52729 4.50195Z"
          fill="white"
        />
        <path
          d="M7.52729 6.56055L7.88236 7.62435L6.9541 6.96628H8.10048L7.17222 7.62435L7.52729 6.56055Z"
          fill="white"
        />
        <path
          d="M7.52729 8.61914L7.88236 9.68294L6.9541 9.02487H8.10048L7.17222 9.68294L7.52729 8.61914Z"
          fill="white"
        />
        <path
          d="M8.76557 1.41504L9.12064 2.47884L8.19238 1.82077H9.33876L8.41557 2.47884L8.76557 1.41504Z"
          fill="white"
        />
        <path
          d="M8.76557 3.47363L9.12064 4.53743L8.19238 3.87936H9.33876L8.41557 4.53743L8.76557 3.47363Z"
          fill="white"
        />
        <path
          d="M8.76557 5.53223L9.12064 6.59603L8.19238 5.93796H9.33876L8.41557 6.59603L8.76557 5.53223Z"
          fill="white"
        />
        <path
          d="M8.76557 7.58984L9.12064 8.65365L8.19238 7.99557H9.33876L8.41557 8.65365L8.76557 7.58984Z"
          fill="white"
        />
        <path
          d="M10.0078 0.385742L10.3628 1.44954L9.43457 0.791471H10.5809L9.65269 1.44954L10.0078 0.385742Z"
          fill="white"
        />
        <path
          d="M10.0078 2.44434L10.3628 3.50814L9.43457 2.85007H10.5809L9.65269 3.50814L10.0078 2.44434Z"
          fill="white"
        />
        <path
          d="M10.0078 4.50195L10.3628 5.56576L9.43457 4.90768H10.5809L9.65269 5.56576L10.0078 4.50195Z"
          fill="white"
        />
        <path
          d="M10.0078 6.56055L10.3628 7.62435L9.43457 6.96628H10.5809L9.65269 7.62435L10.0078 6.56055Z"
          fill="white"
        />
        <path
          d="M10.0078 8.61914L10.3628 9.68294L9.43457 9.02487H10.5809L9.65269 9.68294L10.0078 8.61914Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1363_73713">
          <rect
            width="28"
            height="19"
            fill="white"
            transform="translate(0.700195)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default USAFlag
