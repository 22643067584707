import React from "react"

type Props = {
  color?: any
  alt?: string
}
const Delete = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.62 19.89"
    width="18"
    height="18"
  >
    <title>{props.alt}</title>
    <path
      fill={props.color || "var(--winter)"}
      d="M16.94,3A9.73,9.73,0,0,0,3.06,3,10,10,0,0,0,3.06,17a9.73,9.73,0,0,0,13.88,0A10,10,0,0,0,16.94,3Zm-3.09,9.37a1.12,1.12,0,0,1,0,1.57,1.08,1.08,0,0,1-1.54,0L10,11.56,7.69,13.91a1.08,1.08,0,0,1-1.54,0,1.12,1.12,0,0,1,0-1.57L8.46,10,6.15,7.66a1.12,1.12,0,0,1,0-1.57,1.08,1.08,0,0,1,1.54,0L10,8.44l2.31-2.35a1.08,1.08,0,0,1,1.54,0,1.12,1.12,0,0,1,0,1.57L11.54,10Z"
      transform="translate(-0.19 -0.06)"
    />
  </svg>
)

export default Delete
