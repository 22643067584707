import React from "react"

type Props = {
  className?: string
  color?: string
}

const Help = (props: Props) => {
  const { className, color = "var(--slate)" } = props
  return (
    <svg
      width="13.333364"
      height="13.333287"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "help" }}
      className={className}
    >
      <path
        fill={color}
        d="m 6.000004,10.666687 h 1.33333 V 9.333337 H 6.000004 Z M 6.666664,0 C 2.986664,0 0,2.986667 0,6.666667 c 0,3.68002 2.986664,6.66662 6.666664,6.66662 3.68,0 6.6667,-2.9866 6.6667,-6.66662 0,-3.68 -2.9867,-6.666667 -6.6667,-6.666667 z m 0,11.999987 c -2.94,0 -5.33333,-2.39332 -5.33333,-5.33332 0,-2.94 2.39333,-5.33333 5.33333,-5.33333 2.94,0 5.3333,2.39333 5.3333,5.33333 0,2.94 -2.3933,5.33332 -5.3333,5.33332 z m 0,-9.33332 c -1.47333,0 -2.66666,1.19333 -2.66666,2.66667 h 1.33333 c 0,-0.73334 0.6,-1.33334 1.33333,-1.33334 0.73334,0 1.33334,0.6 1.33334,1.33334 0,1.33333 -2,1.16666 -2,3.33333 h 1.33333 c 0,-1.5 2,-1.66667 2,-3.33333 0,-1.47334 -1.19333,-2.66667 -2.66667,-2.66667 z"
      />
    </svg>
  )
}

export default Help
