import { Dialog as BlueprintDialog, DialogProps, Icon } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./Dialog.module.css"

type Props = {
  // our custom created props
  children: React.ReactNode
  className?: string
  portalClassName?: string
  showCloseButton?: boolean
} & DialogProps

const Dialog = (props: Props) => {
  const {
    className,
    backdropClassName,
    portalClassName,
    onClose,
    showCloseButton,
    children,
    ...rest
  } = props
  return (
    <BlueprintDialog
      className={cc([styles.dialog, className])}
      backdropClassName={cc([styles.dialogBackdrop, backdropClassName])}
      portalClassName={cc([styles.portal, portalClassName])}
      onClose={onClose}
      enforceFocus={false}
      canOutsideClickClose={false}
      {...rest}
    >
      {showCloseButton && (
        <Icon
          icon="cross"
          size={20}
          onClick={onClose}
          className={styles.closeIcon}
        />
      )}
      {children}
    </BlueprintDialog>
  )
}

export default Dialog
