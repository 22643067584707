/**
 * @generated SignedSource<<86f9b650a72f8dd2700cea674502c2a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountActionsQuery$variables = {
  accountId: number;
};
export type AccountActionsQuery$data = {
  readonly account: {
    readonly account_type: string;
    readonly cancel_reasons: ReadonlyArray<{
      readonly account_name: string | null | undefined;
    }>;
    readonly id: number;
    readonly name: string;
    readonly subscription: any | null | undefined;
    readonly users: ReadonlyArray<{
      readonly permissions: any;
    }>;
  } | null | undefined;
  readonly current_user: {
    readonly email: string;
    readonly first_name: string | null | undefined;
    readonly id: number;
    readonly last_name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_user">;
  } | null | undefined;
};
export type AccountActionsQuery = {
  response: AccountActionsQuery$data;
  variables: AccountActionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v3 = {
  "alias": "account",
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "accountId"
    }
  ],
  "concreteType": "accounts",
  "kind": "LinkedField",
  "name": "accounts_by_pk",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "account_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "users",
      "kind": "LinkedField",
      "name": "users",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "cancel_reasons",
      "kind": "LinkedField",
      "name": "cancel_reasons",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "account_name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountActionsQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionsProvider_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountActionsQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheets_protected",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "manageable_projects",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "11b4924b8d6beb4bba72bb1d6a425b03",
    "id": null,
    "metadata": {},
    "name": "AccountActionsQuery",
    "operationKind": "query",
    "text": "query AccountActionsQuery(\n  $accountId: Int!\n) {\n  account: accounts_by_pk(id: $accountId) {\n    name\n    id\n    account_type\n    subscription\n    users {\n      permissions\n    }\n    cancel_reasons {\n      account_name\n    }\n  }\n  current_user {\n    id\n    first_name\n    last_name\n    email\n    ...PermissionsProvider_user\n  }\n}\n\nfragment PermissionsProvider_user on users {\n  id\n  email\n  account {\n    id\n    timesheets_protected\n  }\n  permissions\n  manageable_projects {\n    id\n    project {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "976aad8072648de496cb30ed523dbe00";

export default node;
