import { Tooltip } from "@blueprintjs/core"
import React, { ReactNode } from "react"

import styles from "./TitledHelpTooltip.module.css"

import { HelpIcon } from "./react-icons"

type Props = {
  title: string | ReactNode
  tooltipContent: string | ReactNode
  url?: string
  iconPosition?: "left" | "right"
}

const renderHelpIconTooltip = (tooltipContent, url) => {
  return (
    <>
      {url ? (
        <a href={url} target="_blank" rel="noopener">
          <Tooltip
            className={styles.tooltip}
            portalClassName={styles.tooltipPortal}
            placement="top"
            content={tooltipContent}
          >
            <HelpIcon />
          </Tooltip>
        </a>
      ) : (
        <Tooltip
          className={styles.tooltip}
          portalClassName={styles.tooltipPortal}
          placement="top"
          content={tooltipContent}
        >
          <HelpIcon />
        </Tooltip>
      )}
    </>
  )
}

const TitledHelpTooltip = (props: Props) => {
  const { title, tooltipContent, url = null, iconPosition = "right" } = props

  return (
    <div className={styles.container}>
      {iconPosition === "left" && tooltipContent
        ? renderHelpIconTooltip(tooltipContent, url)
        : null}
      {title}
      {iconPosition === "right" && tooltipContent
        ? renderHelpIconTooltip(tooltipContent, url)
        : null}
    </div>
  )
}

export default TitledHelpTooltip
