import cc from "classcat"
import React from "react"

import styles from "./DashboardListsEmptyStateContainer.module.css"

type Props = {
  button?: React.ReactNode
  text: React.ReactNode
  secondaryText?: React.ReactNode
  tangram?: React.ReactNode
  className?: string
  isAccordion?: boolean
}

const DashboardListsEmptyStateContainer = (props: Props) => {
  const { tangram, text, button, secondaryText, className, isAccordion } = props

  return (
    <div
      className={cc([
        isAccordion ? styles.accordionWrapper : styles.emptyStateWrapper,
        className,
      ])}
    >
      {tangram && (
        <div
          className={cc([
            styles.tangramsWrapper,
            isAccordion && styles.accordionSVG,
          ])}
        >
          {tangram}
        </div>
      )}
      <p className={cc[(styles.text, isAccordion && styles.accordionText)]}>
        {text}
      </p>
      {secondaryText && <p className={styles.secondaryText}>{secondaryText}</p>}
      {button && <div className={styles.button}>{button}</div>}
    </div>
  )
}

export default DashboardListsEmptyStateContainer
