import React from "react"

type Props = {
  alt?: string
  size?: number
  style?: Record<string, any>
}
const Clock = (props: Props) => (
  <div
    style={{
      width: "100%",
      height: `${props.size || 20}px`,
      display: "flex",
      justifyContent: "center",
    }}
  >
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "100%",
        maxWidth: "32px",
        height: "100%",
        maxHeight: "32px",
      }}
    >
      <title>{props.alt}</title>
      <defs>
        <circle id="path-1" cx="12" cy="12" r="12"></circle>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-15.000000, -210.000000)">
          <g transform="translate(0.000000, 147.000000)">
            <g transform="translate(0.000000, 63.000000)">
              <g transform="translate(15.000000, 0.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <use id="Oval-5" fill="#707593" xlinkHref="#path-1"></use>
                <g
                  id="time-clock-circle-1"
                  mask="url(#mask-2)"
                  stroke="#FFFFFF"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <g transform="translate(3.000000, 3.000000)">
                    <circle cx="9" cy="9" r="8.59090909"></circle>
                    <circle cx="9" cy="9" r="1.22727273"></circle>
                    <path d="M9,7.77272727 L9,4.5" id="Shape"></path>
                    <path d="M9.5,10.5 L11.5,13.5" id="Shape"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
)

export default Clock
