import { minutesInHour } from "date-fns/constants"

export const roundTwoDecimals = (n: number): number => Math.round(n * 100) / 100

export const formatDuration = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / minutesInHour)
  const minutes = totalMinutes - hours * minutesInHour

  if (totalMinutes === 0) {
    return `0`
  }
  if (hours && !minutes) {
    return `${hours}h`
  }
  if (!hours && minutes) {
    return `${minutes}m`
  }

  return `${hours}h ${minutes}m`
}

const formatHours = (hoursToFormat: number) => {
  const totalMinutes = hoursToFormat * minutesInHour
  const hours = Math.floor(totalMinutes / minutesInHour)
  const minutes = Math.round(totalMinutes - hours * minutesInHour)

  if (!hoursToFormat) {
    return "0h"
  }
  if (hours && !minutes) {
    return `${hours}h`
  }
  if (!hours && minutes) {
    return `${minutes}m`
  }

  return `${hours}h ${minutes}m`
}

const formatMinutesToTime = (totalMinutes: number) => {
  if (!totalMinutes) {
    return "0"
  }

  const hours = Math.floor(totalMinutes / minutesInHour)
  const minutes = Math.round(totalMinutes - hours * minutesInHour)
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

  return `${hours}:${formattedMinutes}`
}

/**
 * Convert a number of minutes to number of hours with rounding
 *
 * This works differently to how date-fns/minutesToHours as this function uses
 * Math.round instead of Math.floor
 *
 * @param minutes number of minutes to be converted
 * @param showDecimals round to a whole number or to 2 decimal places
 * @returns the number of minutes converted to hours
 */
const minutesToHours = (minutes: number, showDecimals = true): number => {
  if (!showDecimals) {
    return Math.round(minutes / minutesInHour)
  }
  return roundTwoDecimals(minutes / minutesInHour) || 0
}

export default {
  formatDuration,
  formatMinutesToTime,
  formatHours,
  minutesToHours,
  roundTwoDecimals,
}
