/**
 * @generated SignedSource<<4ba73327db792cbec496803d195e4972>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RateCardCreateInput = {
  blended_rate: number;
  blended_rate_card: boolean;
  description?: string | null | undefined;
  name: string;
  rate_type: string;
  references?: any | null | undefined;
  roles: ReadonlyArray<RateCardCreateRoleInput | null | undefined>;
};
export type RateCardCreateRoleInput = {
  charge_out_rate: number;
  role_id: number;
};
export type RateCardCreateMutation$variables = {
  input: RateCardCreateInput;
};
export type RateCardCreateMutation$data = {
  readonly action_rate_card_create: {
    readonly account: {
      readonly id: number;
      readonly rate_cards: ReadonlyArray<{
        readonly id: number;
        readonly projects_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
      }>;
    } | null | undefined;
    readonly rate_card: {
      readonly account_id: number;
      readonly active: boolean;
      readonly archivable: boolean | null | undefined;
      readonly description: string | null | undefined;
      readonly id: number;
      readonly name: string | null | undefined;
    } | null | undefined;
  };
};
export type RateCardCreateMutation = {
  response: RateCardCreateMutation$data;
  variables: RateCardCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RateCardCreateOutput",
    "kind": "LinkedField",
    "name": "action_rate_card_create",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "rate_cards",
        "kind": "LinkedField",
        "name": "rate_card",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "account_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "order_by",
                "value": {
                  "name": "asc"
                }
              }
            ],
            "concreteType": "rate_cards",
            "kind": "LinkedField",
            "name": "rate_cards",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "projects_aggregate",
                "kind": "LinkedField",
                "name": "projects_aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "projects_aggregate_fields",
                    "kind": "LinkedField",
                    "name": "aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "rate_cards(order_by:{\"name\":\"asc\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateCardCreateMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateCardCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0e05d208e4d48115c4a186b12576111f",
    "id": null,
    "metadata": {},
    "name": "RateCardCreateMutation",
    "operationKind": "mutation",
    "text": "mutation RateCardCreateMutation(\n  $input: RateCardCreateInput!\n) {\n  action_rate_card_create(input: $input) {\n    rate_card {\n      id\n      name\n      account_id\n      active\n      archivable\n      description\n    }\n    account {\n      id\n      rate_cards(order_by: {name: asc}) {\n        id\n        projects_aggregate {\n          aggregate {\n            count\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b2571196662b28c38cd828f863be96e7";

export default node;
