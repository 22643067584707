/**
 * @generated SignedSource<<85ea3271ebd57bc6da2c221fd25778c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditHolidaysGroupForm_holidays_group$data = {
  readonly country_code: string;
  readonly country_name: string | null | undefined;
  readonly holidays: ReadonlyArray<{
    readonly date: any;
    readonly holiday_api_uuid: any | null | undefined;
    readonly id: number;
    readonly name: string;
    readonly observed: any | null | undefined;
    readonly type: string;
  }>;
  readonly id: number;
  readonly name: string;
  readonly region_name: string | null | undefined;
  readonly " $fragmentType": "EditHolidaysGroupForm_holidays_group";
};
export type EditHolidaysGroupForm_holidays_group$key = {
  readonly " $data"?: EditHolidaysGroupForm_holidays_group$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditHolidaysGroupForm_holidays_group">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "maxDate"
    },
    {
      "kind": "RootArgument",
      "name": "minDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditHolidaysGroupForm_holidays_group",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "region_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country_code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "_gte",
                  "variableName": "minDate"
                },
                {
                  "kind": "Variable",
                  "name": "_lte",
                  "variableName": "maxDate"
                }
              ],
              "kind": "ObjectValue",
              "name": "date"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "holidays",
      "kind": "LinkedField",
      "name": "holidays",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "holiday_api_uuid",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "observed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "holidays_groups",
  "abstractKey": null
};
})();

(node as any).hash = "b4721157c5b638462b2ab73786ec8243";

export default node;
