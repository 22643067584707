import React from "react"

type Props = {
  color?: string
  size?: number
  alt?: string
}
const SharedFolder = (props: Props) => {
  const { size = 18, color = "var(--midnight)", alt } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        d="M11.2875 9.975C11.7309 9.975 12.1105 9.81711 12.4263 9.50132C12.7421 9.18554 12.9 8.80594 12.9 8.3625C12.9 7.91906 12.7421 7.53946 12.4263 7.22368C12.1105 6.90789 11.7309 6.75 11.2875 6.75C10.8441 6.75 10.4645 6.90789 10.1487 7.22368C9.83289 7.53946 9.67499 7.91906 9.67499 8.3625C9.67499 8.80594 9.83289 9.18554 10.1487 9.50132C10.4645 9.81711 10.8441 9.975 11.2875 9.975ZM8.13749 13.05H14.4375V12.5625C14.4375 11.975 14.1469 11.5031 13.5656 11.1469C12.9844 10.7906 12.225 10.6125 11.2875 10.6125C10.35 10.6125 9.59062 10.7906 9.00937 11.1469C8.42812 11.5031 8.13749 11.975 8.13749 12.5625V13.05ZM2.88749 15.75C2.34093 15.75 1.87304 15.5554 1.48383 15.1662C1.09461 14.777 0.899994 14.3091 0.899994 13.7625V4.125C0.899994 3.57844 1.09461 3.11055 1.48383 2.72134C1.87304 2.33211 2.34093 2.1375 2.88749 2.1375H7.01249L8.99999 4.125H15.1125C15.6591 4.125 16.1269 4.31961 16.5162 4.70884C16.9054 5.09805 17.1 5.56594 17.1 6.1125V13.7625C17.1 14.3091 16.9054 14.777 16.5162 15.1662C16.1269 15.5554 15.6591 15.75 15.1125 15.75H2.88749Z"
        fill={color}
      />
    </svg>
  )
}

export default SharedFolder
