import React from "react"

type Props = {
  color?: string
}

const Archive = (props: Props) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7092 0C12.0406 0 12.3436 0.187252 12.4918 0.483688L14 3.5V13.125C14 13.6082 13.6082 14 13.125 14H0.875C0.391751 14 0 13.6082 0 13.125V3.5L1.50816 0.483688C1.65637 0.187252 1.95935 0 2.29078 0H11.7092ZM7 5.25C6.51875 5.25 6.125 5.64375 6.125 6.125V6.125V8.39125L5.87125 8.1375L5.8001 8.07061C5.65071 7.94571 5.46 7.875 5.25 7.875C4.76875 7.875 4.375 8.26875 4.375 8.75C4.375 8.995 4.47125 9.21375 4.62875 9.37125V9.37125L6.37875 11.1213L6.46244 11.1947C6.6099 11.3082 6.79583 11.375 7 11.375C7.245 11.375 7.46375 11.2788 7.62125 11.1213V11.1213L9.37125 9.37125L9.44473 9.28756C9.55816 9.1401 9.625 8.95417 9.625 8.75C9.625 8.26875 9.23125 7.875 8.75 7.875C8.505 7.875 8.28625 7.97125 8.12875 8.12875V8.12875L7.875 8.39125V6.125L7.86908 6.02333C7.8183 5.58951 7.44688 5.25 7 5.25ZM11.375 1.75H2.625L1.75 3.5H12.25L11.375 1.75Z"
      fill={props.color ?? "#3C4260"}
    />
  </svg>
)

export default Archive
