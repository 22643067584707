import { sessionStore } from "~/store/hasura"

type Log = any

/*
   This will send a log to the node server, which logs it to the console.
   It can then be picked up by server logging tools such as Coralogix.
   This is untyped, so you can anything you want to log into Coralogix.
 */

export const sendLogToNodeServer = async (log: Log) => {
  const hasuraAuthToken = await sessionStore.getAuthToken()
  const nodeEndpoint = await sessionStore.getNodeServerEndpoint()
  fetch(`${nodeEndpoint}/client-logger`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${hasuraAuthToken}`,
    },
    body: JSON.stringify({ log: log }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }).catch((err) => {})
}
