import React from "react"

const SignOut = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 2.25H14.25C15.075 2.25 15.75 2.925 15.75 3.75V14.25C15.75 15.075 15.075 15.75 14.25 15.75H3.75C2.9175 15.75 2.25 15.075 2.25 14.25V11.25H3.75V14.25H14.25V3.75H3.75V6.75H2.25V3.75C2.25 2.925 2.9175 2.25 3.75 2.25ZM8.625 12.75L7.5675 11.6925L9.5025 9.75H2.25V8.25H9.5025L7.5675 6.3075L8.625 5.25L12.375 9L8.625 12.75Z"
      fill="#707593"
    />
  </svg>
)

export default SignOut
