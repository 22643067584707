import React, { useState } from "react"

import { reportError } from "~/helpers/error-helpers"
import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import { resetAccount } from "~/helpers/routes"

import Input from "~/common/Input"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"

import { showToast } from "~/containers/ToasterContainer"

type Props = {
  accountName: string
  onClose: () => void
}

const deleteAccount = () => {
  fetchWithCsrf(resetAccount(), { method: "POST" }).catch((e) => {
    {
      void reportError(`ResetAccount error: `, e)
      showToast({
        message:
          "Sorry, we couldn't reset your account. Please refresh the page and try again.",
        type: "error",
      })
    }
  })
}

const ResetAccountForm = (props: Props) => {
  const { accountName, onClose } = props

  const [resetValue, setResetValue] = useState("")
  const [isResetting, setIsResetting] = useState(false)

  const handleValue = (e) => {
    setResetValue(e.target.value.toUpperCase())
  }

  const handleClose = () => {
    if (!isResetting) {
      onClose()
      setResetValue("")
    }
  }

  const handleSubmit = () => {
    setIsResetting(true)
    deleteAccount()
  }
  return (
    <ModalFormWrapper headerTitle={`Reset ${accountName}`}>
      <ModalBody>
        <p>
          This will reset your entire account back to it's starting state.{" "}
          <br />
          All data will be lost and can not be retrieved.
        </p>
        <p>
          If you are sure you want to reset <b>{accountName}</b> and all its
          data please type <span className="notranslate">"RESET"</span> below.
        </p>
        <Input
          name="delete-account"
          value={resetValue}
          onChange={handleValue}
          nofocus
          autoFocus
        />
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" disabled={isResetting} onClick={handleClose} />
        <Button
          text="Reset Account"
          intent="danger"
          outlined={false}
          loading={isResetting}
          onClick={handleSubmit}
          disabled={resetValue !== "RESET" || isResetting}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default ResetAccountForm
