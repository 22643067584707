import { graphql } from "react-relay"

import { ClientActiveStatusUpdateMutation } from "./__generated__/ClientActiveStatusUpdateMutation.graphql"
import { ClientCreateMutation } from "./__generated__/ClientCreateMutation.graphql"
import { ClientDeleteMutation } from "./__generated__/ClientDeleteMutation.graphql"
import { ClientUpdateMutation } from "./__generated__/ClientUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

const clientCreateMutation = graphql`
  mutation ClientCreateMutation(
    $name: String!
    $website: String
    $references: [ReferencesInput!]
  ) {
    createClient(name: $name, website: $website, references: $references) {
      client {
        id
        name
        website
        active
        archivable
        image_key
        deletable
        references
        account_id
        real_client
        projects {
          active
        }
        account {
          id
          clients {
            id
          }
        }
      }
    }
  }
`

type ClientCreateRelayOptions = {
  variables: ClientCreateMutation["variables"]
}

export const clientCreateRelay = async (options: ClientCreateRelayOptions) => {
  const { variables } = options

  const data = await commitMutationPromise<ClientCreateMutation>(environment, {
    mutation: clientCreateMutation,
    variables,
  })

  return data.createClient
}

const clientUpdateMutation = graphql`
  mutation ClientUpdateMutation(
    $id: Int!
    $name: String!
    $website: String
    $references: [ReferencesInput!]
  ) {
    updateClient(
      id: $id
      name: $name
      website: $website
      references: $references
    ) {
      client {
        id
        name
        website
        active
        archivable
        image_key
        deletable
        references
        account_id
        account {
          id
          clients {
            id
          }
        }
      }
    }
  }
`

type UpdateClientRelayOptions = {
  variables: ClientUpdateMutation["variables"]
}

export const clientUpdateRelay = async (options: UpdateClientRelayOptions) => {
  const { variables } = options
  const data = await commitMutationPromise<ClientUpdateMutation>(environment, {
    mutation: clientUpdateMutation,
    variables,
  })
  return data.updateClient
}

const clientActiveStatusUpdateMutation = graphql`
  mutation ClientActiveStatusUpdateMutation($id: Int!, $active: Boolean!) {
    updateClientActiveStatus(id: $id, active: $active) {
      client {
        id
        active
      }
    }
  }
`

export const clientActiveStatusUpdateRelay = (
  variables: ClientActiveStatusUpdateMutation["variables"],
) => {
  return commitMutationPromise<ClientActiveStatusUpdateMutation>(environment, {
    mutation: clientActiveStatusUpdateMutation,
    variables,
  })
}

const clientDeleteMutation = graphql`
  mutation ClientDeleteMutation($id: Int!) {
    deleteClient(id: $id) {
      account {
        id
        clients {
          id
        }
      }
    }
  }
`

export const clientDeleteRelay = async (
  variables: ClientDeleteMutation["variables"],
) => {
  const data = await commitMutationPromise<ClientDeleteMutation>(environment, {
    mutation: clientDeleteMutation,
    variables,
  })

  return data.deleteClient
}
