/**
 * @generated SignedSource<<d8f0d5a9ff4e300785822095c42f7be1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HolidaysBulkUpdateInput = {
  custom_holidays_to_add: ReadonlyArray<CustomHoliday | null | undefined>;
  holidays_group_id: number;
  holidays_to_delete_ids: ReadonlyArray<number | null | undefined>;
  public_holidays_to_add: ReadonlyArray<PublicHoliday | null | undefined>;
};
export type CustomHoliday = {
  date: string;
  holidays_group_id: number;
  id?: number | null | undefined;
  name: string;
  type: string;
};
export type PublicHoliday = {
  date: string;
  holiday_api_uuid: string;
  holidays_group_id: number;
  name: string;
  observed: string;
  type: string;
};
export type HolidaysBulkUpdateMutation$variables = {
  holidaysGroupId: number;
  input: HolidaysBulkUpdateInput;
  maxDate: any;
  minDate: any;
};
export type HolidaysBulkUpdateMutation$data = {
  readonly action_holidays_bulk_update: {
    readonly account: {
      readonly holidays_groups: ReadonlyArray<{
        readonly holidays: ReadonlyArray<{
          readonly created_at: string;
          readonly date: any;
          readonly holiday_api_uuid: any | null | undefined;
          readonly id: number;
          readonly name: string;
          readonly observed: any | null | undefined;
          readonly type: string;
        }>;
        readonly holidays_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly id: number;
      }>;
      readonly id: number;
    } | null | undefined;
  } | null | undefined;
};
export type HolidaysBulkUpdateMutation = {
  response: HolidaysBulkUpdateMutation$data;
  variables: HolidaysBulkUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "holidaysGroupId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "minDate"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "HolidaysBulkUpdateOutput",
    "kind": "LinkedField",
    "name": "action_holidays_bulk_update",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "_eq",
                        "variableName": "holidaysGroupId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "where"
              }
            ],
            "concreteType": "holidays_groups",
            "kind": "LinkedField",
            "name": "holidays_groups",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "distinct_on",
                    "value": "name"
                  }
                ],
                "concreteType": "holidays_aggregate",
                "kind": "LinkedField",
                "name": "holidays_aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "holidays_aggregate_fields",
                    "kind": "LinkedField",
                    "name": "aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "count",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "holidays_aggregate(distinct_on:\"name\")"
              },
              {
                "alias": null,
                "args": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "_gte",
                            "variableName": "minDate"
                          },
                          {
                            "kind": "Variable",
                            "name": "_lte",
                            "variableName": "maxDate"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "date"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "where"
                  }
                ],
                "concreteType": "holidays",
                "kind": "LinkedField",
                "name": "holidays",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "holiday_api_uuid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "observed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HolidaysBulkUpdateMutation",
    "selections": (v5/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "HolidaysBulkUpdateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "6f1d9285355cb06e91989f081f9693fa",
    "id": null,
    "metadata": {},
    "name": "HolidaysBulkUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation HolidaysBulkUpdateMutation(\n  $holidaysGroupId: Int!\n  $minDate: date!\n  $maxDate: date!\n  $input: HolidaysBulkUpdateInput!\n) {\n  action_holidays_bulk_update(input: $input) {\n    account {\n      id\n      holidays_groups(where: {id: {_eq: $holidaysGroupId}}) {\n        id\n        holidays_aggregate(distinct_on: name) {\n          aggregate {\n            count\n          }\n        }\n        holidays(where: {date: {_gte: $minDate, _lte: $maxDate}}) {\n          id\n          name\n          date\n          holiday_api_uuid\n          observed\n          type\n          created_at\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c92ebff058fb55a577ab4f91d2c2ae8e";

export default node;
