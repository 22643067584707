import React from "react"

const Select = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1171 3H1.56857C0.705857 3 0 3.70586 0 4.56857V12.4114C0 13.2741 0.705857 13.98 1.56857 13.98H14.1171C14.9799 13.98 15.6857 13.2741 15.6857 12.4114V4.56857C15.6857 3.70586 14.9799 3 14.1171 3ZM14 12H2V5H14V12Z"
      fill="#3C4260"
    />
  </svg>
)
export default Select
