import React from "react"

type Props = {
  color?: string
  alt?: string
}
const Collapse = (props: Props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.alt}</title>
    <path
      fill={props.color || "#969AB3"}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 0C0.335786 0 0 0.335786 0 0.75C0 1.16421 0.335786 1.5 0.75 1.5H11.25C11.6642 1.5 12 1.16421 12 0.75C12 0.335786 11.6642 0 11.25 0H0.75Z"
    />
    <path
      fill={props.color || "#969AB3"}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 10.5C0.335786 10.5 0 10.8358 0 11.25C0 11.6642 0.335786 12 0.75 12H11.25C11.6642 12 12 11.6642 12 11.25C12 10.8358 11.6642 10.5 11.25 10.5H0.75Z"
    />
    <path
      fill={props.color || "#969AB3"}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09259 9.24615C6.5068 9.24615 6.84259 8.91037 6.84259 8.49615V5.0605L8.21666 6.15975C8.54011 6.41851 9.01208 6.36607 9.27083 6.04262C9.52959 5.71918 9.47715 5.24721 9.1537 4.98845L6.56111 2.91438C6.2872 2.69524 5.89798 2.69524 5.62407 2.91438L3.03148 4.98845C2.70803 5.24721 2.65559 5.71918 2.91435 6.04262C3.1731 6.36607 3.64507 6.41851 3.96852 6.15975L5.34259 5.0605V8.49615C5.34259 8.91037 5.67838 9.24615 6.09259 9.24615Z"
    />
  </svg>
)

export default Collapse
