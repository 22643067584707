import React from "react"

const EmptyCircle = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5.99998"
        cy="6.00004"
        r="4.835"
        stroke="var(--shadow)"
        strokeLinejoin="round"
        strokeDasharray="4 2 4 2"
      />
    </svg>
  )
}

export default EmptyCircle
