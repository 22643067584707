/**
 * @generated SignedSource<<328e64554714f1a7c9e6d0b41b6b26e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkstreamForm_user$data = {
  readonly account: {
    readonly all_workstreams: ReadonlyArray<{
      readonly id: number;
      readonly name: string;
    }>;
  };
  readonly " $fragmentType": "WorkstreamForm_user";
};
export type WorkstreamForm_user$key = {
  readonly " $data"?: WorkstreamForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkstreamForm_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkstreamForm_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": "all_workstreams",
          "args": null,
          "concreteType": "workstreams",
          "kind": "LinkedField",
          "name": "workstreams",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "users",
  "abstractKey": null
};

(node as any).hash = "51d7fb9150c3c5c3ebe9a018502c7b31";

export default node;
