import React from "react"

import styles from "./TangramTable.module.css"

import * as tangrams from "~/common/tangrams"

import { TangramTableItem } from "./TangramTableItem"

const tangramList = Object.entries(tangrams).map(([name, Component]) => ({
  name,
  Component,
}))

const TangramTable = () => {
  return (
    <>
      <pre
        className={styles.example}
      >{`import { TangramXYZ } from "~/common/tangrams"`}</pre>
      <div className={styles.table}>
        {tangramList.map((entry) => {
          return (
            <TangramTableItem
              key={entry.name}
              name={entry.name}
              Tangram={entry.Component}
            />
          )
        })}
      </div>
    </>
  )
}

export default TangramTable
