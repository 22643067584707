import React from "react"

type Props = {
  color?: string
}

const Unlock = (props: Props) => {
  const { color = "var(--midnight)" } = props

  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 6H10.5C11.325 6 12 6.675 12 7.5V15C12 15.825 11.325 16.5 10.5 16.5H1.5C0.675 16.5 0 15.825 0 15V7.5C0 6.675 0.675 6 1.5 6H8.25V4.5C8.25 3.255 7.245 2.25 6 2.25C4.755 2.25 3.75 3.255 3.75 4.5H2.25C2.25 2.43 3.93 0.75 6 0.75C8.07 0.75 9.75 2.43 9.75 4.5V6ZM7.5 11.25C7.5 12.075 6.825 12.75 6 12.75C5.175 12.75 4.5 12.075 4.5 11.25C4.5 10.425 5.175 9.75 6 9.75C6.825 9.75 7.5 10.425 7.5 11.25Z"
        fill={color}
      />
    </svg>
  )
}

export default Unlock
