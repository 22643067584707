import React from "react"

const Danger = (svgProps: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="31"
      viewBox="0 0 34 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.9048 15.6083L25.4524 0.96875H8.54761L0.0952148 15.6083L8.54761 30.2478L25.4524 30.2478L33.9048 15.6083ZM18.3951 8.14611L18.1098 17.8132H15.8911L15.6058 8.14611H18.3951ZM17.0004 23.0747C16.1526 23.0747 15.4869 22.4329 15.4869 21.6167C15.4869 20.8005 16.1526 20.1587 17.0004 20.1587C17.8483 20.1587 18.5139 20.8005 18.5139 21.6167C18.5139 22.4329 17.8483 23.0747 17.0004 23.0747Z"
        fill="#CD3939"
      />
    </svg>
  )
}

export default Danger
