import React from "react"

type Props = {
  color?: any
  alt?: string
}
const WarningInfo = (props: Props) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.alt}</title>
    <path
      d="M8.50073 16.4378C8.2896 16.4378 8.08837 16.3982 7.89704 16.319C7.7057 16.2399 7.53086 16.1277 7.37252 15.9826L1.0189 9.62895C0.873753 9.4706 0.761592 9.29576 0.682419 9.10443C0.603246 8.9131 0.56366 8.71187 0.56366 8.50074C0.56366 8.28961 0.603246 8.08508 0.682419 7.88715C0.761592 7.68922 0.873753 7.51768 1.0189 7.37253L7.37252 1.01891C7.53086 0.860565 7.7057 0.745105 7.89704 0.67253C8.08837 0.599955 8.2896 0.563667 8.50073 0.563667C8.71186 0.563667 8.91639 0.599955 9.11432 0.67253C9.31225 0.745105 9.48379 0.860565 9.62894 1.01891L15.9826 7.37253C16.1409 7.51768 16.2564 7.68922 16.3289 7.88715C16.4015 8.08508 16.4378 8.28961 16.4378 8.50074C16.4378 8.71187 16.4015 8.9131 16.3289 9.10443C16.2564 9.29576 16.1409 9.4706 15.9826 9.62895L9.62894 15.9826C9.48379 16.1277 9.31225 16.2399 9.11432 16.319C8.91639 16.3982 8.71186 16.4378 8.50073 16.4378ZM7.709 9.29247H9.29246V4.5421H7.709V9.29247ZM8.50073 11.6677C8.72505 11.6677 8.91309 11.5918 9.06484 11.44C9.21659 11.2883 9.29246 11.1002 9.29246 10.8759C9.29246 10.6516 9.21659 10.4636 9.06484 10.3118C8.91309 10.1601 8.72505 10.0842 8.50073 10.0842C8.27641 10.0842 8.08837 10.1601 7.93663 10.3118C7.78488 10.4636 7.709 10.6516 7.709 10.8759C7.709 11.1002 7.78488 11.2883 7.93663 11.44C8.08837 11.5918 8.27641 11.6677 8.50073 11.6677Z"
      fill={props.color || "var(--white)"}
    />
  </svg>
)

export default WarningInfo
