import { graphql } from "react-relay"

import { RateCardCreateMutation } from "./__generated__/RateCardCreateMutation.graphql"
import { RateCardDeleteMutation } from "./__generated__/RateCardDeleteMutation.graphql"
import { RateCardUpdateActiveMutation } from "./__generated__/RateCardUpdateActiveMutation.graphql"
import { RateCardUpdateMutation } from "./__generated__/RateCardUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

import { showToast } from "~/containers/ToasterContainer"

export type CreateRateCard = RateCardCreateMutation["variables"]
export type CreatedRateCard =
  RateCardCreateMutation["response"]["action_rate_card_create"]["rate_card"]

const rateCardCreateMutation = graphql`
  mutation RateCardCreateMutation($input: RateCardCreateInput!) {
    action_rate_card_create(input: $input) {
      rate_card {
        id
        name
        account_id
        active
        archivable
        description
      }
      account {
        id
        rate_cards(order_by: { name: asc }) {
          id
          projects_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`

export const rateCardCreateRelay = async (
  variables: RateCardCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<RateCardCreateMutation>(
    environment,
    {
      mutation: rateCardCreateMutation,
      variables,
    },
  )

  const rateCard = data.action_rate_card_create?.rate_card
  if (rateCard) {
    showToast({
      message: "New rate card created",
      description: rateCard.name,
      type: "success",
    })
  }

  return rateCard
}

export type UpdateRateCardInput = RateCardUpdateMutation["variables"]["input"]
export type UpdatedRateCard =
  RateCardUpdateMutation["response"]["action_rate_card_update"]["rate_card"]

const updateRateCardMutation = graphql`
  mutation RateCardUpdateMutation($input: RateCardUpdateInput!) {
    action_rate_card_update(input: $input) {
      rate_card {
        id
        name
        blended_rate: blended_rate_private
        blended_rate_card
        references
        description
        role_charge_out_rates {
          role_id
          rate_card_id
          charge_out_rate: charge_out_rate_private
          role {
            id
            name
          }
        }
      }
    }
  }
`

type RateCardsUpdateOptions = {
  input: UpdateRateCardInput
  hideToaster?: boolean
}

export const updateRateCardRelay = async (options: RateCardsUpdateOptions) => {
  const { input, hideToaster } = options

  const data = await commitMutationPromise<RateCardUpdateMutation>(
    environment,
    {
      mutation: updateRateCardMutation,
      variables: { input },
    },
  )

  const rateCard = data.action_rate_card_update.rate_card
  if (rateCard && !hideToaster) {
    showToast({
      message: "Rate card successfully updated",
      type: "success",
    })
  }

  return rateCard
}

const rateCardUpdateActiveMutation = graphql`
  mutation RateCardUpdateActiveMutation($id: Int!, $active: Boolean) {
    update_rate_cards(
      where: {
        id: { _eq: $id }
        _and: [{ name: { _neq: "Standard" } }, { name: { _neq: "Internal" } }]
      }
      _set: { active: $active }
    ) {
      returning {
        id
        active
      }
    }
  }
`

export const rateCardUpdateActiveRelay = async (
  variables: RateCardUpdateActiveMutation["variables"],
) => {
  const data = await commitMutationPromise<RateCardUpdateActiveMutation>(
    environment,
    {
      mutation: rateCardUpdateActiveMutation,
      variables,
    },
  )

  if (!data.update_rate_cards.returning.length) {
    const changeType = variables.active ? "unarchived" : "archived"
    showToast({
      message: `This rate card cannot be ${changeType}`,
      type: "error",
    })
    return null
  }

  return data.update_rate_cards.returning
}

const rateCardDeleteMutation = graphql`
  mutation RateCardDeleteMutation($id: Int!) {
    deleteRateCard(id: $id) {
      account {
        id
        rate_cards(order_by: { name: asc }) {
          id
        }
      }
    }
  }
`

export const rateCardDeleteRelay = async (
  variables: RateCardDeleteMutation["variables"],
) => {
  const data = await commitMutationPromise<RateCardDeleteMutation>(
    environment,
    {
      mutation: rateCardDeleteMutation,
      variables,
    },
  )

  return data.deleteRateCard
}
