import React from "react"

import styles from "./IconTable.module.css"

import * as icons from "~/common/react-icons"

import IconTableItem from "./IconTableItem"

const allIconEntries = Object.entries(icons).filter(([name]) => {
  const hasDeprecatedSuffix = name.endsWith("Icon")
  const alternativeExists = Object.keys(icons).includes(
    name.replace(/Icon$/, ""),
  )

  return !hasDeprecatedSuffix || !alternativeExists
})

const IconTable = () => {
  return (
    <>
      <pre
        className={styles.example}
      >{`import { myIconName } from "~/common/react-icons"`}</pre>
      <div className={styles.table}>
        {allIconEntries.map((entry) => {
          const [name, Icon] = entry
          return <IconTableItem key={name} name={name} Icon={Icon} />
        })}
      </div>
    </>
  )
}

export default IconTable
