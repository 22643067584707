import Hashids from "hashids"
import last from "lodash-es/last"

// should match config/initializers/hashid.rb
const SALT = "GRdrheher42342@gerergwqefqwwt346SDGDFGG"
const HASH_LENGTH = 6
const ALPHABET = "abcdefghijklmnopqrstuvwxyz1234567890"

// https://github.com/jcypret/hashid-rails/blob/e0e2982583fc32a635d1fd5da937588fb04a8e03/lib/hashid/rails.rb#L12
const HASHID_TOKEN = 42

type Encoder = (id: number) => string
type Decoder = (hashid: string) => number

type HashidInstance = {
  encode: Encoder
  decode: Decoder
}

const createInstance = (tableName: string): HashidInstance => {
  const instance = new Hashids(tableName + SALT, HASH_LENGTH, ALPHABET)

  const encode: Encoder = (id) => instance.encode([HASHID_TOKEN, id])

  const decode: Decoder = (hashid) => last(instance.decode(hashid)) as number

  return {
    encode,
    decode,
  }
}

export const accounts = createInstance("accounts")
export const actuals = createInstance("actuals")
export const assignments = createInstance("assignments")
export const clients = createInstance("clients")
export const contracts = createInstance("contracts")
export const helpDocuments = createInstance("help_documents")
export const invitations = createInstance("invitations")
export const milestones = createInstance("milestones")
export const notes = createInstance("notes")
export const people = createInstance("people")
export const phases = createInstance("phases")
export const projectMembers = createInstance("project_members")
export const projectRates = createInstance("project_rates")
export const projectRoles = createInstance("project_roles")
export const projects = createInstance("projects")
export const rateCards = createInstance("rate_cards")
export const roleChargeOutRate = createInstance("role_charge_out_rate")
export const roles = createInstance("roles")
export const tags = createInstance("tags")
export const teams = createInstance("teams")
export const timeOffs = createInstance("time_offs")
export const users = createInstance("users")
export const user_accounts = createInstance("user_accounts")
export const holidaysGroups = createInstance("holidays_groups")
export const workstreams = createInstance("workstreams")
export const reports = createInstance("reports")
export const savedReports = createInstance("saved_reports")
