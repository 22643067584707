import React from "react"

export const TangramSquare = () => (
  <svg
    width="384"
    height="384"
    viewBox="0 0 384 384"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Square</title>
    <path d="M192 384H0L96 288H288L192 384Z" fill="#FFB077" />
    <path d="M384 384H192L384 192V384Z" fill="#67D0D5" />
    <path d="M288 288L192 192L288 96L384 192L288 288Z" fill="#FDCD4F" />
    <path d="M288 96L384 192L384 0L288 96Z" fill="#0A9CC1" />
    <path d="M192 192L96 288L288 288L192 192Z" fill="#B19DE6" />
    <path d="M192 192L384 0L0 4.57915e-06L192 192Z" fill="#4057DF" />
    <path d="M192 192L0 0V384L192 192Z" fill="#F5848C" />
  </svg>
)
