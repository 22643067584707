/**
 * @generated SignedSource<<c138e2fac7a9e08b7144b7d7e756ddda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PersonBulkUpdateTeamMutation$variables = {
  id: ReadonlyArray<number>;
  team_id?: number | null | undefined;
};
export type PersonBulkUpdateTeamMutation$data = {
  readonly update_people: {
    readonly returning: ReadonlyArray<{
      readonly account: {
        readonly people: ReadonlyArray<{
          readonly id: number;
          readonly team: {
            readonly id: number;
            readonly name: string;
          } | null | undefined;
          readonly team_id: number | null | undefined;
        }>;
        readonly teams: ReadonlyArray<{
          readonly id: number;
          readonly people: ReadonlyArray<{
            readonly id: number;
            readonly team: {
              readonly id: number;
            } | null | undefined;
            readonly team_id: number | null | undefined;
          }>;
        }>;
      };
      readonly id: number;
      readonly team_id: number | null | undefined;
    }>;
  } | null | undefined;
};
export type PersonBulkUpdateTeamMutation = {
  response: PersonBulkUpdateTeamMutation$data;
  variables: PersonBulkUpdateTeamMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "team_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "team_id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "team_id",
            "variableName": "team_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "people_mutation_response",
    "kind": "LinkedField",
    "name": "update_people",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "people",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "teams",
                "kind": "LinkedField",
                "name": "teams",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "people",
                    "kind": "LinkedField",
                    "name": "people",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "teams",
                        "kind": "LinkedField",
                        "name": "team",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "people",
                "kind": "LinkedField",
                "name": "people",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "teams",
                    "kind": "LinkedField",
                    "name": "team",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonBulkUpdateTeamMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PersonBulkUpdateTeamMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "985ee427409507023ddb08ea9d208a8c",
    "id": null,
    "metadata": {},
    "name": "PersonBulkUpdateTeamMutation",
    "operationKind": "mutation",
    "text": "mutation PersonBulkUpdateTeamMutation(\n  $id: [Int!]!\n  $team_id: Int\n) {\n  update_people(where: {id: {_in: $id}}, _set: {team_id: $team_id}) {\n    returning {\n      id\n      team_id\n      account {\n        teams {\n          id\n          people {\n            id\n            team_id\n            team {\n              id\n            }\n          }\n        }\n        people {\n          id\n          team_id\n          team {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "69bb6d0010535a1a7a8a61d72620fa42";

export default node;
