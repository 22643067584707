import React from "react"

type Props = {
  size?: number
  color?: string
}

const SyncDisabled = (props: Props) => {
  const { size = 12, color = "var(--smoke)" } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.974654L0.974654 0L12 11.0253L11.0253 12L9.24194 10.2166C8.77189 10.5069 8.25346 10.735 7.70046 10.8802V9.43548C7.88018 9.36636 8.05299 9.29032 8.22581 9.20046L2.64055 3.61521C2.34332 4.18894 2.17051 4.83871 2.17051 5.52995C2.17051 6.67742 2.64055 7.70737 3.3871 8.46083L4.93548 6.91244V11.0599H0.788018L2.41935 9.42857C1.41705 8.43318 0.788018 7.0576 0.788018 5.52995C0.788018 4.45161 1.09908 3.44931 1.63134 2.60599L0 0.974654ZM11.8486 5.52995C11.8486 4.0023 11.2196 2.62673 10.2173 1.63134L11.8486 0H7.70117V4.14747L9.24956 2.59908C9.9961 3.35253 10.4661 4.38249 10.4661 5.52995C10.4661 6.11751 10.3348 6.67051 10.1136 7.17512L11.1505 8.21198C11.5929 7.42396 11.8486 6.50461 11.8486 5.52995ZM4.93626 1.62426V0.179561C4.48004 0.297073 4.04455 0.476797 3.63672 0.697995L4.67358 1.73486C4.71852 1.71758 4.76172 1.69857 4.80492 1.67956C4.84812 1.66055 4.89133 1.64154 4.93626 1.62426Z"
        fill={color}
      />
    </svg>
  )
}

export default SyncDisabled
