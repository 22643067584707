import React from "react"

export const TangramRocket = () => (
  <svg
    width="47"
    height="94"
    viewBox="0 0 47 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Rocket</title>
    <path
      d="M35.2192 82.1276L23.4385 70.3776L35.2192 58.6276L47 70.3776L35.2192 82.1276Z"
      fill="#FDCD4F"
    />
    <path
      d="M35.1983 82.1056L46.979 70.3557L46.979 93.8556L35.1983 82.1056Z"
      fill="#B19DE6"
    />
    <path d="M11.7525 11.7217V35.2217L35.314 11.7217H11.7525Z" fill="#67D0D5" />
    <path
      d="M11.7807 82.25L11.7807 58.75L0 70.5L0 94L11.7807 82.25Z"
      fill="#FFB077"
    />
    <path
      d="M23.561 6.30223e-06L35.3418 11.75L11.7803 11.75L23.561 6.30223e-06Z"
      fill="#0A9CC1"
    />
    <path
      d="M35.2995 58.7067L11.738 82.2067L11.738 35.2067L35.2995 58.7067Z"
      fill="#4057DF"
    />
    <path
      d="M11.7373 35.2069L35.2988 58.7068V11.7069L11.7373 35.2069Z"
      fill="#F5848C"
    />
  </svg>
)
