import React, { useEffect, useState } from "react"

import styles from "./CreateBulkCustomHolidaysForm.module.css"

import DateInput from "~/common/DateInput"
import Input from "~/common/Input"
import AddButton from "~/common/buttons/AddButton"
import { Delete } from "~/common/react-icons"

import { HOLIDAYS_YEARS } from "~/GLOBALVARS"

type Props = {
  savedHolidays?: any
  setCustomHolidays: (customHolidays: any) => void
}

const defaultCustomHolidayData = {
  id: null,
  name: "",
  currentYear: null,
  secondYear: null,
  thirdYear: null,
  autoFocus: false,
}

const CreateBulkCustomHolidaysForm = (props: Props) => {
  const { savedHolidays, setCustomHolidays } = props

  const [customHolidays, setCreatedCustomHolidays] = useState(
    savedHolidays
      ? [...savedHolidays, { ...defaultCustomHolidayData }]
      : [{ ...defaultCustomHolidayData }],
  )
  const addNewRow = () => {
    setCreatedCustomHolidays([
      ...customHolidays,
      { ...defaultCustomHolidayData, autoFocus: true },
    ])
  }

  const removeRowFromList = (index) => {
    setCreatedCustomHolidays(customHolidays.filter((_, i) => i !== index))
  }

  const addNewRowOnInputChange = () => {
    // check all values of the last row, except id, are filled out
    // eslint-disable-next-line
    const { id, ...lastEntryRow } = customHolidays.at(-1)
    const lastEntryRowIsComplete = Object.values(lastEntryRow).every(
      (x) => x !== null,
    )

    if (lastEntryRowIsComplete) {
      addNewRow()
    }
  }

  const handleInputChange = (index, inputId, value) => {
    const editedCustomHolidays = [...customHolidays]

    if (inputId === "name") {
      editedCustomHolidays[index].name = value
      return setCreatedCustomHolidays(editedCustomHolidays)
    }

    if (!value) {
      delete editedCustomHolidays[index][inputId]
      return setCreatedCustomHolidays(editedCustomHolidays)
    }

    editedCustomHolidays[index][inputId] = {
      ...editedCustomHolidays[index][inputId],
      date: value,
    }
    setCreatedCustomHolidays(editedCustomHolidays)
    addNewRowOnInputChange()
  }

  useEffect(() => {
    setCustomHolidays(customHolidays)
  }, [customHolidays, setCustomHolidays])

  const getSelectedDatesForYear = (year) =>
    customHolidays.reduce(
      (dates, holiday) =>
        holiday[year] ? [...dates, holiday[year].date] : dates,
      [],
    )

  const HolidayInputsRow = (index) => {
    const targetInputRow = customHolidays[index]

    const dateValue = (year) => targetInputRow[year]?.date || null
    const currentYearsDate = dateValue("currentYear")
    const todaysDate = new Date(new Date().setHours(0, 0, 0, 0))
    const isPastDate = currentYearsDate && currentYearsDate < todaysDate

    return (
      <div
        className={styles.tableRow}
        key={`custom-holiday-row-${index}`}
        data-test={`custom-holiday-row-${index}`}
      >
        <Input
          className={styles.nameInput}
          data-idx={index}
          id="name"
          value={targetInputRow.name}
          placeholder="e.g. Team Anniversary"
          onBlur={addNewRowOnInputChange}
          onChange={(e) => handleInputChange(index, "name", e.target.value)}
          autoFocus={!index || targetInputRow.autoFocus}
          dataTest={`custom-holiday-name-input`}
        />
        <DateInput
          className={styles.dateInput}
          data-idx={index}
          value={currentYearsDate}
          placeholder="Type or select a date"
          inputProps={{ id: `custom-holiday-currentYear` }}
          onChange={(date) => handleInputChange(index, "currentYear", date)}
          minDate={isPastDate ? currentYearsDate : todaysDate}
          maxDate={new Date(HOLIDAYS_YEARS.CURRENT_YEAR, 11, 31)}
          disabled={isPastDate}
          dataTest={`custom-holiday-currentYear-input`}
          dayPickerProps={{
            disabledDays: getSelectedDatesForYear("currentYear"),
          }}
        />
        <DateInput
          className={styles.dateInput}
          data-idx={index}
          value={dateValue("secondYear")}
          placeholder="Type or select a date"
          inputProps={{ id: `custom-holiday-secondYear` }}
          onChange={(date) => handleInputChange(index, "secondYear", date)}
          minDate={new Date(HOLIDAYS_YEARS.SECOND_YEAR, 0, 1)}
          maxDate={new Date(HOLIDAYS_YEARS.SECOND_YEAR, 11, 31)}
          initialMonth={new Date(HOLIDAYS_YEARS.SECOND_YEAR, 0)}
          dataTest={`custom-holiday-secondYear-input`}
          dayPickerProps={{
            disabledDays: getSelectedDatesForYear("secondYear"),
          }}
        />
        <DateInput
          className={styles.dateInput}
          data-idx={index}
          value={dateValue("thirdYear")}
          placeholder="Type or select a date"
          inputProps={{ id: `custom-holiday-thirdYear` }}
          onChange={(date) => handleInputChange(index, "thirdYear", date)}
          minDate={new Date(HOLIDAYS_YEARS.THIRD_YEAR, 0, 1)}
          maxDate={new Date(HOLIDAYS_YEARS.THIRD_YEAR, 11, 31)}
          initialMonth={new Date(HOLIDAYS_YEARS.THIRD_YEAR, 0)}
          dataTest={`custom-holiday-thirdYear-input`}
          dayPickerProps={{
            disabledDays: getSelectedDatesForYear("thirdYear"),
          }}
        />
        <div
          className={`${styles.delete} ${
            customHolidays.length === 1 ? styles.disabled : ""
          }`}
          onClick={
            customHolidays.length > 1 ? () => removeRowFromList(index) : null
          }
        >
          <Delete color="var(--winter)" />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={`${styles.tableRow} ${styles.tableHeaderRow}`}>
          <div>Custom Holiday Name</div>
          <div>{HOLIDAYS_YEARS.CURRENT_YEAR}</div>
          <div>{HOLIDAYS_YEARS.SECOND_YEAR}</div>
          <div>{HOLIDAYS_YEARS.THIRD_YEAR}</div>
          <div>{/* leave empty for delete */}</div>
        </div>
        {customHolidays.map((_, index) => HolidayInputsRow(index))}
      </div>
      <AddButton
        data-test="new-custom-holiday-row"
        text="New Custom Holiday"
        onClick={addNewRow}
      />
    </>
  )
}

export default CreateBulkCustomHolidaysForm
