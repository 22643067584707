import React from "react"

type Props = {
  color?: any
  alt?: string
}
const End = (props: Props) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.alt}</title>
    <path
      fill={props.color || "#fff"}
      d="M7.6665 0.333374H0.166504V14.5H1.83317V8.66671H6.49984L6.83317 10.3334H12.6665V2.00004H7.99984L7.6665 0.333374Z"
    />
  </svg>
)

export default End
