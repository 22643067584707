/**
 * @generated SignedSource<<1ab7f3941221ac58608f3f2cb88e2a9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompetencyDeleteMutation$variables = {
  id: number;
};
export type CompetencyDeleteMutation$data = {
  readonly deleteCompetency: {
    readonly account: {
      readonly competencies: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly id: number;
      readonly people: ReadonlyArray<{
        readonly competencies: ReadonlyArray<{
          readonly id: number;
        }>;
        readonly id: number;
      }>;
    } | null | undefined;
  };
};
export type CompetencyDeleteMutation = {
  response: CompetencyDeleteMutation$data;
  variables: CompetencyDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "competencies",
  "kind": "LinkedField",
  "name": "competencies",
  "plural": true,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DeleteCompetencyResult",
    "kind": "LinkedField",
    "name": "deleteCompetency",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "people",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetencyDeleteMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetencyDeleteMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "67f3154b0315d0da74f56662da0cf9c7",
    "id": null,
    "metadata": {},
    "name": "CompetencyDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation CompetencyDeleteMutation(\n  $id: Int!\n) {\n  deleteCompetency(id: $id) {\n    account {\n      id\n      people {\n        id\n        competencies {\n          id\n        }\n      }\n      competencies {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f9c2dcf6fd8ed803e121924747afc6a";

export default node;
