import cc from "classcat"
import React from "react"

type Props = {
  level?: number
  darkMode?: boolean
}

const competency = (props: Props) => {
  const { level, darkMode } = props
  const fill = darkMode ? "var(--white)" : "var(--midnight)"
  const empty = darkMode ? "rgb(255 255 255 / 30%)" : "var(--winter)"

  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="9"
        width="2"
        height="3"
        rx="1"
        fill={level > 0 ? fill : empty}
        // Do this so we can add styles to class in the parent modules based on the level even on hover
        className={cc({ competencyIconFill: level > 0 })}
      />
      <rect
        x="4"
        y="6"
        width="2"
        height="6"
        rx="1"
        className={cc({ competencyIconFill: level > 1 })}
        fill={level > 1 ? fill : empty}
      />
      <rect
        x="8"
        y="3"
        width="2"
        height="9"
        rx="1"
        className={cc({ competencyIconFill: level > 2 })}
        fill={level > 2 ? fill : empty}
      />
      <rect
        x="12"
        width="2"
        height="12"
        rx="1"
        className={cc({ competencyIconFill: level > 3 })}
        fill={level > 3 ? fill : empty}
      />
    </svg>
  )
}

export default competency
