import React from "react"

type Props = {
  color?: string
} & React.SVGProps<SVGSVGElement>

const BookmarkOutline = (props: Props) => {
  const { color = "var(--midnight)", ...otherProps } = props
  return (
    <svg
      {...otherProps}
      width="16"
      height="16"
      viewBox="2 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 2.25H12.75C13.575 2.25 14.25 2.925 14.25 3.75V15.75L9 13.5L3.75 15.75V3.75C3.75 2.925 4.425 2.25 5.25 2.25ZM9 11.865L12.75 13.5V3.75H5.25V13.5L9 11.865Z"
        fill={color}
      />
    </svg>
  )
}

export default BookmarkOutline
