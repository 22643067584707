import cc from "classcat"
import React, { ReactNode } from "react"

import styles from "./ModalFormWrapper.module.css"

type Props = {
  children: ReactNode
  style?: Record<string, any>
  className?: string
}
const ModalFooter = (props: Props) => (
  <div
    className={cc([styles.footer, props.className])}
    style={props.style}
    data-test="modal-footer"
  >
    {props.children}
  </div>
)

export default ModalFooter
