import React from "react"

const ReportGroupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={11} fill="none">
    <mask
      id="a"
      width={14}
      height={11}
      x={-0.25}
      y={0}
      fill="#000"
      maskUnits="userSpaceOnUse"
    >
      <path fill="#fff" d="M-.25 0h14v11h-14z" />
      <path
        fillRule="evenodd"
        d="M2.25 1.75a.75.75 0 0 0-1.5 0v8a.75.75 0 0 0 1.5 0v-8ZM4.5 1a.75.75 0 0 0 0 1.5H12A.75.75 0 0 0 12 1H4.5Zm-.75 4.75A.75.75 0 0 1 4.5 5H12a.75.75 0 0 1 0 1.5H4.5a.75.75 0 0 1-.75-.75ZM4.5 9a.75.75 0 0 0 0 1.5H12A.75.75 0 0 0 12 9H4.5Z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#707593"
      fillRule="evenodd"
      d="M2.25 1.75a.75.75 0 0 0-1.5 0v8a.75.75 0 0 0 1.5 0v-8ZM4.5 1a.75.75 0 0 0 0 1.5H12A.75.75 0 0 0 12 1H4.5Zm-.75 4.75A.75.75 0 0 1 4.5 5H12a.75.75 0 0 1 0 1.5H4.5a.75.75 0 0 1-.75-.75ZM4.5 9a.75.75 0 0 0 0 1.5H12A.75.75 0 0 0 12 9H4.5Z"
      clipRule="evenodd"
    />
    <path
      fill="#707593"
      d="M1.5 1.2a.55.55 0 0 1 .55.55h.4A.95.95 0 0 0 1.5.8v.4Zm-.55.55a.55.55 0 0 1 .55-.55V.8a.95.95 0 0 0-.95.95h.4Zm0 8v-8h-.4v8h.4Zm.55.55a.55.55 0 0 1-.55-.55h-.4c0 .525.425.95.95.95v-.4Zm.55-.55a.55.55 0 0 1-.55.55v.4a.95.95 0 0 0 .95-.95h-.4Zm0-8v8h.4v-8h-.4Zm1.9 0a.55.55 0 0 1 .55-.55V.8a.95.95 0 0 0-.95.95h.4Zm.55.55a.55.55 0 0 1-.55-.55h-.4c0 .525.425.95.95.95v-.4Zm7.5 0H4.5v.4H12v-.4Zm.55-.55a.55.55 0 0 1-.55.55v.4a.95.95 0 0 0 .95-.95h-.4ZM12 1.2a.55.55 0 0 1 .55.55h.4A.95.95 0 0 0 12 .8v.4Zm-7.5 0H12V.8H4.5v.4Zm0 3.6a.95.95 0 0 0-.95.95h.4a.55.55 0 0 1 .55-.55v-.4Zm7.5 0H4.5v.4H12v-.4Zm.95.95A.95.95 0 0 0 12 4.8v.4a.55.55 0 0 1 .55.55h.4ZM12 6.7a.95.95 0 0 0 .95-.95h-.4a.55.55 0 0 1-.55.55v.4Zm-7.5 0H12v-.4H4.5v.4Zm-.95-.95c0 .525.425.95.95.95v-.4a.55.55 0 0 1-.55-.55h-.4Zm.4 4a.55.55 0 0 1 .55-.55v-.4a.95.95 0 0 0-.95.95h.4Zm.55.55a.55.55 0 0 1-.55-.55h-.4c0 .525.425.95.95.95v-.4Zm7.5 0H4.5v.4H12v-.4Zm.55-.55a.55.55 0 0 1-.55.55v.4a.95.95 0 0 0 .95-.95h-.4ZM12 9.2a.55.55 0 0 1 .55.55h.4A.95.95 0 0 0 12 8.8v.4Zm-7.5 0H12v-.4H4.5v.4Z"
      mask="url(#a)"
    />
  </svg>
)

export default ReportGroupIcon
