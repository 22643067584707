import React from "react"

const BanCircle = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0C3.1325 0 0 3.1325 0 7C0 10.8675 3.1325 14 7 14C10.8675 14 14 10.8675 14 7C14 3.1325 10.8675 0 7 0ZM9.625 7.875H4.375C3.89375 7.875 3.5 7.48125 3.5 7C3.5 6.51875 3.89375 6.125 4.375 6.125H9.625C10.1062 6.125 10.5 6.51875 10.5 7C10.5 7.48125 10.1062 7.875 9.625 7.875Z"
      fill="#3C4260"
    />
  </svg>
)

export default BanCircle
