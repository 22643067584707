import React from "react"

import { WorkstreamCard_user$data } from "./__generated__/WorkstreamCard_user.graphql"

import { track } from "~/helpers/analytics"

import AddNewInlineItem from "~/common/AddNewInlineItem"

import {
  activateWorkstream,
  workstreamCreateRelay,
} from "~/mutations/Workstream"

import { showToast } from "~/containers/ToasterContainer"

type Props = {
  workstreams: WorkstreamCard_user$data["account"]["workstreams"]
  archivedWorkstreams: WorkstreamCard_user$data["account"]["workstreams"]
}

const AddNewWorkstream = (props: Props) => {
  const { workstreams, archivedWorkstreams } = props

  const saveWorkstream = async (workstreamValue: string) => {
    if (!workstreamValue) {
      return
    }

    const workstreamAlreadyExists = workstreams.find(
      (t) => t.name.toLowerCase() === workstreamValue.toLowerCase(),
    )
    const archivedWorkstreamFound = archivedWorkstreams.find(
      (t) => t.name.toLowerCase() === workstreamValue.toLowerCase(),
    )

    if (workstreamAlreadyExists) {
      showToast({
        message: `Workstream ${workstreamValue} already exists`,
        type: "warning",
      })
      return
    }

    if (archivedWorkstreamFound) {
      void activateWorkstream(archivedWorkstreamFound.id)

      showToast({
        message: `${archivedWorkstreamFound.name} successfully unarchived`,
        type: "success",
      })
      return
    }

    await workstreamCreateRelay({
      name: workstreamValue,
    })
    track("Workstream Created", { value: workstreamValue })
  }

  return (
    <AddNewInlineItem
      onSaveItem={saveWorkstream}
      placeholder="New workstream"
      name="workstream"
    />
  )
}

export default AddNewWorkstream
