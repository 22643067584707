import { graphql } from "react-relay"

import { InvitationRenewMutation } from "./__generated__/InvitationRenewMutation.graphql"
import { InvitationCreateMutation } from "~/mutations/__generated__/InvitationCreateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

import { showToast } from "~/containers/ToasterContainer"

const invitationRenewMutation = graphql`
  mutation InvitationRenewMutation($input: InvitationRenewInput!) {
    action_invitation_renew(input: $input) {
      invitation {
        id
        expires_at
      }
    }
  }
`

type InvitationRenewRelayOptions = {
  variables: InvitationRenewMutation["variables"]
}

export const invitationRenewRelay = async (
  options: InvitationRenewRelayOptions,
) => {
  const { variables } = options
  await commitMutationPromise<InvitationRenewMutation>(environment, {
    mutation: invitationRenewMutation,
    variables,
  })
  showToast({
    message: "Successfully re-sent invitation",
    type: "success",
  })
}

const invitationCreateMutation = graphql`
  mutation InvitationCreateMutation($input: InvitationCreateInput!) {
    action_invitation_create(input: $input) {
      invitation {
        id
        email
      }
      account {
        id
        people {
          id
        }
        invitations {
          id
          email
        }
      }
      error
    }
  }
`

export const invitationCreateRelay = async (
  variables: InvitationCreateMutation["variables"],
) => {
  const { action_invitation_create } =
    await commitMutationPromise<InvitationCreateMutation>(environment, {
      mutation: invitationCreateMutation,
      variables,
    })

  const error = action_invitation_create.error
  if (error) {
    return {
      error: action_invitation_create.error,
    }
  }

  return {
    id: action_invitation_create.invitation.id,
  }
}
