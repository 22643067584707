import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import React, { useState } from "react"
import AutosizeInput from "react-input-autosize"
import { graphql, useFragment } from "react-relay"

import styles from "./WorkstreamItem.module.css"

import { WorkstreamItem_user$key } from "./__generated__/WorkstreamItem_user.graphql"

import Dialog from "~/common/Dialog"
import Dropdown from "~/common/Dropdown/Dropdown"
import { IconThreeDot } from "~/common/IconThreeDot"
import MenuItem from "~/common/MenuItem"

import { activateWorkstream } from "~/mutations/Workstream"

import { showToast } from "~/containers/ToasterContainer"

import ArchiveWorkstreamModal from "./ArchiveWorkstreamModal"
import WorkstreamForm from "./WorkstreamForm"

export type Workstream = {
  id: number
  name: string
  archived: boolean
}

type Props = {
  children: string | typeof AutosizeInput
  workstream: Workstream
  canEdit: boolean
  user: WorkstreamItem_user$key
}

const WorkstreamItem = (props: Props) => {
  const { canEdit, workstream, children } = props

  const [showEditDialog, setShowEditDialog] = useState(false)
  const openEditDialog = () => setShowEditDialog(true)
  const closeEditDialog = () => setShowEditDialog(false)

  const [showArchiveDialog, setShowArchiveDialog] = useState(false)
  const openArchiveDialog = () => setShowArchiveDialog(true)
  const closeArchiveDialog = () => setShowArchiveDialog(false)

  const handleUnarchive = () => {
    void activateWorkstream(workstream.id)

    showToast({
      message: `${workstream.name} successfully unarchived`,
      type: "success",
    })
  }

  const user = useFragment(
    graphql`
      fragment WorkstreamItem_user on users {
        id
        ...WorkstreamForm_user
      }
    `,
    props.user,
  )

  return (
    <div className={styles.inlineFlex}>
      <Dropdown
        Target={() => (
          <div
            className={cc({
              [styles.workstream]: true,
              [styles.editable]: canEdit,
            })}
          >
            <div className={styles.workstreamContent}>{children}</div>
            {canEdit && (
              <IconThreeDot className={styles.menuTarget} size={10} />
            )}
          </div>
        )}
        disabled={!canEdit}
      >
        {workstream.archived ? (
          <MenuItem
            text="Unarchive"
            icon={<Icon icon="unarchive" />}
            onClick={handleUnarchive}
          />
        ) : (
          <>
            <MenuItem
              text="Edit"
              icon={<Icon icon="cog" />}
              onClick={openEditDialog}
            />
            <MenuItem
              text="Archive"
              icon={<Icon icon="archive" />}
              onClick={openArchiveDialog}
            />
          </>
        )}
      </Dropdown>
      <Dialog isOpen={showEditDialog} onClose={closeEditDialog}>
        <WorkstreamForm
          closeDialog={closeEditDialog}
          workstream={workstream}
          user={user}
        />
      </Dialog>
      <Dialog isOpen={showArchiveDialog} onClose={closeArchiveDialog}>
        <ArchiveWorkstreamModal
          workstream={workstream}
          closeDialog={closeArchiveDialog}
        />
      </Dialog>
    </div>
  )
}

export default WorkstreamItem
