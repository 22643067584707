import { Client, createClient } from "graphql-ws"
import mem from "mem"

import type SessionStore from "./SessionStore"

const getSubscriptionClient = mem(
  async (sessionStore: SessionStore): Promise<Client> => {
    const hasuraEndpoint = await sessionStore.getHasuraEndpoint()

    const HASURA_WS_URL = hasuraEndpoint.replace(/^http/, "ws") + "/v1/graphql"
    const subscriptionClient = createClient({
      url: HASURA_WS_URL,
      retryAttempts: 10,
      connectionParams: async () => {
        // Promise needs to resolve within connectionInitWaitTimeout
        // See https://github.com/enisdenjo/graphql-ws/blob/master/docs/interfaces/client.ClientOptions.md#connectionparams
        const hasuraAuthToken = await sessionStore.getAuthToken()
        return {
          headers: {
            Authorization: `Bearer ${hasuraAuthToken}`,
          },
        }
      },
      isFatalConnectionProblem: (errOrCloseEvent) => {
        if (process.env.NODE_ENV !== "development") {
          // Ensure this is logged as context in case app errors happen afterwards
          // eslint-disable-next-line no-console
          console.debug(
            "subscriptionClient.isFatalConnectionProblem",
            errOrCloseEvent,
          )
        }

        // Server restarts need to allow for connection retries.
        // See https://github.com/enisdenjo/graphql-ws/discussions/275#discussioncomment-1705615
        return false
      },
    })

    return subscriptionClient
  },
)

export { getSubscriptionClient }
