import React from "react"

type Props = {
  color?: any
  alt?: string
  width?: number
  height?: number
  marginRight?: number
}

const Workstream = ({ color, width = 16, height = 13, marginRight }: Props) => (
  <svg
    width={width}
    height={height}
    style={{ marginRight: marginRight }}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color || "#3C4260"}
      fillRule="evenodd"
      d="M13.066 7.147a.892.892 0 0 0-1.262 1.262l.258.258h-1.04L6.578 4.223h5.484l-.258.257a.892.892 0 0 0 1.262 1.262l1.778-1.777a.886.886 0 0 0 .258-.631.886.886 0 0 0-.258-.631L13.067.925a.892.892 0 0 0-1.262 1.262l.257.258H1.77c-.49 0-.89.4-.89.889s.4.889.89.889H4.07l5.964 5.964c.16.16.383.258.631.258h1.414l-.258.258a.891.891 0 0 0 1.262 1.262l1.778-1.778a.886.886 0 0 0 .258-.631.886.886 0 0 0-.258-.631l-1.796-1.778Z"
      clipRule="evenodd"
    />
  </svg>
)

export default Workstream
