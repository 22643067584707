import React from "react"

export const TangramCat = () => (
  <svg
    width="91"
    height="108"
    viewBox="0 0 91 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Cat</title>
    <rect
      width="18.8366"
      height="18.8366"
      transform="matrix(0.747346 0.664435 -0.747346 0.664435 14.0775 12.5157)"
      fill="#FDCD4F"
    />
    <path
      d="M51.1088 54.836L51.1088 90.2787H11.2435L51.1088 54.836Z"
      fill="#F5848C"
    />
    <path
      d="M22.9285 29.7694V65.2121L2.99588 47.4908L22.9285 29.7694Z"
      fill="#67D0D5"
    />
    <path
      d="M22.8886 29.7742L51.0776 54.836L22.8885 79.8977L22.8886 29.7742Z"
      fill="#4057DF"
    />
    <path
      d="M14.0945 12.5309L-3.83741e-05 -5.47742e-07L-3.96063e-05 25.0617L14.0945 12.5309Z"
      fill="#0A9CC1"
    />
    <path
      d="M70.9878 72.5703L51.0776 90.2717L71.0328 90.2517L90.9429 72.5503L70.9878 72.5703Z"
      fill="#FFB077"
    />
    <path
      d="M14.0544 12.5309L28.149 0L28.149 25.0618L14.0544 12.5309Z"
      fill="#B19DE6"
    />
  </svg>
)
