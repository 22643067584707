export const isNewTabClick = (e: MouseEvent) => {
  if (e.button === 0 && (e.metaKey || e.shiftKey || e.ctrlKey)) {
    return true
  }
  return false
}

export const underscoreToReadable = (text: string) => text.replace(/_/g, " ")

export const dashify = (text: string) => text.replace(/ /g, "-").toLowerCase()

export const dateTimeEqual = (a: Date, b: Date) => a?.getTime() === b?.getTime()

export const defaultToFalse = (value) => (value === true ? true : false)

export const defaultToTrue = (value) => (value === false ? false : true)

export const randomId = () => Math.random().toString(36).substring(2, 9)

export const isAltAccount = (account_type: string) =>
  ["test", "copy"].includes(account_type)
