import cc from "classcat"
import React, { useLayoutEffect, useReducer } from "react"

import styles from "./CardHeader.module.css"

type Props = {
  className?: string
  id?: string
  noSticky?: boolean
  children: React.ReactNode
}

const CardHeader = (props: Props) => {
  const { children, noSticky, className, id } = props
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const pageHeaderHeight =
    document.getElementById("page-header")?.clientHeight || 0
  const topBarHeight = document.getElementById("top-bar")?.clientHeight || 0
  const fixedTopHeight = noSticky ? 0 : pageHeaderHeight + topBarHeight + "px"

  // Only way I could come up with to adjust the height
  // Based on if the page-header height changes after this rendered
  // For example when a new line of tags are added on Project/People Dashboard
  useLayoutEffect(() => {
    // @ts-ignore
    if (window.ResizeObserver) {
      // No supported by Safari until about May 2020.
      // @ts-ignore
      const resizeObserver = new ResizeObserver(() => forceUpdate())

      // SetTimeout to delay this by 0.5s. Sometimes a bug occures when the dom hasn't loaded
      setTimeout(() => {
        const element = document.getElementById("page-header")

        if (element && element instanceof HTMLElement) {
          const el = document.getElementById("page-header")
          if (el) {
            resizeObserver.observe(el)
          }
        }
      }, 500)

      return () => resizeObserver.disconnect()
    }
  }, [])

  return (
    <div
      id={id}
      style={{ top: fixedTopHeight }}
      className={cc([className, styles.container])}
    >
      {children}
    </div>
  )
}

export default CardHeader
