import cc from "classcat"
import React from "react"

import styles from "./AddButton.module.css"

import IconPlus from "~/common/IconPlus"

import Button, { ButtonProps } from "./Button"

export default (props: ButtonProps) => {
  const { style, className, ...rest } = props
  const paddingLeft = props.small ? 5 : 7
  const paddingRight = 15

  return (
    <Button
      icon={<IconPlus />}
      className={cc([
        className,
        {
          [styles.small]: props.small,
        },
      ])}
      style={{ paddingLeft, paddingRight, ...style }}
      {...rest}
    />
  )
}
