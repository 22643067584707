import React from "react"

type Props = {
  size?: number
  color?: string
}

const skill = (props: Props) => {
  const { size = 18, color = "var(--midnight)" } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6644 5.43543L15.2972 2.48621C15.2079 2.2937 15.015 2.18652 14.803 2.18652H9.94042H9.93785H5.14474H5.14203H3.42737C3.22686 2.18652 3.04291 2.27986 2.94834 2.45663L1.35429 5.41398C1.30722 5.5027 1.29542 5.59387 1.29922 5.68721C1.27154 5.83535 1.30681 5.98919 1.40029 6.11293L8.57518 15.5988C8.67747 15.7347 8.8381 15.8133 9.00889 15.8133C9.17942 15.8133 9.34046 15.7331 9.44302 15.5974L16.603 6.11018C16.691 5.99297 16.7244 5.8474 16.7045 5.70645C16.7125 5.61609 16.7053 5.52371 16.6643 5.43539L16.6644 5.43543ZM12.0147 3.35352L11.4003 4.73825L10.7854 3.35352H12.0147ZM8.40803 3.35352H9.5959L10.4823 4.90947L7.52157 4.90934L8.40803 3.35352ZM7.2188 3.35352L6.60383 4.73825L5.98953 3.35352H7.2188ZM3.75242 3.35352H4.80016L5.68647 4.90947L2.67877 4.90934L3.75242 3.35352ZM2.92678 6.0763H6.16606L7.92483 12.6848L2.92678 6.0763ZM9.00143 12.5038L7.2919 6.0763H10.7108L9.00143 12.5038ZM10.1029 12.6673L11.8368 6.0762H15.077L10.1029 12.6673ZM12.3183 4.90926L13.2043 3.35332H14.4549L15.3766 4.90926H12.3183Z"
        fill={color}
      />
    </svg>
  )
}

export default skill
