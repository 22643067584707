/**
 * @generated SignedSource<<6fc3a93c5fc29ab3c8cb267c8299b8a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectTeamUpdateMutation$variables = {
  id: number;
  team_id?: number | null | undefined;
};
export type ProjectTeamUpdateMutation$data = {
  readonly update_projects_by_pk: {
    readonly account: {
      readonly id: number;
      readonly teams: ReadonlyArray<{
        readonly id: number;
        readonly " $fragmentSpreads": FragmentRefs<"TeamProjects_team">;
      }>;
    };
    readonly id: number;
    readonly name: string;
  } | null | undefined;
};
export type ProjectTeamUpdateMutation = {
  response: ProjectTeamUpdateMutation$data;
  variables: ProjectTeamUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "team_id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "team_id",
        "variableName": "team_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectTeamUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "update_projects_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "teams",
                "kind": "LinkedField",
                "name": "teams",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TeamProjects_team"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectTeamUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projects",
        "kind": "LinkedField",
        "name": "update_projects_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "teams",
                "kind": "LinkedField",
                "name": "teams",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                          "name": "asc"
                        }
                      },
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "active": {
                            "_eq": true
                          }
                        }
                      }
                    ],
                    "concreteType": "projects",
                    "kind": "LinkedField",
                    "name": "projects",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "clients",
                        "kind": "LinkedField",
                        "name": "client",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "website",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "image_key",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "projects(order_by:{\"name\":\"asc\"},where:{\"active\":{\"_eq\":true}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67dd29b1524c598088b713d9ba033a89",
    "id": null,
    "metadata": {},
    "name": "ProjectTeamUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectTeamUpdateMutation(\n  $id: Int!\n  $team_id: Int\n) {\n  update_projects_by_pk(pk_columns: {id: $id}, _set: {team_id: $team_id}) {\n    id\n    name\n    account {\n      id\n      teams {\n        id\n        ...TeamProjects_team\n      }\n    }\n  }\n}\n\nfragment TeamProjects_team on teams {\n  id\n  projects(where: {active: {_eq: true}}, order_by: {name: asc}) {\n    id\n    name\n    client {\n      id\n      website\n      image_key\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7bbf8623579b5647497293d5abed0fe";

export default node;
