import React from "react"

type Props = {
  color?: any
  alt?: string
  size?: number
}

const AddCircle = (props: Props) => (
  <svg
    width={props.size || "18"}
    height={props.size || "18"}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.alt}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={props.color || "var(--slate)"}
      d="M1.5 9C1.5 4.86 4.86 1.5 9 1.5C13.14 1.5 16.5 4.86 16.5 9C16.5 13.14 13.14 16.5 9 16.5C4.86 16.5 1.5 13.14 1.5 9ZM9.75 9.75H12.75V8.25H9.75V5.25H8.25V8.25H5.25V9.75H8.25V12.75H9.75V9.75Z"
    />
  </svg>
)

export default AddCircle
