import React, { Suspense } from "react"
import { RelayEnvironmentProvider } from "react-relay"

import { environment } from "./store/hasura"

import ErrorBoundary from "./helpers/ErrorBoundary"

import Loading from "./common/Loading"

import { Toaster } from "./containers/ToasterContainer"

type RelayPageContainerProps = {
  children: React.ReactNode
  fallback: string | React.ReactNode
}

export const RelayPageContainer = (props: RelayPageContainerProps) => {
  const { children, fallback } = props
  return (
    <ErrorBoundary>
      <Suspense fallback={fallback}>
        <RelayEnvironmentProvider environment={environment}>
          {children}
        </RelayEnvironmentProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

export const withRelayPageContainer =
  <Props,>(
    Component: React.FunctionComponent<Props>,
    fallback: string | React.ReactNode = <Loading />,
    toaster?: boolean,
  ) =>
  (props: Props) => (
    <>
      <RelayPageContainer fallback={fallback}>
        <Component {...props} />
      </RelayPageContainer>
      {toaster && <Toaster />}
    </>
  )

export const withRelayPageContainerNoLoading =
  <Props,>(Component: React.FunctionComponent<Props>) =>
  (props: Props) => (
    <RelayPageContainer fallback="">
      <Component {...props} />
    </RelayPageContainer>
  )
