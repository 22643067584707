import React, { SyntheticEvent } from "react"

import styles from "./ModalFormWrapper.module.css"

import CardHelp from "~/dashboard/Card/CardHelp"

import { CrossIcon } from "../react-icons"

type Props = {
  text: string | React.ReactNode
  subText?: string
  helpLink?: string
  bold?: boolean
  onCloseClick?: (e?: SyntheticEvent<HTMLElement, Event>) => void
}

const ModalHeader = (props: Props) => {
  const { text, subText, helpLink, bold, onCloseClick } = props

  const TextWrapper = () => (
    <>
      {text} {subText && <span className={styles.subText}>{subText}</span>}
      {helpLink && <CardHelp url={helpLink} />}
    </>
  )

  return (
    <div className={`${styles.header} ${bold ? styles.bold : ""}`}>
      {onCloseClick ? (
        <div className={styles.headerWithCloseButton}>
          <TextWrapper />
          <div className={styles.closeIcon} onClick={onCloseClick}>
            <CrossIcon size={12} />
          </div>
        </div>
      ) : (
        <TextWrapper />
      )}
    </div>
  )
}

export default ModalHeader
