import { graphql } from "react-relay"

import { WorkstreamActivateMutation } from "./__generated__/WorkstreamActivateMutation.graphql"
import { WorkstreamArchiveMutation } from "./__generated__/WorkstreamArchiveMutation.graphql"
import { WorkstreamCreateMutation } from "./__generated__/WorkstreamCreateMutation.graphql"
import { WorkstreamUpdateMutation } from "./__generated__/WorkstreamUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

import { showToast } from "~/containers/ToasterContainer"

const workstreamCreateMutation = graphql`
  mutation WorkstreamCreateMutation($name: String!) {
    createWorkstream(name: $name) {
      account {
        id
        workstreams {
          id
          name
          archived
        }
      }
    }
  }
`

export const workstreamCreateRelay = async (
  variables: WorkstreamCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<WorkstreamCreateMutation>(
    environment,
    {
      mutation: workstreamCreateMutation,
      variables,
    },
  )

  const response = data.createWorkstream

  if (response) {
    showToast({
      message: `New workstream created`,
      description: variables.name,
      type: "success",
    })
  }

  return response.account
}

const workstreamUpdateMutation = graphql`
  mutation WorkstreamUpdateMutation($workstreamId: Int!, $name: String!) {
    updateWorkstream(id: $workstreamId, name: $name) {
      workstream {
        id
        name
      }
    }
  }
`

type UpdateWorkstreamRelayOptions = {
  variables: WorkstreamUpdateMutation["variables"]
}

export const workstreamUpdateRelay = async (
  options: UpdateWorkstreamRelayOptions,
) => {
  const { variables } = options

  const data = await commitMutationPromise<WorkstreamUpdateMutation>(
    environment,
    {
      mutation: workstreamUpdateMutation,
      variables,
    },
  )
  const workstream = data.updateWorkstream.workstream
  if (workstream) {
    showToast({
      message: "Updated workstream",
      description: variables.name,
      type: "success",
    })
  }

  return workstream
}

export function archiveWorkstream(id: number) {
  return commitMutationPromise<WorkstreamArchiveMutation>(environment, {
    mutation: graphql`
      mutation WorkstreamArchiveMutation($id: Int!) {
        archiveWorkstream(id: $id) {
          workstream {
            id
            name
            archived
          }
        }
      }
    `,
    variables: { id },
  })
}

export function activateWorkstream(id: number) {
  return commitMutationPromise<WorkstreamActivateMutation>(environment, {
    mutation: graphql`
      mutation WorkstreamActivateMutation($id: Int!) {
        activateWorkstream(id: $id) {
          workstream {
            id
            name
            archived
          }
        }
      }
    `,
    variables: { id },
  })
}
