import { Icon } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React from "react"

import styles from "./UpgradeBanner.module.css"

import { useIsInFreePlan } from "~/Entitlements/useEntitlements"

import StartTrialButton from "./StartTrialButton"

type Props = {
  isAdmin: boolean
  headingText?: string
  subscription?: {
    plan_id?: string
    subscription_items?: {
      item_price_id: string
    }[]
  }
}

const UpgradeBanner = (props: Props) => {
  const { isAdmin, subscription, headingText } = props
  const entitlementsEnabled = useFeature("subscription_entitlements")
  const isFreePlan = useIsInFreePlan()

  // TODO(feature-sets): This banner can be removed once we turn entitlements on for all
  if (entitlementsEnabled || !subscription || !isFreePlan) {
    return null
  }

  const heading =
    headingText ?? "Start your 14 day free trial to manage unlimited people"

  return (
    <div className={styles.container}>
      <div className={styles.border} />
      <div className={styles.icon}>
        <Icon icon="info-sign" />
      </div>
      <div className={styles.content}>
        <h2>{heading}</h2>
        <p>
          After your free trial, you'll be charged $10 USD per person, per
          month.
          <br />
          Runn also offers a 20% discount on annual subscriptions.&nbsp;
          <a href="#" onClick={() => window.Intercom("show")}>
            Send us a message
          </a>{" "}
          to find out more.
        </p>
        {!isAdmin && (
          <p>
            <b>Contact your account admin to upgrade.</b>
          </p>
        )}
      </div>
      <div className={styles.noCreditCard}>
        <i>No credit card required</i>
      </div>
      <div className={styles.trialButton}>
        <StartTrialButton />
      </div>
    </div>
  )
}

export default UpgradeBanner
