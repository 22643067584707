import React from "react"

export const TangramPersonBalancing = () => (
  <svg
    width="88"
    height="86"
    viewBox="0 0 88 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5029 17.6221L-0.0150778 17.6221L-0.0150766 -0.0150106L17.5029 -0.0150128L17.5029 17.6221Z"
      fill="#FDCD4F"
    />
    <path
      d="M43.8633 85.9926L43.8633 68.3555L26.3453 85.9925L43.8633 85.9926Z"
      fill="#B19DE6"
    />
    <path
      d="M70.4363 6.48047H87.9543L70.4363 24.1176V6.48047Z"
      fill="#0A9CC1"
    />
    <path
      d="M35.1954 24.2617L70.2314 24.2617L35.1954 59.5359L35.1954 24.2617Z"
      fill="#4057DF"
    />
    <path d="M77.5408 41.882V16.9395L52.7666 41.882H77.5408Z" fill="#67D0D5" />
    <path
      d="M35.2411 59.4982L52.7591 41.8611V59.4982L35.2411 77.1353V59.4982Z"
      fill="#FFB077"
    />
    <path
      d="M35.3396 34.9304V-0.34375L0.303598 34.9304H35.3396Z"
      fill="#F5848C"
    />
    <defs>
      <clipPath id="clip0_616_46580">
        <rect width="88" height="86" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
