import React from "react"

type Props = {
  color?: string
  alt?: string
}
const Expand = (props: Props) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.alt}</title>
    <path
      fill={props.color || "#969AB3"}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 0C0.335786 0 0 0.335786 0 0.75C0 1.16421 0.335786 1.5 0.75 1.5H11.25C11.6642 1.5 12 1.16421 12 0.75C12 0.335786 11.6642 0 11.25 0H0.75Z"
    />
    <path
      fill={props.color || "#969AB3"}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 3C0.335786 3 0 3.33579 0 3.75C0 4.16421 0.335786 4.5 0.75 4.5H11.25C11.6642 4.5 12 4.16421 12 3.75C12 3.33579 11.6642 3 11.25 3H0.75Z"
    />
    <path
      fill={props.color || "#969AB3"}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09259 5.75C6.50681 5.75 6.84259 6.08579 6.84259 6.5V9.93566L8.21666 8.8364C8.54011 8.57764 9.01208 8.63009 9.27084 8.95353C9.52959 9.27698 9.47715 9.74895 9.15371 10.0077L6.56111 12.0818C6.2872 12.3009 5.89799 12.3009 5.62407 12.0818L3.03148 10.0077C2.70803 9.74895 2.65559 9.27698 2.91435 8.95353C3.17311 8.63009 3.64508 8.57764 3.96852 8.8364L5.34259 9.93566V6.5C5.34259 6.08579 5.67838 5.75 6.09259 5.75Z"
    />
  </svg>
)

export default Expand
