export const ignoreHotKeyEvent = ({ target }) => {
  const nodeName = target.nodeName.toLowerCase()

  return (
    nodeName === "input" ||
    nodeName === "select" ||
    nodeName === "textarea" ||
    target.isContentEditable
  )
}

export const submitOnEnterOrSpace = (
  e,
  onSubmit: ((e) => void) | undefined,
) => {
  if (onSubmit && (e.keyCode === 32 || e.keyCode === 13)) {
    onSubmit(e)
  }
}
