/**
 * @generated SignedSource<<79380ba28d6901f477fe6d3917a4eee3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateRoleInput = {
  chargeOutRate: number;
  defaultHourCost: number;
  name: string;
  references?: any | null | undefined;
};
export type RoleCreateMutation$variables = {
  includeAggregateFields: boolean;
  input: CreateRoleInput;
  todaysDate?: any | null | undefined;
};
export type RoleCreateMutation$data = {
  readonly createRole: {
    readonly account: {
      readonly id: number;
      readonly projects: ReadonlyArray<{
        readonly id: number;
        readonly project_rates: ReadonlyArray<{
          readonly id: number;
          readonly project_id: number;
          readonly rate: number | null | undefined;
          readonly role_id: number;
        }>;
      }>;
      readonly rate_cards: ReadonlyArray<{
        readonly id: number;
        readonly name: string | null | undefined;
        readonly role_charge_out_rates: ReadonlyArray<{
          readonly charge_out_rate: number | null | undefined;
          readonly rate_card_id: number;
          readonly role_id: number;
        }>;
      }>;
      readonly roles: ReadonlyArray<{
        readonly actuals_aggregate?: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly assignments_aggregate?: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly contracts_aggregate?: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly id: number;
      }>;
    } | null | undefined;
    readonly role: {
      readonly active: boolean;
      readonly default_hour_cost: number | null | undefined;
      readonly id: number;
      readonly name: string | null | undefined;
      readonly references: any;
      readonly role_has_actuals: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly role_has_assignments: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly role_has_contracts: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
  };
};
export type RoleCreateMutation = {
  response: RoleCreateMutation$data;
  variables: RoleCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeAggregateFields"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "todaysDate"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  }
],
v6 = [
  (v3/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRoleResult",
    "kind": "LinkedField",
    "name": "createRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "roles",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": "default_hour_cost",
            "args": null,
            "kind": "ScalarField",
            "name": "default_hour_cost_private",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "references",
            "storageKey": null
          },
          {
            "alias": "role_has_contracts",
            "args": (v5/*: any*/),
            "concreteType": "contracts",
            "kind": "LinkedField",
            "name": "contracts",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": "contracts(limit:1)"
          },
          {
            "alias": "role_has_actuals",
            "args": (v5/*: any*/),
            "concreteType": "actuals",
            "kind": "LinkedField",
            "name": "actuals",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": "actuals(limit:1)"
          },
          {
            "alias": "role_has_assignments",
            "args": (v5/*: any*/),
            "concreteType": "assignments",
            "kind": "LinkedField",
            "name": "assignments",
            "plural": true,
            "selections": (v6/*: any*/),
            "storageKey": "assignments(limit:1)"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "roles",
            "kind": "LinkedField",
            "name": "roles",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "condition": "includeAggregateFields",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "fields": [
                          {
                            "items": [
                              {
                                "fields": [
                                  {
                                    "fields": [
                                      {
                                        "kind": "Variable",
                                        "name": "_gte",
                                        "variableName": "todaysDate"
                                      }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "end_date_iso"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "_or.0"
                              },
                              {
                                "kind": "Literal",
                                "name": "_or.1",
                                "value": {
                                  "end_date_iso": {
                                    "_is_null": true
                                  }
                                }
                              }
                            ],
                            "kind": "ListValue",
                            "name": "_or"
                          },
                          {
                            "kind": "Literal",
                            "name": "person",
                            "value": {
                              "active": {
                                "_eq": true
                              },
                              "is_placeholder": {
                                "_eq": false
                              }
                            }
                          },
                          {
                            "fields": [
                              {
                                "kind": "Variable",
                                "name": "_lte",
                                "variableName": "todaysDate"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "start_date_iso"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "where"
                      }
                    ],
                    "concreteType": "contracts_aggregate",
                    "kind": "LinkedField",
                    "name": "contracts_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "contracts_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "actuals_aggregate",
                    "kind": "LinkedField",
                    "name": "actuals_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "actuals_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "assignments_aggregate",
                    "kind": "LinkedField",
                    "name": "assignments_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "assignments_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "name": {
                    "_eq": "Standard"
                  }
                }
              }
            ],
            "concreteType": "rate_cards",
            "kind": "LinkedField",
            "name": "rate_cards",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "role_charge_out_rates",
                "kind": "LinkedField",
                "name": "role_charge_out_rates",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rate_card_id",
                    "storageKey": null
                  },
                  {
                    "alias": "charge_out_rate",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "charge_out_rate_private",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "rate_cards(where:{\"name\":{\"_eq\":\"Standard\"}})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "projects",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project_rates",
                "kind": "LinkedField",
                "name": "project_rates",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "project_id",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": "rate",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rate_private",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleCreateMutation",
    "selections": (v9/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RoleCreateMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "a942a9301f96cd5c4f486b09412959a4",
    "id": null,
    "metadata": {},
    "name": "RoleCreateMutation",
    "operationKind": "mutation",
    "text": "mutation RoleCreateMutation(\n  $input: CreateRoleInput!\n  $todaysDate: date\n  $includeAggregateFields: Boolean!\n) {\n  createRole(input: $input) {\n    role {\n      id\n      name\n      active\n      default_hour_cost: default_hour_cost_private\n      references\n      role_has_contracts: contracts(limit: 1) {\n        id\n      }\n      role_has_actuals: actuals(limit: 1) {\n        id\n      }\n      role_has_assignments: assignments(limit: 1) {\n        id\n      }\n    }\n    account {\n      id\n      roles {\n        id\n        contracts_aggregate(where: {start_date_iso: {_lte: $todaysDate}, _or: [{end_date_iso: {_gte: $todaysDate}}, {end_date_iso: {_is_null: true}}], person: {active: {_eq: true}, is_placeholder: {_eq: false}}}) @include(if: $includeAggregateFields) {\n          aggregate {\n            count\n          }\n        }\n        actuals_aggregate @include(if: $includeAggregateFields) {\n          aggregate {\n            count\n          }\n        }\n        assignments_aggregate @include(if: $includeAggregateFields) {\n          aggregate {\n            count\n          }\n        }\n      }\n      rate_cards(where: {name: {_eq: \"Standard\"}}) {\n        id\n        name\n        role_charge_out_rates {\n          role_id\n          rate_card_id\n          charge_out_rate: charge_out_rate_private\n        }\n      }\n      projects {\n        id\n        project_rates {\n          id\n          project_id\n          role_id\n          rate: rate_private\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f88e164766aa001d9104811745fbbb1";

export default node;
