import React from "react"

export const TangramPersonFlying = () => (
  <svg
    width="65"
    height="99"
    viewBox="0 0 65 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.0076 17.2656L47.8389 17.2656L47.8389 0.011855L65.0076 0.0118521L65.0076 17.2656Z"
      fill="#FDCD4F"
    />
    <path
      d="M12.1402 76.3409L24.2803 64.1406L0.000118256 64.1406L12.1402 76.3409Z"
      fill="#B19DE6"
    />
    <path
      d="M27.8136 64.1278L27.8136 88.5283L52.0938 64.1278H27.8136Z"
      fill="#67D0D5"
    />
    <path
      d="M19.8467 28.4337V4.0332L31.9868 16.2335L31.9868 40.634L19.8467 28.4337Z"
      fill="#FFB077"
    />
    <path
      d="M34.5383 99.0488L17.3696 99.0488L34.5383 81.7951L34.5383 99.0488Z"
      fill="#0A9CC1"
    />
    <path
      d="M52.0639 64.1152L17.7266 64.1152L52.0639 29.6077L52.0639 64.1152Z"
      fill="#4057DF"
    />
    <path
      d="M56.2521 25.4962L31.9719 1.0957V49.8967L56.2521 25.4962Z"
      fill="#F5848C"
    />
    <defs>
      <clipPath id="clip0_616_46595">
        <rect width="65" height="99" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
