import { graphql } from "react-relay"

import { RoleActivateMutation } from "./__generated__/RoleActivateMutation.graphql"
import { RoleArchiveMutation } from "./__generated__/RoleArchiveMutation.graphql"
import { RoleBulkUpdateMutation } from "./__generated__/RoleBulkUpdateMutation.graphql"
import { RoleCreateMutation } from "./__generated__/RoleCreateMutation.graphql"
import { RoleDeleteMutation } from "./__generated__/RoleDeleteMutation.graphql"
import { RoleUpdateMutation } from "./__generated__/RoleUpdateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"
import * as hashids from "~/helpers/hashids"
import { viewRoleUrl } from "~/helpers/routes"

import { showToast } from "~/containers/ToasterContainer"

const roleCreateMutation = graphql`
  mutation RoleCreateMutation(
    $input: CreateRoleInput!
    $todaysDate: date
    $includeAggregateFields: Boolean!
  ) {
    createRole(input: $input) {
      role {
        id
        name
        active
        default_hour_cost: default_hour_cost_private
        references
        role_has_contracts: contracts(limit: 1) {
          id
        }
        role_has_actuals: actuals(limit: 1) {
          id
        }
        role_has_assignments: assignments(limit: 1) {
          id
        }
      }
      account {
        id
        roles {
          id
          contracts_aggregate(
            where: {
              start_date_iso: { _lte: $todaysDate }
              _or: [
                { end_date_iso: { _gte: $todaysDate } }
                { end_date_iso: { _is_null: true } }
              ]
              person: { active: { _eq: true }, is_placeholder: { _eq: false } }
            }
          ) @include(if: $includeAggregateFields) {
            aggregate {
              count
            }
          }
          actuals_aggregate @include(if: $includeAggregateFields) {
            aggregate {
              count
            }
          }
          assignments_aggregate @include(if: $includeAggregateFields) {
            aggregate {
              count
            }
          }
        }
        rate_cards(where: { name: { _eq: "Standard" } }) {
          id
          name
          role_charge_out_rates {
            role_id
            rate_card_id
            charge_out_rate: charge_out_rate_private
          }
        }
        projects {
          id
          project_rates {
            id
            project_id
            role_id
            rate: rate_private
          }
        }
      }
    }
  }
`

type CreateRoleRelayOptions = {
  variables: RoleCreateMutation["variables"]
}

export const roleCreateRelay = async (options: CreateRoleRelayOptions) => {
  const { variables } = options

  const data = await commitMutationPromise<RoleCreateMutation>(environment, {
    mutation: roleCreateMutation,
    variables,
  })
  const role = data.createRole.role
  if (role) {
    const hashid = hashids.roles.encode(role.id)
    showToast({
      message: "New role created",
      description: role.name,
      actions: [{ href: `roles/${hashid}` }],
      type: "success",
    })
  }
  return role
}
const roleUpdateMutation = graphql`
  mutation RoleUpdateMutation($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      role {
        id
        name
        active
        default_hour_cost: default_hour_cost_private
        references
      }
      account {
        id
        roles {
          id
        }
        rate_cards(where: { name: { _eq: "Standard" } }) {
          id
          name
          role_charge_out_rates {
            role_id
            rate_card_id
            charge_out_rate: charge_out_rate_private
          }
        }
      }
    }
  }
`

type UpdateRoleRelayOptions = {
  variables: RoleUpdateMutation["variables"]
}

export const roleUpdateRelay = async (options: UpdateRoleRelayOptions) => {
  const { variables } = options
  const data = await commitMutationPromise<RoleUpdateMutation>(environment, {
    mutation: roleUpdateMutation,
    variables,
  })
  const role = data.updateRole.role
  if (role) {
    const hashid = hashids.roles.encode(role.id)
    showToast({
      message: "Updated role",
      description: role.name,
      actions: [{ href: viewRoleUrl(hashid) }],
      type: "success",
    })
  }
  return role
}

export function archiveRole(id: number) {
  return commitMutationPromise<RoleArchiveMutation>(environment, {
    mutation: graphql`
      mutation RoleArchiveMutation($id: Int!) {
        archiveRole(id: $id) {
          role {
            id
            name
            active
          }
        }
      }
    `,
    variables: { id },
  })
}

export function activateRole(id: number) {
  return commitMutationPromise<RoleActivateMutation>(environment, {
    mutation: graphql`
      mutation RoleActivateMutation($id: Int!) {
        activateRole(id: $id) {
          role {
            id
            name
            active
          }
        }
      }
    `,
    variables: { id },
  })
}

const roleDeleteMutation = graphql`
  mutation RoleDeleteMutation($id: Int!) {
    deleteRole(id: $id) {
      account {
        id
        roles {
          id
        }
      }
    }
  }
`

export const roleDeleteRelay = async (
  variables: RoleDeleteMutation["variables"],
) => {
  const data = await commitMutationPromise<RoleDeleteMutation>(environment, {
    mutation: roleDeleteMutation,
    variables,
  })

  return data.deleteRole.account.roles
}

const roleBulkUpdateMutation = graphql`
  mutation RoleBulkUpdateMutation($input: RoleBulkUpdateInput!) {
    bulkUpdateRole(input: $input) {
      account {
        rate_type
        rate_cards(where: { name: { _eq: "Standard" } }) {
          id
          name
          role_charge_out_rates {
            role_id
            rate_card_id
            charge_out_rate: charge_out_rate_private
          }
        }
        roles {
          id
          name
          active
          default_hour_cost: default_hour_cost_private
        }
      }
    }
  }
`

export const roleBulkUpdateRelay = async (
  variables: RoleBulkUpdateMutation["variables"],
) => {
  const updatedRoleIds = await commitMutationPromise<RoleBulkUpdateMutation>(
    environment,
    {
      mutation: roleBulkUpdateMutation,
      variables,
    },
  )

  showToast({
    message: "Roles successfully updated",
    type: "success",
  })

  return updatedRoleIds
}
