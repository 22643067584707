import cc from "classcat"
import React from "react"

import styles from "./Tangram.module.css"

export const trophy = {
  name: "trophy",
  yellow: "translate(75.127685px,91.508182px) rotate(0deg)",
  mint: "translate(88.161967px, 118.098117px) rotate(0deg)",
  pink: "translate(85.03374px, 40.413798px) rotate(0deg)",
  blue: "translate(67.307116px, 58.661792px) rotate(0deg)",
  teal: "translate(43.324038px, 33.635971px) rotate(0deg)",
  purple: "translate(106.931333px, 34.157343px) rotate(0deg)",
  orange: "translate(63.638837px, 115.590346px) rotate(0deg)",
}

export const flower = {
  name: "flower",
  yellow: "translate(74.88871px, 67.994655px) rotate(-45deg)",
  mint: "translate(85.797415px, 39.66944px) rotate(-135deg)",
  pink: "translate(60.236026px, 99.891947px) rotate(0deg)",
  blue: "translate(90.690729px, 100.531429px) rotate(-270deg)",
  teal: "translate(92.456267px, 55.831619px) rotate(135deg)",
  purple: "translate(51.977086px, 51.630547px) rotate(-45deg)",
  orange: "translate(66.787585px, 41.626563px) rotate(-45deg)",
}

export const mountain = {
  name: "mountain",
  yellow: "translate(74.415199px, 67.676791px) rotate(45deg)",
  mint: "translate(91.761082px, 86.614824px) rotate(-90deg)",
  pink: "translate(34.091981px, 96.220384px) rotate(90deg)",
  blue: "translate(117.157541px, 95.822493px) rotate(-360deg)",
  teal: "translate(81.758591px, 103.131971px) rotate(180deg)",
  purple: "translate(57.855141px, 104.425264px) rotate(-90deg)",
  orange: "translate(61.398341px, 87.101345px) rotate(-90deg)",
}

export const heart = {
  name: "heart",
  yellow: "translate(75.053832px, 103.366865px) rotate(135deg)",
  mint: "translate(46.959114px, 80.023193px) rotate(-315deg)",
  pink: "translate(91.881916px, 82.731233px) rotate(135deg)",
  blue: "translate(57.130643px, 57.16252px) rotate(-585deg)",
  teal: "translate(63.043746px, 86.824018px) rotate(45deg)",
  purple: "translate(90.652873px, 46.326661px) rotate(45deg)",
  orange: "translate(100.593392px, 61.044537px) rotate(45deg)",
}

export const runner = {
  name: "runner",
  yellow: "translate(75.1277px, 18.5082px) rotate(45deg)",
  pink: "translate(79.0337px, 51.4138px) rotate(-90deg)",
  blue: "translate(74.3071px, 91.6618px) rotate(270deg)",
  mint: "translate(99.162px, 110.098px) rotate(90deg)",
  teal: "translate(84.324px, 132.636px) rotate(225deg)",
  purple: "translate(32.931px, 107.1573px) rotate(0deg)",
  orange: "translate(52px, 60.59px) rotate(90deg) scaleX(-1)",
}

export const balance = {
  name: "balance",
  yellow: "translate(35px, 30px) rotate(90deg)",
  mint: "translate(120px, 66px) rotate(135deg)",
  pink: "translate(86px, 68px) rotate(270deg)",
  blue: "translate(56px, 52px) rotate(-90deg)",
  teal: "translate(128px, 37px) rotate(270deg)",
  purple: "translate(78px, 127px) rotate(180deg)",
  orange: "translate(83px, 101.5px) rotate(-90deg) scaleX(-1)",
}

export const handstand = {
  name: "handstand",
  yellow: "translate(63.1px, 115.508px) rotate(45deg) scale(0.98)",
  pink: "translate(102.5px, 112.5px) rotate(90deg) scale(0.98)",
  blue: "translate(85px, 64px) rotate(360deg) scale(0.98)",
  mint: "translate(94px, 89.5px) rotate(-45deg) scale(0.98)",
  teal: "translate(64.5px, 16px) rotate(225deg) scale(0.92)",
  purple: "translate(78.5px, 17px) scale(0.98)",
  orange: "translate(93.5px, 139px) rotate(0deg) scale(0.98)",
}

export const march = {
  name: "march",
  yellow: "translate(80px, 19px) rotate(135deg)",
  mint: "translate(58px, 47.5px) rotate(45deg)",
  pink: "translate(83px, 52px) rotate(270deg)",
  blue: "translate(90px, 86px) rotate(45deg)",
  teal: "translate(94.2px, 136.5px) rotate(90deg)",
  purple: "translate(35.2px, 96.3px) rotate(-90deg)",
  orange: "translate(59.5px, 94.7px) rotate(45deg)",
}

const defaultTangrams = [
  trophy,
  flower,
  mountain,
  heart,
  runner,
  balance,
  handstand,
  march,
]

export type TangramType = (typeof defaultTangrams)[0]

type Props = {
  initialTangramIndex: number
  style?: Record<string, any>
  className?: string
  tangrams?: TangramType[]
  intervalMs: number
}

const Tangram = ({
  initialTangramIndex,
  tangrams = defaultTangrams,
  className,
  style,
  intervalMs,
}: Props) => {
  const currentTangramIndex = React.useRef(
    initialTangramIndex >= tangrams.length
      ? tangrams.length
      : initialTangramIndex,
  )

  const selectedTangramRefs = React.useRef({
    yellow: React.useRef(null),
    mint: React.useRef(null),
    pink: React.useRef(null),
    blue: React.useRef(null),
    teal: React.useRef(null),
    purple: React.useRef(null),
    orange: React.useRef(null),
  })

  React.useLayoutEffect(() => {
    const setNewTangram = () => {
      const nextTangramIndex =
        currentTangramIndex.current + 1 >= tangrams.length
          ? 0
          : currentTangramIndex.current + 1

      const nextTangram = tangrams[nextTangramIndex]

      currentTangramIndex.current = nextTangramIndex

      Object.keys(nextTangram).forEach((color) => {
        if (color === "name") {
          return
        }

        selectedTangramRefs.current[color].current.style.transform =
          nextTangram[color]
      })
    }

    setNewTangram()

    const interval = setInterval(() => {
      setNewTangram()
    }, intervalMs)

    return () => clearInterval(interval)
  })

  return (
    <svg
      id="eZ2txJ1OlP61"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      className={cc([styles.tangramAnimation, className])}
      style={{ ...style }}
    >
      <g ref={selectedTangramRefs.current.yellow}>
        <rect
          id="eZ2txJ1OlP62"
          width="24.3596"
          height="24.3596"
          rx="0"
          ry="0"
          transform="translate(-11.476897,-12.394735)"
          fill="rgb(253,205,79)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <g ref={selectedTangramRefs.current.mint}>
        <path
          id="eZ2txJ1OlP63"
          d="M85.5219,127.283L36.7437,127.283L61.1328,102.894L85.5219,127.283Z"
          transform="translate(-61.244079,-117.69027)"
          fill="rgb(103,208,213)"
          stroke="none"
          strokeWidth="1"
        />
      </g>

      <g ref={selectedTangramRefs.current.pink}>
        <path
          id="eZ2txJ1OlP64"
          d="M24.4072,24.8892L73.1855,24.8892L73.1855,73.6674L24.4072,24.8892Z"
          transform="translate(-58.115852,-40.005951)"
          fill="rgb(245,132,140)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <g ref={selectedTangramRefs.current.blue}>
        <path
          id="eZ2txJ1OlP65"
          d="M24.6128,24.9233L24.6128,73.7016L73.3911,73.7016L24.6128,24.9233Z"
          transform="translate(-40.389228,-58.253945)"
          fill="rgb(64,87,223)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <g ref={selectedTangramRefs.current.teal}>
        <path
          id="eZ2txJ1OlP66"
          d="M24.5623,25.03L24.5623,49.4192L0.173141,25.03L24.5623,25.03Z"
          transform="translate(-16.40615,-33.228124)"
          fill="rgb(10,156,193)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <g ref={selectedTangramRefs.current.purple}>
        <path
          id="eZ2txJ1OlP67"
          d="M73.1042,25.03L73.1042,49.4192L97.4934,25.03L73.1042,25.03Z"
          transform="translate(-80.013445,-33.749496)"
          fill="rgb(177,157,230)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <g ref={selectedTangramRefs.current.orange}>
        <path
          id="eZ2txJ1OlP68"
          d="M36.6934,103.015L12.3318,127.377L36.7485,127.349L61.1101,102.988L36.6934,103.015Z"
          transform="translate(-36.720949,-115.182499)"
          fill="rgb(255,176,119)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}

export default Tangram
