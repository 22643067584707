import React from "react"

const Pause = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 1.58333C5.13 1.58333 1.58333 5.13 1.58333 9.5C1.58333 13.87 5.13 17.4167 9.5 17.4167C13.87 17.4167 17.4167 13.87 17.4167 9.5C17.4167 5.13 13.87 1.58333 9.5 1.58333ZM8.70833 12.6667H7.125V6.33333H8.70833V12.6667ZM10.2917 12.6667H11.875V6.33333H10.2917V12.6667Z"
      fill="var(--smoke)"
    />
  </svg>
)

export default Pause
