import React from "react"

type Props = {
  color?: any
  alt?: string
  size?: number
}

const Planner = (props: Props) => (
  <svg
    width={props.size || 10}
    height={props.size || 10}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{props.alt}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0C0.447715 0 0 0.447715 0 1V9C0 9.55229 0.447715 10 1 10H9C9.55229 10 10 9.55228 10 9V1C10 0.447715 9.55228 0 9 0H1ZM1 1H3V3H1V1ZM1 4H3V6H1V4ZM3 7H1V9H3V7ZM4 1H9V3H4V1ZM9 4H4V6H9V4ZM4 7H9V9H4V7Z"
      fill={props.color || "var(--runn-blue)"}
    />
  </svg>
)

export default Planner
