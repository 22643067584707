import { useFeature } from "flagged"

import { track } from "~/helpers/analytics"

import { getSettings, setSetting } from "~/localsettings"

const useReportVersion = () => {
  const nameQueryParam = new URLSearchParams(window.location.search).get("name")
  const isReportsV2Enabled = !!useFeature("reports_v2")
  const canOnlyAccessReportsV2 = !!useFeature("only_reports_v2")
  const explicitReportSelection = getSettings().reportsVersion

  const setVersionAndReload = (newVersion: "v1" | "v2") => {
    track("Report Version Selected", {
      value: newVersion,
    })
    setSetting("reportsVersion", newVersion)
    window.location.reload()
  }

  const version =
    (isReportsV2Enabled && explicitReportSelection !== "v1") ||
    canOnlyAccessReportsV2
      ? "v2"
      : "v1"

  return {
    version,
    isReportsV2Enabled,
    canOnlyAccessReportsV2,
    setVersionAndReload,
    shouldRenderLegacyReport: nameQueryParam !== null,
  } as const
}

export default useReportVersion
