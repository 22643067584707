import React from "react"

const ProjectTemplateAvatarWhite = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.52229 20.125V3.43063H0.476367V0.475752H16.095V3.43063H10.0355V20.125H6.52229Z"
      fill="var(--runn-blue)"
    />
  </svg>
)

export default ProjectTemplateAvatarWhite
