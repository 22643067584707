import { dateHelpers } from "@runn/calculations"
import { graphql } from "react-relay"
import { RecordProxy, RecordSourceProxy } from "relay-runtime"

import { AssignmentBulkChangesMutation } from "./__generated__/AssignmentBulkChangesMutation.graphql"
import { AssignmentsTransferredMutation } from "./__generated__/AssignmentsTransferredMutation.graphql"

import { environment } from "~/store/hasura"

import {
  addLinkedRecord,
  commitMutationPromise,
  removeLinkedRecord,
} from "./helpers"
import { getLocalId } from "~/helpers/local-id"
import * as relayids from "~/helpers/relayids"

import { RELAY_ASSIGNMENT_LOCATION } from "~/GLOBALVARS"

export type Assignment = {
  person_id: number
  project_id: number
  phase_id: number
  role_id: number
  workstream_id: number
}

type AssignmentRecordProxy = RecordProxy<Assignment>

const assignmentDeleter = (
  store: RecordSourceProxy,
  assignment: AssignmentRecordProxy,
) => {
  if (!assignment) {
    return
  }

  // Remove from persons assignments
  const person = store.get(
    relayids.people.encode(assignment.getValue("person_id")),
  )
  removeLinkedRecord(person, RELAY_ASSIGNMENT_LOCATION, assignment)

  // Remove assignments from project
  const project = store.get(
    relayids.projects.encode(assignment.getValue("project_id")),
  )
  removeLinkedRecord(project, RELAY_ASSIGNMENT_LOCATION, assignment)

  // Remove assignments from phase
  const phase = store.get(
    relayids.phases.encode(assignment.getValue("phase_id")),
  )
  removeLinkedRecord(phase, RELAY_ASSIGNMENT_LOCATION, assignment)

  // Remove assignments from roles
  const role = store.get(relayids.roles.encode(assignment.getValue("role_id")))
  removeLinkedRecord(role, RELAY_ASSIGNMENT_LOCATION, assignment)

  const siblings = assignment.getLinkedRecords("assignments") || []
  siblings.forEach((sibling) =>
    removeLinkedRecord(sibling, RELAY_ASSIGNMENT_LOCATION, assignment),
  )

  store.delete(assignment.getDataID())
}

export const assignmentUpdater = (
  store: RecordSourceProxy,
  assignment: AssignmentRecordProxy,
) => {
  if (!assignment) {
    return
  }
  // Update assignments for people
  const person = store.get(
    relayids.people.encode(assignment.getValue("person_id")),
  )
  addLinkedRecord(person, RELAY_ASSIGNMENT_LOCATION, assignment)

  // Update assignments for projects
  const project = store.get(
    relayids.projects.encode(assignment.getValue("project_id")),
  )
  addLinkedRecord(project, RELAY_ASSIGNMENT_LOCATION, assignment)

  // Update assignment for roles
  const role = store.get(relayids.roles.encode(assignment.getValue("role_id")))
  addLinkedRecord(role, RELAY_ASSIGNMENT_LOCATION, assignment)

  // Update assignment for phases
  const phase = store.get(
    relayids.phases.encode(assignment.getValue("phase_id")),
  )
  addLinkedRecord(phase, RELAY_ASSIGNMENT_LOCATION, assignment)

  // Update assignment for workstreams
  const workstream = store.get(
    relayids.workstreams.encode(assignment.getValue("workstream_id")),
  )
  addLinkedRecord(workstream, RELAY_ASSIGNMENT_LOCATION, assignment)
}

const assignmentBulkChangesMutation = graphql`
  mutation AssignmentBulkChangesMutation($input: AssignmentBulkChangesInput!) {
    action_assignment_bulk_changes(input: $input) {
      status
      id
      assignment {
        id
        person_id
        project_id
        role_id
        workstream_id
        phase_id
        note
        start_date: start_date_runn
        end_date: end_date_runn
        minutes_per_day
        is_billable
        is_template
        total_minutes
        non_working_day
        updated_at
      }
      project {
        id
        calc_end_date
        calc_start_date
        archivable
        members {
          id
          project_id
          person_id
          role_id
          workstream_id
          workstream {
            id
            name
          }
          role {
            id
            name
          }
          person {
            id
            first_name
            last_name
          }
        }
        project_rates {
          id
        }
        summary {
          role_id
          workstream_id
          total_billable_minutes
          total_nonbillable_minutes
        }
        assignments_aggregate {
          aggregate {
            count
          }
        }
        ...ChangeProjectRoleModal_project
      }
      person {
        id
        archivable
        assignments_aggregate {
          aggregate {
            count
          }
        }
        project_memberships {
          id
          workstream_id
          project {
            id
            name
          }
        }
        assignments {
          id
        }
        ...ChangeProjectRoleModal_person
      }
    }
  }
`

type BulkChangeAssignmentsRelayOptions = {
  values: AssignmentBulkChangesMutation["variables"]["input"]
}

export const assignmentBulkChangesRelay = (
  options: BulkChangeAssignmentsRelayOptions,
) => {
  const { values } = options

  const variables = {
    input: values,
    todaysDate: dateHelpers.getTodaysDate(),
  }

  return commitMutationPromise<AssignmentBulkChangesMutation>(environment, {
    mutation: assignmentBulkChangesMutation,
    variables,
    updater: (store, data) => {
      // Before deleting this -- check you can create assignments 1 year in the past
      // Using your new way.
      // We need this crazy updater because of the way we get assignments
      // with a variable. This means we can't return assignments that are before
      // the planner start date, as they would be missing. e.g. This doesn't work:
      // person {assignments(where: { end_date_iso: { _gte: $plannerStartDate } }) { id }

      const payload = data.action_assignment_bulk_changes

      payload.forEach((assignmentData) => {
        const assignmentId = relayids.assignments.encode(assignmentData.id)

        if (assignmentData.status === "deleted") {
          const assignment = store.get<Assignment>(assignmentId)
          assignmentDeleter(store, assignment)
        }

        if (assignmentData.status === "created") {
          const assignment = store.get<Assignment>(assignmentId)
          assignmentUpdater(store, assignment)
          window.userflow?.track("Assignment Created and Updated")
        }

        if (assignmentData.status === "updated") {
          // Updated assignments only change values. So no work need to be done.
          // When project, person, or role changes, we instead delete and create.
        }
      })
    },
    optimisticUpdater: (store) => {
      variables.input.create.forEach((a) => {
        const person = store.get(relayids.people.encode(a.person_id))
        if (!person) {
          // If there no person. Its a place holder. Wait for the response.
          return
        }

        const id = getLocalId()
        const assignmentId = relayids.assignments.encode(id)
        const assignment = store.create(
          assignmentId,
          "assignment",
        ) as AssignmentRecordProxy

        const role = store.get(relayids.roles.encode(a.role_id))
        if (!role) {
          // eslint-disable-next-line no-console
          console.debug(
            `assignmentBulkChangesRelay: role id=${a.role_id} on create is null`,
          )
          return
        }

        const project = store.get(relayids.projects.encode(a.project_id))
        if (!project) {
          // eslint-disable-next-line no-console
          console.debug(
            `assignmentBulkChangesRelay: project id=${a.project_id} on create is null`,
          )
          return
        }

        const client_id = project.getValue("client_id")
        assignment.setValue(id, "id")
        assignment.setValue(`${a.start_date}`, "start_date_runn")
        assignment.setValue(`${a.end_date}`, "end_date_runn")
        assignment.setValue(a.minutes_per_day, "minutes_per_day")
        assignment.setValue(a.person_id, "person_id")
        assignment.setValue(a.project_id, "project_id")
        assignment.setValue(a.role_id, "role_id")
        assignment.setValue(a.workstream_id, "workstream_id")
        assignment.setValue(a.is_billable, "is_billable")
        assignment.setValue(a.is_template, "is_template")
        assignment.setValue(a.non_working_day, "non_working_day")
        assignment.setValue(a.note, "note")
        assignment.setValue(client_id, "client_id")
        assignment.setValue(person.getValue("is_placeholder"), "is_placeholder")
        assignment.setValue(a.phase_id, "phase_id")
        assignment.setLinkedRecord(role, "role")
        assignment.setLinkedRecord(person, "person")

        assignmentUpdater(store, assignment)
      })

      // Only optimistic update when doing a multi-select
      // As we can 'fake' update a single movement in react UI.
      if (variables.input.update.length > 1) {
        variables.input.update.forEach((a) => {
          const assignment = store.get<Assignment>(
            relayids.assignments.encode(a.id),
          )
          if (!assignment) {
            // eslint-disable-next-line no-console
            console.debug(
              `assignmentBulkChangesRelay: assignment id=${a.id} on update is null`,
            )
            return
          }

          const person = store.get(relayids.people.encode(a.person_id))
          if (!person) {
            // eslint-disable-next-line no-console
            console.debug(
              `assignmentBulkChangesRelay: person id=${a.person_id} on update is null`,
            )
            return
          }

          assignment.setValue(`${a.start_date}`, "start_date_runn")
          assignment.setValue(`${a.end_date}`, "end_date_runn")
          assignment.setValue(a.minutes_per_day, "minutes_per_day")
          assignment.setValue(a.person_id, "person_id")
          assignment.setValue(a.is_billable, "is_billable")
          assignment.setValue(a.note, "note")
          assignment.setValue(
            person.getValue("is_placeholder"),
            "is_placeholder",
          )
          assignment.setValue(a.phase_id, "phase_id")
          assignment.setValue(a.updated_at, "updated_at")

          assignmentUpdater(store, assignment)
        })
      }

      // Only optimistic update when doing a multi-select
      // As we can 'fake' update a single movement in react UI.
      if (variables.input.delete.length > 1) {
        variables.input.delete.forEach((a) => {
          const assignment = store.get<Assignment>(
            relayids.assignments.encode(a.id),
          )
          if (!assignment) {
            // eslint-disable-next-line no-console
            console.debug(
              `assignmentBulkChangesRelay: assignment id=${a.id} on delete is null`,
            )
            return
          }

          assignmentDeleter(store, assignment)
        })
      }
    },
  })
}

const assignmentsTransferredMutation = graphql`
  mutation AssignmentsTransferredMutation(
    $toPersonId: Int!
    $assignments: [AssignmentObjectInput!]!
  ) {
    assignmentTransferred(toPersonId: $toPersonId, assignments: $assignments) {
      person_id
    }
  }
`

type AssignmentsTransferredRelayOptions = {
  values: AssignmentsTransferredMutation["variables"]
}

export const assignmentsTransferredRelay = (
  options: AssignmentsTransferredRelayOptions,
) => {
  const { values } = options

  return commitMutationPromise<AssignmentsTransferredMutation>(environment, {
    mutation: assignmentsTransferredMutation,
    variables: values,
  })
}
