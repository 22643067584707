import { Icon } from "@blueprintjs/core"
import React, { Suspense, useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import { HolidaysContainerQuery } from "./__generated__/HolidaysContainerQuery.graphql"

import { HasuraContextProvider } from "~/store/hasura"

import Dialog from "~/common/Dialog"
import AddButton from "~/common/buttons/AddButton"
import DashboardListsEmptyStateContainer from "~/common/dashboard-lists/DashboardListsEmptyStateContainer"
import {
  TangramPersonBalancing,
  TangramPersonFlying,
  TangramPersonWalking,
} from "~/common/tangrams"

import PermissionsProvider, {
  usePermissionsContext,
} from "~/Permissions/PermissionsProvider"
import { withRelayPageContainer } from "~/RelayPageContainer"
import PageBody from "~/containers/PageBody"
import { Card } from "~/dashboard/Card"
import { PageHeader } from "~/dashboard/PageHeader"

import { HOLIDAYS_DATE_RANGES } from "../GLOBALVARS"

import CreateHolidaysGroupForm from "./Forms/CreateHolidaysGroupForm/CreateHolidaysGroupForm"
import HolidaysGroupList from "./List/HolidaysGroupList"

const HOLIDAYS_CONTAINER_QUERY = graphql`
  query HolidaysContainerQuery($minDate: date!, $maxDate: date!) {
    current_user {
      account {
        id
        holidays_groups(order_by: { name: asc }) {
          id
        }
        ...CreateHolidaysGroupForm_viewer
        ...HolidaysGroupList_viewer
        ...EditHolidaysGroupForm_account
      }
      ...PermissionsProvider_user
    }
  }
`

const HolidaysContainer = () => {
  const [showDialog, setShowDialog] = useState(false)

  const data = useLazyLoadQuery<HolidaysContainerQuery>(
    HOLIDAYS_CONTAINER_QUERY,
    { ...HOLIDAYS_DATE_RANGES },
  )
  const { account } = data.current_user
  const holidaysGroups = account.holidays_groups

  const { can, subject } = usePermissionsContext({
    user: data.current_user,
  })
  const canCreate = can("create", subject("HolidaysGroup"))

  return (
    <PermissionsProvider user={data.current_user}>
      <HasuraContextProvider value={account}>
        <PageHeader
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              Public Holidays
            </div>
          }
          icon={<Icon icon="calendar" size={20} color="var(--runn-blue)" />}
        >
          {canCreate && (
            <AddButton
              text="New Holiday Group"
              data-test="add-holiday-group-button"
              onClick={() => setShowDialog(true)}
            />
          )}
        </PageHeader>
        <PageBody>
          <Card dataTest="holidays-container">
            {holidaysGroups.length ? (
              <HolidaysGroupList />
            ) : (
              <DashboardListsEmptyStateContainer
                tangram={
                  <>
                    <TangramPersonBalancing />
                    <TangramPersonFlying />
                    <TangramPersonWalking />
                  </>
                }
                text="Set up regional public holidays"
                button={
                  canCreate && (
                    <AddButton
                      outlined={false}
                      text="New Holiday Group"
                      onClick={() => setShowDialog(true)}
                    />
                  )
                }
              />
            )}
          </Card>
        </PageBody>
        <Dialog isOpen={showDialog} onClose={() => setShowDialog(false)}>
          <Suspense fallback={<div />}>
            <CreateHolidaysGroupForm closeDialog={() => setShowDialog(false)} />
          </Suspense>
        </Dialog>
      </HasuraContextProvider>
    </PermissionsProvider>
  )
}

export default withRelayPageContainer(HolidaysContainer)
