/**
 * @generated SignedSource<<4435e338da0f323ce74ca8f3018080e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReferencesInput = {
  externalId: string;
  referenceName: string;
};
export type ClientCreateMutation$variables = {
  name: string;
  references?: ReadonlyArray<ReferencesInput> | null | undefined;
  website?: string | null | undefined;
};
export type ClientCreateMutation$data = {
  readonly createClient: {
    readonly client: {
      readonly account: {
        readonly clients: ReadonlyArray<{
          readonly id: number;
        }>;
        readonly id: number;
      };
      readonly account_id: number;
      readonly active: boolean;
      readonly archivable: boolean | null | undefined;
      readonly deletable: boolean | null | undefined;
      readonly id: number;
      readonly image_key: string | null | undefined;
      readonly name: string;
      readonly projects: ReadonlyArray<{
        readonly active: boolean;
      }>;
      readonly real_client: boolean | null | undefined;
      readonly references: any;
      readonly website: string | null | undefined;
    } | null | undefined;
  };
};
export type ClientCreateMutation = {
  response: ClientCreateMutation$data;
  variables: ClientCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "references"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "website"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "references",
        "variableName": "references"
      },
      {
        "kind": "Variable",
        "name": "website",
        "variableName": "website"
      }
    ],
    "concreteType": "CreateClientResult",
    "kind": "LinkedField",
    "name": "createClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "clients",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "website",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archivable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image_key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deletable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "references",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "account_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "real_client",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "projects",
            "plural": true,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "clients",
                "kind": "LinkedField",
                "name": "clients",
                "plural": true,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientCreateMutation",
    "selections": (v5/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientCreateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "a231f5bed47dbaffacbe1871bd8c3b0a",
    "id": null,
    "metadata": {},
    "name": "ClientCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ClientCreateMutation(\n  $name: String!\n  $website: String\n  $references: [ReferencesInput!]\n) {\n  createClient(name: $name, website: $website, references: $references) {\n    client {\n      id\n      name\n      website\n      active\n      archivable\n      image_key\n      deletable\n      references\n      account_id\n      real_client\n      projects {\n        active\n      }\n      account {\n        id\n        clients {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f203a09f90f0cba7d28b1629e375c667";

export default node;
