import React from "react"

const Send = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.755503 11.4745C0.755993 11.8379 1.13169 12.0794 1.46249 11.929L13.4992 6.45515C13.8898 6.27748 13.8898 5.72252 13.4992 5.54485L1.46249 0.071034C1.13169 -0.079398 0.755993 0.162115 0.755503 0.525506L0.750586 4.17625C0.75025 4.4256 0.933682 4.63709 1.18057 4.672L7.07055 5.50493C7.64387 5.586 7.64387 6.414 7.07055 6.49507L1.18057 7.328C0.93368 7.36291 0.75025 7.5744 0.750586 7.82375L0.755503 11.4745Z"
      fill="white"
    />
  </svg>
)

export default Send
