import React from "react"

type Props = {
  size?: number
  color?: string
}

const LinkOff = (props: Props) => {
  const { size = 12, color = "var(--smoke)" } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.866 3.11391L0 1.14339L0.846 0.25L10.89 10.8566L10.044 11.75L7.638 9.20923H6.6V8.11309L5.238 6.67479H3.6V5.40758H4.038L2.796 4.09601C1.866 4.20372 1.14 5.03375 1.14 6.04118C1.14 7.12466 1.974 8.00537 3 8.00537H5.4V9.20923H3C1.344 9.20923 0 7.78995 0 6.04118C0 4.71694 0.774 3.58278 1.866 3.11391ZM8.99961 2.87385H6.59961V4.0777H8.99961C10.0256 4.0777 10.8596 4.95842 10.8596 6.04189C10.8596 6.84657 10.3976 7.54354 9.73761 7.84134L10.5776 8.72839C11.4296 8.17082 11.9996 7.17605 11.9996 6.04189C11.9996 4.29313 10.6556 2.87385 8.99961 2.87385ZM7.43359 5.40683L8.39959 6.42694V5.40683H7.43359Z"
        fill={color}
      />
    </svg>
  )
}

export default LinkOff
