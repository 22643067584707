import { useContext } from "react"

import {
  Can,
  PermissionsContext,
  PermissionsContextType,
} from "./PermissionsProvider"

export const usePermissions = () => {
  const context = useContext<PermissionsContextType | null>(PermissionsContext)
  if (!context) {
    throw new Error("usePermissions must be used within an PermissionsProvider")
  }

  return { Can, ...context }
}
