/**
 * @generated SignedSource<<b7c93485421b56dec89eb7490b9ef671>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentBulkChangesInput = {
  create?: ReadonlyArray<AssignmentUpdate | null | undefined> | null | undefined;
  delete?: ReadonlyArray<AssignmentUpdate | null | undefined> | null | undefined;
  update?: ReadonlyArray<AssignmentUpdate | null | undefined> | null | undefined;
};
export type AssignmentUpdate = {
  end_date?: string | null | undefined;
  id?: number | null | undefined;
  is_billable?: boolean | null | undefined;
  is_template?: boolean | null | undefined;
  minutes_per_day?: number | null | undefined;
  non_working_day?: boolean | null | undefined;
  note?: string | null | undefined;
  person_id?: number | null | undefined;
  phase_id?: number | null | undefined;
  project_id?: number | null | undefined;
  role_id?: number | null | undefined;
  start_date?: string | null | undefined;
  total_minutes?: number | null | undefined;
  updated_at?: string | null | undefined;
  workstream_id?: number | null | undefined;
};
export type AssignmentBulkChangesMutation$variables = {
  input: AssignmentBulkChangesInput;
};
export type AssignmentBulkChangesMutation$data = {
  readonly action_assignment_bulk_changes: ReadonlyArray<{
    readonly assignment: {
      readonly end_date: string | null | undefined;
      readonly id: number;
      readonly is_billable: boolean | null | undefined;
      readonly is_template: boolean;
      readonly minutes_per_day: number;
      readonly non_working_day: boolean;
      readonly note: string | null | undefined;
      readonly person_id: number;
      readonly phase_id: number | null | undefined;
      readonly project_id: number;
      readonly role_id: number;
      readonly start_date: string | null | undefined;
      readonly total_minutes: number | null | undefined;
      readonly updated_at: string;
      readonly workstream_id: number | null | undefined;
    } | null | undefined;
    readonly id: number | null | undefined;
    readonly person: {
      readonly archivable: boolean | null | undefined;
      readonly assignments: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly assignments_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
      readonly id: number;
      readonly project_memberships: ReadonlyArray<{
        readonly id: number;
        readonly project: {
          readonly id: number;
          readonly name: string;
        };
        readonly workstream_id: number | null | undefined;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectRoleModal_person">;
    } | null | undefined;
    readonly project: {
      readonly archivable: boolean | null | undefined;
      readonly assignments_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
      readonly calc_end_date: string | null | undefined;
      readonly calc_start_date: string | null | undefined;
      readonly id: number;
      readonly members: ReadonlyArray<{
        readonly id: number;
        readonly person: {
          readonly first_name: string;
          readonly id: number;
          readonly last_name: string;
        };
        readonly person_id: number;
        readonly project_id: number;
        readonly role: {
          readonly id: number;
          readonly name: string | null | undefined;
        };
        readonly role_id: number;
        readonly workstream: {
          readonly id: number;
          readonly name: string;
        } | null | undefined;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly project_rates: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly summary: ReadonlyArray<{
        readonly role_id: number | null | undefined;
        readonly total_billable_minutes: number | null | undefined;
        readonly total_nonbillable_minutes: number | null | undefined;
        readonly workstream_id: number | null | undefined;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"ChangeProjectRoleModal_project">;
    } | null | undefined;
    readonly status: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export type AssignmentBulkChangesMutation = {
  response: AssignmentBulkChangesMutation$data;
  variables: AssignmentBulkChangesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "person_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workstream_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "assignments",
  "kind": "LinkedField",
  "name": "assignment",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phase_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": "start_date",
      "args": null,
      "kind": "ScalarField",
      "name": "start_date_runn",
      "storageKey": null
    },
    {
      "alias": "end_date",
      "args": null,
      "kind": "ScalarField",
      "name": "end_date_runn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minutes_per_day",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_billable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total_minutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "non_working_day",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated_at",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calc_end_date",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calc_start_date",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archivable",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = [
  (v3/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "project_members",
  "kind": "LinkedField",
  "name": "members",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v5/*: any*/),
    (v4/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "workstreams",
      "kind": "LinkedField",
      "name": "workstream",
      "plural": false,
      "selections": (v13/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "roles",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": (v13/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "people",
      "kind": "LinkedField",
      "name": "person",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = [
  (v3/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "project_rates",
  "kind": "LinkedField",
  "name": "project_rates",
  "plural": true,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "v_summary_total",
  "kind": "LinkedField",
  "name": "summary",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total_billable_minutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total_nonbillable_minutes",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "assignments_aggregate",
  "kind": "LinkedField",
  "name": "assignments_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "assignments_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "projects",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "assignments",
  "kind": "LinkedField",
  "name": "assignments",
  "plural": true,
  "selections": (v17/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignmentBulkChangesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignmentBulkChangesOutput",
        "kind": "LinkedField",
        "name": "action_assignment_bulk_changes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ChangeProjectRoleModal_project"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v11/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project_members",
                "kind": "LinkedField",
                "name": "project_memberships",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v22/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ChangeProjectRoleModal_person"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssignmentBulkChangesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssignmentBulkChangesOutput",
        "kind": "LinkedField",
        "name": "action_assignment_bulk_changes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "projects",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "people",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v11/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project_members",
                "kind": "LinkedField",
                "name": "project_memberships",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v21/*: any*/),
                  (v6/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "has_assignments",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v22/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "355e7226813c2c39c7f5384564a651f6",
    "id": null,
    "metadata": {},
    "name": "AssignmentBulkChangesMutation",
    "operationKind": "mutation",
    "text": "mutation AssignmentBulkChangesMutation(\n  $input: AssignmentBulkChangesInput!\n) {\n  action_assignment_bulk_changes(input: $input) {\n    status\n    id\n    assignment {\n      id\n      person_id\n      project_id\n      role_id\n      workstream_id\n      phase_id\n      note\n      start_date: start_date_runn\n      end_date: end_date_runn\n      minutes_per_day\n      is_billable\n      is_template\n      total_minutes\n      non_working_day\n      updated_at\n    }\n    project {\n      id\n      calc_end_date\n      calc_start_date\n      archivable\n      members {\n        id\n        project_id\n        person_id\n        role_id\n        workstream_id\n        workstream {\n          id\n          name\n        }\n        role {\n          id\n          name\n        }\n        person {\n          id\n          first_name\n          last_name\n        }\n      }\n      project_rates {\n        id\n      }\n      summary {\n        role_id\n        workstream_id\n        total_billable_minutes\n        total_nonbillable_minutes\n      }\n      assignments_aggregate {\n        aggregate {\n          count\n        }\n      }\n      ...ChangeProjectRoleModal_project\n    }\n    person {\n      id\n      archivable\n      assignments_aggregate {\n        aggregate {\n          count\n        }\n      }\n      project_memberships {\n        id\n        workstream_id\n        project {\n          id\n          name\n        }\n      }\n      assignments {\n        id\n      }\n      ...ChangeProjectRoleModal_person\n    }\n  }\n}\n\nfragment ChangeProjectRoleModal_person on people {\n  id\n  first_name\n  last_name\n  project_memberships {\n    id\n    role_id\n    workstream_id\n    project_id\n    has_assignments\n  }\n}\n\nfragment ChangeProjectRoleModal_project on projects {\n  id\n  name\n  members {\n    id\n    role_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f54ab3d302e9cee0b555642c496f973";

export default node;
