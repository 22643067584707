import React from "react"

import Select, { ReactSelectProps } from "~/common/Select"

import { FINANCIAL_PERMISSIONS, FinancialPermissionsOption } from "~/GLOBALVARS"

type Props = ReactSelectProps & {
  onChange: (value: FinancialPermissionsOption) => void
  value: FinancialPermissionsOption
  isDisabled?: boolean
}

const SelectFinancialPermission = (props: Props) => {
  const { onChange, value, label, name, id, ...rest } = props

  const selectedFinancialPermission = value
    ? FINANCIAL_PERMISSIONS.find((p) => p.value === value)
    : FINANCIAL_PERMISSIONS[2]

  const handleChange = (e: FinancialPermissionsOption) => {
    onChange(e)
  }

  return (
    <Select
      name={name || "user-financial-permission"}
      id={id || "user-financial-permission"}
      value={selectedFinancialPermission}
      onChange={handleChange}
      label={label || ""}
      options={FINANCIAL_PERMISSIONS}
      placeholder="Select Financial Permission"
      doNotSortOptions
      {...rest}
    />
  )
}

export default SelectFinancialPermission
