/**
 * @generated SignedSource<<900fa9cfa469f5356bf316d4136e9a3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SkillDeleteMutation$variables = {
  id: number;
};
export type SkillDeleteMutation$data = {
  readonly deleteSkill: {
    readonly account: {
      readonly competencies: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly id: number;
      readonly skills: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
  };
};
export type SkillDeleteMutation = {
  response: SkillDeleteMutation$data;
  variables: SkillDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DeleteSkillResult",
    "kind": "LinkedField",
    "name": "deleteSkill",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "skills",
            "kind": "LinkedField",
            "name": "skills",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "competencies",
            "kind": "LinkedField",
            "name": "competencies",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillDeleteMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillDeleteMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "00404a67d5054ff08b029500f96ec660",
    "id": null,
    "metadata": {},
    "name": "SkillDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation SkillDeleteMutation(\n  $id: Int!\n) {\n  deleteSkill(id: $id) {\n    account {\n      id\n      skills {\n        id\n      }\n      competencies {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eaf6c650806f73509bcfa93df6f3121a";

export default node;
