import cc from "classcat"
import React from "react"

import styles from "./ModalFormWrapper.module.css"

import { TangramTriangle } from "~/common/tangrams"

import ModalHeader from "./ModalHeader"

type Props = {
  headerTitle?: string | React.ReactNode
  headerSubTitle?: string
  helpLink?: string
  children: React.ReactNode
  wide?: boolean
  wider?: boolean
  autoWidth?: boolean
  tangram?: React.ReactNode
  tangramStyles?: React.CSSProperties
  onCloseClick?: () => void
  "data-test"?: string
}

const ModalFormWrapper = (props: Props) => {
  const {
    headerTitle,
    headerSubTitle,
    helpLink,
    children,
    wide,
    wider,
    autoWidth,
    tangramStyles,
    onCloseClick,
  } = props

  return (
    <div
      className={cc([
        styles.wrapper,
        {
          [styles.wrapperWide]: wide,
          [styles.wrapperWider]: wider,
          [styles.wrapperAutoWidth]: autoWidth,
        },
      ])}
      data-test={props["data-test"] || "modal-form"}
    >
      <div className={styles.panelLeft}>
        <div className={styles.tangram} style={tangramStyles}>
          {props.tangram || <TangramTriangle />}
        </div>
      </div>
      <div className={styles.panelRight}>
        {headerTitle && (
          <ModalHeader
            text={headerTitle}
            subText={headerSubTitle}
            helpLink={helpLink}
            onCloseClick={onCloseClick}
          />
        )}
        {children}
      </div>
    </div>
  )
}

export default ModalFormWrapper
