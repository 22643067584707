import React from "react"

type Props = React.SVGProps<SVGSVGElement> & {
  color?: any
  innerColor?: any
  alt?: string
  solidWhiteMark?: boolean
}
const Warning = (props: Props) => {
  const { color, innerColor, alt, solidWhiteMark, ...svgProps } = props
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <title>{alt}</title>
      {solidWhiteMark && <rect x="7" y="5" width="4" height="8" fill="white" />}
      <path
        fill={color || "var(--warning)"}
        fillRule="nonzero"
        d="M7.04723 0.808984L0.821054 12.1715C0.427304 12.8816 0.951132 13.75 1.77379 13.75H14.2296C15.0488 13.75 15.5726 12.8816 15.1824 12.1715L8.95269 0.808984C8.54137 0.0636719 7.45855 0.0636719 7.04723 0.808984V0.808984ZM8.61871 5.52344L8.49215 9.8125H7.50777L7.38121 5.52344H8.61871ZM7.99996 12.1469C7.62379 12.1469 7.32848 11.8621 7.32848 11.5C7.32848 11.1379 7.62379 10.8531 7.99996 10.8531C8.37613 10.8531 8.67144 11.1379 8.67144 11.5C8.67144 11.8621 8.37613 12.1469 7.99996 12.1469Z"
      />
      {innerColor && (
        <path
          fill={innerColor || "var(--warning)"}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.49215 9.8125L8.61871 5.52344H7.38121L7.50777 9.8125H8.49215ZM7.32848 11.5C7.32848 11.8621 7.62379 12.1469 7.99996 12.1469C8.37613 12.1469 8.67144 11.8621 8.67144 11.5C8.67144 11.1379 8.37613 10.8531 7.99996 10.8531C7.62379 10.8531 7.32848 11.1379 7.32848 11.5Z"
        />
      )}
    </svg>
  )
}

export default Warning
