import React from "react"

import styles from "./DeleteUserForm.module.css"

import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import * as hashids from "~/helpers/hashids"
import { formatName } from "~/helpers/person"
import routes from "~/helpers/routes"

import DeleteModal from "~/Modals/Delete/DeleteModal"

type Props = {
  user: {
    first_name: string
    last_name: string
    id: number
  }
  accountName: string
  isSelf: boolean
  isInTestAccount?: boolean
  onClose: () => void
  isMemberOfOtherAccounts?: boolean
}

const DeleteUserForm = (props: Props) => {
  const {
    user: { first_name, last_name, id },
    isSelf,
    isInTestAccount,
    onClose,
    accountName,
    isMemberOfOtherAccounts,
  } = props
  const hashid = hashids.users.encode(id)

  const onDelete = async () => {
    if (isInTestAccount) {
      // If the user is in the test account we can't delete them
      // So we toggle them to the live account then delete them
      await fetch(routes.toggleUserTestAccount(hashid), {
        method: "GET",
        redirect: "manual",
      })
    }
    await fetchWithCsrf(routes.viewUserUrl(hashid), {
      method: "DELETE",
      redirect: "manual",
    })

    if (isSelf) {
      document.location.href = routes.selfDeletedUrl()
    } else {
      document.location.href = routes.userListUrl()
    }
  }

  const deleteSelfMessage = isMemberOfOtherAccounts ? (
    <>
      <p>
        Deleting your account will <b>remove your access</b> to{" "}
        <b>{accountName}</b>.
      </p>
      <p>You will still be able to access your other account/s.</p>
    </>
  ) : (
    <span>
      Deleting your account will <b>remove your access to Runn</b> and you will{" "}
      <b>no longer be able to log in.</b>
    </span>
  )

  const deleteOtherMessage = (
    <>
      <p>
        Deleting this user will <b>remove their access</b> to{" "}
        <b>{accountName}</b>.
      </p>
    </>
  )
  const warning = isSelf ? deleteSelfMessage : deleteOtherMessage
  const name = formatName(first_name, last_name)

  const confirmation = (
    <p>
      If you are sure you want to delete <b>{name}</b> as a user on{" "}
      <b>{accountName}</b>, please type{" "}
      <b className={`notranslate ${styles.red}`}>DELETE</b> below.
    </p>
  )

  return (
    <DeleteModal
      type="user account"
      name={name}
      closeDialog={onClose}
      onSubmit={onDelete}
      isAdmin={true}
      adminCanDelete
      deletable
      customWarning={warning}
      customHeader="Delete User Account"
      customConfirmation={confirmation}
    />
  )
}

export default DeleteUserForm
