/**
 * @generated SignedSource<<aeeed5a2ccdff3ff114cf49e98ccaf5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreateHolidaysGroupFormQuery$variables = Record<PropertyKey, never>;
export type CreateHolidaysGroupFormQuery$data = {
  readonly action_countries_get: {
    readonly countries: ReadonlyArray<any> | null | undefined;
  } | null | undefined;
};
export type CreateHolidaysGroupFormQuery = {
  response: CreateHolidaysGroupFormQuery$data;
  variables: CreateHolidaysGroupFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CountriesGetOutput",
    "kind": "LinkedField",
    "name": "action_countries_get",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countries",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateHolidaysGroupFormQuery",
    "selections": (v0/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CreateHolidaysGroupFormQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "fc4544e85e64de699f8b8d0779903c54",
    "id": null,
    "metadata": {},
    "name": "CreateHolidaysGroupFormQuery",
    "operationKind": "query",
    "text": "query CreateHolidaysGroupFormQuery {\n  action_countries_get {\n    countries\n  }\n}\n"
  }
};
})();

(node as any).hash = "58ae194abcb1c76bc4c5fe33b9834924";

export default node;
