import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay/hooks"

import { NavigationContainerQuery } from "./__generated__/NavigationContainerQuery.graphql"

import EntitlementsProvider from "~/Entitlements/EntitlementsProvider"
import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"
import ServerFlagsProvider from "~/ServerFlags/ServerFlagsProvider"

import Navigation, { Props } from "./Navigation"

const NAVIGATION_CONTAINER_QUERY = graphql`
  query NavigationContainerQuery {
    billing(syncSubscription: false) {
      ...EntitlementsProvider_billing
    }
    ...ServerFlagsProvider_gql
  }
`

type ContainerProps = Props & {
  handlePageChange: (page: string) => void
  activePage: string
}

const NavigationContainer = (props: ContainerProps) => {
  const data = useLazyLoadQuery<NavigationContainerQuery>(
    NAVIGATION_CONTAINER_QUERY,
    {},
  )

  const { handlePageChange } = props

  // If planner ("/"), add `_planner` to the active page.
  const activePage =
    window.location.pathname === "/"
      ? `${props.activePage}_planner`
      : props.activePage

  return (
    <ServerFlagsProvider gql={data}>
      <EntitlementsProvider billing={data.billing}>
        <Navigation
          {...props}
          pageToggle={handlePageChange}
          activePage={activePage}
        />
      </EntitlementsProvider>
    </ServerFlagsProvider>
  )
}

export default withRelayPageContainerNoLoading(NavigationContainer)
