/**
 * @generated SignedSource<<af0780600263cd5d631ca03f983a67e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignmentObjectInput = {
  id: number;
  person_id: number;
  project_id: number;
};
export type AssignmentsTransferredMutation$variables = {
  assignments: ReadonlyArray<AssignmentObjectInput>;
  toPersonId: number;
};
export type AssignmentsTransferredMutation$data = {
  readonly assignmentTransferred: ReadonlyArray<{
    readonly person_id: number;
  }>;
};
export type AssignmentsTransferredMutation = {
  response: AssignmentsTransferredMutation$data;
  variables: AssignmentsTransferredMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignments"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toPersonId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "assignments",
        "variableName": "assignments"
      },
      {
        "kind": "Variable",
        "name": "toPersonId",
        "variableName": "toPersonId"
      }
    ],
    "concreteType": "AssignmentTransferredOutput",
    "kind": "LinkedField",
    "name": "assignmentTransferred",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "person_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignmentsTransferredMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssignmentsTransferredMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2e18055d67871f8826531a3c51aece6e",
    "id": null,
    "metadata": {},
    "name": "AssignmentsTransferredMutation",
    "operationKind": "mutation",
    "text": "mutation AssignmentsTransferredMutation(\n  $toPersonId: Int!\n  $assignments: [AssignmentObjectInput!]!\n) {\n  assignmentTransferred(toPersonId: $toPersonId, assignments: $assignments) {\n    person_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2bc7ec10353c6ebd913cf59af2a4e87f";

export default node;
