import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React, { useLayoutEffect, useRef, useState } from "react"

import styles from "./TooltipEllipsis.module.css"

type Props = {
  text: string | JSX.Element
  // secondaryText can be used when details or hints needs to be displayed alongside overflowed text
  // Will display underneath the overflowed text.
  secondaryText?: string | JSX.Element
  className?: string
  style?: Record<string, any>
  href?: string
  onClick?: (e: MouseEvent) => void
  // useTooltip="never" can be used to keep consistency in UIs that only sometimes need a tooltip,
  // but always want to benefit from the ellipsis styling.
  useTooltip?: "overflow" | "always" | "never"
  customMaxWidth?: string
  placement?: "top" | "bottom" | "left" | "right" | "auto"
  matchTargetWidth?: boolean
}

const TooltipEllipsis = (props: Props) => {
  const {
    text,
    secondaryText,
    href,
    onClick,
    useTooltip = "overflow",
    className,
    customMaxWidth,
    placement = "auto",
    matchTargetWidth,
    ...rest
  } = props

  const [textOverflows, setTextOverflows] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (textRef.current) {
      setTextOverflows(
        textRef.current.offsetWidth < textRef.current.scrollWidth,
      )
    }
  }, [textRef, text])

  const handleOnClick = (e) => {
    e.stopPropagation()
    if (onClick) {
      onClick(e)
    }
  }

  const renderDueToOverflow =
    textOverflows && ["always", "overflow"].includes(useTooltip)

  const renderTooltip =
    renderDueToOverflow || useTooltip === "always" || secondaryText

  const content = (
    <>
      {(renderDueToOverflow || useTooltip === "always") && <div>{text}</div>}
      {secondaryText && <div>{secondaryText}</div>}
    </>
  )

  return (
    <>
      {renderTooltip ? (
        <Tooltip
          content={content}
          className={styles.tooltipOverflow}
          placement={placement}
          matchTargetWidth={matchTargetWidth}
          fill={true}
        >
          <div
            className={styles.tooltipEllipsis}
            style={{ maxWidth: customMaxWidth || "100%" }}
          >
            <div
              ref={textRef}
              className={cc([styles.text, className])}
              {...rest}
            >
              {href || onClick ? (
                <a href={href} onClick={handleOnClick}>
                  {text}
                </a>
              ) : (
                text
              )}
            </div>
          </div>
        </Tooltip>
      ) : (
        <div
          className={styles.tooltipEllipsis}
          style={{ maxWidth: customMaxWidth || "100%" }}
        >
          <div ref={textRef} className={cc([styles.text, className])} {...rest}>
            {href || onClick ? (
              <a href={href} onClick={handleOnClick}>
                {text}
              </a>
            ) : (
              text
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default TooltipEllipsis
