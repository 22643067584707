import React from "react"

const Group = () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.29626 0.101868C1.71048 0.101868 2.04626 0.437654 2.04626 0.851868V8.62965C2.04626 9.04386 1.71048 9.37965 1.29626 9.37965C0.882051 9.37965 0.546265 9.04386 0.546265 8.62965V0.851868C0.546265 0.437654 0.882051 0.101868 1.29626 0.101868Z"
      fill="#707593"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.14811 0.333374C3.71855 0.333374 3.37033 0.681597 3.37033 1.11115C3.37033 1.54071 3.71855 1.88893 4.14811 1.88893H11.9259C12.3554 1.88893 12.7037 1.54071 12.7037 1.11115C12.7037 0.681597 12.3554 0.333374 11.9259 0.333374H4.14811Z"
      fill="#707593"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.14811 3.96295C3.71855 3.96295 3.37033 4.31117 3.37033 4.74073C3.37033 5.17028 3.71855 5.51851 4.14811 5.51851H11.9259C12.3554 5.51851 12.7037 5.17028 12.7037 4.74073C12.7037 4.31117 12.3554 3.96295 11.9259 3.96295H4.14811Z"
      fill="#707593"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.14811 7.59259C3.71855 7.59259 3.37033 7.94081 3.37033 8.37037C3.37033 8.79992 3.71855 9.14815 4.14811 9.14815H11.9259C12.3554 9.14815 12.7037 8.79992 12.7037 8.37037C12.7037 7.94081 12.3554 7.59259 11.9259 7.59259H4.14811Z"
      fill="#707593"
    />
  </svg>
)

export default Group
