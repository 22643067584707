import React, { useEffect } from "react"

import styles from "./LoadingSkeleton.module.css"

import CalendarOutline from "~/common/calendar/CalendarOutline"

import PlannerGrid from "~/Planner/PlannerGrid"
import PlannerRightColumn from "~/Planner/PlannerLayout/PlannerRightColumn"

import Tangram, {
  TangramType,
  flower,
  heart,
  mountain,
  runner,
  trophy,
} from "./Tangram"

type Props = {
  height?: number
  showCalenderGrid?: boolean
}

export const SkeletonRow = ({
  height = 55,
  showCalenderGrid = false,
}: Props) => (
  <>
    <div
      className={`${styles.skeletonRow} pulse`}
      style={{
        height: height,
      }}
    >
      <div className={styles.circle} />
      <div className={styles.loadingSkeleton} />
    </div>
    {showCalenderGrid ? (
      <PlannerRightColumn>
        <CalendarOutline type="standard" />
      </PlannerRightColumn>
    ) : null}
  </>
)

const randomInt = (max: number): number => Math.floor(Math.random() * max)

type Message = {
  title?: string
  text: string
  tangram: TangramType
}

const messages: Message[] = [
  {
    text: "Do the mahi, get the treats",
    tangram: trophy,
  },
  {
    text: "Runn Forrest Runn",
    tangram: runner,
  },
  {
    text: "ba bump, ba bump, ba bump...",
    tangram: heart,
  },
  {
    title: "Breathe...",
    text: "Stop and smell the roses",
    tangram: flower,
  },
  {
    title: "Life's an adventure...",
    text: "Dare to dream",
    tangram: mountain,
  },
]

type MessageBoxProps = {
  embed?: boolean
}

export const MessageBox = (props: MessageBoxProps) => {
  const { embed } = props
  const messageIndex = React.useRef<number>(randomInt(messages.length))

  const titleRef = React.useRef<HTMLDivElement>(null)
  const descriptionRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    const messageDuration = 3000

    const setNewMessage = () => {
      const currentIndex = messageIndex.current

      const nextMessage =
        currentIndex + 1 >= messages.length ? 0 : currentIndex + 1

      messageIndex.current = nextMessage

      if (titleRef.current) {
        titleRef.current.innerText = messages[nextMessage].title ?? "Running..."
      }

      if (descriptionRef.current) {
        descriptionRef.current.innerText = messages[nextMessage].text
      }
    }

    const interval = setInterval(() => {
      setNewMessage()
    }, messageDuration)

    setNewMessage()

    return () => clearInterval(interval)
  }, [messageIndex])

  return (
    <div className={`${styles.messageBox} ${embed ? styles.embed : ""}`}>
      <Tangram
        initialTangramIndex={messageIndex.current}
        tangrams={messages.map((m) => m.tangram)}
        className="heartbeat"
        style={{ width: 60 }}
        intervalMs={3000}
      />
      <div>
        <div ref={titleRef} className={styles.textLarge}>
          Running...
        </div>
        <div ref={descriptionRef} className={styles.text} />
      </div>
    </div>
  )
}

const LoadingSkeleton = () => {
  return (
    <>
      <PlannerGrid className={styles.plannerHeaderSkeleton}>
        <div className={styles.pageControlsSkeleton} />
        <div className={styles.filterSkeleton} />
        <div className={styles.timelineSkeleton} />
      </PlannerGrid>
      <PlannerGrid className={styles.container}>
        <div style={{ height: "calc(100vh - 138px)" }}>
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.weekendLines} />
          <div className={styles.weekendLines} />
          <div className={styles.weekendLines} />
          <div className={styles.weekendLines} />
        </div>
      </PlannerGrid>
      <MessageBox />
    </>
  )
}

export default LoadingSkeleton
