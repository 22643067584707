import { omit } from "lodash-es"

import {
  bulkAssignmentDelete,
  bulkCalculateAssignmentUpdates,
} from "./common/Pill/AssignmentActionHelpers"
import { Assignment, TimeOff } from "./common/PillActions/PillActions"

import GLOBAL from "./GLOBALVARS"

const ENABLE_MULTI_SELECT = "mode/enableMultiSelect"
const DISABLE_MULTI_SELECT = "mode/disableMultiSelect"
const DISABLE_MULTI_SELECT_RESET_ITEMS = "mode/disableResetMultiSelect"
const ADD_ITEMS_TO_MODE = "mode/additem"
const REMOVE_ITEMS_FROM_MODE = "mode/removeitem"
const RESET_ITEMS = "mode/resetItems"
const SET_MODE_ACTION = "mode/modeAction"
const ADD_UPDATED_ITEMS = "mode/addUpdatedItems"
const SET_PHASE_TO_ADD = "mode/setPhaseToAdd"
const SET_MOVED_BY = "mode/setMovedBy"

export type SplitScreenModeAction = "transfer" | "clone"

export type ModeAction =
  | "move"
  | "delete"
  | "transfer"
  | "clone"
  | "addPhase"
  | null

export const isSplitScreenMode = (modeAction: ModeAction) => {
  return modeAction === "transfer" || modeAction === "clone"
}

export type Item =
  | (Assignment & { minutes: number; minutesLessTimeOff: number })
  | (TimeOff & { minutes: number; minutesLessTimeOff: number })

// IMPORTANT:
// Mode.reducer.ts is essentially a MultiSelect reducer.
// I haven't renamed the file itself for the sake of keeping history
// At some point we will/should move this to redux toolkit.

// Multi-select has multiple branches that has different actions:
// -- Transfer
// -- Clone
// -- Move
// -- Delete
// -- Add Phase

export type MultiSelectState = {
  isEnabled: boolean
  items: Item[]
  updatedItems: any[]
  modeAction: ModeAction
  movedBy: number
  combinedMinutes: number
  combinedMinutesLessTimeOff: number
  phaseIdToAdd: number
  personRequestStatus: string | null
  entirePlaceholderTransfer: boolean
}

const initialState = {
  isEnabled: false,
  items: [],
  updatedItems: [],
  modeAction: null,
  movedBy: 0,
  combinedMinutes: 0,
  combinedMinutesLessTimeOff: 0,
  phaseIdToAdd: null,
  personRequestStatus: null,
  entirePlaceholderTransfer: false,
}

export default (
  state: MultiSelectState = initialState,
  action,
): MultiSelectState => {
  switch (action.type) {
    case ENABLE_MULTI_SELECT: {
      return {
        ...state,
        isEnabled: true,
        personRequestStatus: action.payload || null,
        updatedItems: [], // safety in case we have lingering updatedItems
      }
    }

    case DISABLE_MULTI_SELECT: {
      // We intentionally don't want to reset the items
      // Because they might have a callback for themselves (see ADD_UPDATED_ITEMS below)
      // "the item itself calculates its changes"
      GLOBAL.INTERACTING_WITH_PILL = false
      return {
        ...state,
        isEnabled: false,
        movedBy: 0,
        combinedMinutes: 0,
        combinedMinutesLessTimeOff: 0,
        personRequestStatus: null,
        entirePlaceholderTransfer: false,
      }
    }

    case DISABLE_MULTI_SELECT_RESET_ITEMS: {
      GLOBAL.INTERACTING_WITH_PILL = false
      return {
        ...state,
        items: [],
        isEnabled: false,
        modeAction: null,
        movedBy: 0,
        combinedMinutes: 0,
        combinedMinutesLessTimeOff: 0,
        personRequestStatus: null,
        entirePlaceholderTransfer: false,
      }
    }

    case RESET_ITEMS: {
      return {
        ...state,
        items: [],
        combinedMinutes: 0,
        combinedMinutesLessTimeOff: 0,
      }
    }

    case ADD_ITEMS_TO_MODE: {
      if (state.items.find((x) => x.id === action.payload.id)) {
        return state
      }

      return {
        ...state,
        items: [...state.items, action.payload],
        combinedMinutes: state.combinedMinutes + action.payload.minutes,
        combinedMinutesLessTimeOff:
          state.combinedMinutesLessTimeOff + action.payload.minutesLessTimeOff,
        updatedItems: [], // safety in case we have lingering updatedItems
      }
    }

    case ADD_UPDATED_ITEMS: {
      /*
        Because we track multi select in redux, we need to figure out via redux when to fire off the changes.
        When someone finished the multi select action, the item itself calculates its changes then fires ADD_UPDATED_ITEMS action
        passing itself.
        When there is the same number of items in `items` and `updatedItems`. We know we have all the updated items, and what they should
        look like. We we then send all this items to the bulk update function, and reset the redux store.
     */
      const { item, isConsistentTimeOffEnabled } = action.payload
      // Safety encase we try to add he same item twice
      if (state.updatedItems.find((a) => a.id === item.id)) {
        return { ...state }
      }

      // If we have all the updated items, and they are move items. Then send bulk delete and reset store
      if (
        state.modeAction === "move" &&
        state.items.length === state.updatedItems.length + 1
      ) {
        const stateItemIds = state.items.map((i) => i.id)
        void bulkCalculateAssignmentUpdates(
          [...state.updatedItems, item],
          stateItemIds,
          isConsistentTimeOffEnabled,
        )
        return {
          ...state,
          updatedItems: [],
          items: [],
          modeAction: null,
          movedBy: 0,
          combinedMinutes: 0,
          combinedMinutesLessTimeOff: 0,
        }
      }

      return {
        ...state,
        updatedItems: [...state.updatedItems, item],
      }
    }

    case REMOVE_ITEMS_FROM_MODE: {
      if (!state.items.find((x) => x.id === action.payload.id)) {
        return state
      }

      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload.id),
        combinedMinutes: state.combinedMinutes - action.payload.minutes,
        combinedMinutesLessTimeOff:
          state.combinedMinutesLessTimeOff - action.payload.minutesLessTimeOff,
      }
    }

    case SET_MODE_ACTION: {
      if (
        action.payload.modeAction === "delete" &&
        action.payload.type === "assignment"
      ) {
        void bulkAssignmentDelete(
          state.items.map((item) =>
            omit(item, "minutes", "minutesLessTimeOff"),
          ) as Assignment[],
        )
        return {
          ...state,
          updatedItems: [],
          items: [],
          modeAction: null,
          movedBy: 0,
          combinedMinutes: 0,
          combinedMinutesLessTimeOff: 0,
        }
      }
      return {
        ...state,
        modeAction: action.payload.modeAction,
        entirePlaceholderTransfer:
          action.payload.entirePlaceholderTransfer || false,
      }
    }

    case SET_MOVED_BY: {
      return {
        ...state,
        movedBy: action.payload,
      }
    }

    case SET_PHASE_TO_ADD: {
      return {
        ...state,
        modeAction: "addPhase",
        phaseIdToAdd: action.payload.phaseIdToAdd || null,
      }
    }

    default: {
      return { ...state }
    }
  }
}

export const enableMultiSelect = (personRequestStatus: string) => ({
  type: ENABLE_MULTI_SELECT,
  payload: personRequestStatus,
})
export const disableMultiSelect = () => ({ type: DISABLE_MULTI_SELECT })

export const addItemToMultiSelect = (item: Item) => ({
  type: ADD_ITEMS_TO_MODE,
  payload: item,
})

export const removeItemFromMultiSelect = (item: Item) => ({
  type: REMOVE_ITEMS_FROM_MODE,
  payload: item,
})

type ModeActionItem = {
  modeAction: string | null
  entirePlaceholderTransfer?: boolean | null
  type?: "assignment" | "timeOff"
}

export const setModeAction = (modeActionItem: ModeActionItem) => ({
  type: SET_MODE_ACTION,
  payload: {
    modeAction: modeActionItem.modeAction,
    entirePlaceholderTransfer: modeActionItem.entirePlaceholderTransfer,
    type: modeActionItem.type,
  },
})

export const resetItems = () => ({ type: RESET_ITEMS })

export const disableMultiSelectAndResetItems = () => ({
  type: DISABLE_MULTI_SELECT_RESET_ITEMS,
})

export const setPhaseToAdd = (phaseIdToAdd: number) => ({
  type: SET_PHASE_TO_ADD,
  payload: { phaseIdToAdd },
})

export const addUpdatedItems = (item, isConsistentTimeOffEnabled: boolean) => ({
  type: ADD_UPDATED_ITEMS,
  payload: { item, isConsistentTimeOffEnabled },
})

export const setMovedBy = (movedBy: number) => ({
  type: SET_MOVED_BY,
  payload: movedBy,
})
