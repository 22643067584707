import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./Insight.module.css"

import { Help } from "~/common/react-icons"

type Props = {
  title: string
  tooltipMessage?: string | JSX.Element
  warn?: boolean
  children: React.ReactNode
  testId?: string
}

const Insight = ({
  title,
  children,
  tooltipMessage = null,
  warn = false,
  testId,
}: Props) => {
  return (
    <Tooltip
      className={styles.tooltip}
      placement="top"
      disabled={tooltipMessage == null}
      content={tooltipMessage}
    >
      <div className={styles.container} data-test={testId}>
        <div className={styles.title}>
          {title}
          {tooltipMessage && (
            <>
              <Help />
            </>
          )}
        </div>
        <hr className={styles.seperator} />
        <div
          className={cc({
            [styles.value]: true,
            [styles.warning]: warn,
          })}
        >
          {children}
        </div>
      </div>
    </Tooltip>
  )
}

export default Insight
