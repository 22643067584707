import { Icon } from "@blueprintjs/core"
import React, { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./AccountActions.module.css"

import { AccountActionsQuery } from "./__generated__/AccountActionsQuery.graphql"

import { HasuraContextProvider } from "~/store/hasura"

import Dialog from "~/common/Dialog"
import Button from "~/common/buttons/Button"

import PermissionsProvider, {
  usePermissionsContext,
} from "~/Permissions/PermissionsProvider"
import { withRelayPageContainer } from "~/RelayPageContainer"
import { Card, CardHeader } from "~/dashboard"
import AutofillTimesheetsForm from "~/forms/AccountForms/AutofillTimesheetsForm"
import DeleteAccountForm from "~/forms/AccountForms/DeleteAccountForm"
import ResetAccountForm from "~/forms/AccountForms/ResetAccountForm"

const ACCOUNT_ACTIONS_QUERY = graphql`
  query AccountActionsQuery($accountId: Int!) {
    account: accounts_by_pk(id: $accountId) {
      name
      id
      account_type
      subscription
      users {
        permissions
      }
      cancel_reasons {
        account_name
      }
    }
    current_user {
      id
      first_name
      last_name
      email
      ...PermissionsProvider_user
    }
  }
`
type Props = {
  error?: Record<string, any>
  accountId: number
}

const AccountActions = (props: Props) => {
  const { accountId } = props

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showResetModal, setShowResetModal] = useState(false)
  const [showAutofillModal, setShowAutofillModal] = useState(false)

  const [showActions, setShowActions] = useState(false)
  const toggleShowActions = () => setShowActions(!showActions)

  const { account, current_user } = useLazyLoadQuery<AccountActionsQuery>(
    ACCOUNT_ACTIONS_QUERY,
    {
      accountId: accountId,
    },
  )

  const { isAdmin } = usePermissionsContext({ user: current_user })
  const isLiveAccount = account.account_type === "live"

  if (!isAdmin || !isLiveAccount) {
    return null
  }

  return (
    <PermissionsProvider user={current_user}>
      <HasuraContextProvider value={current_user}>
        <div className="mv4 br2 ba b--mid-gray-2 shadow-18 w-100 w-50-l">
          <Card>
            <CardHeader noSticky>
              <span>Account Actions</span>
              <Button onClick={toggleShowActions}>
                {showActions ? "Hide" : "Show"} Actions
              </Button>
            </CardHeader>
            <>
              <div className={styles.wrapper}>
                <b>
                  These actions will permanently change the entire account and
                  all data.
                </b>
                <br />
                <span>
                  If you wish to just remove yourself as user go to My Settings
                  instead.
                </span>
                {showActions && (
                  <div>
                    <div>
                      <p className={styles.sectionHeading}>Account data</p>
                      <div className={styles.buttonContainer}>
                        <Button
                          text="Reset Account"
                          onClick={() => setShowResetModal(true)}
                          icon={<Icon icon="reset" />}
                        />
                        <Button
                          text="Delete Account & All Data"
                          onClick={() => setShowDeleteModal(true)}
                          icon={<Icon icon="trash" />}
                        />
                      </div>
                      <b>These actions can not be reversed.</b>
                    </div>
                    <div>
                      <p className={styles.sectionHeading}>Timesheet data</p>
                      <div className={styles.buttonContainer}>
                        <Button
                          text="Autofill with zero"
                          onClick={() => setShowAutofillModal(true)}
                          icon={<Icon icon="add" />}
                        />
                      </div>
                      <b>This action can not be reversed.</b>
                    </div>
                  </div>
                )}
              </div>
              <Dialog isOpen={showDeleteModal}>
                <DeleteAccountForm
                  account={account}
                  onClose={() => setShowDeleteModal(false)}
                />
              </Dialog>
              <Dialog isOpen={showResetModal}>
                <ResetAccountForm
                  accountName={account.name}
                  onClose={() => setShowResetModal(false)}
                />
              </Dialog>
              <Dialog isOpen={showAutofillModal}>
                <AutofillTimesheetsForm
                  onClose={() => setShowAutofillModal(false)}
                />
              </Dialog>
            </>
          </Card>
        </div>
      </HasuraContextProvider>
    </PermissionsProvider>
  )
}

export default withRelayPageContainer(AccountActions)
