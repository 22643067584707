import React from "react"

type Props = {
  color?: any
  alt?: string
}
const ChevronDown = (props: Props) => (
  <svg
    fill={props.color || "#d5d8e4"}
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    enableBackground="new 0 0 20 20"
  >
    <title>{props.alt}</title>
    <g>
      <g>
        <path
          d="M16,6c-0.28,0-0.53,0.11-0.71,0.29L10,11.59L4.71,6.29C4.53,6.11,4.28,6,4,6
        C3.45,6,3,6.45,3,7c0,0.28,0.11,0.53,0.29,0.71l6,6C9.47,13.89,9.72,14,10,14s0.53-0.11,0.71-0.29l6-6C16.89,7.53,17,7.28,17,7
        C17,6.45,16.55,6,16,6z"
        />
      </g>
    </g>
  </svg>
)

export default ChevronDown
