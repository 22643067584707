import { isWeekend, parseISO } from "date-fns"
import { isObject } from "lodash-es"
import React from "react"

import {
  Assignment as PillAssignment,
  TimeOff as PillTimeOff,
} from "~/common/PillActions/PillActions"

import { ModeAction, isSplitScreenMode } from "~/Mode.reducer"
import { isTentativeProjectEnabled } from "~/Planner/reducer2/scenarioPlanningSlice"
import { getSettings } from "~/localsettings"

import { Permissions, UserType } from "./permissions"
import { pluralize } from "./plural"

export type Assignment = PillAssignment
export type TimeOff = PillTimeOff
export type Item = Assignment | TimeOff
export type ItemType = "assignment" | "timeOff" | "weekend" | null

const plannerGraphHeight = getSettings().plannerGraphHeight

export const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
  const parent = ref.current.offsetParent as HTMLElement
  const refIsAboveSplitScreenPanel =
    ref.current.getBoundingClientRect().top <
    parent.offsetHeight - plannerGraphHeight

  if (refIsAboveSplitScreenPanel) {
    return
  }

  ref.current.scrollIntoView({
    behavior: "smooth",
    block: "end",
  })
}

export const willBannerRender = (
  permissions: Permissions,
  accountType: string,
  isInTrialOrFreePlan,
): boolean =>
  isInTrialOrFreePlan ||
  permissions.type === UserType.Superuser ||
  ["test", "copy", "demo_template"].includes(accountType)

export const determineItemType = (item: Item): ItemType => {
  if ((item as TimeOff).hasOwnProperty("leave_type")) {
    return "timeOff"
  }
  if (
    (item as Assignment).non_working_day === true &&
    // Future proof as not all non_working_days will be weekends (e.g. public holidays)
    isWeekend(parseISO((item as Assignment).start_date))
  ) {
    return "weekend"
  }
  return "assignment"
}

export const getReadablePillType = (item: Item) => {
  if (!item) {
    return
  }

  const type = determineItemType(item)
  let pillType = ""
  if (type === "timeOff") {
    pillType = "time off"
  }
  if (type === "assignment") {
    pillType = "weekday assignment"
  }
  if (type === "weekend") {
    pillType = "weekend assignment"
  }
  return pillType
}

type PillDisabledProps = {
  multiSelectItems: Item[] | []
  pill: Item
  modeAction: ModeAction
  isTransferPlaceholderMode: boolean
  pillPermissions: { edit: boolean }
}
export const getReasonPillIsDisabled = ({
  multiSelectItems,
  pill,
  modeAction,
  isTransferPlaceholderMode,
  pillPermissions,
}: PillDisabledProps): string => {
  // Pill is the pill users are trying to select
  const pillType = determineItemType(pill)

  if (pillType === "timeOff") {
    if (!pillPermissions.edit) {
      return "You do not have permission to edit time off"
    }
  } else if (pillType === "assignment") {
    if (!pillPermissions.edit) {
      return "You do not have permission to edit this project's assignments"
    }
  }

  const splitScreenMode = isSplitScreenMode(modeAction)

  if (isTransferPlaceholderMode) {
    return "Assignments are locked while transferring an entire placeholder"
  }

  if (!multiSelectItems.length) {
    if (splitScreenMode && pillType === "timeOff") {
      return `You can only ${modeAction} assignments`
    }
    return null
  }

  const firstSelectedItem = multiSelectItems[0]
  const firstSelectedItemType = determineItemType(firstSelectedItem)

  if (firstSelectedItemType !== pillType) {
    const readableType = getReadablePillType(firstSelectedItem)
    // First selected pill is not the same type as the pill user is trying to select
    return `You can only select ${pluralize(2, readableType)}`
  }

  if (splitScreenMode) {
    // Cannot transfer/clone time offs - will always be assignments
    const a = firstSelectedItem as Assignment
    const b = pill as Assignment

    if (a.person_id !== b.person_id || a.project_id !== b.project_id) {
      return `You can only select items on the same row in ${modeAction} mode`
    }
  }

  return null
}

export const excludeUntoggledAssignments = <
  A extends { project_id: number },
  P extends { id: number; confirmed: boolean },
>(
  assignments: readonly A[],
  projects: readonly P[],
  enabledTentativeProjects: number[],
) => {
  return assignments.filter((a) => {
    const confirmedProjects = projects.some(
      (p) => p.id === a.project_id && p.confirmed,
    )
    return (
      confirmedProjects ||
      isTentativeProjectEnabled(enabledTentativeProjects, a.project_id)
    )
  })
}

// Check if any nested object values are true
export const anyNestedTrue = (array: unknown[]): boolean => {
  let result = false

  array.forEach((obj) => {
    if (isObject(obj)) {
      if (anyNestedTrue(Object.values(obj))) {
        result = true
      }
    } else if (obj === true) {
      result = true
    }
  })

  return result
}

export const getProjectsFilter = (
  projectIDs: number[],
  peopleIDs: number[],
) => {
  return {
    _or: [
      { id: { _in: projectIDs } },
      { members: { person_id: { _in: peopleIDs } } },
    ],
  }
}

export const getPeopleFilter = (projectIDs: number[], peopleIDs: number[]) => {
  return {
    _or: [
      { id: { _in: peopleIDs } },
      { project_memberships: { project_id: { _in: projectIDs } } },
    ],
  }
}
