import React from "react"

type Props = {
  color?: string
  size?: number
  alt?: string
}
const Face = (props: Props) => {
  const { size = 18, color = "var(--midnight)", alt } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {alt && <title>{alt}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1C4.584 1 1 4.584 1 9C1 13.416 4.584 17 9 17C13.416 17 17 13.416 17 9C17 4.584 13.416 1 9 1ZM5.6 9.8C5.6 9.248 6.048 8.8 6.6 8.8C7.152 8.8 7.6 9.248 7.6 9.8C7.6 10.352 7.152 10.8 6.6 10.8C6.048 10.8 5.6 10.352 5.6 9.8ZM10.4 9.8C10.4 9.248 10.848 8.8 11.4 8.8C11.952 8.8 12.4 9.248 12.4 9.8C12.4 10.352 11.952 10.8 11.4 10.8C10.848 10.8 10.4 10.352 10.4 9.8ZM2.6 9.00015C2.6 12.5281 5.472 15.4001 9 15.4001C12.528 15.4001 15.4 12.5281 15.4 9.00015C15.4 8.36815 15.304 7.76015 15.136 7.19215C14.56 7.32815 13.96 7.40015 13.336 7.40015C10.64 7.40015 8.256 6.06415 6.808 4.01615C6.024 5.92815 4.528 7.47215 2.64 8.31215C2.616 8.53615 2.6 8.76815 2.6 9.00015Z"
        fill={color}
      />
    </svg>
  )
}

export default Face
