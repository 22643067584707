import { graphql } from "react-relay"

import { HolidaysBulkCreateMutation } from "./__generated__/HolidaysBulkCreateMutation.graphql"
import { HolidaysBulkUpdateMutation } from "./__generated__/HolidaysBulkUpdateMutation.graphql"
import { HolidaysGroupCreateMutation } from "./__generated__/HolidaysGroupCreateMutation.graphql"
import { HolidaysGroupUpdateMutation } from "./__generated__/HolidaysGroupUpdateMutation.graphql"
import { HolidaysTimeOffBulkCreateMutation } from "./__generated__/HolidaysTimeOffBulkCreateMutation.graphql"
import { HolidaysTimeOffBulkDeleteMutation } from "./__generated__/HolidaysTimeOffBulkDeleteMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"

const holidaysGroupCreateMutation = graphql`
  mutation HolidaysGroupCreateMutation($group: holidays_groups_insert_input!) {
    insert_holidays_groups_one(object: $group) {
      id
      name
      account {
        id
        holidays_groups(order_by: { name: asc }) {
          id
          name
          country_code
          region_name
          country_name
          holidays {
            id
          }
        }
      }
    }
  }
`

export const holidaysGroupCreateRelay = async (
  variables: HolidaysGroupCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<HolidaysGroupCreateMutation>(
    environment,
    {
      mutation: holidaysGroupCreateMutation,
      variables,
    },
  )

  return {
    id: data.insert_holidays_groups_one.id,
    name: data.insert_holidays_groups_one.name,
  }
}

const holidaysGroupUpdateMutation = graphql`
  mutation HolidaysGroupUpdateMutation(
    $id: Int!
    $input: holidays_groups_set_input!
  ) {
    update_holidays_groups_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
      name
    }
  }
`

export const holidaysGroupUpdateRelay = async (
  variables: HolidaysGroupUpdateMutation["variables"],
) => {
  const data = await commitMutationPromise<HolidaysGroupUpdateMutation>(
    environment,
    {
      mutation: holidaysGroupUpdateMutation,
      variables,
    },
  )

  return {
    id: data.update_holidays_groups_by_pk.id,
    name: data.update_holidays_groups_by_pk.name,
  }
}

const holidaysBulkCreateMutation = graphql`
  mutation HolidaysBulkCreateMutation(
    $holidays: [holidays_insert_input!]!
    $minDate: date!
    $maxDate: date!
  ) {
    insert_holidays(objects: $holidays) {
      returning {
        id
        holidays_group {
          id
          holidays(where: { date: { _gte: $minDate, _lte: $maxDate } }) {
            id
            holiday_api_uuid
            name
            date
            observed
            type
          }
          holidays_aggregate(distinct_on: name) {
            aggregate {
              count
            }
          }
          people_aggregate(where: { active: { _eq: true } }, distinct_on: id) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`

export const holidaysBulkCreateRelay = async (
  variables: HolidaysBulkCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<HolidaysBulkCreateMutation>(
    environment,
    {
      mutation: holidaysBulkCreateMutation,
      variables,
    },
  )

  return data
}

const holidaysBulkUpdateMutation = graphql`
  mutation HolidaysBulkUpdateMutation(
    $holidaysGroupId: Int!
    $minDate: date!
    $maxDate: date!
    $input: HolidaysBulkUpdateInput!
  ) {
    action_holidays_bulk_update(input: $input) {
      account {
        id
        holidays_groups(where: { id: { _eq: $holidaysGroupId } }) {
          id
          holidays_aggregate(distinct_on: name) {
            aggregate {
              count
            }
          }
          holidays(where: { date: { _gte: $minDate, _lte: $maxDate } }) {
            id
            name
            date
            holiday_api_uuid
            observed
            type
            created_at
          }
        }
      }
    }
  }
`

export const holidaysBulkUpdateRelay = (
  variables: HolidaysBulkUpdateMutation["variables"],
) => {
  return commitMutationPromise<HolidaysBulkUpdateMutation>(environment, {
    mutation: holidaysBulkUpdateMutation,
    variables,
  })
}

const holidaysTimeOffBulkCreateMutation = graphql`
  mutation HolidaysTimeOffBulkCreateMutation(
    $input: HolidaysTimeOffBulkCreateInput!
  ) {
    action_holidays_time_off_bulk_create(input: $input) {
      account {
        id
      }
    }
  }
`

export const holidaysTimeOffBulkCreateRelay = (
  variables: HolidaysTimeOffBulkCreateMutation["variables"],
) => {
  return commitMutationPromise<HolidaysTimeOffBulkCreateMutation>(environment, {
    mutation: holidaysTimeOffBulkCreateMutation,
    variables,
  })
}

const holidaysTimeOffBulkDeleteMutation = graphql`
  mutation HolidaysTimeOffBulkDeleteMutation(
    $input: HolidaysTimeOffBulkDeleteInput!
  ) {
    action_holidays_time_off_bulk_delete(input: $input) {
      account {
        id
      }
    }
  }
`

export const holidaysTimeOffBulkDeleteRelay = (
  variables: HolidaysTimeOffBulkDeleteMutation["variables"],
) => {
  return commitMutationPromise<HolidaysTimeOffBulkDeleteMutation>(environment, {
    mutation: holidaysTimeOffBulkDeleteMutation,
    variables,
  })
}
