import { Tab, Tooltip } from "@blueprintjs/core"
import React, { Suspense, useEffect, useState } from "react"

import styles from "./HolidaysGroupForm.module.css"

import { EditHolidaysGroupForm_holidays_group$data } from "../EditHolidaysGroupForm/__generated__/EditHolidaysGroupForm_holidays_group.graphql"

import { ModalBody, ModalFooter } from "~/common/ModalForm"
import Tabs from "~/common/Tabs"
import Button from "~/common/buttons/Button"
import { Warning } from "~/common/react-icons"
import {
  TangramPersonBalancing,
  TangramPersonFlying,
  TangramPersonWalking,
} from "~/common/tangrams"

import CreateBulkCustomHolidaysForm from "./CreateBulkCustomHolidaysForm"
import HolidaysTable from "./HolidaysTable"

type Props = {
  InputFields: React.ReactNode
  locationCode: string
  disableSubmitButton: boolean
  publicHolidays: any
  customHolidays: any[]
  isLoading: boolean
  setPublicHolidays: (publicHolidays) => void
  setCustomHolidays: (customHolidays) => void
  closeDialog: () => void
  onSubmit: () => void
  holidaysGroup?: EditHolidaysGroupForm_holidays_group$data
  savedHolidays?: any
  defaultSelectedTabId?: "public" | "custom"
}

type LoadingContentProps = {
  setIsLoadingHolidays: (isLoadingHolidays: boolean) => void
}

const LoadingContent = (props: LoadingContentProps) => {
  const { setIsLoadingHolidays } = props

  useEffect(() => {
    setIsLoadingHolidays(true)

    return () => {
      setIsLoadingHolidays(false)
    }
  }, [setIsLoadingHolidays])

  return (
    <div className={styles.loadingWrapper}>
      <div className={styles.tangramsWrapper}>
        <TangramPersonBalancing />
        <TangramPersonFlying />
        <TangramPersonWalking />
      </div>
      <p>...Loading holidays</p>
    </div>
  )
}

const HolidaysGroupForm = (props: Props) => {
  const {
    InputFields,
    locationCode,
    savedHolidays,
    publicHolidays,
    customHolidays,
    disableSubmitButton,
    holidaysGroup,
    isLoading,
    setPublicHolidays,
    setCustomHolidays,
    closeDialog,
    onSubmit,
    defaultSelectedTabId,
  } = props

  const [isLoadingHolidays, setIsLoadingHolidays] = useState<boolean>(false)

  const filledRows = customHolidays.filter(
    (holiday) =>
      holiday.name.trim() ||
      holiday.currentYear ||
      holiday.secondYear ||
      holiday.thirdYear,
  )

  const completedCustomHolidayRowsCount = filledRows.filter(
    (holiday) =>
      holiday.name.trim() &&
      (holiday.currentYear || holiday.secondYear || holiday.thirdYear),
  ).length

  const hasIncompleteCustomHolidayRows =
    filledRows.length !== completedCustomHolidayRowsCount

  const customHolidaysNamesLower = customHolidays
    .filter((h) => h.name)
    .map((h) => h.name.toLowerCase().trim())
  const hasDuplicateCustomNamedHolidayRows =
    customHolidaysNamesLower.length > 1 &&
    new Set(customHolidaysNamesLower).size !== customHolidaysNamesLower.length

  return (
    <>
      <ModalBody wide>
        {holidaysGroup && (
          <div className={styles.warning}>
            <Warning />
            <div className={styles.message}>
              Changes will be applied to all people assigned to holiday group{" "}
              {holidaysGroup.name}.
            </div>
          </div>
        )}
        <div className={styles.inputFieldsWrapper}>{InputFields}</div>

        {locationCode && (
          <Tabs id="holidays" defaultSelectedTabId={defaultSelectedTabId}>
            <Tab
              id="public"
              title={`PUBLIC HOLIDAYS (${publicHolidays.length})`}
              panel={
                <div className={styles.tabContainer}>
                  {locationCode ? (
                    <Suspense
                      fallback={
                        <LoadingContent
                          setIsLoadingHolidays={setIsLoadingHolidays}
                        />
                      }
                    >
                      <HolidaysTable
                        savedHolidays={savedHolidays?.public}
                        locationCode={locationCode}
                        setPublicHolidays={setPublicHolidays}
                      />
                    </Suspense>
                  ) : (
                    <p>Select a Country and Region</p>
                  )}
                </div>
              }
            ></Tab>
            <Tab
              id="custom"
              title={`CUSTOM HOLIDAYS (${completedCustomHolidayRowsCount})`}
              panel={
                <div className={styles.tabContainer}>
                  {
                    <CreateBulkCustomHolidaysForm
                      savedHolidays={savedHolidays?.custom}
                      setCustomHolidays={setCustomHolidays}
                    />
                  }
                </div>
              }
            ></Tab>
          </Tabs>
        )}
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" onClick={closeDialog} />
        <Tooltip
          content={
            "Custom Holidays must have a unique name and at least one date selected"
          }
          autoFocus={false}
          disabled={
            !hasDuplicateCustomNamedHolidayRows &&
            !hasIncompleteCustomHolidayRows
          }
        >
          <Button
            forceAnchorButton
            data-test={`${
              savedHolidays ? "save" : "create"
            }-holiday-group-button`}
            text={savedHolidays ? "Save" : "Create"}
            outlined={false}
            onClick={onSubmit}
            loading={isLoading}
            disabled={
              disableSubmitButton ||
              hasIncompleteCustomHolidayRows ||
              hasDuplicateCustomNamedHolidayRows ||
              isLoadingHolidays
            }
            style={{ marginLeft: 7 }}
          />
        </Tooltip>
      </ModalFooter>
    </>
  )
}

export default HolidaysGroupForm
