import React from "react"

type Props = {
  fill?: string
  className?: string
}

const Person = (props: Props) => {
  const { className, fill } = props

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.68,14.32c-0.46-1.05-2.68-1.75-4.16-2.4c-1.48-0.65-1.28-1.05-1.33-1.59
			c-0.01-0.07-0.01-0.15-0.01-0.23c0.51-0.45,0.92-1.07,1.19-1.78c0,0,0.01-0.04,0.02-0.05c0.06-0.15,0.11-0.32,0.15-0.48
			c0.34-0.07,0.54-0.44,0.61-0.78c0.08-0.14,0.23-0.48,0.2-0.87c-0.05-0.5-0.25-0.73-0.47-0.82c0-0.03,0-0.06,0-0.09
			c0-0.63-0.06-1.55-0.17-2.15c-0.02-0.17-0.06-0.33-0.11-0.5c-0.22-0.73-0.67-1.4-1.28-1.86C9.68,0.25,8.79-0.01,8-0.01
			c-0.79,0-1.68,0.25-2.31,0.73C5.08,1.19,4.63,1.85,4.41,2.58C4.36,2.75,4.32,2.91,4.3,3.08c-0.12,0.6-0.17,1.51-0.17,2.15
			c0,0.03,0,0.05,0,0.08C3.89,5.4,3.68,5.63,3.63,6.14C3.6,6.52,3.76,6.86,3.83,7c0.08,0.35,0.28,0.72,0.63,0.78
			C4.5,7.95,4.55,8.11,4.61,8.27c0,0.01,0.01,0.02,0.01,0.03l0.01,0.01c0.27,0.72,0.7,1.35,1.22,1.8c0,0.07-0.01,0.14-0.01,0.21
			c-0.05,0.54,0.1,0.94-1.37,1.59c-1.48,0.65-3.7,1.35-4.16,2.4c-0.46,1.05-0.27,1.67-0.27,1.67h15.92
			C15.95,15.99,16.14,15.37,15.68,14.32z"
      />
    </svg>
  )
}

export default Person
