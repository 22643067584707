import React from "react"

type Props = {
  color?: any
  alt?: string
  withBackground?: boolean
}
const Dollar = (props: Props) =>
  props.withBackground ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.alt}</title>
      <path
        fill={props.color || "var(--midnight)"}
        d="M0 8C0 3.584 3.584 0 8 0C12.416 0 16 3.584 16 8C16 12.416 12.416 16 8 16C3.584 16 0 12.416 0 8ZM9.12804 14.4V12.872C10.52 12.608 11.616 11.808 11.624 10.344C11.624 8.328 9.89604 7.632 8.28004 7.216C6.66404 6.8 6.14404 6.36 6.14404 5.688C6.14404 4.92 6.86404 4.376 8.06404 4.376C9.32804 4.376 9.80004 4.984 9.84004 5.872H11.408C11.36 4.648 10.616 3.52 9.12804 3.16V1.6H6.99204V3.144C5.61604 3.44 4.50404 4.336 4.50404 5.712C4.50404 7.352 5.86404 8.168 7.84804 8.648C9.63204 9.072 9.98404 9.696 9.98404 10.36C9.98404 10.848 9.63204 11.632 8.06404 11.632C6.60004 11.632 6.02404 10.976 5.94404 10.136H4.37604C4.46404 11.688 5.62404 12.568 6.99204 12.856V14.4H9.12804Z"
      />
    </svg>
  ) : (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.alt}</title>
      <path
        fill={props.color || "#fff"}
        d="M4.56667 6.58333C2.675 6.09167 2.06667 5.58333 2.06667 4.79167C2.06667 3.88333 2.90833 3.25 4.31667 3.25C5.8 3.25 6.35 3.95833 6.4 5H8.24167C8.18333 3.56667 7.30833 2.25 5.56667 1.825V0H3.06667V1.8C1.45 2.15 0.15 3.2 0.15 4.80833C0.15 6.73333 1.74167 7.69167 4.06667 8.25C6.15 8.75 6.56667 9.48333 6.56667 10.2583C6.56667 10.8333 6.15833 11.75 4.31667 11.75C2.6 11.75 1.925 10.9833 1.83333 10H0C0.1 11.825 1.46667 12.85 3.06667 13.1917V15H5.56667V13.2083C7.19167 12.9 8.48333 11.9583 8.48333 10.25C8.48333 7.88333 6.45833 7.075 4.56667 6.58333Z"
      />
    </svg>
  )

export default Dollar
