import cc from "classcat"
import React, { useEffect, useState } from "react"

import styles from "./Card.module.css"

import { getDashboardHeaderHeight } from "~/helpers/layout-helper"

type Props = {
  style?: React.CSSProperties
  className?: string
  children?: React.ReactNode
  dataTest?: string
  id?: string
}

const Card = (props: Props) => {
  const { className = "", style, dataTest, children, id } = props

  const [dashboardHeaderHeight, setDashboardHeaderHeight] = useState(null)

  useEffect(() => {
    setDashboardHeaderHeight(`${getDashboardHeaderHeight()}px`)
  }, [])

  return (
    <div
      className={cc([styles.container, "dashboard-card", className])}
      style={{ ...style, scrollMarginTop: dashboardHeaderHeight }}
      data-test={dataTest}
      id={id}
    >
      {children}
    </div>
  )
}

export default Card
