import { Tabs as BlueprintTabs, Tab, TabsProps } from "@blueprintjs/core"
import cc from "classcat"
import React, { ReactElement } from "react"

import styles from "./Tabs.module.css"

type Props = {
  id?: string
  children: Array<ReactElement<typeof Tab>>
  large?: boolean
  className?: string
  renderActiveTabPanelOnly?: boolean
  rightTabs?: boolean
} & TabsProps

const Tabs = ({
  children,
  large,
  renderActiveTabPanelOnly = false,
  className,
  rightTabs,
  ...rest
}: Props) => (
  <BlueprintTabs
    className={cc([
      styles.tabs,
      className,
      {
        [styles.rightTabs]: rightTabs,
      },
    ])}
    key={"horizontal"}
    renderActiveTabPanelOnly={renderActiveTabPanelOnly}
    vertical={false}
    large={large}
    animate={true}
    {...rest}
  >
    {children}
  </BlueprintTabs>
)

export default Tabs
