/**
 * @generated SignedSource<<c19e0bdedfce89d7bd02b314cd1d91c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateRoleInput = {
  chargeOutRate?: number | null | undefined;
  defaultHourCost?: number | null | undefined;
  name: string;
  references?: any | null | undefined;
  roleId: number;
};
export type RoleUpdateMutation$variables = {
  input: UpdateRoleInput;
};
export type RoleUpdateMutation$data = {
  readonly updateRole: {
    readonly account: {
      readonly id: number;
      readonly rate_cards: ReadonlyArray<{
        readonly id: number;
        readonly name: string | null | undefined;
        readonly role_charge_out_rates: ReadonlyArray<{
          readonly charge_out_rate: number | null | undefined;
          readonly rate_card_id: number;
          readonly role_id: number;
        }>;
      }>;
      readonly roles: ReadonlyArray<{
        readonly id: number;
      }>;
    } | null | undefined;
    readonly role: {
      readonly active: boolean;
      readonly default_hour_cost: number | null | undefined;
      readonly id: number;
      readonly name: string | null | undefined;
      readonly references: any;
    } | null | undefined;
  };
};
export type RoleUpdateMutation = {
  response: RoleUpdateMutation$data;
  variables: RoleUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RoleChangedResult",
    "kind": "LinkedField",
    "name": "updateRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "roles",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": "default_hour_cost",
            "args": null,
            "kind": "ScalarField",
            "name": "default_hour_cost_private",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "references",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "accounts",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "roles",
            "kind": "LinkedField",
            "name": "roles",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "name": {
                    "_eq": "Standard"
                  }
                }
              }
            ],
            "concreteType": "rate_cards",
            "kind": "LinkedField",
            "name": "rate_cards",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "role_charge_out_rates",
                "kind": "LinkedField",
                "name": "role_charge_out_rates",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rate_card_id",
                    "storageKey": null
                  },
                  {
                    "alias": "charge_out_rate",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "charge_out_rate_private",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "rate_cards(where:{\"name\":{\"_eq\":\"Standard\"}})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleUpdateMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleUpdateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "18990d61d66fadd68f65defbe539eef1",
    "id": null,
    "metadata": {},
    "name": "RoleUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation RoleUpdateMutation(\n  $input: UpdateRoleInput!\n) {\n  updateRole(input: $input) {\n    role {\n      id\n      name\n      active\n      default_hour_cost: default_hour_cost_private\n      references\n    }\n    account {\n      id\n      roles {\n        id\n      }\n      rate_cards(where: {name: {_eq: \"Standard\"}}) {\n        id\n        name\n        role_charge_out_rates {\n          role_id\n          rate_card_id\n          charge_out_rate: charge_out_rate_private\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4eeed6521fd80031649939f7700cbcfc";

export default node;
