import React from "react"
import { Table as ReactTable } from "react-table"

import BaseTable from "./BaseTable"
import Row from "./Row"

type Props = {
  table: ReactTable
  className?: string
}

const Table = (props: Props) => {
  return (
    <BaseTable {...props}>
      {props.table.rows.map((row, i) => (
        <Row key={i} row={row} table={props.table} />
      ))}
    </BaseTable>
  )
}

export default Table
