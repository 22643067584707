import React from "react"

const MultiSelect = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.98H4C3.45 3.98 3 4.43 3 4.98V5.98H11V10.98H12C12.55 10.98 13 10.53 13 9.98V4.98C13 4.43 12.55 3.98 12 3.98ZM15 0.979996H7C6.45 0.979996 6 1.43 6 1.98V2.98H14V7.98H15C15.55 7.98 16 7.53 16 6.98V1.98C16 1.43 15.55 0.979996 15 0.979996ZM9 6.98H1C0.45 6.98 0 7.43 0 7.98V12.98C0 13.53 0.45 13.98 1 13.98H9C9.55 13.98 10 13.53 10 12.98V7.98C10 7.43 9.55 6.98 9 6.98ZM8 11.98H2V8.98H8V11.98Z"
      fill="#3C4260"
    />
  </svg>
)
export default MultiSelect
