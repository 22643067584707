import { Alignment, Checkbox, Classes, Icon, Text } from "@blueprintjs/core"
import { useFeature } from "flagged"
import React from "react"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

import { FeatureAccountCheckbox_feature$key } from "./__generated__/FeatureAccountCheckbox_feature.graphql"

import { addLinkedRecord } from "../../mutations/helpers"
import * as relayids from "~/helpers/relayids"

import { handleCopy } from "../helper"

export const FeatureAccountCheckbox = (props: {
  feature: FeatureAccountCheckbox_feature$key
}) => {
  const feature = useFragment(
    graphql`
      fragment FeatureAccountCheckbox_feature on features {
        id
        enabled
        description
        name
      }
    `,
    props.feature,
  )
  const [commit, isInFlight] = useMutation(graphql`
    mutation FeatureAccountCheckboxToggleMutation(
      $feature_id: String
      $enabled: Boolean
    ) {
      insert_features_accounts_one(
        object: { feature_id: $feature_id, enabled: $enabled }
        on_conflict: {
          constraint: index_features_accounts_on_account_id_and_feature_id
          update_columns: [enabled]
        }
      ) {
        id
        feature_id
        enabled
      }
    }
  `)
  const enabled = useFeature(feature.id)

  return (
    <div style={{ paddingBottom: 5, position: "relative" }}>
      <Icon
        icon="clipboard"
        onClick={() => handleCopy(feature.id)}
        style={{ position: "absolute", cursor: "pointer", marginTop: "2px" }}
        size={15}
        color="var(--smoke)"
      />
      <Checkbox
        label={feature.name || feature.id}
        checked={Boolean(enabled)}
        large
        disabled={isInFlight}
        alignIndicator={Alignment.RIGHT}
        onChange={() =>
          commit({
            variables: {
              feature_id: feature.id,
              enabled: !enabled,
            },
            updater: (store, data) => {
              const payload = data["insert_features_accounts_one"]
              const relayId = relayids.featureAccounts.encode(payload.id)
              const feature_account = store.get(relayId)
              const root = store.getRoot()
              addLinkedRecord(root, "features_accounts", feature_account)
            },
          })
        }
        style={{ marginLeft: 20 }}
      >
        <Text className={Classes.TEXT_SMALL}>{feature.description}</Text>
      </Checkbox>
    </div>
  )
}
