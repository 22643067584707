import React from "react"

const ReportColumns = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={12} fill="none">
    <path
      fill="#707593"
      fillRule="evenodd"
      d="M3.75.75H1.5a.752.752 0 0 0-.75.75v9c0 .412.337.75.75.75h2.25c.412 0 .75-.338.75-.75v-9a.752.752 0 0 0-.75-.75Zm10.5 0H12a.752.752 0 0 0-.75.75v9c0 .412.338.75.75.75h2.25c.412 0 .75-.338.75-.75v-9a.752.752 0 0 0-.75-.75Zm-7.5 0H9c.412 0 .75.337.75.75v9c0 .412-.338.75-.75.75H6.75A.752.752 0 0 1 6 10.5v-9c0-.413.338-.75.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
)

export default ReportColumns
