import { Tooltip } from "@blueprintjs/core"
import cc from "classcat"
import { format as formatDate, parseISO } from "date-fns"
import React, { useEffect, useMemo } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useRowSelect, useTable } from "react-table"

import styles from "./HolidaysTable.module.css"

import { HolidaysTableQuery } from "./__generated__/HolidaysTableQuery.graphql"

import Table from "~/common/Table/Table"
import Checkbox from "~/common/inputs/Checkbox"

import { HOLIDAYS_YEARS } from "~/GLOBALVARS"

type Props = {
  locationCode: string
  setPublicHolidays: (holidays: any) => void
  savedHolidays?: any
}

export const HOLIDAYS_TABLE_QUERY = graphql`
  query HolidaysTableQuery($countryCode: String!, $year: Int!) {
    action_holidays_get(country_code: $countryCode, year: $year) {
      holidays
    }
  }
`

const dateColumn = (cell) => {
  if (!cell.value) {
    return "-"
  }

  const isObserved = cell.value.observed !== cell.value.date

  return (
    // this div wrapper is needed for centering the tooltip
    <div>
      <Tooltip
        disabled={!isObserved}
        placement="top"
        className={styles.tooltip}
        content={
          <div>
            Observed holiday for {""}
            {formatDate(parseISO(cell.value.date), "ccc, d MMM")}
          </div>
        }
      >
        <div
          className={cc({
            [styles.observed]: isObserved,
          })}
        >
          {formatDate(parseISO(cell.value.observed), "ccc, d MMM")}
        </div>
      </Tooltip>
    </div>
  )
}

const formatHolidaysData = (
  currentYearHolidays,
  secondYearHolidays,
  thirdYearHolidays,
) => {
  const combinedHolidays = currentYearHolidays.concat(
    secondYearHolidays,
    thirdYearHolidays,
  )

  // get unique holidays
  const uniqueHolidays = combinedHolidays.filter(
    (e, i) => combinedHolidays.findIndex((a) => a.name === e.name) === i,
  )

  // sort unique holidays by date
  const sortedHolidays = uniqueHolidays.sort((a, b) => {
    const aDate = a.date.replace(/\d{4}[-]/, "")
    const bDate = b.date.replace(/\d{4}[-]/, "")
    return aDate > bDate ? 1 : bDate > aDate ? -1 : 0
  })

  const formattedHolidays = sortedHolidays.map((holiday) => ({
    holiday,
    currentYear:
      currentYearHolidays.find((data) => data.name === holiday.name) || null,
    secondYear:
      secondYearHolidays.find((data) => data.name === holiday.name) || null,
    thirdYear:
      thirdYearHolidays.find((data) => data.name === holiday.name) || null,
  }))

  return formattedHolidays
}

const HolidaysTable = (props: Props) => {
  const { locationCode, savedHolidays, setPublicHolidays } = props

  const {
    action_holidays_get: { holidays: currentYearHolidays },
  } = useLazyLoadQuery<HolidaysTableQuery>(HOLIDAYS_TABLE_QUERY, {
    countryCode: locationCode,
    year: HOLIDAYS_YEARS.CURRENT_YEAR,
  })
  const {
    action_holidays_get: { holidays: secondYearHolidays },
  } = useLazyLoadQuery<HolidaysTableQuery>(HOLIDAYS_TABLE_QUERY, {
    countryCode: locationCode,
    year: HOLIDAYS_YEARS.SECOND_YEAR,
  })
  const {
    action_holidays_get: { holidays: thirdYearHolidays },
  } = useLazyLoadQuery<HolidaysTableQuery>(HOLIDAYS_TABLE_QUERY, {
    countryCode: locationCode,
    year: HOLIDAYS_YEARS.THIRD_YEAR,
  })

  const holidays = useMemo(() => {
    const formattedHolidays = formatHolidaysData(
      currentYearHolidays,
      secondYearHolidays,
      thirdYearHolidays,
    )

    return formattedHolidays
  }, [currentYearHolidays, secondYearHolidays, thirdYearHolidays])

  const columns = useMemo(
    () => [
      {
        Header: "Public Holiday",
        accessor: "holiday",
        width: "35%",
        Cell: ({ cell }) => {
          return <div>{cell.value.name}</div>
        },
      },
      {
        Header: HOLIDAYS_YEARS.CURRENT_YEAR,
        accessor: "currentYear",
        Cell: ({ cell }) => dateColumn(cell),
      },
      {
        Header: HOLIDAYS_YEARS.SECOND_YEAR,
        accessor: "secondYear",
        Cell: ({ cell }) => dateColumn(cell),
      },
      {
        Header: HOLIDAYS_YEARS.THIRD_YEAR,
        accessor: "thirdYear",
        Cell: ({ cell }) => dateColumn(cell),
      },
    ],
    [],
  )

  const selectedRowIds = holidays.reduce((acc, { holiday }, index) => {
    if (savedHolidays) {
      const isSaved = savedHolidays.some(
        ({ holiday_api_uuid }) => holiday_api_uuid === holiday.uuid,
      )
      if (isSaved) {
        acc[index] = true
        return acc
      }
    } else {
      acc[index] = true
      return acc
    }

    return acc
  }, {})

  const table = useTable(
    {
      columns,
      data: holidays,
      initialState: {
        selectedRowIds,
      },
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((c) => [
        {
          id: "selection",
          width: "12px",
          Header: ({ getToggleAllRowsSelectedProps }) => {
            const { onChange, checked } = getToggleAllRowsSelectedProps()

            return (
              <Checkbox
                id="holiday_select_all"
                containerClassName={styles.checkBox}
                onChange={onChange}
                checked={checked}
              />
            )
          },
          Cell: ({ row }) => {
            const { onChange, checked } = row.getToggleRowSelectedProps()

            return (
              <Checkbox
                id={`holiday_select_${row.id}`}
                containerClassName={styles.checkBox}
                onChange={onChange}
                checked={checked}
              />
            )
          },
        },
        ...c,
      ])
    },
  )

  const selectedHolidays = table.selectedFlatRows.map((row) => row.original)

  useEffect(() => {
    setPublicHolidays(selectedHolidays)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationCode, selectedHolidays.length])

  return <Table className={styles.table} table={table} />
}

export default HolidaysTable
