import { Tooltip } from "@blueprintjs/core"
import React from "react"

import styles from "./CardHelp.module.css"

import { HelpIcon } from "~/common/react-icons"

type Props = {
  url: string
  style?: any
  tooltip?: string
}

const CardHelp = (props: Props) => {
  const tooltipContent = props.tooltip
    ? `Click to learn more about ${props.tooltip}`
    : "Click to learn more"
  return (
    <a
      href={props.url}
      target="_blank"
      rel="noopener"
      className={styles.link}
      style={{ ...props.style }}
    >
      <Tooltip
        placement="top"
        content={tooltipContent}
        className={styles.tooltip}
      >
        <HelpIcon className={styles.helpIcon} />
      </Tooltip>
    </a>
  )
}

export default CardHelp
