import { Environment, UseMutationConfig, useMutation } from "react-relay"
import {
  GraphQLTaggedNode,
  MutationConfig,
  MutationParameters,
  RecordProxy,
  commitMutation,
} from "relay-runtime"

type Variables = {
  [key: string]: any
}
const containsLinkedRecord = (
  record: RecordProxy,
  name: string,
  linkedRecord: RecordProxy,
  args: Variables | null = null,
) => {
  const linkedRecordDataId = linkedRecord.getDataID()
  const linkedRecords = record.getLinkedRecords(name, args) ?? []
  return linkedRecords.some((lr) => lr?.getDataID() === linkedRecordDataId)
}

export const removeLinkedRecord = (
  record: RecordProxy,
  name: string,
  linkedRecord: RecordProxy,
  args: Variables | null = null,
) => {
  if (linkedRecord == null) {
    return
  }

  if (record && containsLinkedRecord(record, name, linkedRecord, args)) {
    const linkedRecords = record.getLinkedRecords(name, args)
    if (linkedRecords) {
      const linkedRecordDataId = linkedRecord.getDataID()
      record.setLinkedRecords(
        linkedRecords.filter((lr) => lr?.getDataID() !== linkedRecordDataId),
        name,
        args,
      )
    }
  }
}

export const addLinkedRecord = (
  record: RecordProxy,
  name: string,
  linkedRecord: RecordProxy,
  args: Variables | null = null,
) => {
  if (linkedRecord == null) {
    return
  }

  if (record && !containsLinkedRecord(record, name, linkedRecord, args)) {
    record.setLinkedRecords(
      [...(record.getLinkedRecords(name, args) || []), linkedRecord],
      name,
      args,
    )
  }
}

export const addLinkedRecords = (
  record: RecordProxy,
  name: string,
  linkedRecords: Array<RecordProxy>,
  args: Variables | null = null,
) => {
  const recordsToAdd = linkedRecords.filter((linkedRecord) => {
    return (
      linkedRecord && !containsLinkedRecord(record, name, linkedRecord, args)
    )
  })

  record.setLinkedRecords(
    [...(record.getLinkedRecords(name, args) || []), ...recordsToAdd],
    name,
    args,
  )
}

export const commitMutationPromise = <T extends MutationParameters>(
  environment: Environment,
  config: MutationConfig<T>,
) => {
  return new Promise<T["response"]>((resolve, reject) => {
    commitMutation(environment, {
      ...config,
      onCompleted: (data: T["response"], errors) => {
        if (errors) {
          reject(errors[0])
        } else {
          resolve(data)
        }
      },
      onError: reject,
    })
  })
}

export const useMutationPromise = <T extends MutationParameters>(
  mutation: GraphQLTaggedNode,
) => {
  const [mutate, isActive] = useMutation<T>(mutation)
  const beginMutation = (
    config: Omit<UseMutationConfig<T>, "onCompleted" | "onError">,
  ) => {
    return new Promise<T["response"]>((resolve, reject) => {
      mutate({
        ...config,
        onCompleted: (data: T["response"], errors) => {
          if (errors) {
            reject(errors[0])
          } else {
            resolve(data)
          }
        },
        onError: reject,
      })
    })
  }

  return [beginMutation, isActive] as const
}
