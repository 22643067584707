import cc from "classcat"
import React, { ReactElement } from "react"

import styles from "./PageHeader.module.css"

import { mustGetElementHeight } from "~/helpers/layout-helper"

import { SimpleLabel } from "~/common/labels"

import PageBody from "~/containers/PageBody"

import PageHeaderLabels from "./PageHeaderLabels"

type Props = {
  children?:
    | ReactElement
    | ReactElement[]
    | ReactElement<typeof PageHeaderLabels>
  className?: string
  sticky?: boolean
  style?: Record<string, any>
  title: ReactElement | string
  subtitle?: ReactElement | string
  icon: ReactElement
  labels?: ReactElement<typeof PageHeaderLabels>
  anchors?: ReactElement
  beta?: boolean
}

const PageHeader = (props: Props) => {
  const {
    children,
    className,
    style,
    title,
    subtitle,
    icon,
    labels,
    anchors,
    beta,
    sticky = true,
    ...rest
  } = props

  const positionFromTop = mustGetElementHeight("top-bar")
  return (
    <div
      className={cc([
        styles.pageHeaderWrapper,
        {
          [styles.sticky]: sticky,
        },
      ])}
      style={{ top: positionFromTop }}
    >
      <PageBody style={{ gridGap: 0, marginBottom: 0 }}>
        <div id="page-header">
          <div
            className={cc([styles.pageHeader, className])}
            style={{ ...style }}
            {...rest}
          >
            <div className={styles.metadata}>
              <div className={styles.icon}>{icon}</div>
              <div className={styles.info}>
                <div data-component="PageHeader/title" className={styles.title}>
                  {title}
                  {beta && (
                    <SimpleLabel
                      text="Beta"
                      type="beta"
                      style={{ marginLeft: "5px" }}
                    />
                  )}
                </div>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
              </div>
            </div>
            <div className={styles.controls} data-test="page-header-controls">
              {children}
            </div>
          </div>
          {anchors}
        </div>
        {labels}
      </PageBody>
    </div>
  )
}

export default PageHeader
