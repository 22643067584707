type BuildFeatureListOptions = {
  readonly features: ReadonlyArray<{
    readonly id: string
    readonly enabled: boolean
  }>
  readonly features_accounts: ReadonlyArray<{
    readonly feature_id: string
    readonly enabled: boolean
  }>
}

const buildFeatureList = (options: BuildFeatureListOptions): string[] => {
  const { features = [], features_accounts = [] } = options

  const featureListSet = new Set<string>()

  for (const feature of features) {
    if (feature.enabled) {
      featureListSet.add(feature.id)
    }
  }

  for (const feature of features_accounts) {
    if (feature.enabled) {
      featureListSet.add(feature.feature_id)
    } else {
      featureListSet.delete(feature.feature_id)
    }
  }

  const featureListArray = [...featureListSet]
  return featureListArray
}

export default buildFeatureList
