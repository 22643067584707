import { Icon, Tooltip } from "@blueprintjs/core"
import React, { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./UserActions.module.css"

import { UserActionsQuery } from "./__generated__/UserActionsQuery.graphql"

import { HasuraContextProvider } from "~/store/hasura"

import { Permissions, UserType } from "~/helpers/permissions"
import { getIsMemberOfOtherAccounts } from "~/helpers/user-helpers"

import Dialog from "~/common/Dialog"
import Button from "~/common/buttons/Button"
import { Help } from "~/common/react-icons"

import PermissionsProvider, {
  usePermissionsContext,
} from "~/Permissions/PermissionsProvider"
import { withRelayPageContainer } from "~/RelayPageContainer"
import { showToast } from "~/containers/ToasterContainer"
import { Card, CardHeader } from "~/dashboard"
import DeleteUserForm from "~/forms/UserForms/DeleteUserForm"

const USER_ACTIONS_QUERY = graphql`
  query UserActionsQuery {
    users {
      email
      permissions
    }
    current_user {
      id
      first_name
      last_name
      email
      account {
        name
        id
        account_type
        subscription
      }
      ...PermissionsProvider_user
    }
    user_accounts {
      id
      account {
        id
        account_type
        alternative_account_id
      }
    }
  }
`
type Props = {
  error?: Record<string, any>
  accountId: string
}

const UserActions = (props: Props) => {
  const data = useLazyLoadQuery<UserActionsQuery>(USER_ACTIONS_QUERY, {})

  const { current_user, users, user_accounts } = data
  const account = current_user.account
  const { isAdmin } = usePermissionsContext({ user: current_user })

  const isMemberOfOtherAccounts = getIsMemberOfOtherAccounts(
    user_accounts,
    account.id,
  )

  const [showModal, setShowModal] = useState(false)

  const isOnlyAdmin =
    isAdmin &&
    users.filter((user) => {
      const userPermissions = user.permissions as Permissions
      return userPermissions.type === UserType.Admin
    }).length === 1

  const isLiveAccount = account.account_type === "live"

  const DeleteButton = () => {
    return (
      <Tooltip
        disabled={isLiveAccount}
        content="Cannot delete in Test Account"
        className={styles.tooltip}
      >
        <Button
          text="Delete User Account"
          onClick={() => setShowModal(true)}
          icon={<Icon icon="trash" />}
          disabled={!isLiveAccount}
        />
      </Tooltip>
    )
  }

  const resetLocalSettings = () => {
    localStorage.clear()
    showToast({
      message: "Your browser cache has been reset. No data has been deleted.",
      type: "success",
    })
  }

  const renderDeleteUserAccount = () => (
    <div className="mv4 br2 ba b--mid-gray-2 hover-shadow-18 black w-100 w-50-l w-50-ns">
      <Card>
        <CardHeader noSticky>Delete User Account</CardHeader>
        <div className={styles.wrapper}>
          <b>
            Deleting your user account on {account.name} is permanent and cannot
            be reversed.
          </b>
          {isMemberOfOtherAccounts ? (
            <p style={{ marginTop: 2 }}>
              You will still be able to access your other account/s.
            </p>
          ) : null}
          <div style={{ marginTop: 15 }}>
            <DeleteButton />
          </div>
        </div>
        <Dialog isOpen={showModal}>
          <DeleteUserForm
            isSelf
            user={current_user}
            onClose={() => setShowModal(false)}
            accountName={account.name}
            isMemberOfOtherAccounts={isMemberOfOtherAccounts}
          />
        </Dialog>
      </Card>
    </div>
  )

  const renderOnlyAdminDeleteUserAccount = () => (
    <div className="mv4 br2 ba b--mid-gray-2 hover-shadow-18 black w-100 w-50-l w-50-ns">
      <Card>
        <CardHeader noSticky>Delete User Account</CardHeader>
        <div className={styles.wrapper}>
          <b>
            As the only account admin, you cannot delete your profile until
            there is another admin. If you would like to delete the account and
            all its data, go to <a href="/account">Account Settings</a>.
          </b>
        </div>
      </Card>
    </div>
  )

  return (
    <PermissionsProvider user={current_user}>
      <HasuraContextProvider value={current_user}>
        <div className="mv4 br2 ba b--mid-gray-2 hover-shadow-18 black w-100 w-50-l w-50-ns">
          <Card>
            <CardHeader noSticky>User Actions</CardHeader>
            <div className={styles.wrapper}>
              <Tooltip
                placement="top"
                content={
                  "This will reset preference such as last viewed project, last used filter, last viewed report. You can safely use this at any time."
                }
              >
                <b>
                  This may help if you are encountering any errors. It will not
                  delete any data and is safe to use.
                  <Help />
                </b>
              </Tooltip>
              <div style={{ marginTop: 15 }}>
                <Button
                  icon={<Icon icon="reset" />}
                  onClick={resetLocalSettings}
                >
                  Reset browser cache
                </Button>
              </div>
            </div>
          </Card>
        </div>
        {isOnlyAdmin
          ? renderOnlyAdminDeleteUserAccount()
          : renderDeleteUserAccount()}
      </HasuraContextProvider>
    </PermissionsProvider>
  )
}

export default withRelayPageContainer(UserActions)
