import React from "react"

export const TangramDiamond = () => (
  <svg
    width="140"
    height="107"
    viewBox="0 0 140 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2_24"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="140"
      height="107"
    >
      <rect width="140" height="107" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_2_24)">
      <path
        d="M69.9649 36.2032V105.647L139.408 36.2032H69.9649Z"
        fill="#F5848C"
      />
      <path
        d="M104.655 1.61663L139.377 36.3384H104.655L69.933 1.61663H104.655Z"
        fill="#FFB077"
      />
      <path
        d="M69.8445 36.0827L35.1227 36.0827L35.1227 1.3609L69.8445 1.3609L69.8445 36.0827Z"
        fill="#FDCD4F"
      />
      <path
        d="M69.9488 36.1581L104.671 36.1581L69.9488 1.43634L69.9488 36.1581Z"
        fill="#0A9CC1"
      />
      <path
        d="M35.1383 36.158L0.416504 36.158L35.1383 1.43621L35.1383 36.158Z"
        fill="#B19DE6"
      />
      <path
        d="M69.9044 36.2032L0.46077 36.2032L69.9044 105.647L69.9044 36.2032Z"
        fill="#4057DF"
      />
    </g>
  </svg>
)
