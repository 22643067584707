import { MenuItem as MenuItem2, MenuItemProps } from "@blueprintjs/core"
import cc from "classcat"
import React from "react"

import styles from "./MenuItem.module.css"

import { submitOnEnterOrSpace } from "~/helpers/hotkeys"

type Props = {
  id?: string
  style?: Record<string, any>
  hidden?: boolean
  // Enabled item, visually disabled
  softDisabled?: boolean
} & MenuItemProps

const MenuItem = (props: Props) => {
  if (props.hidden) {
    return null
  }
  const { softDisabled, ...rest } = props
  // Blueprint currently doesn't have tabbable capability for their menuItems
  // Pressing enter or space on a tabbed MenuItem2 does nothing
  // This calls the onClick function on Enter (keycode 13) or Space (keycode 32)

  const onKeyDown = (e) => {
    submitOnEnterOrSpace(e, props.onClick)
  }

  return (
    <MenuItem2
      {...rest}
      className={cc([
        props.className,
        styles.menuItem,
        { [styles.softDisabled]: softDisabled },
      ])}
      onKeyDown={onKeyDown}
    />
  )
}

export default MenuItem
