import React from "react"

type Props = {
  color?: string
  size?: number
  alt?: string
}
const Plus = (props: Props) => {
  const { size = 10 } = props

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.alt}</title>
      <path
        fill={props.color || "#fff"}
        d="M4.13575 0.397949H5.86975V3.83195H9.28675V5.56595H5.86975V8.99995H4.13575V5.56595H0.71875V3.83195H4.13575V0.397949Z"
      />
    </svg>
  )
}

export default Plus
