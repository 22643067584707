import { Icon } from "@blueprintjs/core"
import { Tooltip } from "@blueprintjs/core"
import { format } from "date-fns"
import React from "react"

type Props = {
  date: Date
}

const LastCopied = (props: Props) => {
  const { date } = props

  return (
    <Tooltip
      content={format(date, "'Last copied' d LLL yyyy 'at' h:mma")}
      position="bottom"
    >
      <Icon icon="time" />
    </Tooltip>
  )
}

export default LastCopied
