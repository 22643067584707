import React from "react"

type Props = {
  size?: number
  color?: string
}

const Refresh = (props: Props) => {
  const { size = 12, color = "var(--smoke)" } = props
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 0.666504C3.9355 0.666504 1.644 2.8845 1.5125 5.6665H0L2.2685 8.625L4.5 5.6665H3.0125C3.1425 3.7155 4.7665 2.1665 6.75 2.1665C8.818 2.1665 10.5 3.8485 10.5 5.9165C10.5 7.9845 8.818 9.6665 6.75 9.6665C5.5595 9.6665 4.499 9.107 3.812 8.2395L2.8885 9.464C3.848 10.508 5.2205 11.1665 6.75 11.1665C9.649 11.1665 12 8.8155 12 5.9165C12 3.0175 9.649 0.666504 6.75 0.666504Z"
        fill={color}
      />
    </svg>
  )
}

export default Refresh
