/**
 * @generated SignedSource<<b88a58a784a55307bbefcb5b1450d581>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HolidaysContainerQuery$variables = {
  maxDate: any;
  minDate: any;
};
export type HolidaysContainerQuery$data = {
  readonly current_user: {
    readonly account: {
      readonly holidays_groups: ReadonlyArray<{
        readonly id: number;
      }>;
      readonly id: number;
      readonly " $fragmentSpreads": FragmentRefs<"CreateHolidaysGroupForm_viewer" | "EditHolidaysGroupForm_account" | "HolidaysGroupList_viewer">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_user">;
  } | null | undefined;
};
export type HolidaysContainerQuery = {
  response: HolidaysContainerQuery$data;
  variables: HolidaysContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "minDate"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "name": "asc"
    }
  }
],
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HolidaysContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "holidays_groups",
                "kind": "LinkedField",
                "name": "holidays_groups",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": "holidays_groups(order_by:{\"name\":\"asc\"})"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreateHolidaysGroupForm_viewer"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "HolidaysGroupList_viewer"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditHolidaysGroupForm_account"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionsProvider_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HolidaysContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "users",
        "kind": "LinkedField",
        "name": "current_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "holidays_groups",
                "kind": "LinkedField",
                "name": "holidays_groups",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country_code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "region_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "distinct_on",
                        "value": "name"
                      }
                    ],
                    "concreteType": "holidays_aggregate",
                    "kind": "LinkedField",
                    "name": "holidays_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "holidays_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": "holidays_aggregate(distinct_on:\"name\")"
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "distinct_on",
                        "value": "id"
                      },
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "active": {
                            "_eq": true
                          }
                        }
                      }
                    ],
                    "concreteType": "people_aggregate",
                    "kind": "LinkedField",
                    "name": "people_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "people_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": "people_aggregate(distinct_on:\"id\",where:{\"active\":{\"_eq\":true}})"
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "kind": "Variable",
                                "name": "_gte",
                                "variableName": "minDate"
                              },
                              {
                                "kind": "Variable",
                                "name": "_lte",
                                "variableName": "maxDate"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "date"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "where"
                      }
                    ],
                    "concreteType": "holidays",
                    "kind": "LinkedField",
                    "name": "holidays",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "holiday_api_uuid",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "observed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "holidays_groups(order_by:{\"name\":\"asc\"})"
              },
              {
                "alias": "allHolidaysGroups",
                "args": null,
                "concreteType": "holidays_groups",
                "kind": "LinkedField",
                "name": "holidays_groups",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timesheets_protected",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "project_managers",
            "kind": "LinkedField",
            "name": "manageable_projects",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "projects",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "61329f60d30a15f57ccf8920596a7b67",
    "id": null,
    "metadata": {},
    "name": "HolidaysContainerQuery",
    "operationKind": "query",
    "text": "query HolidaysContainerQuery(\n  $minDate: date!\n  $maxDate: date!\n) {\n  current_user {\n    account {\n      id\n      holidays_groups(order_by: {name: asc}) {\n        id\n      }\n      ...CreateHolidaysGroupForm_viewer\n      ...HolidaysGroupList_viewer\n      ...EditHolidaysGroupForm_account\n    }\n    ...PermissionsProvider_user\n  }\n}\n\nfragment CreateHolidaysGroupForm_viewer on accounts {\n  holidays_groups(order_by: {name: asc}) {\n    id\n    name\n    country_code\n  }\n}\n\nfragment EditHolidaysGroupForm_account on accounts {\n  allHolidaysGroups: holidays_groups {\n    id\n    name\n  }\n}\n\nfragment EditHolidaysGroupForm_holidays_group on holidays_groups {\n  id\n  name\n  region_name\n  country_name\n  country_code\n  holidays(where: {date: {_gte: $minDate, _lte: $maxDate}}) {\n    id\n    holiday_api_uuid\n    name\n    date\n    observed\n    type\n  }\n}\n\nfragment HolidaysGroupList_viewer on accounts {\n  holidays_groups(order_by: {name: asc}) {\n    id\n    name\n    country_code\n    region_name\n    country_name\n    holidays_aggregate(distinct_on: name) {\n      aggregate {\n        count\n      }\n    }\n    people_aggregate(where: {active: {_eq: true}}, distinct_on: id) {\n      aggregate {\n        count\n      }\n    }\n    ...EditHolidaysGroupForm_holidays_group\n  }\n  ...EditHolidaysGroupForm_account\n}\n\nfragment PermissionsProvider_user on users {\n  id\n  email\n  account {\n    id\n    timesheets_protected\n  }\n  permissions\n  manageable_projects {\n    id\n    project {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "63f4a6eee2b65a638b59552d81f761ca";

export default node;
