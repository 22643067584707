import { Icon } from "@blueprintjs/core"
import cc from "classcat"
import React, { useState } from "react"
import AutosizeInput from "react-input-autosize"

import styles from "./Tag.module.css"

import { Skill } from "../common/react-icons"
import Dialog from "~/common/Dialog"
import Dropdown from "~/common/Dropdown/Dropdown"
import { IconThreeDot } from "~/common/IconThreeDot"
import MenuItem from "~/common/MenuItem"

import ConvertPersonTagToSkill from "./ConvertPersonTagToSkill"

export type Tag = {
  id: number
  name: string
}

type Props = {
  children: string | typeof AutosizeInput
  model?: "person" | "project"
  tag?: Tag
  canEdit?: boolean
  light?: boolean
  className?: string
  onDelete?: (tag: Tag) => void
}

const Tag = ({
  canEdit,
  tag,
  model,
  light,
  children,
  className,
  onDelete,
}: Props) => {
  const [showDialog, setShowDialog] = useState(false)
  const openDialog = () => setShowDialog(true)
  const closeDialog = () => setShowDialog(false)

  // TODO(rowan): Fix this issue caused by transactions
  const showConvertToTag = false

  return (
    <div className={cc([styles.inlineFlex, className])}>
      <Dropdown
        Target={() => (
          <div
            data-component="Tag/Target"
            data-test={`tag-${tag?.name ?? "create-new"}`}
            className={cc({
              [styles.tag]: true,
              [styles.light]: light,
              [styles.editable]: canEdit,
            })}
          >
            <div className={styles.tagContent}>{children}</div>
            {canEdit && (
              <IconThreeDot className={styles.menuTarget} size={10} />
            )}
          </div>
        )}
        disabled={!canEdit}
      >
        <MenuItem
          text="Delete"
          icon={<Icon icon="trash" />}
          onClick={() => onDelete(tag)}
        />
        {showConvertToTag && model === "person" && (
          <>
            <MenuItem
              text="Convert to Skill"
              icon={<Skill />}
              onClick={openDialog}
            />
          </>
        )}
      </Dropdown>
      {model === "person" && (
        <Dialog isOpen={showDialog} onClose={closeDialog}>
          <ConvertPersonTagToSkill closeDialog={closeDialog} tag={tag} />
        </Dialog>
      )}
    </div>
  )
}

export default Tag
