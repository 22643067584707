import { dateHelpers } from "@runn/calculations"

export const itemsOverlap = (item1: ItemWithDates, item2: ItemWithDates) => {
  if (item1.id && item2.id && item1.id === item2.id) {
    return false
  }
  if (item1.end_date && Number(item2.start_date) > Number(item1.end_date)) {
    return false
  }
  if (item2.end_date && Number(item2.end_date) < Number(item1.start_date)) {
    return false
  }
  return true
}

export const itemFullyOverlaps = (
  overlapping: ItemWithDates,
  overlapped: ItemWithDates,
) =>
  overlapped.start_date >= overlapping.start_date &&
  overlapped.end_date <= overlapping.end_date

export const getEarliestDate = (date1: string, date2: string) =>
  Number(date1) < Number(date2) ? date1 : date2

export const getLatestDate = (date1: string, date2: string) =>
  Number(date1) > Number(date2) ? date1 : date2

export const sortByStartDate = (
  item1: { start_date: string },
  item2: { start_date: string },
) => Number(item1.start_date) - Number(item2.start_date)

export const addCalendarDays = (date: string, days: number) =>
  dateHelpers.addBusDays(date, days)

export const subtractCalendarDays = (date: string, days: number) =>
  dateHelpers.subBusDays(date, days)

export const isBefore = (date1: string, date2: string) =>
  Number(date1) < Number(date2)

export const isBeforeOrSame = (date1: string, date2: string) =>
  Number(date1) <= Number(date2)

export const isAfter = (date1: string, date2: string) =>
  Number(date1) > Number(date2)

export const isAfterOrSame = (date1: string, date2: string) =>
  Number(date1) >= Number(date2)

type ItemWithDates = {
  id?: number
  start_date: string
  end_date: string
}

export default {
  itemsOverlap,
  getEarliestDate,
  getLatestDate,
  sortByStartDate,
  addCalendarDays,
  subtractCalendarDays,
  isBefore,
  isBeforeOrSame,
  isAfter,
  isAfterOrSame,
}
