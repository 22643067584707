import { showToast } from "~/containers/ToasterContainer"

import { syncAll } from "./routes"

export const fetchWithCsrf = (
  info: RequestInfo,
  init?: RequestInit,
): Promise<Response> => {
  return fetch(info, {
    ...(init || {}),
    headers: {
      ...(init?.headers || {}),
      // @ts-ignore
      "X-CSRF-Token": Rails.csrfToken(),
    },
  })
}

export const syncIntegrationData = () => {
  fetchWithCsrf(syncAll(), { method: "POST" })
    .then(() =>
      showToast({
        type: "success",
        message: `Import scheduled, it might take a few minutes.`,
      }),
    )
    .catch((e) => e)
}

export default {
  syncIntegrationData,
  fetchWithCsrf,
}
