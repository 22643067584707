import React, { useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./WorkstreamsContainer.module.css"

import {
  WorkstreamsContainerQuery,
  WorkstreamsContainerQuery$data,
} from "./__generated__/WorkstreamsContainerQuery.graphql"

import { HasuraContextProvider } from "~/store/hasura"

import Select from "~/common/Select"
import { WorkstreamIcon } from "~/common/react-icons"

import EntitlementsProvider from "~/Entitlements/EntitlementsProvider"
import FeatureAccessWrapper from "~/Entitlements/FeatureAccessWrapper"
import { ChargebeeFeatures } from "~/Entitlements/plansAndFeatures"
import {
  ACTIVE_FILTER_OPTIONS,
  DEFAULT_ACTIVE_FILTER_OPTION,
} from "~/GLOBALVARS"
import PermissionsProvider, {
  usePermissionsContext,
} from "~/Permissions/PermissionsProvider"
import { withRelayPageContainer } from "~/RelayPageContainer"
import ServerFlagsProvider from "~/ServerFlags/ServerFlagsProvider"
import PageBody from "~/containers/PageBody"
import { Card } from "~/dashboard"
import { PageHeader } from "~/dashboard/PageHeader"

import WorkstreamCard from "./WorkstreamCard"

const QUERY = graphql`
  query WorkstreamsContainerQuery {
    current_user {
      account {
        id
      }
      ...WorkstreamCard_user
      ...PermissionsProvider_user
    }
    billing(syncSubscription: false) {
      ...EntitlementsProvider_billing
    }
    ...ServerFlagsProvider_gql
  }
`

const Inner = ({
  current_user,
}: {
  current_user: WorkstreamsContainerQuery$data["current_user"]
}) => {
  const { can, subject } = usePermissionsContext({ user: current_user })
  const canCreate = can("create", subject("Workstream"))
  const canEdit = can("edit", subject("Workstream"))

  const [activeFilter, setActiveFilter] = useState(DEFAULT_ACTIVE_FILTER_OPTION)

  const handleFilterChange = (newFilter) => {
    setActiveFilter(newFilter)
  }

  return (
    <>
      <PageHeader
        title={"Workstreams"}
        icon={<WorkstreamIcon color="var(--runn-blue)" />}
      />
      <FeatureAccessWrapper featureId={ChargebeeFeatures.workstreams}>
        <PageBody style={{ gridGap: 0 }}>
          <div className={styles.header}>
            <div className={styles.statusFilter}>
              <span>Filter</span>
              <Select
                name="activeFilter"
                id="activeFilter"
                value={activeFilter}
                onChange={handleFilterChange}
                options={ACTIVE_FILTER_OPTIONS}
                width="175px"
                isSearchable={false}
              />
            </div>
          </div>
          <Card>
            <WorkstreamCard
              user={current_user}
              canCreate={canCreate}
              canEdit={canEdit}
              activeFilter={activeFilter.value}
            />
          </Card>
        </PageBody>
      </FeatureAccessWrapper>
    </>
  )
}

const WorkstreamsContainer = () => {
  const data = useLazyLoadQuery<WorkstreamsContainerQuery>(QUERY, {})
  const { current_user, billing } = data

  return (
    <ServerFlagsProvider gql={data}>
      <PermissionsProvider user={current_user}>
        <EntitlementsProvider billing={billing}>
          <HasuraContextProvider value={current_user}>
            <Inner current_user={current_user} />
          </HasuraContextProvider>
        </EntitlementsProvider>
      </PermissionsProvider>
    </ServerFlagsProvider>
  )
}

export default withRelayPageContainer(WorkstreamsContainer)
