import { Popover as BlueprintPopover, PopoverProps } from "@blueprintjs/core"
import React, { useCallback } from "react"

type Popover2Props = PopoverProps

const Popover2 = (props: Popover2Props) => {
  const { onClose, children, ...rest } = props
  const onCloseWrapper = useCallback(
    (e) => {
      // Popovers can contain elements which remove themselves on click,
      // for example the "clear" icon inside a react-select, within a dialog triggered by a popover menu.
      // When initialised with canOutsideClickClose=true (the default),
      // Blueprint's Dialog and Overlay component would falsely interpret this orphaned element as a click "outside".
      // We can intercept this behaviour here and cancel the close event if we're dealing with orphaned DOM nodes.
      const target = e?.composed ? e.composedPath()[0] : e?.target
      if (target && !document.body.contains(target)) {
        return
      }

      // Execute original handler (if present)
      onClose ? onClose(e) : null
    },
    [onClose],
  )

  return (
    <BlueprintPopover onClose={onCloseWrapper} {...rest} fill={true}>
      {children}
    </BlueprintPopover>
  )
}

export { Popover2, Popover2Props }
