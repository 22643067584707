import React from "react"

import styles from "./IconTableItem.module.css"

import TooltipEllipsis from "~/common/TooltipEllipsis"

import { handleCopy } from "../../helper"

type Props = {
  name: string
  Icon: React.ComponentType<any>
}

const IconTableItem = (props: Props) => {
  const { name, Icon } = props

  return (
    <div className={styles.container} onClick={() => handleCopy(name)}>
      <div className={styles.icon}>
        <Icon size={30} color="var(--midnight)" />
      </div>
      <TooltipEllipsis
        text={name}
        className={styles.name}
        customMaxWidth="100px"
      />
    </div>
  )
}

export default IconTableItem
