import React from "react"

export const TangramPersonWalking = () => (
  <svg
    width="66"
    height="100"
    viewBox="0 0 66 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.9429 56.8049L32.9429 23.5293L1.38401e-05 56.8049L32.9429 56.8049Z"
      fill="#F5848C"
    />
    <path
      d="M33 23.5293L44.6471 11.7646L33 -0.00011946L21.3529 11.7646L33 23.5293Z"
      fill="#FDCD4F"
    />
    <path
      d="M1.44141 80.3496L17.9128 80.3496L1.44141 96.9874L1.44141 80.3496Z"
      fill="#0A9CC1"
    />
    <path
      d="M32.9714 80.3351H9.67725L32.9714 56.8057L32.9714 80.3351Z"
      fill="#67D0D5"
    />
    <path
      d="M49.4427 73.441L32.9713 56.8032V73.441L49.4427 90.0788L49.4427 73.441Z"
      fill="#FFB077"
    />
    <path
      d="M42.6917 99.9161L42.6917 83.2783L59.1631 99.9161L42.6917 99.9161Z"
      fill="#B19DE6"
    />
    <path
      d="M32.9715 23.501L65.9143 23.501L32.9715 56.7766L32.9715 23.501Z"
      fill="#4057DF"
    />
    <defs>
      <clipPath id="clip0_616_46610">
        <rect width="66" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
