import { Dialog, Tab } from "@blueprintjs/core"
import React from "react"

import styles from "./AssetsDialog.module.css"

import Tabs from "~/common/Tabs"

import IconTable from "~/DevTools/Assets/IconTable/IconTable"
import TangramTable from "~/DevTools/Assets/TangramTable/TangramTable"

type Props = {
  isOpen: boolean
  onClose: () => void
}

const AssetsDialog = (props: Props) => {
  const { isOpen, onClose } = props
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <Tabs large id="iconsAndTangrams" renderActiveTabPanelOnly>
          <Tab id="icons" title="React Icons" panel={<IconTable />} />
          <Tab id="tangrams" title="Tangrams" panel={<TangramTable />} />
        </Tabs>
      </div>
    </Dialog>
  )
}

export default AssetsDialog
