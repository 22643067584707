import React from "react"

type Props = {
  color?: any
  alt?: string
}
const Reports = (props: Props) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25002 10.75V9.25003H12.75V10.75H5.25002ZM5.25002 6.74998V5.25003H12.75V6.74998H5.25002ZM2.9 12.925H10.075C10.5702 12.925 11.0286 13.032 11.4502 13.246C11.8718 13.46 12.2294 13.7627 12.523 14.1539L15.1 17.5039V3.15773C15.1 3.06798 15.0711 2.99425 15.0134 2.93655C14.9557 2.87885 14.882 2.85 14.7923 2.85H3.20772C3.11797 2.85 3.04425 2.87885 2.98655 2.93655C2.92885 2.99425 2.9 3.06798 2.9 3.15773V12.925ZM3.20772 19.15H13.6673L10.8173 15.4712C10.725 15.3558 10.6156 15.2609 10.4891 15.1865C10.3626 15.1122 10.2246 15.075 10.075 15.075H2.9V18.8423C2.9 18.932 2.92885 19.0058 2.98655 19.0635C3.04425 19.1212 3.11797 19.15 3.20772 19.15ZM14.7923 21.3H3.20772C2.52384 21.3 1.94326 21.0613 1.46597 20.584C0.988673 20.1067 0.750023 19.5262 0.750023 18.8423V3.15773C0.750023 2.47384 0.988673 1.89326 1.46597 1.41598C1.94326 0.938677 2.52384 0.700027 3.20772 0.700027H14.7923C15.4762 0.700027 16.0567 0.938677 16.534 1.41598C17.0113 1.89326 17.25 2.47384 17.25 3.15773V18.8423C17.25 19.5262 17.0113 20.1067 16.534 20.584C16.0567 21.0613 15.4762 21.3 14.7923 21.3Z"
      fill={props.color || "var(--runn-blue)"}
    />
  </svg>
)

export default Reports
