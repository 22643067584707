import { graphql } from "react-relay"

import { TagBulkCreateMutation } from "./__generated__/TagBulkCreateMutation.graphql"
import { TagPeopleArchiveMutation } from "./__generated__/TagPeopleArchiveMutation.graphql"
import { TagPeopleCreateMutation } from "./__generated__/TagPeopleCreateMutation.graphql"
import { TagPeopleDeleteMutation } from "./__generated__/TagPeopleDeleteMutation.graphql"
import { TagProjectArchiveMutation } from "./__generated__/TagProjectArchiveMutation.graphql"
import { TagProjectCreateMutation } from "./__generated__/TagProjectCreateMutation.graphql"

import { environment } from "~/store/hasura"

import { commitMutationPromise } from "./helpers"
import { addLinkedRecord } from "./helpers"

import { showToast } from "~/containers/ToasterContainer"

const projectTagCreateMutation = graphql`
  mutation TagProjectCreateMutation($name: String!) {
    createProjectTag(name: $name) {
      account {
        id
        tags {
          id
          model
          name
        }
      }
    }
  }
`

export const projectTagCreateRelay = async (
  variables: TagProjectCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<TagProjectCreateMutation>(
    environment,
    {
      mutation: projectTagCreateMutation,
      variables,
      updater: (store) => {
        const tag = store.getRootField("createProjectTag")
        const account = tag.getLinkedRecord("account")
        addLinkedRecord(account, "tags", tag)
      },
    },
  )

  const response = data.createProjectTag

  if (response) {
    showToast({
      message: `New project tag created`,
      description: variables.name,
      type: "success",
    })
  }

  return response.account
}

const peopleTagCreateMutation = graphql`
  mutation TagPeopleCreateMutation($name: String!) {
    createPeopleTag(name: $name) {
      account {
        id
        tags {
          id
          model
          name
        }
      }
    }
  }
`

export const peopleTagCreateRelay = async (
  variables: TagPeopleCreateMutation["variables"],
) => {
  const data = await commitMutationPromise<TagPeopleCreateMutation>(
    environment,
    {
      mutation: peopleTagCreateMutation,
      variables,
      updater: (store) => {
        const tag = store.getRootField("createPeopleTag")
        const account = tag.getLinkedRecord("account")
        addLinkedRecord(account, "tags", tag)
      },
    },
  )

  const response = data.createPeopleTag

  if (response) {
    showToast({
      message: `New people tag created`,
      description: variables.name,
      type: "success",
    })
  }

  return response.account
}

export const tagBulkCreateMutation = graphql`
  mutation TagBulkCreateMutation($input: TagBulkCreateInput!) {
    action_tag_bulk_create(input: $input) {
      account {
        id
        tags {
          id
        }
        person_tags: tags(where: { model: { _eq: "person" } }) {
          id
          name
          model
          archived
        }
        project_tags: tags(where: { model: { _eq: "project" } }) {
          id
          name
          model
          archived
        }
      }
    }
  }
`

type TagBulkCreateOptions = {
  variables: TagBulkCreateMutation["variables"]
}

export const tagBulkCreateRelay = async (options: TagBulkCreateOptions) => {
  const { variables } = options

  const data = await commitMutationPromise<TagBulkCreateMutation>(environment, {
    mutation: tagBulkCreateMutation,
    variables,
  })

  const tags = data.action_tag_bulk_create

  if (tags) {
    showToast({
      message: "Tags saved",
      type: "success",
    })
  }
  return tags
}

export const deletePeopleTagRelay = (
  variables: TagPeopleDeleteMutation["variables"],
) => {
  return commitMutationPromise<TagPeopleDeleteMutation>(environment, {
    mutation: graphql`
      mutation TagPeopleDeleteMutation($id: Int!, $peopleIds: [Int!]!) {
        deletePeopleTag(id: $id, peopleIds: $peopleIds) {
          account {
            id
            tags {
              id
              model
              name
              archived
            }
          }
        }
      }
    `,
    variables,
  })
}

export const archivePeopleTagRelay = (
  variables: TagPeopleArchiveMutation["variables"],
) => {
  return commitMutationPromise<TagPeopleArchiveMutation>(environment, {
    mutation: graphql`
      mutation TagPeopleArchiveMutation($id: Int!, $archived: Boolean!) {
        updatePeopleTag(id: $id, archived: $archived) {
          account {
            id
            tags {
              id
              model
              name
              archived
            }
          }
        }
      }
    `,
    variables,
  })
}

export const archiveProjectTagRelay = (
  variables: TagProjectArchiveMutation["variables"],
) => {
  return commitMutationPromise<TagProjectArchiveMutation>(environment, {
    mutation: graphql`
      mutation TagProjectArchiveMutation($id: Int!, $archived: Boolean!) {
        updateProjectTag(id: $id, archived: $archived) {
          account {
            id
            tags {
              id
              model
              name
              archived
            }
          }
        }
      }
    `,
    variables,
  })
}
