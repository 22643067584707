import React from "react"

import styles from "./PersonDetails.module.css"

import { buildImageUrl } from "~/helpers/image"
import { formatName, formatNameAsInitials } from "~/helpers/person"

import Avatar from "./Avatar"
import TooltipEllipsis from "./TooltipEllipsis"

type Props = {
  person: {
    first_name: string
    last_name: string
    image_key?: string
    email?: string
    is_placeholder?: boolean
    team?: {
      name: string
    }
  }
  subtitle?: string
  size?: number
  large?: boolean
  reverse?: boolean
  link?: string
  icons?: React.ReactNode
  fontSize?: number
  customMaxWidth?: number
  placeholderProjectName?: string
  onClick?: () => void
}

const PersonDetails = (props: Props) => {
  const {
    person,
    person: { first_name, last_name, is_placeholder },
    subtitle,
    size,
    large,
    reverse = false,
    link,
    icons,
    fontSize,
    customMaxWidth,
    placeholderProjectName,
    onClick,
  } = props
  const name = formatName(first_name, last_name)
  const initials = formatNameAsInitials(first_name, last_name)

  const avatarSize = large ? 40 : size

  const avatar = (
    <Avatar
      avatar={buildImageUrl(person.image_key, {
        width: avatarSize,
        height: avatarSize,
        quality: "auto",
      })}
      email={person.email}
      initials={initials}
      is_placeholder={is_placeholder}
      size={avatarSize}
    />
  )

  const width = customMaxWidth || 230
  const widthIcons = icons ? width - 30 : width

  const enhancedSubtitle = (() => {
    if (person.team?.name == null && !is_placeholder) {
      return subtitle
    }

    const str = `${subtitle ? `${subtitle} | ` : ""}`

    if (is_placeholder) {
      return `${str} ${placeholderProjectName ? placeholderProjectName : ""}`
    }

    return `${str} ${person.team?.name || ""}`
  })()

  return (
    <>
      {reverse ? null : avatar}
      <div className={`${styles.personDetails} fs-exclude`}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TooltipEllipsis
            text={name}
            className={styles.title}
            style={{ fontSize: (large && "16px") || fontSize }}
            href={!onClick ? link : null}
            customMaxWidth={`${widthIcons}px`}
            onClick={onClick}
          />
          {!!icons && icons}
        </div>
        {enhancedSubtitle && (
          <TooltipEllipsis
            text={enhancedSubtitle}
            className={styles.subtitle}
            style={{ fontSize: (large && "14px") || fontSize }}
          />
        )}
      </div>
      {reverse ? avatar : null}
    </>
  )
}

export default PersonDetails
