import React, { useState } from "react"

import { reportError } from "~/helpers/error-helpers"
import { fetchWithCsrf } from "~/helpers/fetch-helpers"
import { autofillAllTimesheetsWithZero } from "~/helpers/routes"

import Input from "~/common/Input"
import { ModalBody, ModalFooter, ModalFormWrapper } from "~/common/ModalForm"
import Button from "~/common/buttons/Button"
import { TangramAlert } from "~/common/tangrams"

import { showToast } from "~/containers/ToasterContainer"

type Props = {
  onClose: () => void
}

const autofillTimesheets = () => {
  fetchWithCsrf(autofillAllTimesheetsWithZero(), { method: "POST" }).catch(
    (e) => {
      {
        void reportError(`Autofill timesheets with zero: `, e)
        showToast({
          message:
            "Sorry, we couldn't autofill timesheets with zero. Please refresh the page and try again.",
          type: "error",
        })
      }
    },
  )
}

const AutofillTimesheetsForm = (props: Props) => {
  const { onClose } = props

  const [isLoading, setIsLoading] = useState(false)
  const [confirmationText, setConfirmationText] = useState("")
  const enableSubmit = confirmationText.toLowerCase() === "autofill with zero"

  const handleClose = () => {
    if (!isLoading) {
      onClose()
    }
  }

  const handleSubmit = () => {
    setIsLoading(true)
    autofillTimesheets()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationText(e.target.value)
  }

  return (
    <ModalFormWrapper
      headerTitle={"Autofill with zero"}
      tangram={<TangramAlert />}
      tangramStyles={{
        bottom: 25,
      }}
    >
      <ModalBody>
        <p>
          This will fill <b>all</b> missing timesheets entries with 0.
        </p>
        <p>
          <b>This can not be reversed.</b>
        </p>
        <p>
          It is primarily used if you are importing timesheet data from another
          source, such as through CSVs or integrations.
        </p>
        <p>
          We strongly advise you speak to Customer Success before proceeding.
        </p>
        <p>
          To proceed write <b className="notranslate">Autofill with zero</b> in
          the text box below.
        </p>
        <Input value={confirmationText} onChange={handleInputChange}></Input>
      </ModalBody>
      <ModalFooter>
        <Button text="Cancel" disabled={isLoading} onClick={handleClose} />
        <Button
          text="Autofill with zero"
          intent="primary"
          outlined={false}
          loading={isLoading}
          onClick={handleSubmit}
          disabled={isLoading || !enableSubmit}
        />
      </ModalFooter>
    </ModalFormWrapper>
  )
}

export default AutofillTimesheetsForm
