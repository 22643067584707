import React from "react"

const Star = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79419 13.7861C8.92087 13.7101 9.07913 13.7101 9.20581 13.7861L13.7506 16.5131C14.0538 16.6951 14.4266 16.423 14.3458 16.0788L13.1414 10.9462C13.1074 10.801 13.1568 10.649 13.2698 10.5517L17.2782 7.09951C17.5468 6.86818 17.404 6.42763 17.0508 6.39784L11.7689 5.95234C11.6213 5.93989 11.4927 5.84689 11.4346 5.71062L9.36796 0.863091C9.22975 0.538916 8.77025 0.538917 8.63204 0.863091L6.56542 5.71062C6.50733 5.84689 6.37869 5.93989 6.23108 5.95234L0.949186 6.39784C0.595951 6.42763 0.453169 6.86818 0.721773 7.09951L4.73019 10.5517C4.84316 10.649 4.89264 10.801 4.85858 10.9462L3.65418 16.0788C3.5734 16.423 3.94622 16.6951 4.2494 16.5131L8.79419 13.7861Z"
      fill="#3C4260"
    />
  </svg>
)

export default Star
