import cc from "classcat"
import React from "react"

import styles from "./Dropdown.module.css"

type Props = React.HTMLProps<HTMLDivElement> & {
  children: React.ReactNode
}

const DropdownHeading = (props: Props) => {
  const { className, ...rest } = props
  return (
    <div className={cc([className, styles.heading])} {...rest}>
      {props.children}
    </div>
  )
}

export default DropdownHeading
