import React from "react"

const Sync = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <mask
      id="mask0_2866_112697"
      style={{ maskType: "alpha" }}
      width="18"
      height="18"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path fill="var(--runn-blue)" d="M0 0H18V18H0z"></path>
    </mask>
    <g mask="url(#mask0_2866_112697)">
      <path
        fill="var(--runn-blue)"
        d="M3.825 12.037a6.188 6.188 0 01-.619-1.462A5.772 5.772 0 013 9.038c0-1.676.581-3.1 1.744-4.276C5.906 3.589 7.325 3 9 3h.131l-1.2-1.2L8.981.75l3 3-3 3-1.05-1.05 1.2-1.2H9c-1.25 0-2.313.44-3.188 1.322C4.938 6.703 4.5 7.775 4.5 9.037c0 .325.037.644.112.957.075.312.188.619.338.918l-1.125 1.125zm5.194 5.213l-3-3 3-3 1.05 1.05-1.2 1.2H9c1.25 0 2.313-.44 3.188-1.322.874-.881 1.312-1.953 1.312-3.216 0-.325-.037-.643-.113-.956a4.288 4.288 0 00-.337-.918l1.125-1.125c.275.474.481.962.619 1.462.137.5.206 1.013.206 1.538 0 1.675-.581 3.1-1.744 4.275C12.094 14.413 10.675 15 9 15h-.131l1.2 1.2-1.05 1.05z"
      ></path>
    </g>
  </svg>
)

export default Sync
