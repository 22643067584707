/**
 * @generated SignedSource<<599f371c053ef7fa20d9d0002a1890b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RateCardUpdateInput = {
  blended_rate?: number | null | undefined;
  blended_rate_card?: boolean | null | undefined;
  description?: string | null | undefined;
  name?: string | null | undefined;
  rate_card_id: number;
  rate_type?: string | null | undefined;
  references?: any | null | undefined;
  roles?: ReadonlyArray<RateCardUpdateInputRole | null | undefined> | null | undefined;
};
export type RateCardUpdateInputRole = {
  charge_out_rate?: number | null | undefined;
  role_id: number;
};
export type RateCardUpdateMutation$variables = {
  input: RateCardUpdateInput;
};
export type RateCardUpdateMutation$data = {
  readonly action_rate_card_update: {
    readonly rate_card: {
      readonly blended_rate: number | null | undefined;
      readonly blended_rate_card: boolean;
      readonly description: string | null | undefined;
      readonly id: number;
      readonly name: string | null | undefined;
      readonly references: any;
      readonly role_charge_out_rates: ReadonlyArray<{
        readonly charge_out_rate: number | null | undefined;
        readonly rate_card_id: number;
        readonly role: {
          readonly id: number;
          readonly name: string | null | undefined;
        };
        readonly role_id: number;
      }>;
    } | null | undefined;
  };
};
export type RateCardUpdateMutation = {
  response: RateCardUpdateMutation$data;
  variables: RateCardUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RateCardUpdateOutput",
    "kind": "LinkedField",
    "name": "action_rate_card_update",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "rate_cards",
        "kind": "LinkedField",
        "name": "rate_card",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": "blended_rate",
            "args": null,
            "kind": "ScalarField",
            "name": "blended_rate_private",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blended_rate_card",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "references",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "role_charge_out_rates",
            "kind": "LinkedField",
            "name": "role_charge_out_rates",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rate_card_id",
                "storageKey": null
              },
              {
                "alias": "charge_out_rate",
                "args": null,
                "kind": "ScalarField",
                "name": "charge_out_rate_private",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "roles",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RateCardUpdateMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RateCardUpdateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "15fe0cc8127648b208deee944c0a50ed",
    "id": null,
    "metadata": {},
    "name": "RateCardUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation RateCardUpdateMutation(\n  $input: RateCardUpdateInput!\n) {\n  action_rate_card_update(input: $input) {\n    rate_card {\n      id\n      name\n      blended_rate: blended_rate_private\n      blended_rate_card\n      references\n      description\n      role_charge_out_rates {\n        role_id\n        rate_card_id\n        charge_out_rate: charge_out_rate_private\n        role {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "26e0161776c024e7883d60246d4b3634";

export default node;
