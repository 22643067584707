import React from "react"

const Charts = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.52552 5.04175H0.233843C0.107343 5.04175 0.00466919 5.14442 0.00466919 5.27092V10.7709C0.00466919 10.8974 0.107343 11.0001 0.233843 11.0001H2.52552C2.65202 11.0001 2.75469 10.8974 2.75469 10.7709V5.2709C2.75467 5.1444 2.65247 5.04175 2.52552 5.04175Z"
      fill="#707593"
    />
    <path
      d="M6.64119 0H4.34951C4.22301 0 4.12033 0.102674 4.12033 0.229174V10.7708C4.12033 10.8973 4.22301 11 4.34951 11H6.64119C6.76769 11 6.87036 10.8973 6.87036 10.7708V0.229174C6.87036 0.102674 6.76769 0 6.64119 0Z"
      fill="#707593"
    />
    <path
      d="M10.7662 2.29175H8.47454C8.34804 2.29175 8.24536 2.39442 8.24536 2.52092V10.7709C8.24536 10.8974 8.34804 11.0001 8.47454 11.0001H10.7662C10.8927 11.0001 10.9954 10.8974 10.9954 10.7709V2.52092C10.9954 2.3944 10.8927 2.29175 10.7662 2.29175Z"
      fill="#707593"
    />
  </svg>
)

export default Charts
